import {Component, Input, OnInit} from '@angular/core';
import {InterviewInfo} from '../../../../model/participant-details';
import {UserRole} from '../../../../../../../static/constants/user-role/user-role.enum';

@Component({
  selector: 'app-participant-interview',
  templateUrl: './participant-interview.component.html',
  styleUrls: ['./participant-interview.component.scss']
})
export class ParticipantInterviewComponent implements OnInit {

  @Input() interview: InterviewInfo;

  public extraInfo: any[];

  constructor() { }

  ngOnInit(): void {
    this.extraInfo = [
      {
        title: 'Interview Notes:',
        description: this.interview.comment
      },
      {
        title: 'Rejection Reason:',
        description: this.interview.rejectionReason
      }
    ];
  }
}
