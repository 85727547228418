import { Routes } from '@angular/router';
import { StaffProfileComponent } from '../../features/profile/component/staff-profile/staff-profile.component';
import { SourcesComponent } from '../../features/settings/sources/sources.component';
import { NotificationsComponent } from '../../features/settings/notifications/notifications.component';
import {
  NotificationsListSystemComponent
} from '../../.deprecated/component/notifications/notifications-list-system/notifications-list-system.component';
import {
  NotificationsListAnnouncementsComponent
} from '../../.deprecated/component/notifications/notifications-list-announcements/notifications-list-announcements.component';
import { AdminTeamComponent } from '../../features/team/component/admin-team/admin-team.component';
import { MatchingComponent } from '../../features/matching/component/matching/matching.component';
import {
  InterviewOverviewComponent
} from '../../features/interview/component/interview-overview/interview-overview.component';
import { InterviewListComponent } from '../../features/interview/component/interview-list/interview-list.component';
import { GroupSessionComponent } from '../../features/group-session/component/group-session/group-session.component';
import {
  GroupSessionParticipantsComponent
} from '../../features/group-session/component/group-session-participants/group-session-participants.component';
import {
  ParticipantDetailsComponent
} from '../../features/participants/manager/component/participant/participant-details/participant-details.component';
import {
  StaffAvailabilityComponent
} from '../../features/availability/component/staff-availability/staff-availability.component';
import { SettingsComponent } from '../../features/settings/settings.component';
import { ProgressLevelsComponent } from '../../features/settings/progress-levels/progress-levels.component';
import { WeAreDownComponent } from '@static/error-page/we-are-down/we-are-down.component';
import { NotFoundPageComponent } from '@static/error-page/not-found-page/not-found-page.component';
import { DatepickerComponent } from '@static/datepicker/datepicker.component';
import { ZoomComponent } from '@static/zoom/zoom/zoom.component';
import { LoginComponent } from '@static/login/component/login/login.component';
import { AcceptanceCriteriaComponent } from '../../features/settings/acceptance-criteria/acceptance-criteria.component';
import { ForgotPasswordComponent } from '@static/login/component/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from '@static/login/component/change-password/change-password.component';
import { PartnersComponent } from '../../features/settings/partners/partners.component';
import { PartnerComponent } from '../../features/settings/partners/partner/partner.component';
// import { TrainingComponent } from '../../features/training/training.component';
import { FailedMatchesComponent } from '../../features/developer/failed-matches/failed-matches.component';
import { ParticipantsComponent } from '../../features/participants/participants/participants.component';
import { DashboardComponent } from '../../features/dashboard/dashboard/dashboard.component';
import { UserRoleGuard } from '../../.deprecated/component/guards/user-role.guard';
import { UserRole } from '@static/constants/user-role/user-role.enum';
import { ImpactComponent } from '../../features/impact/component/impact.component';
import { ExportComponent } from '../../features/settings/export/export.component';
import { SubprogramsComponent } from '../../features/settings/subprograms/subprograms.component';
import { AvailabilityTrainingComponent } from '../../features/availability-training/availability-training.component';
import { TrainingListComponent } from '../../features/trainings/training-list/training-list.component';
import { TrainingOverviewComponent } from '../../features/trainings/training-overview/training-overview.component';

export class AppRoutes {
  public static allRoles: Routes = [
    {
      path: 'dashboard', // all
      component: DashboardComponent, // *
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.MATCHER, UserRole.INTERVIEWER, UserRole.MARKETER, UserRole.OLD_MATCHER, UserRole.TRAINER] }
    },
    {
      path: 'team', // admin
      component: AdminTeamComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'notifications', // admin
      component: NotificationsComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'notifications/email', // admin
      component: NotificationsComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER] }
    },
    {
      path: 'notifications/system', // admin
      component: NotificationsListSystemComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'notifications/announcement', // admin
      component: NotificationsListAnnouncementsComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'acceptance-criteria', // admin
      component: AcceptanceCriteriaComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'sources', // admin
      component: SourcesComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'partners', // admin
      component: PartnersComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'partners/:id', // admin
      component: PartnerComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'partners/:id/participants', // admin
      component: ParticipantsComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'developer/failed-matches',
      component: FailedMatchesComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.OLD_MATCHER] }
    },
    {
      path: 'participants', // admin, manager, partner
      component: ParticipantsComponent, // *
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.PARTNER] }
    },
    {
      path: 'participants/:id', // admin, manager
      component: ParticipantDetailsComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER] }
    },
    {
      path: 'participants/:id/:userRole', // admin, manager
      component: ParticipantDetailsComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER] }
    },
    {
      path: 'group-sessions', // admin, manager
      component: GroupSessionComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER] }
    },
    {
      path: 'group-sessions/:id', // admin, manager
      component: GroupSessionParticipantsComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER] }
    },
    {
      path: 'availability-training', // admin, trainer
      component: AvailabilityTrainingComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.TRAINER] }
    },
    {
      path: 'trainings', // admin, trainer
      component: TrainingListComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.TRAINER] }
    },
    {
      path: 'trainings/:id', // admin, trainer
      component: TrainingOverviewComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.TRAINER] }
    },
    {
      path: 'interviews', // admin, manager, interviewer
      component: InterviewListComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.INTERVIEWER] }
    },
    {
      path: 'interviews/:id', // admin, manager, interviewer
      component: InterviewOverviewComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.INTERVIEWER] }

    },
    {
      path: 'availability',  // admin, manager, interviewer
      component: StaffAvailabilityComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.INTERVIEWER] }
    },
    {
      path: 'matching', // admin, manager, matcher
      component: MatchingComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.MATCHER, UserRole.OLD_MATCHER] }
    },
    {
      path: 'developer/failed-matches',
      component: FailedMatchesComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.OLD_MATCHER] }
    },
    {
      path: 'login', // all
      component: LoginComponent
    },
    {
      path: 'profile', // all
      component: StaffProfileComponent
    },
    {
      path: 'datepicker', // all
      component: DatepickerComponent
    },
    {
      path: 'forgot-password', // all
      component: ForgotPasswordComponent
    },
    {
      path: 'change-password', // all
      component: ChangePasswordComponent
    },
    {
      path: 'group-session-manager-participants', // admin, manager
      component: GroupSessionParticipantsComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER] }
    },
    {
      path: 'settings', // admin, manager
      component: SettingsComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER] }
    },
    {
      path: 'exports', // admin, manager
      component: ExportComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER] }
    },
    {
      path: 'subprograms', // admin, manager
      component: SubprogramsComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN, UserRole.MANAGER] }
    },
    {
      path: 'impact',
      component: ImpactComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'resources', // admin
      loadChildren: () => import('src/app/features/resources/resources.module').then((m) => m.ResourcesModule),
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'announcements', // admin
      loadChildren: () => import('src/app/features/announcements/announcements.module').then((m) => m.AnnouncementsModule),
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'progress-levels', // admin
      component: ProgressLevelsComponent,
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'progress/:id',
      loadChildren: () => import('src/app/features/progress/progress.module').then((m) => m.ProgressModule),
      canActivate: [UserRoleGuard],
      data: { roles: [UserRole.ADMIN] }
    },
    {
      path: 'we-are-down', // all
      component: WeAreDownComponent
    },
    {
      path: 'not-found', // all
      component: NotFoundPageComponent
    },
    {
      path: 'zoom', // all
      component: ZoomComponent
    },
    {
      path: '', // all
      redirectTo: 'login',
      pathMatch: 'full'
    },
    {
      path: '**', // all
      redirectTo: 'not-found'
    }
  ];
}
