import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { MatchDto } from '../model/match-dto';
import { UserRole } from '../../../static/constants/user-role/user-role.enum';
import { FailedMatchCard } from '../component/failed-matches/failed-match-card';
import {
  ProfileAnswersData,
  ProfileQuestionsData,
  SelectedAnswer,
} from '../../participants/manager/model/profile-questions-data';
import {
  QuestionKey,
  StudentsQuestionId,
  VolunteerQuestionId,
} from '../../participants/manager/model/question-id-mapper';
import { ParticipantDto } from '../model/participant-dto';
import { ParticipantCardDto } from '../model/participant-card-dto';
import { PageableResponse } from '../../../shared/types/pageable.response';
import { StudentMiniGroupPreference } from '../../interview/model/participant-profile';

@Injectable({
  providedIn: 'root',
})
export class MatchingService {
  private _profileQuestionsData: ProfileQuestionsData[] = [];

  private static baseUrl = '/matching';

  constructor(private httpClient: HttpClient) {}

  public getAges(role: UserRole): Observable<number[]> {
    const params = new HttpParams().append('role', `${role.valueOf()}`);

    return this.httpClient.get<number[]>(`${MatchingService.baseUrl}/ages`, {
      params,
    });
  }

  public getStudentCards(
    page: number,
    age?: number | null,
    s?: string | null,
    sub?: number | null,
    id?: number | null,
    programFormat?: StudentMiniGroupPreference | null,
    englishLevel?: number | null
  ): Observable<{
    content: ParticipantCardDto[];
    totalElements: number;
  }> {
    return this.getParticipantCards(
      'students',
      page,
      age,
      s,
      sub,
      id,
      programFormat,
      englishLevel
    );
  }

  public getVolunteerCards(
    page: number,
    age?: number | null,
    s?: string | null,
    sub?: number | null,
    id?: number | null,
    programFormat?: StudentMiniGroupPreference | null,
    englishLevel?: number | null
  ): Observable<any> {
    return this.getParticipantCards(
      'volunteers',
      page,
      age,
      s,
      sub,
      id,
      programFormat,
      englishLevel
    );
  }

  private getParticipantCards(
    path: string,
    page: number,
    age?: number | null,
    s?: string | null,
    sub?: number | null,
    id?: number | null,
    programFormat?: StudentMiniGroupPreference | null,
    englishLevel?: number | null
  ): Observable<any> {
    let params = new HttpParams()
      .append('page', `${page}`)
      .append('size', `${10}`);

    if (id) {
      params = params.append('id', `${id}`);
    }

    if (age) {
      params = params.append('age', `${age}`);
    }

    if (sub) {
      params = params.append('sub', `${sub}`);
    }

    if (programFormat) {
      params = params.append('programFormat', `${programFormat}`);
    }

    if (englishLevel) {
      params = params.append('englishLevel', `${englishLevel}`);
    }

    if (s && s.trim().length > 0) {
      params = params.append('s', s);
    }

    return this.httpClient.get(`${MatchingService.baseUrl}/${path}`, {
      params,
    });
  }

  public getStudent(studentId: number): Observable<ParticipantDto> {
    return this.httpClient.get<ParticipantDto>(
      `${MatchingService.baseUrl}/students/${studentId}`
    );
  }

  public getVolunteer(volunteerId: number): Observable<any> {
    return this.httpClient.get(
      `${MatchingService.baseUrl}/volunteers/${volunteerId}`
    );
  }

  public match(studentId: number, volunteerId: number, groupId: number): Observable<any> {
    let dto: MatchDto = {
      studentId,
      volunteerId
    };
    if (groupId !== 0) {
      dto = { ...dto, groupId}
    }
    return this.httpClient.post(`${MatchingService.baseUrl}/match`, dto);
  }

  public previousMatch(
    studentId: number,
    volunteerId: number
  ): Observable<any> {
    return this.httpClient.get(
      `${MatchingService.baseUrl}/previous_match?studentId=${studentId}&volunteerId=${volunteerId}`
    );
  }

  public getMatchingGroups(): Observable<any> {
    return this.httpClient.get(`${MatchingService.baseUrl}/groups`);
  }

  public getFailed(): Observable<FailedMatchCard[]> {
    return this.httpClient.get<FailedMatchCard[]>(
      `${MatchingService.baseUrl}/failed`
    );
  }

  public resolveFailed(id: number): Observable<any> {
    return this.httpClient.patch(
      `${MatchingService.baseUrl}/failed/${id}`,
      null
    );
  }

  public getPossibleMatches(params: {
    participantId: number;
    roleId: UserRole.STUDENT | UserRole.VOLUNTEER;
    sub?: number;
    s?: string;
    age?: number;
    page?: number;
  }): Observable<PageableResponse<ParticipantCardDto>> {
    let httpParams = new HttpParams().append(
      'participantId',
      `${params.participantId}`
    );

    if (params.sub) {
      httpParams = httpParams.append('sub', `${params.sub}`);
    }

    if (params.s) {
      httpParams = httpParams.append('s', params.s);
    }

    if (params.age) {
      httpParams = httpParams.append('age', `${params.age}`);
    }

    if (params.page) {
      httpParams = httpParams.append('page', `${params.page}`);
    }

    httpParams = httpParams.append('role', params.roleId);

    return this.httpClient.get<PageableResponse<ParticipantCardDto>>(
      `${MatchingService.baseUrl}/possible-matches`,
      { params: httpParams }
    );
  }

  getQuestionsData(
    id: number,
    role: UserRole.STUDENT | UserRole.VOLUNTEER
  ): Observable<ProfileAnswersData> {
    return this.httpClient
      .get<ProfileQuestionsData[]>(`/answer/profile/${id}`, {
        params: { role: role },
      })
      .pipe(
        map((questions) => {
          this._profileQuestionsData = questions;

          return {
            experience: this.getAnswersCustom('experience', role)
              ? [
                  ...this.getSelectedAnswers('experience', role),
                  { value: this.getAnswersCustom('experience', role) },
                ]
              : this.getSelectedAnswers('experience', role),
            motivation: this.getAnswersCustom('motivation', role)
              ? [
                  ...this.getSelectedAnswers('motivation', role),
                  { value: this.getAnswersCustom('motivation', role) },
                ]
              : this.getSelectedAnswers('motivation', role),
            studentLevel: this.getSelectedAnswers('studentLevel', role) || null,
            hobbies: this.getSelectedAnswers('hobbies', role),
            academicInterests: this.getSelectedAnswers(
              'academicInterests',
              role
            ),
            availability: this.getSelectedAnswers('availability', role),
            studentsCount:
              this.getSelectedAnswers('studentsCount', role)[0] || null,
            english: this.getSelectedAnswers('english', role)?.[0] || null,
            genderPref:
              this.getSelectedAnswers('genderPref', role)?.[0] || null,
          };
        })
      );
  }

  private getSelectedAnswers(
    key: keyof ProfileAnswersData,
    role: UserRole.STUDENT | UserRole.VOLUNTEER
  ): SelectedAnswer[] {
    return this.getQuestion(key, role)?.selectedAnswers || [];
  }

  private getAnswersCustom(
    key: keyof ProfileAnswersData,
    role: UserRole.STUDENT | UserRole.VOLUNTEER
  ): string {
    return this.getQuestion(key, role)?.customAnswer || '';
  }

  public getQuestion(
    key: QuestionKey,
    role: UserRole.STUDENT | UserRole.VOLUNTEER
  ): ProfileQuestionsData | undefined {
    const keyToIdMapper =
      role === UserRole.VOLUNTEER ? VolunteerQuestionId : StudentsQuestionId;

    return this._profileQuestionsData.find(
      (question) => question.registrationQuestion.id === keyToIdMapper[key]
    );
  }
}
