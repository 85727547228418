<div class="container" *ngIf="participant?.profile && !!answersService.profileQuestionsData.length">
  <div class="panel">
    <div class="header-container">
      <span class="body-highlighted-text">Basic Information</span>
      <div class="buttons-container">
        <button class="secondary-button-icons" (click)="openChangeParticipantPasswordDialog(id)">
          <span class="material-icons">key</span>
        </button>
        <button class="secondary-button-icons" (click)="editProfile()">
          <span class="material-icons">edit</span>
        </button>
      </div>
    </div>
    <div class="info-container">
      <app-participant-profile [participant]="participant" [userRole]="currentUserRole"></app-participant-profile>
    </div>
  </div>

  <div class="panel" *ngIf="showBecomeVolunteerStepsInfo">
    <div class="header-container">
      <span class="body-highlighted-text">Become volunteer Info</span>
      <div class="buttons-container">
        <button class="secondary-button-icons" (click)="resetBecomeVolunteerStepsInfo()">
          <span class="material-icons">restart_alt</span>
        </button>
      </div>
    </div>
    <div class="info-container">
      <div class="become-volunteer-grid">
        <div>Step 1</div>
        <p>Update answers:</p>
        <div>{{ becomeVolunteerStep1Good ? '✅' : '❌'}}</div>
        <div>Step 2</div>
        <p>Schedule an Interview:</p>
        <div>
          {{ becomeVolunteerStep2Good ? '✅' : '❌'}}
          <span *ngIf="becomeVolunteerInfo?.interview && !becomeVolunteerStep2Good">
            Status: {{ becomeVolunteerInfo.interview.interviewStatus | interviewStatus }}
          </span>
        </div>
        <div>Step 3</div>
        <p>Complete the Interview:</p>
        <div>
          {{ becomeVolunteerStep3Good ? '✅' : '❌'}}
          <span *ngIf="becomeVolunteerStep2Good && !becomeVolunteerStep3Good">
            <span *ngIf="!interviewCompleted">
              Status: {{ becomeVolunteerInfo?.interview?.interviewStatus | interviewStatus }}
            </span>
            <span *ngIf="interviewCompleted">
              Decision: {{ becomeVolunteerInfo?.interview?.decision | interviewDecision }}
            </span>
          </span>
        </div>
        <div>Step 4</div>
        <p>Sign an Agreement:</p>
        <div>{{ becomeVolunteerInfo?.agreement ? '✅' : '❌'}}</div>
        <div>Step 5</div>
        <p>Training:</p>
        <div>{{ becomeVolunteerInfo?.training ? '✅' : '❌'}}</div>
      </div>
    </div>
  </div>


  <div class="panel">
    <div class="header-container">
      <span class="body-highlighted-text">Notes</span>
      <div class="buttons-container">
        <button class="secondary-button-icons" (click)="editComment()">
          <span class="material-icons">edit</span>
        </button>
      </div>
    </div>
    <div class="info-container">
      <app-participant-profile-notes [comment]="participant.comment"></app-participant-profile-notes>
    </div>
  </div>
</div>
<div class="container" *ngIf="participant && !!answersService.profileQuestionsData.length">
  <div class="panel">
    <div class="header-container">
      <span class="body-highlighted-text">Matching Profile</span>
      <div class="buttons-container">
        <button class="secondary-button-icons" (click)="editMatching()">
          <span class="material-icons">edit</span>
        </button>
      </div>
    </div>
    <div class="info-container">
      <app-participant-matching-settings
        [role]="currentUserRole"
        [profile]="participant.matchingProfile"
      ></app-participant-matching-settings>
    </div>
  </div>
  <div class="panel">
    <div class="header-container">
      <span class="body-highlighted-text">Automated Emails</span>
    </div>
    <div class="info-container">
      <app-participant-automated-emails
        [data]="participant.notifications"
      ></app-participant-automated-emails>
    </div>
  </div>
</div>
<div class="container" *ngIf="participant">
  <div class="panel">
    <div class="header-container">
      <span class="body-highlighted-text">Interview</span>
    </div>
    <div class="info-container">
      <app-participant-interview [interview]="participant.interview"></app-participant-interview>
    </div>
  </div>

  <div class="panel">
    <div class="header-container">
      <span class="body-highlighted-text">Interview History</span>
      <div class="buttons-container">
      </div>
    </div>
    <div class="info-container">
      <app-participant-history-block [data]="participant.interviewHistory"></app-participant-history-block>
    </div>
  </div>
  <div class="panel">
    <div class="header-container">
      <span class="body-highlighted-text">Impact measurement</span>
    </div>
    <div class="info-container">
      <app-participant-impact-measurement
        [id]="id"
        [userRole]="currentUserRole"
        [selfEvaluationTimestamps]="participant.selfEvaluationTimestamps"
        [studentEvaluationTimestamps]="participant.studentEvaluationTimestamps"
      ></app-participant-impact-measurement>
    </div>
  </div>
</div>
<div class="container" *ngIf="participant">
  <div class="panel">
    <div class="header-container">
      <span class="body-highlighted-text">Matching</span>
      <div id="matching-buttons-container" class="buttons-container" *ngIf="isVolunteer">
        <button class="secondary-button-icons" (click)="matchingHistory()" >
          <span class="material-icons">edit</span>
        </button>
      </div>
      <div *ngIf="isStudent" class="buttons-container">
        <button class="secondary-button-icons" (click)="openEditMiniGroupDialog()">
          <span class="material-icons">edit</span>
        </button>
      </div>
    </div>
    <div class="info-container">
      <app-participant-matching
        [id]="id"
        [userRole]="currentUserRole"
        [miniGroup]="participant.miniGroup"
        [matching]="participant.matching"
        (matchRemoved)="getParticipantDetails()"
      ></app-participant-matching>
    </div>
  </div>
  <div class="panel">
    <div class="header-container">
      <span class="body-highlighted-text">Previous Matches</span>
    </div>
    <div class="info-container">
      <app-participant-previous-matches
        [data]="participant.previousMatches"
      ></app-participant-previous-matches>
    </div>
  </div>
  <div class="panel">
    <div class="header-container">
      <span class="body-highlighted-text">Session history</span>
      <div id="sessions-buttons-container" class="buttons-container">
        <button class="secondary-button-icons" (click)="addSession()" >
          <span class="material-icons">edit</span>
        </button>
      </div>
    </div>
    <div class="info-container">
      <app-participant-session-history [history]="participant.sessionHistory"
                                       (sessionsChanged)="getParticipantDetails()"
                                       [participant]="participant">
      </app-participant-session-history>
    </div>
  </div>
</div>
<div class="panel">
  <div class="header-container"><span class="body-highlighted-text">Changes history</span>
  </div>
  <div class="info-container">
    <app-participant-changes-history [changes]="changes"></app-participant-changes-history>
  </div>
</div>
