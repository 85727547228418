import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EditSource } from './edit-source';
import { Source, SubSource } from './source';

@Injectable({
  providedIn: 'root'
})
export class SourcesService {

  constructor(private http: HttpClient) { }


  getSources(): Observable<Source[]> {
    return this.http.get<Source[]>('/settings/sources');
  }

  getParents(): Observable<SubSource[]> {
    return this.http.get<SubSource[]>('/settings/sources/parent');
  }

  getParticipantCount(): Observable<number> {
    return this.http.get<number>(`/settings/participants`);
  }

  editSource(id: number, dto: EditSource): Observable<any> {
    return this.http.patch(`/settings/sources/${id}`, dto);
  }

  isActiveSources(id: number): Observable<any> {
    return this.http.patch(`/settings/sources/${id}/toggle-active`, null);
  }

  createSource(dto: EditSource): Observable<any> {
    return this.http.post(`/settings/source`, dto);
  }
}
