import {Component, Input, OnInit} from '@angular/core';
import {
  EvaluationItem, EvaluationMappedData,
  SelfEvaluationTimestamps,
  StudentEvaluationTimestamp
} from '../../../../model/participant-details';
import moment from 'moment';
import {UserRole} from '@static/constants/user-role/user-role.enum';

@Component({
  selector: 'app-participant-impact-measurement',
  templateUrl: './participant-impact-measurement.component.html',
  styleUrls: ['./participant-impact-measurement.component.scss']
})
export class ParticipantImpactMeasurementComponent implements OnInit {
  @Input() selfEvaluationTimestamps: SelfEvaluationTimestamps;
  @Input() studentEvaluationTimestamps: StudentEvaluationTimestamp[];
  @Input() id: number;
  @Input() userRole: UserRole;

  mappedData: EvaluationMappedData = {
    completed: [],
    upcomingAndOverdue: [],
  };
  now = +moment();

  ngOnInit(): void {
    this.createData();
  }

  createData(): void {
    this.mappedData = this.getMappedData();
  }

  getMappedData(): EvaluationMappedData {
    const selfEvaluationData = this.getMappedSelfEvaluation();
    const studentEvaluationData = this.getMappedStudentEvaluation();
    //console.log('EvaluationMappedData');
    //console.log(selfEvaluationData);
    //console.log(studentEvaluationData);

    return [...selfEvaluationData, ...studentEvaluationData]
      .sort((a, b) => a.timestamp - b.timestamp)
      .reduce((acc, curr) => {
        if (curr.isPassed) {
          acc.completed.push(curr);
        } else {
          acc.upcomingAndOverdue.push(curr);
        }
        return acc;
      }, this.mappedData);
  }

  getMappedSelfEvaluation(): EvaluationItem[] {
    const initialTestDate = this.selfEvaluationTimestamps.initialTestDate * 1000;
    var threeMonthTestDate = this.selfEvaluationTimestamps.threeMonthTestDate * 1000;
    var nineMonthTestDate = this.selfEvaluationTimestamps.nineMonthTestDate * 1000;
    
    var result: EvaluationItem[] = [];
    if (initialTestDate) {
      threeMonthTestDate ||= +moment(initialTestDate).add(3, 'M');
      nineMonthTestDate ||= +moment(initialTestDate).add(9, 'M');
      result.push(
        {
          desc: 'Initial Self',
          timestamp: initialTestDate,
          isPassed: !!this.selfEvaluationTimestamps.initialTestDate,
          isOverdue: initialTestDate < this.now
        }
      );
    }
    if (threeMonthTestDate) {
      nineMonthTestDate ||= +moment(threeMonthTestDate).add(6, 'M');
      result.push(
        {
          desc: '3-Month Self',
          timestamp: threeMonthTestDate,
          isPassed: !!this.selfEvaluationTimestamps.threeMonthTestDate,
          isOverdue: threeMonthTestDate < this.now
        }
      );
    }
    if (nineMonthTestDate) {
      result.push(
        {
          desc: '9-Month Self',
          timestamp: nineMonthTestDate,
          isPassed: !!this.selfEvaluationTimestamps.nineMonthTestDate,
          isOverdue: nineMonthTestDate < this.now
        }
      );
    }
    return result;
  }

  getMappedStudentEvaluation(): EvaluationItem[] {
    var result: EvaluationItem[] = [];
    for (const item of this.studentEvaluationTimestamps ?? []) {
      const initialTestDate = item.initialTestDate * 1000;
      var threeMonthTestDate = (item.threeMonthTestDate * 1000);
      var nineMonthTestDate = (item.nineMonthTestDate * 1000);
      if (initialTestDate) {
        threeMonthTestDate ||= +moment(initialTestDate).add(3, 'M');
        nineMonthTestDate ||= +moment(initialTestDate).add(9, 'M');
        result.push(
          {
            desc: 'Initial Student',
            email: item.email,
            timestamp: initialTestDate,
            isPassed: !!item.initialTestDate
          }
        );
      }
      if (threeMonthTestDate) {
        nineMonthTestDate ||= +moment(threeMonthTestDate).add(6, 'M');
        result.push(
          {
            desc: '3-Month Student',
            email: item.email,
            timestamp: threeMonthTestDate,
            isPassed: !!item.threeMonthTestDate,
            isOverdue: threeMonthTestDate < this.now
          }
        );
      }
      if (nineMonthTestDate) {
        result.push(
          {
            desc: '9-Month Student',
            email: item.email,
            timestamp: nineMonthTestDate,
            isPassed: !!item.nineMonthTestDate,
            isOverdue: nineMonthTestDate < this.now
          }
        );
      }
    }
    return result;
  }

   get isStudent(): boolean {
    return this.userRole === UserRole.STUDENT;
  }
}
