<div class="photo-and-name-container">
  <img [src]="profile.avatarEnabled ? profile.avatar : '/assets/avatars/no-avatar.png'" alt="" class="avatar">
  <h5 class="name">{{ profile.firstName }} {{ profile.lastName }}</h5>

  <h6 class="name">
    {{ currentUserRole | userRole | capitalCase }}
    <a *ngIf="hasAdditionalProfile" [routerLink]="['/participants', participant.id, additionalProfileRole]">
      (has {{ additionalProfileRole | userRole }} profile)
    </a>
  </h6>

  <h5 class="body-regular-text">{{ profile.status | participantStatus | capitalCase }}</h5>
  <a class="link-text cursor-pointer" (click)="freezeAccount()">Freeze account</a>
  <a class="link-text cursor-pointer" (click)="disableAccount()">Deactivate account</a>
  <a class="delete-text" (click)="deleteAccountConfirm()">Delete account</a>
</div>

<div class="section">
  <span class="caption-text">Programs</span>
  <div class="subprogram" *ngFor="let subprogram of profile.subprograms">
    <div class="color-circle" [style.background-color]="subprogram.color"></div>
    <span class="body-regular-text">{{ subprogram.name }}</span>
  </div>
</div>

<div class="participant-info">
  <div *ngFor="let info of userInfo" class="info-block">
    <span class="material-icons">{{ info.icon }}</span>
    <span class="body-regular-text">{{ info.option | nullReplace }}</span>
  </div>
</div>
<div class="section">
  <span class="caption-text">Time zone:</span>
  <span class="body-regular-text">{{ profile.timeZone | timeZoneInfo | nullReplace }}</span>
</div>
<div class="section" *ngIf="isStudent">
  <app-payment-select [selectedPaymentRequirement]="profile.paymentRequirement"
                      (paymentRequirementChange)="paymentRequirementChange($event)"></app-payment-select>
</div>

<div *ngFor="let extraInfo of extraInfo" class="section">
  <span class="caption-text">{{ extraInfo.title }}</span>
  <span class="body-regular-text">{{ extraInfo.description | nullReplace }}</span>
</div>

<div class="applied-on">
  <span class="caption-text">Applied on:</span>
  <span class="body-regular-text">{{profile.applicationDate *1000 | date:'medium'}}</span>
</div>
