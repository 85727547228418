import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EditMessageAnnouncementWindowComponent} from '../modal-windows/edit-message-announcement-window/edit-message-announcement-window.component';

export interface interviewParticipant {
  image: string;
  subject: string;
  heading: string;
  body: string;
  recipient: string;
  action: string;
  lastUpdate: string;
  options: string;
}

const ELEMENT_DATA: interviewParticipant[] = [
  {
    image: '😥',
    subject: 'Registration results',
    heading: 'Lorem ipsum dolor sit amet',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent placerat leo in erat iaculis, sit amet varius odio facilisis.  Mauris suscipit...;',
    recipient: 'Student, Volunteer',
    action: 'Immediately',
    lastUpdate: 'Oct 23, 2020 16:00, Olive Green',
    options: '',
  }
];

@Component({
  selector: 'app-notifications-list-announcements',
  templateUrl: './notifications-list-announcements.component.html',
  styleUrls: ['./notifications-list-announcements.component.scss']
})
export class NotificationsListAnnouncementsComponent implements OnInit {
  displayedColumns: string[] = ['image', 'subject', 'heading', 'body', 'recipient', 'action', 'lastUpdate', 'options'];
  dataSource = ELEMENT_DATA;

  constructor(private dialog: MatDialog) {
  }

  EditAnnouncementTamplate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    this.dialog.open(EditMessageAnnouncementWindowComponent, dialogConfig);
  }

  ngOnInit(): void {
  }

}
