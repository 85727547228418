import {Component, Input} from '@angular/core';
import {ParticipantNotification} from '../../../../model/participant-details';

@Component({
  selector: 'app-participant-automated-emails',
  templateUrl: './participant-automated-emails.component.html',
  styleUrls: ['./participant-automated-emails.component.scss']
})
export class ParticipantAutomatedEmailsComponent {
  @Input() data: ParticipantNotification[];
}
