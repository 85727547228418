import { Component, OnInit } from '@angular/core';
import { TrainerDashboardService } from '../../service/trainer-dashboard.service';
import { TrainerDashboardPipe } from '../../pipe/trainer-dashboard.pipe';
import { TrainerDashboard } from '../../model/dashboard';
import { Card } from '../../model/card';
import { Router } from '@angular/router';
import { TrainingFilterService } from '../../../../trainings/training-filter.service';
import { DatePipe } from '@angular/common';
import { TrainingStatus } from '../../../../availability-training/model/training';

@Component({
  selector: 'app-trainer-dashboard',
  templateUrl: './trainer-dashboard.component.html',
  styleUrls: ['./trainer-dashboard.component.scss']
})
export class TrainerDashboardComponent implements OnInit {

  cards: Card[];

  constructor(
    private service: TrainerDashboardService,
    private pipe: TrainerDashboardPipe,
    private datePipe: DatePipe,
    private trainingFilterService: TrainingFilterService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.service.getData().subscribe(
      (value: TrainerDashboard) => this.cards = this.pipe.transform(value),
    );
  }

  click(index: number): void {
    switch (index) {
      case 0:
        const dateStringToday = this.datePipe.transform(new Date(), 'yyyy-MM-dd') as string;
        this.trainingFilterService.filterByStatus = TrainingStatus.SCHEDULED;
        this.trainingFilterService.filterByStartDate = dateStringToday;
        this.trainingFilterService.filterByEndDate = dateStringToday;
        break;
      case 1:
        this.trainingFilterService.filterByStatus = TrainingStatus.SCHEDULED;
        this.trainingFilterService.filterByStartDate = null;
        this.trainingFilterService.filterByEndDate = null;
        break;
      case 3:
        this.trainingFilterService.filterByStatus = TrainingStatus.COMPLETED;
        this.trainingFilterService.filterByStartDate = null;
        this.trainingFilterService.filterByEndDate = null;
        break;
      default: return;
    }
    this.router.navigateByUrl('/trainings');
  }
}
