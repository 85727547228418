import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SessionService } from '../../../../service/session.service';
import { NotifierService } from 'angular-notifier';
import { HttpErrorResponse } from '@angular/common/http';
import { UserRole } from '../../../../../../../static/constants/user-role/user-role.enum';
import { ScheduleSession, SessionEditDto, SessionInfoDto } from '../../../../model/sessions';
import { CurrentMatchCard, ParticipantDetails } from '../../../../model/participant-details';
import * as moment from 'moment';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SessionsDuration } from '../../../../../../../shared/consts/sessions.duration';

@Component({
  selector: 'app-schedule-session',
  templateUrl: './schedule-session.component.html',
  styleUrls: ['./schedule-session.component.scss']
})
export class ScheduleSessionComponent implements OnInit, OnDestroy {

  participantId: number;
  participantDetails: ParticipantDetails;
  participants: Partial<CurrentMatchCard>[];
  pastParticipants: CurrentMatchCard[];

  subs = new Subscription();
  sessionDuration = SessionsDuration;

  formGroup = new FormGroup({
    startDate: new FormControl(null, Validators.required),
    startTime: new FormControl(null, Validators.required),
    participantIds: new FormControl([]),
    pastParticipantIds: new FormControl([]),
    instructions: new FormControl(),
    repeatTillControl: new FormControl(),
    repeatTillDate: new FormControl(),
    duration: new FormControl(null, Validators.required),
    approved: new FormControl(null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      participantId: number,
      participantDetails: ParticipantDetails,
      session?: SessionInfoDto
    },
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private service: SessionService,
    private notifier: NotifierService
  ) {
    this.formGroup.get('participantIds')?.addValidators(
      this.requiredIfAnotherEmpty(this.formGroup.get('pastParticipantIds'))
    );
  }

  requiredIfAnotherEmpty(anotherControl: AbstractControl | null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.haveValue(control.value)) {
        return null;
      }

      if (anotherControl && this.haveValue(anotherControl.value)) {
        return null;
      }

      return { requiredAnotherEmpty: true };
    };
  }

  haveValue(value: any): boolean {
    return Array.isArray(value) ? value.length > 0 : value; 
  }

  get haveAnyParticipants(): boolean {
    return this.participants?.length > 0 || this.pastParticipants?.length > 0;
  }

  ngOnInit(): void {
    this.subs.add(this.formGroup.get('pastParticipantIds')?.valueChanges.subscribe({
        next: () => this.formGroup.get('participantIds')?.updateValueAndValidity({emitEvent: false})
    }));

    this.participantId = this.data.participantId;
    this.participantDetails = this.data.participantDetails;
    this.participants = [... this.isStudent() ?
      this.participantDetails.matching.matchedVolunteers : this.participantDetails.matching.matchedStudents];

    // Past matched participants may repeat
    const pastMatchedByIdMap = new Map(
      (this.participantDetails.matching.pastMatchedStudents ?? [])
        .filter(match => !this.participants.some(p => p.participantId == match.participantId))
        .map(match => [match.participantId, match])
    );

    this.pastParticipants = [...pastMatchedByIdMap.values()];

    if (!this.data.session) {
      this.formGroup.patchValue({
        startDate: moment().format('YYYY-MM-DD'),
        startTime: moment().format('HH:mm'),
        participantIds: [],
        pastParticipantIds: [],
        instructions: '',
      });
    } else {
      const ids = this.data.session.participants.map(p => p.id);

      this.formGroup.patchValue({
        startDate: moment(this.data.session.startDate * 1000).format('YYYY-MM-DD'),
        startTime: moment(this.data.session.startDate * 1000).format('HH:mm'),
        participantIds: ids,
        pastParticipantIds: ids,
        instructions: this.data.session.instructions,
        duration: this.data.session.duration,
        approved: this.data.session.approved !== undefined ? this.data.session.approved : null,
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

  checkSelected(option, selection): boolean {
    return selection?.length ? selection.includes(option) : option === selection;
  }

  save(): void {
    const repeatTill = this.formGroup.value.repeatTillControl ?
      new Date(moment(this.formGroup.value.repeatTillDate).format('YYYY-MM-DD') + ' 23:59:59').getTime() / 1000 : undefined;
    const studentIds = this.isStudent() ? [this.participantId] : [...this.formGroup.value.participantIds ?? [], ...this.formGroup.value.pastParticipantIds ?? []];
    const startTime = new Date(moment(this.formGroup.value.startDate).format('YYYY-MM-DD') + ' ' + this.formGroup.value.startTime).getTime() / 1000;

    if (this.data.session === undefined) {
      const dto: ScheduleSession = {
        volunteerId: this.isStudent() ? this.formGroup.value.participantIds : this.participantId,
        studentIds,
        startTime,
        url: '',
        instructions: this.formGroup.value.instructions,
        repeatTill,
        duration: this.formGroup.value.duration,
        approved: this.formGroup.value.approved,
      };

      this.subs.add(this.service.schedule(dto).subscribe({
        next: () => {
          this.notifier.notify('success', 'Created successfully');
          this.dialogRef.close(true);
        },
        error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
      }));
    } else {
      const editDto: SessionEditDto = {
        startTime,
        studentIds,
        url: '',
        instructions: this.formGroup.value.instructions,
        duration: this.formGroup.value.duration,
        approved: this.formGroup.value.approved,
        repeatTill,
      };

      this.subs.add(this.service.updateSession(this.data.session.id, editDto).subscribe({
        next: () => {
          this.notifier.notify('success', 'Updated successfully');
          this.dialogRef.close(true);
        },
        error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
      }));
    }

  }

  isStudent(): boolean {
    return this.participantDetails.userRole === UserRole.STUDENT;
  }

  get isSessionInFuture(): boolean {
    const startDate = new Date(this.formGroup.get('startDate')?.value);
    const startTime = this.formGroup.get('startTime')?.value;

    if (startTime) {
      const [hours, minutes] = startTime.split(':').map(Number);
      startDate.setHours(hours);
      startDate.setMinutes(minutes);
    } else {
      startDate.setHours(0, 0, 0, 0);
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return startDate.getTime() > currentDate.getTime();
  }

}
