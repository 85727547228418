<form class="wrapper">
  <h4>Log in 📝</h4>
  <div class="inputs-container">
    <span class="overline-text">E-mail</span>
    <input name="email" type="email" [(ngModel)]="email">
  </div>
  <div class="inputs-container">
    <span class="overline-text">Password</span>
    <input name="password" type="password" [(ngModel)]="password">
  </div>
  <button class="primary-button2" (click)="login()">Log in</button>
  <a class="link-text" routerLink="/forgot-password">Forgot password</a>
</form>
