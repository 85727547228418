import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-participant-profile-notes',
  templateUrl: './participant-profile-notes.component.html',
  styleUrls: ['./participant-profile-notes.component.scss']
})
export class ParticipantProfileNotesComponent implements OnInit {

  constructor() { }

  @Input() comment: string;

  ngOnInit(): void {
  }

}
