import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SubprogramCard} from './subprogram-card';
import {Subprogram} from './edit-subprogram/subprogram';
import {EditSubprogram} from './edit-subprogram/edit-subprogram';

@Injectable({
  providedIn: 'root'
})
export class SubprogramsService {

  BASE_URL = '/sub-programs';

  constructor(private http: HttpClient) { }

  getAll(): Observable<SubprogramCard[]> {
    return this.http.get<SubprogramCard[]>(`${this.BASE_URL}/list`);
  }

  create(dto: Subprogram): Observable<any> {
    return this.http.post(this.BASE_URL, dto);
  }

  edit(dto: EditSubprogram): Observable<any> {
    return this.http.patch(this.BASE_URL, dto);
  }
}
