import { Component, OnInit } from '@angular/core';
import {AcceptanceCriteria} from './acceptance-criteria';
import {UserRole} from '../../../static/constants/user-role/user-role.enum';
import {SettingsService} from '../settings.service';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-program',
  templateUrl: './acceptance-criteria.component.html',
  styleUrls: ['./acceptance-criteria.component.scss']
})
export class AcceptanceCriteriaComponent implements OnInit {

  role: UserRole;
  criteria: AcceptanceCriteria;

  constructor(
    private service: SettingsService,
    private notifier: NotifierService
  ) {
    this.criteria = {
      studentMaxAge: 0,
      studentMinAge: 0,
      studentMinTest: 0,
      volunteerMaxAge: 0,
      volunteerMinAge: 0,
      volunteerMinTraining: 0,
      studentsUnderEighteenLimit: 0,
      studentsAfterEighteenLimit: 0,
      volunteersUnderEighteenLimit: 0,
      volunteersAfterEighteenLimit: 0
    };
  }

  ngOnInit(): void {
    this.service.getAcceptanceCriteria().subscribe(
      (value: AcceptanceCriteria) => this.criteria = value,
      _ => this.notifier.notify('error', 'Error loading content')
    );
  }

  setCriteria(): void {
    this.service.setAcceptanceCriteria(this.criteria).subscribe(
      _ => this.notifier.notify('success', 'Saved'),
      _ => this.notifier.notify('error', 'Error loading content')
    );
  }
}
