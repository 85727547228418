import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditSourceComponent } from './edit-source/edit-source.component';
import { SourcesService } from './sources.service';
import { Source } from './source';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { SourceCard } from './source-card';
import { EMPTY, map, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-source-of-users',
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss']
})
export class SourcesComponent implements OnInit {
  displayedColumns: string[] = ['name', 'participants', 'percent', 'parentName', 'userRole', 'options'];
  sources: SourceCard[];

  editSource(source?: Source): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    dialogConfig.data = source;
    const ref = this.dialog.open(EditSourceComponent, dialogConfig);
    ref.afterClosed().subscribe(_ => this.getSources());
  }

  constructor(
    private dialog: MatDialog,
    private service: SourcesService,
    private notifier: NotifierService
  ) {
    this.sources = [];
  }

  ngOnInit(): void {
    this.getSources();
  }

  private getSources(): void {
    this.service.getSources().pipe(
      switchMap((sources) =>
        this.service.getParticipantCount().pipe(
          map((participantCount) =>
            sources.map((source) => ({
              id: source.id,
              name: source.name,
              participants: source.participants,
              percent: source.participants / participantCount * 100,
              parentName: source.parentName,
              userRole: source.userRole,
              active: source.active
            }))
          )
        )
      ),
      catchError((error: HttpErrorResponse) => {
        this.notifier.notify('error', error.error.message);
        return EMPTY; // Return an empty observable to prevent breaking the stream
      })
    ).subscribe(
      (processedSources) => {
        this.sources = processedSources;
      }
    );
  }


  isActiveSources(id: number): void {
    this.service.isActiveSources(id).subscribe({
      next: _ => {
        const source = this.sources.find(it => it.id === id);
        const index = this.sources.indexOf(source);
        source.active = !source.active;
        this.sources[index] = source;
        this.sources = [...this.sources];
      },
      error: error => this.notifier.notify('error', error.error.message)
    });
  }

}
