import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CalendarComponent} from './calendar.component';
import {MomentPipe} from './utils/pipes/moment.pipe';

@NgModule({
  declarations: [CalendarComponent, MomentPipe],
  imports: [CommonModule],
  exports: [CalendarComponent, MomentPipe],
})
export class CalendarModule {}
