import {Pipe, PipeTransform} from '@angular/core';
import {ParticipantStatus} from '../../../../../static/constants/participant-status/participant-status.enum';

@Pipe({
  name: 'partnerParticipantStatus'
})
export class PartnerParticipantStatusPipe implements PipeTransform {

  transform(value: ParticipantStatus, ...args: any[]): string {
    switch (value) {
      case ParticipantStatus.INACTIVE: return 'Неактивний';
      case ParticipantStatus.REJECTED_AGE_LOW: return 'Відхилено через недосягнення віку участі';
      case ParticipantStatus.REJECTED_AGE_HIGH: return 'Відхилено через перевищення віку участі';
      case ParticipantStatus.FAILED_TEST: return 'Відхилено через неуспішні результати тесту';
      case ParticipantStatus.DID_NOT_SCHEDULE_INTERVIEW: return 'Не заплановано інтерв`ю';
      case ParticipantStatus.FAILED_INTERVIEW_LIGHT:
      case ParticipantStatus.FAILED_INTERVIEW_STRICT: return 'Інтерв`ю не пройдено';
      case ParticipantStatus.DID_NOT_SIGN_AGREEMENT: return 'Не підписано згоду про участь';
      case ParticipantStatus.DROPOUT_PENDING:
      case ParticipantStatus.DROPPED_OUT: return 'Участь припинено за власним бажанням';
      case ParticipantStatus.KICKED_OUT: return 'Участь припинено за рішенням Програми';
      case ParticipantStatus.REJECTED_NOT_FROM_UKRAINE: return 'Відхилено через те, що учасник/ця не з України';
      case ParticipantStatus.ON_BOARDING:
      case ParticipantStatus.PROFILE_IN_PROGRESS: return 'Профіль в процесі заповнення';
      case ParticipantStatus.PROFILE_COMPLETED: return 'Профіль заповнено';
      case ParticipantStatus.TEST_IN_PROGRESS: return 'В процесі проходження тесту';
      case ParticipantStatus.TEST_COMPLETED: return 'У процесі заповнення профілю';
      case ParticipantStatus.INTERVIEW_SCHEDULED:
      case ParticipantStatus.INTERVIEW_IN_PROGRESS: return 'Заплановано інтерв`ю';
      case ParticipantStatus.INTERVIEW_CANCELLED_BY_PARTICIPANT: return 'Інтерв`ю скасовано за бажанням учасника/ці';
      case ParticipantStatus.INTERVIEW_CANCELLED_BY_INTERVIEWER: return 'Інтерв`ю скасовано за бажанням інтерв`юєра/ки';
      case ParticipantStatus.INTERVIEW_NO_SHOW_PARTICIPANT: return 'Учасник/ця не з`явився/сь на інтерв`ю';
      case ParticipantStatus.INTERVIEW_NO_SHOW_INTERVIEWER: return 'Інтерв`юєр/ка не з`явився/лась на інтерв`ю';
      case ParticipantStatus.INTERVIEW_COMPLETED: return 'Інтерв`ю пройдено';
      case ParticipantStatus.AGREEMENT_PENDING: return 'Триває підписання згоди про участь';
      case ParticipantStatus.TRAINING_PENDING: return 'Триває планування зустрічі знайомства з ENGin';
      case ParticipantStatus.TRAINING_SCHEDULED: return 'Зустріч знайомство з ENGin заплановано';
      case ParticipantStatus.MATCHED: return 'Поєднано з волонтером/кою';
      case ParticipantStatus.WAITING_FOR_MATCH: return 'Очікує на поєднання з волонтером/кою';
      case ParticipantStatus.ON_THE_WAITLIST: return 'У листі очікування';
      case ParticipantStatus.REQUESTED_REMATCH: return 'Подано запит на зміну волонтера/ки';
      default: return value.toString();
    }
  }
}
