import {Component, Input} from '@angular/core';
import {ParticipantPreviousMatches} from '../../../../model/participant-details';

@Component({
  selector: 'app-participant-previous-matches',
  templateUrl: './participant-previous-matches.component.html',
  styleUrls: ['./participant-previous-matches.component.scss']
})
export class ParticipantPreviousMatchesComponent {
  @Input() data: ParticipantPreviousMatches[];
}
