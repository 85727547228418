import {Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, OnChanges} from '@angular/core';
import {ParticipantCardDto} from '../../model/participant-card-dto';
import {UserInfoService} from '../../../../static/login/service/user-info.service';
import {UserRole} from '../../../../static/constants/user-role/user-role.enum';
import { PaymentRequirement, PaymentRequirementMap } from '../../../participants/manager/model/payment.types';

@Component({
  selector: 'app-matching-table',
  templateUrl: './matching-table.component.html',
  styleUrls: ['./matching-table.component.scss']
})
export class MatchingTableComponent implements OnInit, OnChanges {
  public readonly PaymentRequirement = PaymentRequirement;
  columnNames: string[] = [
    'lastName',
    'firstName',
    'daysWaiting',
    'genderPreference',
    'age',
    'color'
  ];

  @Output() pageChanged: EventEmitter<number>;
  @Output() cardClick: EventEmitter<number>;
  @Input() data: ParticipantCardDto[];
  @Input() length: number;

  UserRole = UserRole;
  role: UserRole;
  paymentRequirementMap = PaymentRequirementMap;

  constructor(
    private cdr: ChangeDetectorRef,
    roleSelectorService: UserInfoService
  ) {
    this.pageChanged = new EventEmitter<number>();
    this.cardClick = new EventEmitter<number>();
    this.role = roleSelectorService.getRole();
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.cdr.detectChanges();
  }

  public passId(id: number): void {
    this.cardClick.emit(id);
  }

  public emitPage(page: number): void {
    this.pageChanged.emit(page);
  }


}
