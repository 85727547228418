import {Component, OnInit} from '@angular/core';
import {StaffProfileService} from '../../service/staff-profile.service';
import {Profile} from '../../model/profile';
import {UserInfoService} from '../../../../static/login/service/user-info.service';
import {UserRole} from '../../../../static/constants/user-role/user-role.enum';
import {elementSelectors} from '@angular/material/schematics/ng-update/data';
import {PartnerProfile} from '../../model/partner-profile';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-staff-profile',
  templateUrl: './staff-profile.component.html',
  styleUrls: ['./staff-profile.component.scss']
})
export class StaffProfileComponent implements OnInit {
  public firstName: string;
  public lastName: string;
  public phone: string;
  public email: string;
  public zoom: string;
  public organizationName: string;
  public coordinatorName: string;
  public coordinatorEmail: string;
  public coordinatorPhone: string;
  public notes: string;
  public UserRole = UserRole;
  public role: UserRole;

  constructor(
    private service: StaffProfileService,
    private roleSelectorService: UserInfoService,
    private notifier: NotifierService
  ) {
    this.role = this.roleSelectorService.getRole();
  }

  ngOnInit(): void {
    if (this.roleSelectorService.getRole() === UserRole.PARTNER) {
      this.service.getProfile<PartnerProfile>().subscribe({
          next: value => {
            this.organizationName = value.organizationName;
            this.coordinatorName = value.coordinatorName;
            this.coordinatorEmail = value.coordinatorEmail;
            this.coordinatorPhone = value.phone;
            this.notes = value.notes;
          }
        }
      );
    } else {
      this.service.getProfile<Profile>().subscribe(
        (value: Profile) => {
          this.firstName = value.firstName;
          this.lastName = value.lastName;
          this.phone = value.phone;
          this.email = value.email;
          this.zoom = value.zoomEmail;
        }
      );
    }
  }

  changePartnerProfile(): void {
    const dto: PartnerProfile = {
      organizationName: this.organizationName,
      coordinatorName: this.coordinatorName,
      coordinatorEmail: this.coordinatorEmail,
      phone: this.coordinatorPhone,
      notes: this.notes
    };
    this.service.changePartnerProfile(dto).subscribe({
      next: _ => {
        this.notifier.notify('success', 'All changes saved');
      },
      error: err => {
        this.notifier.notify('error', err.error.message);
      }
    });
  }

  change(): void {
    const dto: Profile = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      zoomEmail: this.zoom,
    };
    this.service.change(dto).subscribe({
      next: _ => {
        this.notifier.notify('success', 'All changes saved');
      },
      error: err => {
        this.notifier.notify('error', err.error.message);
      }
    });
  }

  buttonSaved(): void {
    if (this.roleSelectorService.getRole() === UserRole.PARTNER) {
      this.changePartnerProfile();
    } else {
      this.change();
    }
  }

  changeStaffProfile(): void {
    this.service.changeFirstName(this.firstName).subscribe(
    );
    this.service.changeLastName(this.lastName).subscribe(
    );
    this.service.changePhone(this.phone).subscribe(
    );
  }
}
