<div class="panel">
  <h3 class="dashboard-block-title">{{ title }}</h3>
  <div *ngFor="let item of diagram.data; let i = index" class="container">
    <div class="options-container-wrapper">
      <div class="options-container">
        <div class="color-block"
        [ngStyle]="{
          'background-color': getColor(i)
        }"
        ></div>
        <h5 class="option-title dashboard-content-text">{{ item.name }}</h5>
      </div>
    </div>
    <div class="diagram-container">
      <div class="option-diagram-container">
        <div
          class="option-diagram"
          [ngStyle]="{
           'grid-template-columns': getGridColumns(item.count)
          }"
        >
          <div class="color-block"
          [ngStyle]="{
            'background-color': getColor(i)
          }"
          ></div>
          <h5 class="option-title percentage dashboard-content-text">
            {{ getLabel(item.count) }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>

