import {Component, Input, OnInit} from '@angular/core';
import {ChangesHistory, SessionHistory} from '../../../../model/participant-details';

@Component({
  selector: 'app-participant-changes-history',
  templateUrl: './participant-changes-history.component.html',
  styleUrls: ['./participant-changes-history.component.scss']
})
export class ParticipantChangesHistoryComponent implements OnInit {

  @Input() changes: ChangesHistory[];
  constructor() { }

  ngOnInit(): void {
  }

}
