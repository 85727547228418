import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {ParticipantService} from '../../service/participant.service';
import {ParticipantCard} from '../../model/participant-card';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {InviteParticipantComponent} from '../invite-participant/invite-participant.component';
import {Role} from '../../../../../static/login/model/role';
import {UserInfoService} from '../../../../../static/login/service/user-info.service';
import {UserRole} from '../../../../../static/constants/user-role/user-role.enum';

@Component({
  selector: 'app-partner-participants',
  templateUrl: './partner-participants.component.html',
  styleUrls: ['./partner-participants.component.scss']
})
export class PartnerParticipantsComponent implements OnInit {

  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'status',
  ];

  constructor(
    private service: ParticipantService,
    private userInfoService: UserInfoService,
    private dialog: MatDialog
  ) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() data: ParticipantCard[];
  @Input() id: number;

  ngOnInit(): void {
    this.getParticipants();
  }

  getParticipants(): void {
    switch (this.userInfoService.getRole()) {
      case UserRole.PARTNER:
        this.service.getAllParticipants().subscribe({
          next: (value: ParticipantCard[]) => {
            this.data = value;
          }
        });
        break;
      case UserRole.ADMIN:
        this.service.getAllParticipantsAdmin(this.id).subscribe({
          next: (value: ParticipantCard[]) => {
            this.data = value;
          }
        });
        break;
    }
  }

  invitePartner(): any {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    this.dialog.open(InviteParticipantComponent, dialogConfig);
  }

  getStatusColor(status: number): any {
    if (status > 299) {
      return 'green';
    } else if (status > 199) {
      return 'yellow';
    } else {
      return 'red';
    }
  }
}
