<div class="row">
  <span class="body-regular-text">
    {{
      (card.startDate * 1000 | date : "shortTime") +
        " - " +
        (card.endDate * 1000 | date : "shortTime")
    }}
  </span>
  <div>
    <div class="text-container">
      <span class="body-regular-text">
        {{ card.supervisorFirstName + " " + card.supervisorLastName }}
      </span>
    </div>
  </div>
  <div>
    <div class="text-container">
      <span class="body-regular-text">
        {{ card.type | trainingType }}
      </span>
    </div>
  </div>
</div>
