<div class="container">
  <h4 class="page-title">
    {{ page }}
  </h4>
  <div class="profile-container">
    <span class="body-regular-text">
      {{ name }}
    </span>
    <div class="img-container">
      <a routerLink="/profile">
        <img
          src="../../../../../assets/images/logo.png"
          alt=""
          class="profile-img"
        >
      </a>
    </div>
  </div>
</div>
