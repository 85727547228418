<div class="wrapper">
  <h4>Set password 📝</h4>
  <div class="inputs-container">
    <span class="overline-text">Password</span>
    <input [(ngModel)]="password1" placeholder="Enter your password" type="password">
    <input [(ngModel)]="password2" placeholder="Enter your password, again 😅" type="password">
  </div>
  <button class="primary-button2" (click)="changePassword()">Next</button>
  <a class="link-text" routerLink="/login">Back to login</a>
</div>
