import { NgModule } from '@angular/core';
import { PaymentSelectComponent } from './paymen-select.component';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PaymentSelectComponent
  ],
  imports: [
    MatInputModule,
    MatSelectModule,
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    PaymentSelectComponent
  ],
  providers: []
})
export class PaymentSelectModule { }
