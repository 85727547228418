<div class="left-side">
  <div class="calendar-container panel">
    <app-calendar
      [highlightedDays]="highlightedDates"
      [showAvailableSlots]="false"
    ></app-calendar>
  </div>
</div>
<div class="right-side panel">
  <div class="header">
    <div class="header-left-side header-block">
      <button
        (click)="goToday()"
        class="text-container secondary-button body-regular-text"
      >
        <span class="body-regular-text">Today</span>
      </button>
      <button class="secondary-button-icons" (click)="go(-1)">
        <span class="material-icons">arrow_back</span>
      </button>
      <button class="secondary-button-icons" (click)="go(1)">
        <span class="material-icons">arrow_forward</span>
      </button>
      <h5>{{ today * 1000 | date : "mediumDate" }}</h5>
    </div>
    <div class="header-right-side">
      <button class="primary-button" (click)="getCreateForm()" [disabled]="role !== UserRole.ADMIN">
        Create Training
      </button>
    </div>
  </div>
  <app-training-card
    *ngFor="let card of cards"
    [card]="card"
  ></app-training-card>
  <div *ngIf="!cards?.length">No trainings</div>
</div>
