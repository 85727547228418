<div class="wrapper" *ngIf="cards">
  <div
    *ngFor="let card of cards; index as i"
    [class]="'panel ' + (i < 3 ? 'block1' : 'block2')"
    (click)="click(i)"
  >
    <h2 class="data">{{ card.value | number:'1.0-2' }}</h2>
    <span class="overline-text title">{{ card.name }}</span>
  </div>
</div>
