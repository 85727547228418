import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PartnerOrganisationCard} from './partner-organisation-card';
import {PartnerOrganisation} from './partner-invite/partner-organisation';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  private static BASE_URL = '/admin/partner-organization';

  constructor(private http: HttpClient) { }

  getAll(s: string): Observable<PartnerOrganisationCard[]> {
    return this.http.get<PartnerOrganisationCard[]>(`${PartnerService.BASE_URL}/list`, {params: {s}});
  }

  addPartnerOrganisation(dto: PartnerOrganisation): Observable<any> {
    return this.http.post(`${PartnerService.BASE_URL}/save`, dto);
  }

  getPartnerOrganisation(id: number): Observable<PartnerOrganisation> {
    return this.http.get<PartnerOrganisation>(`${PartnerService.BASE_URL}/${id}`);
  }

  editPartnerOrganisation(id: number, dto: PartnerOrganisation): Observable<any> {
    return this.http.patch(`${PartnerService.BASE_URL}/${id}`, dto);
  }

  changePartnerPassword(partnerId: number, password: string): Observable<any> {
    return this.http.patch<any>(`/admin/staff/${partnerId}/partner-password`, {password});
  }

  public deletePartnerOrganisation(id: number): Observable<any> {
    return this.http.delete<any>(`${PartnerService.BASE_URL}/${id}`);
  }

  isActiveOrganization(id: number): Observable<any> {
    return this.http.patch(`${PartnerService.BASE_URL}/${id}/toggle-active`, null);
  }
}
