import { Pipe, PipeTransform } from '@angular/core';
import { InterviewType } from '../../features/interview/model/interview';

@Pipe({
  name: 'interviewTypeName'
})
export class InterviewTypeNamePipe implements PipeTransform {

  transform(value: InterviewType | undefined | null): unknown {
    switch(value) {
      case InterviewType.ONBOARDING:
        return 'Onboarding';
      case InterviewType.BECOME_VOLUNTEER:
        return 'Become Volunteer';
      default:
        return null;
    }
  }

}
