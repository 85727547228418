import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {InterviewDecision} from '../../../../../../../static/constants/interview-decision/interview-decision.enum';
import {InterviewHistoryCard} from '../../../../model/participant-details';

@Component({
  selector: 'app-participant-history-block',
  templateUrl: './participant-history-block.component.html',
  styleUrls: ['./participant-history-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantHistoryBlockComponent {
  public filteredData: InterviewHistoryCard[] = [];
  @Input() private set data(data: InterviewHistoryCard[]) {
    this.filteredData = this.showOnlyLast ? [this.filterLatestAcceptedInterview(data)] : data;
  }
  @Input() private showOnlyLast = false;

  private filterLatestAcceptedInterview(cards: InterviewHistoryCard[]): InterviewHistoryCard {
    return cards.reduce((result, item) => {
      if (item.status === InterviewDecision.ACCEPTED && (!result || item.date > result.date)) {
        return item;
      } else if (!result || item.date > result.date) {
        return item;
      }

      return result;
    });
  }
}
