import {ApexOptions} from 'ng-apexcharts';
import {IMPACT_MAP_CONFIG, REPLACE_VALUE} from '../entities/constants';
import {Impact} from '../entities/common.namespace';
import {ImpactApi} from '../entities/api.namespace';
import {retry} from 'rxjs';

export class ImpactServiceHelper {
  public static mapImpactData(
    {studentChartsData, volunteerChartsData}: ImpactApi.ChartsData
  ): Impact.Data {
    return {
      student: [
        ImpactServiceHelper.mapBarGroupData(
          studentChartsData.studentSelfAssessmentData,
          IMPACT_MAP_CONFIG.studentSelfAssessment
        ),
        ImpactServiceHelper.mapBarGroupData(
          studentChartsData.volunteerAssessmentOfStudentData,
          IMPACT_MAP_CONFIG.volunteerAssessmentOfStudent
        ),
        ImpactServiceHelper.getPieGroupChartData(
          studentChartsData.englishImprovementData,
          IMPACT_MAP_CONFIG.englishImprovement
        ),
        ImpactServiceHelper.getPieGroupChartData(
          studentChartsData.languageBarrierData,
          IMPACT_MAP_CONFIG.languageBarrier
        ),
        ImpactServiceHelper.getPieGroupChartData(
          studentChartsData.satisfactionWithProgramData,
          IMPACT_MAP_CONFIG.satisfactionWithProgram
        ),
        ImpactServiceHelper.mapBarData(
          studentChartsData.satisfactionWithOnboardingProcessData,
          IMPACT_MAP_CONFIG.satisfactionWithOnboardingProcessStudent
        ),
        ImpactServiceHelper.mapBarReversedData(
          studentChartsData.satisfactionWithStudentExperienceData,
          IMPACT_MAP_CONFIG.satisfactionWithStudentExperience
        ),
        ImpactServiceHelper.mapBarData(
          studentChartsData.satisfactionWithBuddy,
          IMPACT_MAP_CONFIG.satisfactionWithBuddy
        ),
      ],
      volunteer: [
        ImpactServiceHelper.mapBarGroupData(
          volunteerChartsData.volunteerSelfAssessmentData,
          IMPACT_MAP_CONFIG.volunteerSelfAssessment
        ),
        ImpactServiceHelper.getPieGroupChartData(
          volunteerChartsData.supportUkraineData,
          IMPACT_MAP_CONFIG.supportUkraine
        ),
        ImpactServiceHelper.getPieGroupChartData(
          volunteerChartsData.satisfactionWithProgramData,
          IMPACT_MAP_CONFIG.satisfactionWithProgram
        ),
        ImpactServiceHelper.mapBarData(
          volunteerChartsData.satisfactionWithOnboardingProcessData,
          IMPACT_MAP_CONFIG.satisfactionWithOnboardingProcessVolunteer
        ),
        ImpactServiceHelper.mapBarReversedData(
          volunteerChartsData.satisfactionWithVolunteerExperienceData,
          IMPACT_MAP_CONFIG.satisfactionWithVolunteerExperience
        ),
      ]
    };
  }

  private static mapPieData(
    data,
    config: Impact.Config
  ): Impact.Item {
    const series = config.seriesProps.map(seriesProps => data[seriesProps]);

    return {
      title: config.title,
      type: 'pie',
      chartData: ImpactServiceHelper.getPieChartData(series, config.categories as string[])
    };
  }

  private static mapBarData(
    data,
    config: Impact.Config
  ): Impact.Item {
    const series = [{
      name: 'Value',
      data: config.seriesProps.map((valueProp) => ImpactServiceHelper.parseNumber(data[valueProp]))
    }];

    return {
      title: config.title.replace(REPLACE_VALUE, data.n),
      type: 'bar',
      chartData: ImpactServiceHelper.getBarChartData(series, config.categories)
    };
  }

  private static mapBarReversedData(
    data,
    config: Impact.Config
  ): Impact.Item {
    const series = config.valueProps.map(valueProp => ({
      name: config.labels[valueProp],
      data: config.seriesProps.map(seriesProp => ImpactServiceHelper.parseNumber(data[valueProp][seriesProp]))
    }));

    return {
      title: config.title.replace(REPLACE_VALUE, `${data.threeMonth.n}/${data.nineMonth.n}`),
      type: 'bar',
      chartData: ImpactServiceHelper.getBarChartData(series, config.categories)
    };
  }

  private static mapBarGroupData(
    data,
    config: Impact.Config
  ): Impact.Item {
    const series = config.valueProps.map(valueProp => ({
        name: config.labels[valueProp],
        data: config.seriesProps.map(seriesProp => {
          return ImpactServiceHelper.parseNumber(data[seriesProp][valueProp]);
        })
      })
    );

    return {
      title: config.title.replace(REPLACE_VALUE, data.n),
      type: 'bar',
      chartData: ImpactServiceHelper.getBarChartData(series, config.categories)
    };
  }

  private static getPieGroupChartData(
    data,
    config: Impact.Config
  ): Impact.Item {
    return {
      title: config.title,
      type: 'groupPie',
      chartItems: config.valueProps.map(valueProp => {
        const title = config.groupTitles?.[valueProp]?.replace(REPLACE_VALUE, data[valueProp].n);
        const itemConfig = {
          ...config,
          title: title ?? ''
        };
        return ImpactServiceHelper.mapPieData(data[valueProp], itemConfig);
      })
    };
  }

  private static getPieChartData(
    series: ApexOptions['series'],
    labels: string[]
  ): ApexOptions {
    return {
      series,
      chart: {
        height: 400,
        type: 'pie'
      },
      labels,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ],
      legend: {
        position: 'bottom'
      }
    };
  }

  private static getBarChartData(
    series: ApexOptions['series'],
    categories: Impact.Config['categories']
  ): ApexOptions {
    return {
      series,
      chart: {
        type: 'bar',
        height: 370,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '80%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -25,
        style: {
          colors: ['#304758']
        }
      },
      stroke: {
        show: true,
        width: 3,
        colors: ['transparent']
      },
      xaxis: {
        categories,
        labels: {
          offsetY: -3
        },
      },
    };
  }

  private static parseNumber(value: number): number {
    return typeof (value) === 'number' ? +value.toFixed(2) : value;
  }
}
