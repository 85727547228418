import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject, map, switchMap} from 'rxjs';
import {AvailabilityStats} from '../features/availability/model/availability-stats';
import {DateService} from '../shared/data-access/date.service';
import {generateCalendarForDate} from './utils/generate-calendar-for-date';

@Component({
  selector: 'app-calendar',
  templateUrl: 'calendar.component.html',
  styleUrls: ['calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent {
  private highlightedDays$ = new BehaviorSubject<number[]>([]);
  @Input() public showAvailableSlots = false;
  @Input() public availabilityStats: AvailabilityStats = {
    us: 0,
    ukraine: 0,
    all: 0,
  };
  @Input() public set highlightedDays(days: number[]) {
    if (days && days.length) {
      this.highlightedDays$.next(days);
    }
  }
  @Output() public date = new EventEmitter<number>();
  @Output() public monthChanged = new EventEmitter<void>();

  public date$ = this.dateService.date$;
  public calendar$ = this.highlightedDays$.pipe(
    switchMap((highlightedDays) =>
      this.date$.pipe(map((dateNow) => generateCalendarForDate(dateNow, highlightedDays)))
    )
  );

  constructor(private dateService: DateService) {}

  public changeMonth(dir: number): void {
    this.dateService.changeMonth(dir);
    this.monthChanged.emit();
  }

  public selectDate(day: moment.Moment): void {
    this.dateService.changeDate(day);
  }
}
