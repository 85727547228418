import { Pipe, PipeTransform } from '@angular/core';
import {SelectedAnswer} from "../../../participants/manager/model/profile-questions-data";

@Pipe({
  name: 'selectedAnswerList'
})
export class SelectedAnswerListPipe implements PipeTransform {
  transform(answers: SelectedAnswer[] | null): string {
    return answers?.map((answer) => answer.value).join(', ') || ''
  }
}
