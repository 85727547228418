import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent {

  password1: string;
  password2: string;

  constructor(
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private notifier: NotifierService
  ) { }

  close(change: boolean): void {
    if (change) {
      if (this.password1 !== this.password2) {
        this.notifier.notify('error', 'Passwords don\'t match');
        return;
      }
      if (this.password1.length < 8) {
        this.notifier.notify('error', 'Password must be at least 8 characters');
        return;
      }
      this.dialogRef.close(this.password1);
    } else {
      this.dialogRef.close();
    }
  }
}
