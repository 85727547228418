
<div class="panel">
  <div class="caption-text" mat-dialog-title>
    {{ title }}
  </div>

  <div mat-dialog-content>
      {{ data.warning }}
  </div>

  <div class="buttons-container" mat-dialog-actions>
      <button (click)="close()" class="secondary-button">
        {{ data.cancelButtonName }}
      </button>
      <button (click)="confirm()" class="primary-button">
        {{ data.confirmButtonName }}
      </button>
  </div>
</div>

