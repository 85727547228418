import {Pipe, PipeTransform} from '@angular/core';
import {Moment} from 'moment';

export const DEFAULT_DATE_FORMAT = 'MMMM YYYY';

@Pipe({
  name: 'moment',
  pure: false,
})
export class MomentPipe implements PipeTransform {
  transform(m: Moment, format = DEFAULT_DATE_FORMAT): string {
    return m.format(format);
  }
}
