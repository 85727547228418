import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ParticipantDetails} from '../../../../model/participant-details';
import {UserRole} from '@static/constants/user-role/user-role.enum';
import {HttpErrorResponse} from '@angular/common/http';
import {NotifierService} from 'angular-notifier';
import {AnswersService} from '../../answers.service';
import {SelectedAnswer} from '../../../../model/profile-questions-data';
import memo from 'memo-decorator';
import {QuestionKey} from '../../../../model/question-id-mapper';
import { DayTimes, WeekDays } from '../../../../../../../shared/consts/time';

@Component({
  selector: 'app-edit-matching-profile',
  templateUrl: './edit-matching-profile.component.html',
  styleUrls: ['./edit-matching-profile.component.scss']
})
export class EditMatchingProfileComponent {
  academicInterests: SelectedAnswer[] = [];

  hobbies: SelectedAnswer[] = [];


  days = WeekDays;
  times = DayTimes;

  UserRole = UserRole;

  id: number;
  userRole: UserRole;

  academicInterestsArr: number[];
  hobbiesArr: number[];
  englishLevel?: number;
  availability: number[];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {participant: ParticipantDetails, id: number, userRole: UserRole},
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private notifier: NotifierService,
    private answersService: AnswersService
  ) {
    this.userRole = data.userRole;
    this.id = data.id;
    this.availability = answersService.profileAnswerData.availability.map(({id}) => id);
    this.academicInterestsArr = answersService.profileAnswerData.academicInterests.map(({id}) => id);
    this.hobbiesArr = answersService.profileAnswerData.hobbies.map(({id}) => id);
    this.englishLevel = answersService.profileAnswerData.english?.id;
  }

  onAvailabilityChange(value): void {
    this.availability = value;
  }

  get possibleAvailabilityAnswers(): SelectedAnswer[] {
    return this.getItemsByKey('availability');
  }

  save(): void {
    const answerDto = {
      academicInterests: this.academicInterestsArr,
      hobbies: this.hobbiesArr,
      english: this.englishLevel,
      availability: this.availability,
    };

    this.answersService.saveAnswers(this.id, answerDto).subscribe({
      next: _ => {
        this.notifier.notify('success', 'Updated successfully');
        this.dialogRef.close(true);
      },
      error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  @memo()
  public getItemsByKey(key: QuestionKey): SelectedAnswer[] {
    const question = this.answersService.getQuestion(key);

    return question ? question.registrationQuestion.possibleAnswers : [];
  }
}
