import { Component, OnInit } from '@angular/core';
import {InterviewerDashboardService} from '../../service/interviewer-dashboard.service';
import {DashboardPipe} from '../../pipe/dashboard.pipe';
import {Dashboard} from '../../model/dashboard';
import {Card} from '../../model/card';
import {InterviewStatus} from '../../../../../static/constants/interview-status/interview-status.enum';
import {Router} from '@angular/router';
import { InterviewFilterService } from '../../../../interview/interview-filter.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-interviewer-dashboard',
  templateUrl: './interviewer-dashboard.component.html',
  styleUrls: ['./interviewer-dashboard.component.scss']
})
export class InterviewerDashboardComponent implements OnInit {

  cards: Card[];

  constructor(
    private service: InterviewerDashboardService,
    private pipe: DashboardPipe,
    private datePipe: DatePipe,
    private interviewFilterService: InterviewFilterService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.service.getData().subscribe(
      (value: Dashboard) => this.cards = this.pipe.transform(value),
    );
  }

  click(index: number): void {
    switch (index) {
      case 0:
        const dateStringToday = this.datePipe.transform(new Date(), 'yyyy-MM-dd') as string;
        this.interviewFilterService.filterByStatus = InterviewStatus.SCHEDULED;
        this.interviewFilterService.filterByStartDate = dateStringToday;
        this.interviewFilterService.filterByEndDate = dateStringToday;
        break;
      case 1:
        this.interviewFilterService.filterByStatus = InterviewStatus.SCHEDULED;
        this.interviewFilterService.filterByStartDate = null;
        this.interviewFilterService.filterByEndDate = null;
        break;
      case 2:
        this.interviewFilterService.filterByStatus = InterviewStatus.PENDING_LOG;
        this.interviewFilterService.filterByStartDate = null;
        this.interviewFilterService.filterByEndDate = null;
        break;
      default: return;
    }
    this.router.navigateByUrl('/interviews');
  }
}
