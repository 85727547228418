import { Pipe, PipeTransform } from '@angular/core';
import { TrainingType } from '../../../features/availability-training/model/training';

@Pipe({
  name: 'trainingType'
})
export class TrainingTypePipe implements PipeTransform {

  transform(type: string): string {
    return type ? TrainingType[type] : null;
  }
}
