import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {PartnerProfile} from '../model/partner-profile';
import {Profile} from '../model/profile';

@Injectable({
  providedIn: 'root'
})
export class StaffProfileService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public getProfile<T>(): Observable<T> {
    return this.httpClient.get<T>('/account/profile');
  }

  public changePartnerProfile(dto: PartnerProfile): Observable<any> {
    return this.httpClient.put(`/account/partner-profile`, dto);
  }

  public change(dto: Profile): Observable<any> {
    return this.httpClient.put(`/account/profile`, dto);
  }

  public changeFirstName(firstName: string): Observable<any> {
    return this.httpClient.patch(
      '/account/first-name',
      firstName
    );
  }

  public changeLastName(lastName: string): Observable<any> {
    return this.httpClient.patch(
      '/account/last-name',
      lastName
    );
  }

  public changePhone(phone: string): Observable<any> {
    return this.httpClient.patch(
      '/account/phone',
      phone
    );
  }
}
