import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { NotifierService } from 'angular-notifier';

import { DateService } from '../../shared/data-access/date.service';
import { TrainingService } from '../trainings/training.service';
import { CreateTrainingComponent } from './create-training/create-training.component';
import { ScheduleTraining } from './model/schedule-training';
import { TrainingCard } from './model/training-card';
import { UserInfoService } from '../../static/login/service/user-info.service';
import { UserRole } from '../../static/constants/user-role/user-role.enum';


@Component({
  selector: 'app-availability-training',
  templateUrl: './availability-training.component.html',
  styleUrls: ['./availability-training.component.scss'],
})
export class AvailabilityTrainingComponent implements OnInit, OnDestroy {
  today: number;
  cards: TrainingCard[] = [];
  highlightedDates: number[];
  role: UserRole;

  private destroy$ = new Subject<void>();

  UserRole = UserRole;

  constructor(
    public dateService: DateService,
    private dialog: MatDialog,
    private trainingService: TrainingService,
    private userInfoService: UserInfoService,
    private notifier: NotifierService
  ) {
    this.role = this.userInfoService.getRole();
    this.highlightedDates = [];
  }

  ngOnInit(): void {
    this.dateService.date$.subscribe((next) => {
      this.today = next.startOf('day').unix();
      this.trainingService
        .getAllForDay(this.today)
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => (this.cards = value));
      this.highlightDays();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  go(dir: number): void {
    this.dateService.changeDay(dir);
  }

  goToday(): void {
    this.dateService.today();
  }

  getCreateForm(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '30vw';
    const dialogRef = this.dialog.open(CreateTrainingComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: ScheduleTraining) => {
        if (value) {
          this.trainingService
            .schedule(value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (_) => {
                this.notifier.notify('success', 'Scheduled successfully');
              },
              (err: HttpErrorResponse) =>
                this.notifier.notify('error', err.error.message)
            );
        }
      });
  }

  highlightDays(): void {
    this.trainingService
      .getDaysInMonth(
        this.dateService.date.year(),
        this.dateService.date.month() + 1,
        this.dateService.date.utcOffset() * 60
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value) => (this.highlightedDates = value),
        error: (err: HttpErrorResponse) =>
          this.notifier.notify('error', err.error.message),
      });
  }
}
