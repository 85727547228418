import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: 'input[numbersOnly]'
})
export class NumberDirective {
  @HostListener('input', ['$event']) onInputChange(event): void {
    event.target.value = event.target.value.replace(/[^0-9]*/g, '');
  }

}
