import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {InterviewService} from '../../service/interview.service';
import {InterviewCard} from '../../model/interview-card';
import {MatTableDataSource} from '@angular/material/table';
import {InterviewStatus} from '../../../../static/constants/interview-status/interview-status.enum';
import {FilterService} from '../../service/filter.service';
import {PageEvent} from '@angular/material/paginator';
import {InterviewList} from '../../model/interview-list';
import {UserRole} from '../../../../static/constants/user-role/user-role.enum';
import {InterviewDecision} from '../../../../static/constants/interview-decision/interview-decision.enum';
import {TeamMember} from '../../../availability/component/select-staff/team-member';
import {AvailabilityService} from '../../../availability/service/availability.service';
import {UserInfoService} from '../../../../static/login/service/user-info.service';
import {InterviewFilterService} from '../../interview-filter.service';
import { InterviewType } from '../../model/interview';

@Component({
  selector: 'app-interview-list',
  templateUrl: './interview-list.component.html',
  styleUrls: ['./interview-list.component.scss']
})

export class InterviewListComponent implements OnInit {

  @Output() cardClick: EventEmitter<number> = new EventEmitter<number>();

  UserRole = UserRole;
  InterviewType = InterviewType;

  role: UserRole;
  displayedColumns: string[];
  interviewStatuses: number[];
  teamMemberId: number;
  teamMembers: TeamMember[];
  decisions: number[];
  dataSource: MatTableDataSource<InterviewCard>;
  maxLength: number;
  pageIndex: number;
  pageSize: number;
  searchByName: string;
  filterByStartDate: string;
  filterByEndDate: string;
  filterByStatus: number;
  filterByUserRole: number;
  filterByDecision: number;
  filterByType: InterviewType | null;
  constructor(
    private service: InterviewService,
    private filterService: FilterService,
    private availability: AvailabilityService,
    private userInfoService: UserInfoService,
    private interviewFilterService: InterviewFilterService,
  ) {
    this.displayedColumns = [
      'interviewTime',
      'interviewDate',
      'photo',
      'lastName',
      'firstName',
      'age',
      'role',
      'interviewer',
      'decision',
      'type',
      'status'
    ];
    this.interviewStatuses = Object
      .values(InterviewStatus)
      .filter(value => typeof value === 'number') as number[];

    this.decisions = [
      100,
      200,
      201,
      302,
      301,
      303,
      304,
      305,
      306,
      300
    ];

    this.role = userInfoService.getRole();

    this.pageIndex = 0;
    this.pageSize = 7;
    this.searchByName = null;
    this.teamMemberId = null;
    this.filterByType = null;
  }

  ngOnInit(): void {
    this.searchByName = this.interviewFilterService.searchByName;
    this.filterByDecision = this.interviewFilterService.filterByDecision;
    this.filterByStatus = this.interviewFilterService.filterByStatus;
    this.filterByStartDate = this.interviewFilterService.filterByStartDate;
    this.filterByEndDate = this.interviewFilterService.filterByEndDate;
    this.filterByUserRole = this.interviewFilterService.filterByUserRole;
    this.teamMemberId = this.interviewFilterService.teamMemberId;
    this.filterByType = this.interviewFilterService.filterByType;
    this.getInterviews();
    if (this.role === UserRole.MANAGER || this.role === UserRole.ADMIN) {
      this.availability.getInterviewerNames().subscribe(
        (value) => this.teamMembers = value
      );
    }
  }

  getInterviews(): void {
    const startDate = this.filterByStartDate ? new Date(this.filterByStartDate + 'T00:00').getTime() / 1000 : null;
    const endDate = this.filterByEndDate ? new Date(this.filterByEndDate + 'T00:00').getTime() / 1000 + (24 * 60 * 60) : null;
    this.service.getInterviews(
      this.pageIndex,
      this.pageSize,
      this.filterByStatus,
      this.filterByUserRole,
      startDate,
      endDate,
      this.filterByDecision,
      this.teamMemberId,
      this.filterByType,
      this.searchByName,
    ).subscribe(
      (value: InterviewList) => {
        this.dataSource = new MatTableDataSource<InterviewCard>(value.list);
        this.maxLength = value.length;
      },
      error => console.error(error)
    );
  }

  doFilter(): void {
    this.getInterviews();
    this.interviewFilterService.searchByName = this.searchByName;
    this.interviewFilterService.filterByDecision = this.filterByDecision;
    this.interviewFilterService.filterByStatus = this.filterByStatus;
    this.interviewFilterService.filterByStartDate = this.filterByStartDate;
    this.interviewFilterService.filterByEndDate = this.filterByEndDate;
    this.interviewFilterService.filterByUserRole = this.filterByUserRole;
    this.interviewFilterService.teamMemberId = this.teamMemberId;
    this.interviewFilterService.filterByType = this.filterByType;
  }

  changePage(page: PageEvent): void {
    this.pageIndex = page.pageIndex;
    this.pageSize = page.pageSize;
    this.getInterviews();
  }
}
