<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Edit Availability and Matching Preferences</h6>
    <button class="secondary-button-icons close-button" (click)="close()">
      <span class="material-icons">close</span>
    </button>
  </div>
  <mat-button-toggle-group
    multiple
    [value]="availability"
    (change)="onChange(buttonsGroup.value)"
    #buttonsGroup="matButtonToggleGroup"
  >
    <table class="for-pc" style="width: 100%">
      <tbody>
        <tr>
          <td class="days overline-text-thin"></td>
          <td *ngFor="let day of days" class="days overline-text-thin">
            {{ day.short }}
          </td>
        </tr>
        <tr *ngFor="let time of times; index as i">
          <td class="time overline-text-thin">{{ time.name }}</td>
          <td *ngFor="let day of days; index as j" class="cell">
            <mat-button-toggle [value]="possibleAnswers[i * 7 + j].id">
              <span class="material-icons">done</span>
            </mat-button-toggle>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-button-toggle-group>

  <ng-container *ngIf="isVolunteer">
    <div class="d-flex flex-column mt-4">
      <label>Individual Sessions Format (one-on-one)</label>
      <mat-form-field appearance="outline" class="w-50">
        <mat-select
          [formControl]="volunteerIndividualSessionControl"
          class="no-global-input-styles"
        >
          <mat-option
            *ngFor="let session of volunteerIndividualSessions"
            [value]="session.id"
            >{{ session.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex flex-column">
      <label>Mini-Groups Sessions Format</label>
      <mat-form-field appearance="outline" class="w-50">
        <mat-select
          [formControl]="volunteerMiniGroupSessionControl"
          class="no-global-input-styles"
        >
          <mat-option
            *ngFor="let session of volunteerMiniGroupSessions"
            [value]="session.id"
            >{{ session.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <div *ngIf="isStudent" class="d-flex flex-column mt-4">
    <label>Individual Sessions Format (one-on-one)</label>
    <mat-form-field appearance="outline" class="w-50">
      <mat-select
        [formControl]="studentMiniGroupControl"
        class="no-global-input-styles"
      >
        <mat-option
          *ngFor="let group of studentMiniGroups"
          [value]="group.id"
          >{{ group.value }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <div class="buttons-container" mat-dialog-actions>
    <button class="secondary-button" (click)="close()">Cancel</button>
    <button class="primary-button2" cdkFocusInitial (click)="save()">
      Save
    </button>
  </div>
</div>
