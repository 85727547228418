import {Component, OnInit} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {SubprogramsService} from './subprograms.service';
import {SubprogramCard} from './subprogram-card';
import {HttpErrorResponse} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EditSubprogram} from './edit-subprogram/edit-subprogram';
import {EditSubprogramComponent} from './edit-subprogram/edit-subprogram.component';

@Component({
  selector: 'app-subprograms',
  templateUrl: './subprograms.component.html',
  styleUrls: ['./subprograms.component.scss']
})
export class SubprogramsComponent implements OnInit {

  displayedColumns: string[];
  subprograms: SubprogramCard[];

  constructor(
    private service: SubprogramsService,
    private notifier: NotifierService,
    private dialog: MatDialog,
  ) {
    this.displayedColumns = [
      'name',
      'description',
      'type',
      'color',
      'numberOfRegisteredStudents',
      'numberOfRegisteredVolunteers',
      'numberOfActiveStudents',
      'numberOfActiveVolunteers',
      'options',
    ];
  }

  ngOnInit(): void {
    this.getAll();
  }

  getAll(): void {
    this.service.getAll().subscribe({
      next: value => this.subprograms = value,
      error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
    });
  }

  edit(subprogram?: EditSubprogram): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    dialogConfig.data = subprogram;
    const ref = this.dialog.open(EditSubprogramComponent, dialogConfig);
    ref.afterClosed().subscribe(_ => this.getAll());
  }

  toggleActive(subprogram: EditSubprogram): void {
    this.service.edit({...subprogram, active: !subprogram.active}).subscribe({
      next: _ => this.notifier.notify('success', 'Changed successfully'),
      error: (error: HttpErrorResponse) => this.notifier.notify('success', error.error.message)
    });
  }
}
