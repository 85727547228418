import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Availability} from '../model/availability';
import {AvailabilityStats} from '../model/availability-stats';
import {TeamMember} from '../component/select-staff/team-member';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {

  constructor(private http: HttpClient) { }

  public getAll(day: number, offset: number, staffId: number): Observable<any[]> {

    let params = new HttpParams()
      .set('day', day)
      .set('offset', offset);

    if (staffId && !isNaN(staffId)) {
      params = params.set('staffId', staffId);
    }

    return this.http.get<any[]>('/account/availability', {params});
  }

  public addAll(dto: Availability, offset: number, staffId: number): Observable<any> {

    let params = new HttpParams()
      .set('offset', offset);

    if (staffId && !isNaN(staffId)) {
      params = params.set('staffId', staffId);
    }

    return this.http.post('/account/availability', dto, {params});
  }

  public clearDay(day: number, offset: number): Observable<any> {
    return this.http.delete('/account/availability', {params: {day, offset}});
  }

  public getCalendarAvailability(year: number, month: number, offset: number): Observable<number[]> {
    return this.http.get<number[]>('/account/calendar-availability', {params: {year, month, offset}});
  }

  public getStats(): Observable<AvailabilityStats> {
    return this.http.get<AvailabilityStats>('/account/availability/stats');
  }

  public getInterviewerNames(): Observable<TeamMember[]> {
    return this.http.get<TeamMember[]>('/admin/staff/interviewers');
  }
}
