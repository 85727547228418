import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {TeamService} from '../../service/team.service';
import {Router} from '@angular/router';
import {StaffCard} from '../../model/staff-card';
import {EditMemberType} from '../../constant/edit-member-type.enum';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EditSourceComponent} from '../../../settings/sources/edit-source/edit-source.component';
import {ChangePasswordDialogComponent} from '../change-password/change-password-dialog.component';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-team',
  templateUrl: './admin-team.component.html',
  styleUrls: ['./admin-team.component.scss']
})
export class AdminTeamComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['photo', 'lastName', 'firstName', 'email', 'phone', 'role', 'lastVisit', 'options'];
  dataSource: StaffCard[];
  searchText: string;
  showEditWindow: boolean;
  editWindowType: EditMemberType;
  editWindowHeading: string;
  editWindowSubmitButton: string;
  staff: StaffCard;
  totalElements: number;
  page: number;
  size: number;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private teamService: TeamService,
    private notifier: NotifierService
  ) {
    this.searchText = '';
    this.page = 0;
    this.size = 10;
  }


  ngOnInit(): void {
    this.refreshTable();
  }

  openInviteWindow(): void {
    this.editWindowType = EditMemberType.INVITE;
    this.editWindowHeading = 'Invite new team member';
    this.editWindowSubmitButton = 'Invite';
    this.showEditWindow = true;
  }

  openEditWindow(staff: StaffCard): void {
    this.editWindowType = EditMemberType.EDIT;
    this.editWindowHeading = 'Edit profile';
    this.editWindowSubmitButton = 'Save changes';
    this.staff = {...staff};
    this.showEditWindow = true;
  }

  closeEditWindow(submitted: boolean): void {
    if (submitted) {
      this.refreshTable();
    }
    this.showEditWindow = false;
  }

  openChangePasswordDialog(id: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    const ref = this.dialog.open(ChangePasswordDialogComponent, dialogConfig);
    ref.afterClosed().subscribe(password => {
      if (password) {
        this.teamService.changePasswordForTeamMember(id, password).subscribe({
          next: _ => this.notifier.notify('success', 'Updated successfully'),
          error: (e) => this.notifier.notify('error', e.error.message)
        });
      }
    });
  }

  delete(id): void {
    this.teamService.deleteStaffAccount(id).subscribe(
      _ => this.refreshTable(),
    );
  }

  refreshTable(): void {
    this.teamService.getStaffListItems(this.searchText, this.page, this.size).subscribe(
      (value) => {
        this.dataSource = value.list;
        this.totalElements = value.totalElements;
      }
    );
  }

  changePage(event: PageEvent): void {
    this.page = event.pageIndex;
    this.size = event.pageSize;
    this.refreshTable();
    this.dataSource = [];
  }
}
