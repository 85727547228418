<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Edit Notification</h6>
    <button (click)="close()" class="secondary-button-icons close-button">
      <span class="material-icons">close</span>
    </button>
  </div>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Notification name</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="wrapper">
        <div class="input-container container">
          <span class="overline-text">Name</span>
          <input [(ngModel)]="notification.name" type="text">
        </div>

      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>E-mail message</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="wrapper">

        <div class="input-container container">
          <span class="overline-text">Subject</span>
          <input [(ngModel)]="notification.email.subject" type="text">
        </div>

        <div class="input-container container">
          <span class="overline-text">Email Body</span>
          <textarea [(ngModel)]="notification.email.text" cols="30" name="" rows="10"></textarea>
        </div>

        <!--      <div class="input-container container">-->
        <!--        <span class="overline-text">Signature</span>-->
        <!--        <input type="text">-->
        <!--      </div>-->

        <!--      <div class="input-container container">-->
        <!--        <span class="body-highlighted-text">Recipients</span>-->
        <!--        <section>-->
        <!--          <mat-checkbox class="example-margin">Student</mat-checkbox>-->
        <!--          <mat-checkbox class="example-margin">Volunteer</mat-checkbox>-->
        <!--        </section>-->
        <!--      </div>-->

        <!--      <div class="input-container container">-->
        <!--        <span class="body-highlighted-text">Apply these message settings to: </span>-->
        <!--        <section>-->
        <!--          <mat-checkbox class="example-margin">Telegram</mat-checkbox>-->
        <!--        </section>-->
        <!--      </div>-->

        <!--      <div class="input-container container">-->
        <!--        <span class="body-highlighted-text">Script</span>-->
        <!--        <section>-->
        <!--          <input type="number"> <span class="body-regular-text">days after invitation</span>-->
        <!--        </section>-->
        <!--      </div>-->

      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Telegram
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="wrapper">

        <!--      <div class="input-container container">-->
        <!--        <span class="overline-text">Heading</span>-->
        <!--        <input type="text">-->
        <!--      </div>-->


        <div class="input-container container">
          <span class="overline-text">Text</span>
          <textarea [(ngModel)]="notification.telegram.text" cols="30" name="" rows="10"></textarea>
        </div>

        <!--      <div class="input-container container">-->
        <!--        <span class="overline-text">Signature</span>-->
        <!--        <input type="text">-->
        <!--      </div>-->

        <!--      <div class="input-container container">-->
        <!--        <span class="body-highlighted-text">Recipients</span>-->
        <!--        <section>-->
        <!--          <mat-checkbox class="example-margin">Student</mat-checkbox>-->
        <!--          <mat-checkbox class="example-margin">Volunteer</mat-checkbox>-->
        <!--        </section>-->
        <!--      </div>-->

        <!--      <div class="input-container container">-->
        <!--        <span class="body-highlighted-text">Apply these message settings to: </span>-->
        <!--        <section>-->
        <!--          <mat-checkbox class="example-margin">E-mail</mat-checkbox>-->
        <!--        </section>-->
        <!--      </div>-->

        <!--      <div class="input-container container">-->
        <!--        <span class="body-highlighted-text">Script</span>-->
        <!--        <section>-->
        <!--          <input type="number"> <span class="body-regular-text">days after invitation</span>-->
        <!--        </section>-->
        <!--      </div>-->

      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Allowed parameters
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="wrapper">
        <p>{{ notification.params?.join(', ') }}</p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="buttons-container" mat-dialog-actions>
    <button (click)="close()" class="secondary-button">Cancel</button>
    <button (click)="submit()" cdkFocusInitial class="primary-button2">Save</button>
  </div>
</div>
