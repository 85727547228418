import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminDashboardService {
  constructor(private httpClient: HttpClient) {
  }

  public getStudentData(): Observable<any> {
    return this.httpClient.get('/admin/dashboard/students');
  }

  public getVolunteerData(): Observable<any> {
    return this.httpClient.get('/admin/dashboard/volunteers');
  }

  public getInterviewData(period: number, size: number, page: number): Observable<any> {
    return this.httpClient.get('/admin/dashboard/interviews', {params: {period, size, page}});
  }

  public getDemographyData(role): Observable<any> {
    return this.httpClient.get(
      '/admin/dashboard/demography',
      {params: {role}}
    );
  }

  public getAgeData(role): Observable<any> {
    return this.httpClient.get(
      '/admin/dashboard/age',
      {params: {role}}
    );
  }

  public getTestData(): Observable<any> {
    return this.httpClient.get('/admin/dashboard/test');
  }

  public getGenderData(role): Observable<any> {
    return this.httpClient.get(
      '/admin/dashboard/gender',
      {params: {role}}
    );
  }

  public getEnglishLevelData(role): Observable<any> {
    return this.httpClient.get(
      '/admin/dashboard/english-level',
      {params: {role}}
    );
  }

  public getSourcesData(role): Observable<any> {
    return this.httpClient.get(
      '/admin/dashboard/sources',
      {params: {role}}
    );
  }

  public getMonthlySummary(role: number): Observable<any> {
    return this.httpClient.get('/admin/dashboard/monthly-summary', {params: {role}});
  }

  public getTimeToFirstMatch(role: number): Observable<any> {
    return this.httpClient.get('/admin/dashboard/first-match-time', {params: {role}});
  }
}
