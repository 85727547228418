<div id="wrapper">
  <div class="common-info">
    <div class="common-info-block">
      <span class="overline-text">Date</span>
    </div>
    <div class="common-info-block">
      <span class="overline-text">Modify by</span>
    </div>
    <div class="common-info-block">
      <span class="overline-text">Changes</span>
    </div>
  </div>
  <div class="container">
    <div class="history-section" *ngFor="let card of changes">
      <span class="body-regular-text date">{{card.revisionDate | date:'mediumDate'}}</span>
      <span class="body-regular-text date">{{card.modifyBy}}</span>
      <p *ngFor="let entry of card.changedFields | keyvalue">
          <span class="body-regular-text">
            {{entry.key}}
          </span>
        <span class="caption-text" *ngIf="entry.value ">
            {{entry.value.first * 1000 | date}} -> {{entry.value.second * 1000 | date}}
          </span>
      </p>
    </div>
  </div>
</div>
