export enum UserRole {
  STUDENT = 100,
  VOLUNTEER,
  ADMIN = 200,
  MANAGER,
  INTERVIEWER,
  MATCHER,
  MARKETER,
  PARTNER,
  OLD_MATCHER,
  TRAINER,
}
