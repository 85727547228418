export enum InterviewDecision {
  ACCEPTED = 100,
  NO_SHOW = 200,
  NO_SHOW_TECHNICAL_ISSUE,
  REJECTED = 300,
  REJECTED_SAFETY,
  REJECTED_ENGLISH,
  REJECTED_SCHEDULE,
  REJECTED_MOTIVATION,
  REJECTED_SOCIAL_SKILLS,
  REJECTED_BY_OPINION_ON_WAR
}
