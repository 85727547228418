import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { InterviewDetails } from '../model/interview-details';
import { environment } from '../../../../environments/environment';
import { MiniGroupPreference, ParticipantProfile } from '../model/participant-profile';
import { Interview, InterviewType } from '../model/interview';

@Injectable({
  providedIn: 'root',
})
export class InterviewService {
  constructor(private httpClient: HttpClient) {}

  public getInterviews(
    page: number,
    size: number,
    status?: number,
    role?: number,
    startDate?: number,
    endDate?: number,
    decision?: number,
    interviewerId?: number,
    type?: InterviewType,
    search?: string
  ): Observable<any> {
    let params = new HttpParams()
      .append('page', `${page}`)
      .append('size', `${size}`);

    if (status) {
      params = params.append('status', `${status}`);
    }

    if (role) {
      params = params.append('role', `${role}`);
    }

    if (startDate) {
      params = params.append('startDate', `${startDate}`);
    }

    if (endDate) {
      params = params.append('endDate', `${endDate}`);
    }

    if (decision) {
      params = params.append('decision', `${decision}`);
    }

    if (interviewerId) {
      params = params.append('interviewerId', `${interviewerId}`);
    }

    if (type) {
      params = params.append('type', type);
    }

    if (search) {
      params = params.append('s', `${search}`);
    }

    return this.httpClient.get(`/interview/list`, { params });
  }

  public getInterview(interviewId: number): Observable<Interview> {
    return this.httpClient.get<Interview>(`/interview/${interviewId}`);
  }

  public getParticipant(
    participantId: number,
    roleId?: number
  ): Observable<ParticipantProfile> {
    const options = roleId ? { params: { role: roleId } } : undefined;
    return this.httpClient.get<ParticipantProfile>(
      `/interview/participant/${participantId}`,
      options
    );
  }

  // Not used
  public deleteInterview(interviewId: number): Observable<any> {
    return this.httpClient.delete('/interview/' + interviewId);
  }

  public cancelInterview(interviewId: number): Observable<any> {
    return this.httpClient.patch(`/interview/${interviewId}/cancel`, null);
  }

  public setInterviewDetails(
    interviewId: number,
    dto: InterviewDetails
  ): Observable<any> {
    return this.httpClient.post(`/interview/${interviewId}/details`, dto);
  }

  public startInterview(id: number, date: number): void {
    const state = encodeURIComponent(JSON.stringify({ id, date }));
    window.open(
      `https://video-service-dev.enginprogram.org/start-interview?state=${state}`,
      '_blank'
    );
  }

  public changeInterviewer(
    interviewId: number,
    staffId: number
  ): Observable<any> {
    return this.httpClient.patch(`/interview/${interviewId}/staff`, {
      id: staffId,
    });
  }

  public updateParticipantMiniGroup(
    participantId: number,
    body: MiniGroupPreference
  ): Observable<any> {
    return this.httpClient.patch<ParticipantProfile>(
      `/interview/participant/${participantId}/mini-group`,
      body
    );
  }
}
