import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, Validators } from "@angular/forms";
import { ParticipantService } from '../../../../service/participant.service';
import { AddNewStatusDTO, UnfreezeDTO } from '../../../../model/sessions';
import { ParticipantStatus } from '../../../../../../../static/constants/participant-status/participant-status.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomValidators } from '../../../../../../../shared/validators/custom-validators';
import { UserRole } from '../../../../../../../static/constants/user-role/user-role.enum';

export interface FreezeDialogData {
  participantId: number,
  participantStatus: ParticipantStatus,
  participantRole: UserRole,
}

@UntilDestroy()
@Component({
  selector: 'app-participant-profile-freeze',
  templateUrl: './participant-profile-freeze.component.html',
  styleUrls: ['./participant-profile-freeze.component.scss'],
})
export class ParticipantProfileFreezeComponent implements OnInit {
  participantId: number;
  participantStatus: ParticipantStatus;
  participantRole: UserRole;
  formGroup = this.fb.group({
    startTime: [null, Validators.required],
    endTime: [null],
  }, { validator: CustomValidators.endDateLessThanStartDate('startTime', 'endTime') });
  maxDate = new Date();
  participantStatusKeys: ParticipantStatus[] = [
    ParticipantStatus.DROPOUT_PENDING,
    ParticipantStatus.DROPPED_OUT,
    ParticipantStatus.MATCHED,
    ParticipantStatus.WAITING_FOR_MATCH,
    ParticipantStatus.REQUESTED_REMATCH,
  ];
  freezeStartDate: Date;

  get isAccountFrozen(): boolean {
    return this.participantStatus === ParticipantStatus.INACTIVE_FROZEN;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: FreezeDialogData,
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private fb: FormBuilder,
    private service: ParticipantService,
    private notifier: NotifierService
  ) {
  }

  ngOnInit(): void {
    this.participantId = this.data.participantId;
    this.participantStatus = this.data.participantStatus;
    this.participantRole = this.data.participantRole;

    if (this.isAccountFrozen) {
      this.formGroup = this.fb.group({
        endTime: [null, Validators.required],
        newStatus: [null, Validators.required]
      });

      this.service.getStatusTimestamp(this.participantId, this.participantRole).pipe(
        untilDestroyed(this)
      ).subscribe(value => this.freezeStartDate = new Date(value))
    }
  }

  frozeAccount(): void {
    const data: AddNewStatusDTO = {
      newStatus: ParticipantStatus.INACTIVE_FROZEN,
      startTime: new Date(this.formGroup.controls.startTime.value).getTime() / 1000,
      endTime: this.formGroup.controls.endTime.value ? new Date(this.formGroup.controls.endTime.value).getTime() / 1000 : undefined,
    };
    this.service.freezeAccount(this.participantId, this.participantRole, data).pipe(
      untilDestroyed(this),
    ).subscribe({
      next: _ => {
        this.notifier.notify('success', 'Profile frozen successfully');
        this.dialogRef.close(true);
      },
      error: (error: HttpErrorResponse) => { 
        this.notifier.notify('error', error.error?.message);
        this.dialogRef.close(false);
      }
    });
  }

  unfreezeAccount(): void {
    const data: UnfreezeDTO = {
      newStatus: this.formGroup.controls.newStatus.value,
      endTime: new Date(this.formGroup.controls.endTime.value).getTime() / 1000,
    };
    this.service.unfreezeAccount(this.participantId, this.participantRole, data).pipe(
      untilDestroyed(this),
    ).subscribe({
      next: _ => {
        this.notifier.notify('success', 'Account status updated successfully');
        this.dialogRef.close(true);
      },
      error: (error: HttpErrorResponse) => { 
        this.notifier.notify('error', error.error?.message);
        this.dialogRef.close(false);
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
