<div class="left-side">
  <div class="calendar-container panel">
    <app-calendar
      [showAvailableSlots]="true"
      [availabilityStats]="availabilityStats"
      [highlightedDays]="highlightedDays"
      (monthChanged)="getCalendarAvailability()"
    ></app-calendar>
  </div>
  <app-select-staff
    *ngIf="role === UserRole.ADMIN"
    [all]="true"
    [button]="false"
    [initialId]="null"
    (selected)="changeTeamMemberId($event)"
  ></app-select-staff>
</div>
<div class="right-side panel">
  <div class="header">
    <div class="header-left-side header-block">
      <button
        (click)="goToday()"
        class="text-container secondary-button body-regular-text">
        <span class="body-regular-text">Today</span>
      </button>
      <button class="secondary-button-icons"
              (click)="go(-1)">
        <span class="material-icons">arrow_back</span>
      </button>
      <button class="secondary-button-icons"
              (click)="go(1)">
        <span class="material-icons">arrow_forward</span>
      </button>
      <h5>{{ today | date:'mediumDate' }}</h5>
    </div>
    <div class="header-right-side">
      <button class="secondary-button" (click)="clearDay()">Clear day</button>
      <button class="primary-button2" (click)="save()">Save</button>
    </div>
  </div>
  <table *ngIf="activeTimes && (role !== UserRole.ADMIN || currentTeamMemberId)">
    <tr class="cells-container" *ngFor="let row of rows">
      <td class="caption-text">{{ row * 1000 | date:'shortTime' }}</td>
      <td
        class="body-regular-text cell"
        [ngClass]="{'active': isActive(row)}"
        (click)="toggleSwitch(row)"
      >{{ (row * 1000 | date:'shortTime') + '-' + ((row + 1800) * 1000 | date:'shortTime') }}</td>
      <td
        class="body-regular-text cell"
        [ngClass]="{'active': isActive(row + 1800)}"
        (click)="toggleSwitch(row + 1800)"
      >{{ ((row + 1800) * 1000 | date:'shortTime') + '-' + ((row + 3600) * 1000 | date:'shortTime') }}</td>
    </tr>
  </table>
  <table *ngIf="activeTimes && role === UserRole.ADMIN && !currentTeamMemberId">
    <tr class="cells-container" *ngFor="let row of rows">
      <td class="caption-text">{{ row * 1000 | date:'shortTime' }}</td>
      <td
        class="body-regular-text cell"
        [ngClass]="{'active': isActive(row)}"
        (click)="toggleSwitch(row)"
      >{{ (row * 1000 | date:'shortTime') + '-' + ((row + 1800) * 1000 | date:'shortTime') }} 
       {{ isActive(row) ? activeTimesAdmin.get(row).join(', ') : '' }}</td>
      <td
        class="body-regular-text cell"
        [ngClass]="{'active': isActive(row + 1800)}"
        (click)="toggleSwitch(row + 1800)"
      >{{ ((row + 1800) * 1000 | date:'shortTime') + '-' + ((row + 3600) * 1000 | date:'shortTime') }} 
       {{ isActive(row+1800) ? activeTimesAdmin.get(row+1800).join(', ') : '' }}</td>
    </tr>
  </table>
</div>
