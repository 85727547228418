<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>{{ id ? 'Edit partner' : 'Invite Partner' }}</h6>
    <button class="secondary-button-icons close-button" (click)="onClose()">
      <span class="material-icons">close</span>
    </button>
  </div>
  <h1 class="thin-text" *ngIf="id && !partnerOrganisation">Loading...</h1>
  <input type="text" placeholder="Organisation name" [(ngModel)]="partnerOrganisation.organisationName">
  <input type="text" placeholder="Coordinator name" [(ngModel)]="partnerOrganisation.coordinatorName">
  <input type="email" placeholder="Coordinator email" [(ngModel)]="partnerOrganisation.coordinatorEmail">
  <input type="text" placeholder="Phone" [(ngModel)]="partnerOrganisation.phone">
  <input type="text" placeholder="Notes" [(ngModel)]="partnerOrganisation.notes">
  <button *ngIf="!id || partnerOrganisation" class="primary-button" (click)="save()">{{ id ? 'Save' : 'Send invite' }}</button>
</div>
