<div class="panel left-block">
  <app-interview-overview-profile
    *ngIf="participant"
    [interview]="interview"
    [participant]="participant"
    ></app-interview-overview-profile>
</div>
<div class="panel middle-block">
  <app-interview-overview-log
    *ngIf="id && details && interview"
    [id]="id"
    [interview]="interview"
    [participant]="participant"
  ></app-interview-overview-log>
</div>
<div class="right-block" *ngIf="interview">
  <div class="panel">
    <div class="block-header">
      <h5>{{ interview.date * 1000 | date:'shortTime' }}</h5>
      <span class="overline-text">{{ interview.date * 1000 | date }}</span>
      <span class="caption-text label">{{ interview.interviewStatus | interviewStatus }}</span>
    </div>
    <div class="confirmed-time">
      <mat-checkbox [checked]="interview.participantConfirmation" disabled></mat-checkbox><span class="caption-text">{{ interview.participantConfirmation ? 'Confirmed the time' : 'Didn\'t confirm the time' }}</span>
    </div>
    <div class="buttons-container" *ngIf="interview.interviewStatus === InterviewStatus.SCHEDULED || interview.joinUrl">
      <button
        class="primary-button2"
        *ngIf="interview.interviewStatus === InterviewStatus.SCHEDULED"
        [disabled]="isInterviewDisabled$ | async"
        (click)="start()"
      >Start</button>
      <button
        class="primary-button2"
        *ngIf="interview.interviewStatus === InterviewStatus.SCHEDULED"
        (click)="cancelInterview()"
      >Cancel Interview</button>
      <button
        class="secondary-button"
        *ngIf="interview.joinUrl"
        (click)="copyUrl()"
      >{{copyButtonText}}</button>
    </div>
  </div>
  <app-select-staff
    *ngIf="interview.interviewerId && (role === UserRole.MANAGER || role === UserRole.ADMIN)"
    [initialId]="interview.interviewerId"
    [button]="true"
    (selected)="changeInterviewer($event)"
  ></app-select-staff>
</div>
