import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import moment, {Moment} from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private _date$: BehaviorSubject<Moment> = new BehaviorSubject(moment());

  public get date$(): Observable<Moment> {
    return this._date$;
  }

  public get date(): Moment {
    return this._date$.value;
  }

  public get year(): number {
    return this.date.year();
  }

  public get month(): number {
    return this.date.month() + 1;
  }

  public get offset(): number {
    return this.date.utcOffset() * 60;
  }

  public changeMonth(dir: number): void {
    const value = this._date$.value.add(dir, 'month');
    this._date$.next(value);
  }

  public changeDay(dir: number): void {
    const value = this._date$.value.add(dir, 'day');
    this._date$.next(value);
  }

  public today(): void {
    this._date$.next(moment());
  }

  public changeDate(date: moment.Moment): void {
    const value = this._date$.value.set({
      date: date.date(),
      month: date.month(),
    });
    this._date$.next(value);
  }
}
