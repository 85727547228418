<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Запросити учасника</h6>
    <button class="secondary-button-icons close-button" (click)="onClose()">
      <span class="material-icons">close</span>
    </button>
  </div>
  <input type="email" placeholder="Email" [(ngModel)]="email">
  <button class="primary-button" (click)="invite()">Надіслати</button>
</div>
