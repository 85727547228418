import {Component, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {ScheduleSession} from '../../model/schedule-session';
import {Supervisor} from './supervisor';
import {GroupSessionService} from '../../service/group-session.service';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-group-session-create',
  templateUrl: './group-session-create.component.html',
  styleUrls: ['./group-session-create.component.scss']
})
export class GroupSessionCreateComponent implements OnInit {

  @Output() dateOutput: EventEmitter<string> = new EventEmitter<string>();

  supervisors: Supervisor[];
  volunteerSlots: number;
  studentSlots: number;
  supervisorId: number;
  startTime: string;
  endTime: string;
  date: string;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private service: GroupSessionService,
    private notifier: NotifierService
  ) {
    this.supervisors = [];
  }

  ngOnInit(): void {
    this.getSupervisors();
  }

  private getSupervisors(): void {
    this.service.getSupervisors().subscribe(
      (value) => this.supervisors = value
    );
  }

  changeEndTime(): void {
    const date = new Date((this.date ? this.date : '2000-01-01') + 'T' + this.startTime);
    date.setHours(date.getHours() + 1);
    this.endTime = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
  }

  onClose(schedule: boolean): void {
    if (schedule) {
      const startTime = new Date(this.date + 'T' + this.startTime).getTime() / 1000;
      if (startTime - (new Date().getTime() / 1000) < (3600 * 72)) {
        this.notifier.notify('warning', 'You can schedule interview only more than 72 hrs upfront');
        return;
      }
      const result: ScheduleSession = {
        studentSlots: this.studentSlots,
        volunteerSlots: this.volunteerSlots,
        supervisorId: this.supervisorId,
        startDate: startTime,
        endDate: new Date(this.date + 'T' + this.endTime).getTime() / 1000
      };
      this.dialogRef.close(result);
    } else {
      this.dialogRef.close();
    }
  }
}
