import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { NotifierService } from 'angular-notifier';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatchingService } from '../../service/matching.service';

@Component({
  selector: 'app-matching-dialog',
  templateUrl: './matching-dialog.component.html',
  styleUrls: ['./matching-dialog.component.scss'],
})
export class MatchingDialogComponent implements OnInit {
  groups: { id: number; name: string }[];

  groupControl: FormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private matchingService: MatchingService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.matchingService
      .getMatchingGroups()
      .subscribe(
        (groups) => (this.groups = [{ name: 'Individual', id: 0 }, ...groups])
      );
  }

  confirm(): void {
    this.dialogRef.close(this.groupControl.value);
  }

  close(): void {
    this.dialogRef.close();
  }
}
