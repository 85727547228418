import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ToolbarOption} from '../../model/toolbar-option';
import {Router} from '@angular/router';
import {UserInfoService} from '@static/login/service/user-info.service';
import {AuthService} from '@static/login/service/auth.service';
import {UserRole} from '@static/constants/user-role/user-role.enum';

@Component({
  selector: 'app-toolbar-menu',
  templateUrl: './toolbar-menu.component.html',
  styleUrls: ['./toolbar-menu.component.scss']
})
export class ToolbarMenuComponent implements OnInit {
  features: ToolbarOption[];
  isOpen: boolean;
  name: string;
  signOutName: string;

  constructor(
    private userInfoService: UserInfoService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isOpen = false;
    this.features = this.userInfoService.getFeatures();
    this.signOutName = this.userInfoService.getRole() === UserRole.PARTNER ? 'Вийти' : 'Sign out';
  }

  signOut(): void {
    this.authService.emit(false);
    this.userInfoService.removeToken();
    this.router.navigateByUrl('/login');
  }
}
