<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Edit {{ participant.userRole === UserRole.STUDENT ? 'Student' : 'Volunteer' }} Profile</h6>
    <button class="secondary-button-icons close-button" (click)="close()">
      <span class="material-icons">close</span>
    </button>
  </div>

  <mat-accordion>
    <div class="wrapper">
      <div class="input-container">
        <mat-form-field class="w-100">
          <mat-label>Status</mat-label>
          <mat-select [(value)]="participant.profile.status" panelClass="participant-status-options" class="no-global-input-styles">
            <mat-option *ngFor="let key of participantStatusKeys" [value]="key">{{ key | participantStatus }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-container">
        <mat-form-field class="w-100">
          <mat-label>First name</mat-label>
          <input matInput placeholder="First name" class="no-global-input-styles" [(ngModel)]="participant.profile.firstName">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="w-100">
          <mat-label>Last name</mat-label>
          <input matInput placeholder="Last name" class="no-global-input-styles" [(ngModel)]="participant.profile.lastName">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="w-100">
          <mat-label>Gender</mat-label>
          <mat-select [(value)]="participant.profile.gender" class="no-global-input-styles">
            <mat-option [value]="null">-</mat-option>
            <mat-option [value]="0">male</mat-option>
            <mat-option [value]="1">female</mat-option>
            <mat-option [value]="2">other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="w-100">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" class="no-global-input-styles" [(ngModel)]="participant.profile.email">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="w-100">
          <mat-label>Telegram</mat-label>
          <input matInput placeholder="Telegram" class="no-global-input-styles" [(ngModel)]="participant.profile.telegramId">
        </mat-form-field>
      </div>

      <div class="inputs-container container">
        <mat-form-field class="w-100">
          <mat-label>Date of birth</mat-label>
          <input
            matInput
            type="date"
            class="no-global-input-styles"
            [ngModel]="participant.profile.dateOfBirth * 1000 | date:'yyyy-MM-dd'"
            (ngModelChange)="setDateOfBirth($event)"
          >
        </mat-form-field>
      </div>



      <!-- UserRole.STUDENT -->
      <ng-container *ngIf="participant.userRole === UserRole.STUDENT">
        <!-- Country -->
        <div class="input-container container">
          <mat-form-field class="w-100">
            <mat-label>Country</mat-label>
            <mat-select [(value)]="participant.profile.currentCountry" class="no-global-input-styles">
              <mat-option *ngFor="let country of ECountries | keyvalue" [value]="country.value">{{ country.value }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Oblast -->
        <div class="input-container container">
          <mat-form-field class="w-100">
            <mat-label>Oblast'/State/Province</mat-label>
            <ng-container *ngIf="participant.profile.currentCountry === ECountries.UKRAINE; else elseNotUkraine">
              <mat-select [(value)]="participant.profile.currentState" class="no-global-input-styles">
                <mat-option *ngFor="let region of EUkraineRegions | keyvalue" [value]="region.value">{{ region.value }}</mat-option>
              </mat-select>
            </ng-container>
            <ng-template #elseNotUkraine>
              <input matInput placeholder="Oblast'/State/Province" class="no-global-input-styles" [(ngModel)]="participant.profile.currentState">
            </ng-template>
          </mat-form-field>
        </div>
        <!-- City -->
        <div class="input-container container">
          <mat-form-field class="w-100">
            <mat-label>City/Town/Village</mat-label>
            <input matInput placeholder="City" class="no-global-input-styles" [(ngModel)]="participant.profile.currentCity">
          </mat-form-field>
        </div>

        <div class="input-container">
          <app-payment-select [selectedPaymentRequirement]="participant?.profile?.paymentRequirement ? participant.profile.paymentRequirement : paymentRequirement.NEED_TO_PAY"
                              (paymentRequirementChange)="paymentRequirementChange($event)"></app-payment-select>
        </div>
        <div class="input-container">
          <mat-checkbox [formControl]="paidControl">{{paidControl.value === true ? 'Paid' : 'Not Paid'}}</mat-checkbox>
        </div>
      </ng-container>


      <!-- UserRole.VOLUNTEER -->
      <ng-container *ngIf="participant.userRole === UserRole.VOLUNTEER">
        <!-- Country -->
        <div class="input-container container">
          <mat-form-field class="w-100">
            <mat-label>Country</mat-label>
            <mat-select [(value)]="participant.profile.country" class="no-global-input-styles">
              <mat-option *ngFor="let country of ECountries | keyvalue" [value]="country.value">{{ country.value }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Oblast -->
        <div class="input-container container">
          <mat-form-field class="w-100">
            <mat-label>Oblast'/State/Province</mat-label>
            <ng-container *ngIf="participant.profile.country === ECountries.UNITED_STATES; else elseNotUS">
              <mat-select [(value)]="participant.profile.state" class="no-global-input-styles">
                <mat-option *ngFor="let region of EUSStates | keyvalue" [value]="region.value">{{ region.value }}</mat-option>
              </mat-select>
            </ng-container>
            <ng-template #elseNotUS>
              <input matInput placeholder="Oblast'/State/Province" class="no-global-input-styles" [(ngModel)]="participant.profile.state">
            </ng-template>
          </mat-form-field>
        </div>
        <!-- City -->
        <div class="input-container container">
          <mat-form-field class="w-100">
            <mat-label>City/Town/Village</mat-label>
            <input matInput placeholder="City" class="no-global-input-styles" [(ngModel)]="participant.profile.city">
          </mat-form-field>
        </div>
        <div class="input-container container">
          <ng-container *ngIf="participant.profile.country === ECountries.UNITED_STATES">
            <mat-form-field class="w-100">
              <mat-label>ZIP code</mat-label>
              <input matInput placeholder="ZIP code" class="no-global-input-styles"
                     [(ngModel)]="participant.profile.zipCode">
            </mat-form-field>
          </ng-container>
        </div>

      </ng-container>

      <mat-form-field class="w-100" *ngIf="timezoneList" appearance="standard">
        <input type="text"
               placeholder="Select your timezone"
               class="no-global-input-styles"
               aria-label="Timezone"
               matInput
               [formControl]="timezoneControl"
               [value]="selectedTimezone?.name"
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onTzOptionSelected($event)">
          <mat-option *ngFor="let zone of $filteredTimezones | async;"
                      [value]="zone.name">{{zone.name}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="input-container container">
        <mat-form-field class="w-100">
          <mat-label>Source</mat-label>
          <mat-select [(value)]="participant.profile.sourceId" class="no-global-input-styles">
            <mat-option *ngFor="let source of sources" [value]="source.id">{{ source.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- TODO: Commenting it for now, since we don't want to edit volunteer's motivation -->
      <!-- <div class="input-container container" *ngIf="participant.userRole === UserRole.VOLUNTEER">
        <mat-form-field class="w-100">
          <mat-label>Prior Experience</mat-label>
          <input matInput placeholder="Experience" class="no-global-input-styles" [(ngModel)]="participant.profile.experience">
        </mat-form-field>
      </div> -->

      <!-- TODO: Commenting it for now, since we don't want to edit volunteer's motivation -->
      <!-- <div class="input-container container" *ngIf="participant.userRole === UserRole.VOLUNTEER">
        <mat-form-field class="w-100">
          <mat-label>Motivation</mat-label>
          <input matInput placeholder="Motivation" class="no-global-input-styles" [(ngModel)]="participant.profile.motivation">
        </mat-form-field>
      </div> -->

      <mat-form-field appearance="standard">
        <mat-select multiple
                    placeholder="Subprograms"
                    [(ngModel)]="selectedSubprograms"
                    (ngModelChange)="onChangeSubprograms()" class="no-global-input-styles">
          <mat-option *ngFor="let subprogram of subprograms" [value]="subprogram.id">
            {{ subprogram.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-accordion>

  <div class="buttons-container" mat-dialog-actions>
    <button class="secondary-button" (click)="close()">Cancel</button>
    <button class="primary-button2" cdkFocusInitial (click)="save()">Save</button>
  </div>
</div>
