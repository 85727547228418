import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SignIn} from '../component/login/sign-in';
import {Password} from '../component/change-password/password';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) {
  }

  signIn(dto: SignIn): Observable<any> {
    return this.http.post('/sign-in', dto);
  }

  signOut(): Observable<any> {
    return this.http.get('/sign-out');
  }

  getRole(): Observable<any> {
    return this.http.get('/role');
  }

  forgotPassword(email: string): Observable<any> {
    email = encodeURIComponent(email);
    return this.http.post('/password', null, {params: {email}});
  }

  changePassword(dto: Password): Observable<any> {
    return this.http.patch('/change-password', dto);
  }
}
