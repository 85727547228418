<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Edit Volunteer Profile</h6>
    <button (click)="onClose()" class="secondary-button-icons close-button"><span class="material-icons">close</span>
    </button>
  </div>

  <app-message-announcement></app-message-announcement>

  <div class="buttons-container" mat-dialog-actions>
    <button (click)="onClose()" class="secondary-button">Cancel</button>
    <button cdkFocusInitial class="primary-button2">Save</button>
  </div>
</div>
