<div class="panel">
  <div class="input-container container">
    <span class="overline-text">Team member</span>
    <select class="form-control" [(ngModel)]="teamMemberId" (ngModelChange)="emitFromSelect()">
      <option *ngIf="all" [value]="null">All</option>
      <option *ngFor="let teamMember of teamMembers" [value]="teamMember.id">{{ teamMember.name }}</option>
    </select>
    <button *ngIf="button" class="primary-button" (click)="emit()">Save</button>
  </div>
</div>

