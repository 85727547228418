<div class="table">
  <div class="table-container">
    <table mat-table [dataSource]="data" *ngIf="data">

      <ng-container matColumnDef="lastName">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>Last name</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element?.lastName | nullReplace }}
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>First name</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element?.firstName | nullReplace }}
        </td>
      </ng-container>

      <ng-container matColumnDef="daysWaiting">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>Days waiting</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element?.daysWaiting | nullReplace }}
        </td>
      </ng-container>

      <ng-container matColumnDef="genderPreference">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>Gender preferences</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ role === UserRole.OLD_MATCHER ? (element?.genderPreference | nullReplace) : (element?.genderPreference | genderPreference | nullReplace) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="age">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>Age</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ role === UserRole.OLD_MATCHER ? (element?.dateOfBirth | nullReplace) : (element?.dateOfBirth | age | nullReplace) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="color">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>Color</th>
        <td  mat-cell *matCellDef="let element">
          <div class="colors-panel">
            <div class="color-circle" [style.background-color]="color"
                 *ngFor="let color of element?.subProgramColors"></div>
          </div>
        </td>
      </ng-container>


      <tr class="overline-text" mat-header-row *matHeaderRowDef="columnNames"></tr>
      <tr class="body-regular-text" mat-row
          *matRowDef="let row; columns: columnNames;"
          [class.highlight_p0]="row.paymentRequirement === PaymentRequirement.NO_NEED_P0"
          [matTooltip]="paymentRequirementMap[row.paymentRequirement]?.title"
          (click)="passId(row.id)"></tr>

    </table>
    <mat-paginator
      [pageSizeOptions]="[10]"
      [pageSize]="10"
      [length]="length"
      (page)="emitPage($event.pageIndex)"
    ></mat-paginator>
    <!--      showFirstLastButtons class="body-regular-text"-->
  </div>
</div>
