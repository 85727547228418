<app-dashboard-card
  heading="First match"
  [schema]="schema"
  [students]="firstMatchedStudents"
  [volunteers]="firstMatchedVolunteers"
></app-dashboard-card>
<app-dashboard-card
  heading="Rematch"
  [schema]="schema"
  [students]="rematchedStudents"
  [volunteers]="rematchedVolunteers"
></app-dashboard-card>
