import { Pipe, PipeTransform } from '@angular/core';
import {GenderPreference} from './gender-preference.enum';

@Pipe({
  name: 'genderPreference'
})
export class GenderPreferencePipe implements PipeTransform {

  transform(value: number | string, ...args: unknown[]): string {
    value = typeof value === 'string' ? parseInt(value, 10) : value;
    const text = GenderPreference[value];
    return text ? text.toLowerCase().replace('_', ' ') : null;
  }

}
