import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {

  private authorizationCodeKey = 'access_token';
  private accessTokenKey = 'access_token';
  private refreshTokenKey = 'refresh_token';

  private id = 'FoxXKsIPSI6j1vBdoGljYQ';
  private secret = 'G28XfGmoSsAx8k4iNKJK8GsFIJg9loOb';

  constructor(private http: HttpClient) {
  }

  get authorizationCode(): string {
    return localStorage.getItem(this.authorizationCodeKey);
  }

  set authorizationCode(authorizationCode: string) {
    localStorage.setItem(this.authorizationCodeKey, authorizationCode);
  }

  get accessToken(): string {
    return localStorage.getItem(this.accessTokenKey);
  }

  set accessToken(accessToken: string) {
    localStorage.setItem(this.accessTokenKey, accessToken);
  }

  get isAuthenticated(): boolean {
    return localStorage.getItem(this.accessTokenKey) !== null;
  }

  authenticate(): void {
    window.open(
      `https://zoom.us/oauth/authorize?client_id=FoxXKsIPSI6j1vBdoGljYQ&response_type=code&redirect_uri=${environment.zoomUrl}`,
      '_blank'
    );
  }

  getToken(): Observable<any> {
    return this.http.get('/zoom/token', {params: {code: this.authorizationCode}});
  }
}
