import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TeamService} from '../../service/team.service';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {EditMemberType} from '../../constant/edit-member-type.enum';
import {StaffCard} from '../../model/staff-card';
import {AccountService} from '../../service/account.service';
import {EditStaff} from '../../model/edit-staff';
import {NotifierService} from 'angular-notifier';
import {HttpErrorResponse} from '@angular/common/http';
import {RegisterStaffAccountDTO} from '../../../../.deprecated/dto/register-staff-account-dto';

@Component({
  selector: 'app-edit-member',
  templateUrl: './admin-team-edit-member.component.html',
  styleUrls: ['./admin-team-edit-member.component.scss']
})
export class AdminTeamEditMemberComponent implements OnInit {

  constructor(
    private router: Router,
    private teamService: TeamService,
    private fb: FormBuilder,
    private notifier: NotifierService
  ) {
  }

  @Input() type: EditMemberType;
  @Input() heading: string;
  @Input() submitButtonText: string;
  @Input() staff: StaffCard;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  // firstName: string;
  // lastName: string;
  // email: string;
  // phone: string;
  // role: number;
  // timeZone: number;

  staffForm = this.fb.group({
    firstName: ['', [
      Validators.required,
      Validators.minLength(2),
      Validators.pattern('[a-zA-Z ]*')
    ]],
    lastName: ['', [
      Validators.required,
      Validators.minLength(2),
      Validators.pattern('[a-zA-Z ]*')
    ]],
    role: [null, [
      Validators.required
    ]],
    email: ['', [
      Validators.required
      // Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')
    ]],
    phone: ['', [
      Validators.required,
      Validators.pattern('[- +()0-9]+')
      // Validators.pattern('[+(0-9]{1}[0-9 -0()]+[0-9)]{10,}')
    ]]
  });

  ngOnInit(): void {
    switch (this.type) {
      case EditMemberType.INVITE:
        this.heading = 'Invite new team member';
        this.submitButtonText = 'Invite';
        this.staff = {
          email: null,
          firstName: null,
          id: null,
          lastName: null,
          lastVisit: null,
          phone: null,
          photo: null,
          role: null,
          timeZone: null
        };
        this.staffForm.patchValue(this.staff);
        break;
      case EditMemberType.EDIT:
        this.heading = 'Edit team member profile';
        this.submitButtonText = 'Change';
        this.staffForm.patchValue(this.staff);
        break;
    }
  }

  submit(): void {
    switch (this.type) {
      case EditMemberType.INVITE:
        this.invite();
        break;
      case EditMemberType.EDIT:
        this.edit();
        break;
    }
  }

  invite(): void {
    this.teamService.registerStaffAccount(
      this.staff.firstName,
      this.staff.lastName,
      this.staff.role,
      this.staff.email,
      this.staff.phone
    ).subscribe(
      _ => {
        this.notifier.notify('success', 'Invited successfully');
        this.close(true);
      },
      (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
    );
  }

  // todo prevent duplicate sending, check if data was changed
  edit(): void {
    const dto: RegisterStaffAccountDTO = {
      email: this.staff.email,
      phone: this.staff.phone,
      firstName: this.staff.firstName,
      lastName: this.staff.lastName,
      role: this.staff.role
    };
    this.teamService.editStaffProfile(this.staff.id, dto).subscribe(
      _ => {
        this.notifier.notify('success', 'Updated successfully');
        this.close(true);
      },
      (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
    );
  }

  close(submitted: boolean): void {
    this.onClose.emit(submitted);
    this.staffForm.reset();
  }
}
