import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {

  onClose(): void {
    this.dialogRef.close();
  }

  constructor(private dialogRef: MatDialogRef<MatDialogRef<string>>) { }

  ngOnInit(): void {
  }

}
