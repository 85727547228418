export const StudentsQuestionId = {
  english: 2,
  academicInterests: 3,
  hobbies: 4,
  genderPref: 6,
  motivation: 5,
  availability: 7
} as const;

export const VolunteerQuestionId = {
  english: 10,
  academicInterests: 11,
  hobbies: 12,
  genderPref: 13,
  experience: 14,
  motivation: 15,
  studentLevel: 18,
  availability: 19,
  studentsCount: 17,
} as const;

export type QuestionKey = keyof typeof StudentsQuestionId | keyof typeof VolunteerQuestionId
