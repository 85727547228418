<div class="row" [routerLink]="['/group-sessions', card.id]">
  <span class="body-regular-text">
    {{ (card.startDate * 1000 | date:'shortTime') + ' - ' + (card.endDate * 1000 | date:'shortTime') }}
  </span>
  <div class="container">
    <img src="../../../../../assets/images/rick-astley.jpg" class="avatar-img">
    <div class="text-container">
      <span class="body-regular-text">
        {{ card.supervisor.firstName + ' ' + card.supervisor.lastName }}
      </span>
<!--      <span class="caption-text">volunteer</span>-->
    </div>
  </div>
  <div class="buttons-container">
    <button class="secondary-button">Cancel</button>
    <button class="primary-button2" (click)="start()">Start session</button>
  </div>
</div>
