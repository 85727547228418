import { Component, OnInit } from '@angular/core';
import { InterviewService } from '../../service/interview.service';
import { ParticipantProfile } from '../../model/participant-profile';
import { Interview, InterviewType } from '../../model/interview';
import { ActivatedRoute, Router } from '@angular/router';
import { InterviewDetails } from '../../model/interview-details';
import { ZoomService } from '@static/zoom/zoom.service';
import { InterviewStatus } from '@static/constants/interview-status/interview-status.enum';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotifierService } from 'angular-notifier';
import { HttpErrorResponse } from '@angular/common/http';
import { filter, interval, map, startWith, switchMap } from 'rxjs';
import moment from 'moment';
import { UserRole } from '../../../../static/constants/user-role/user-role.enum';
import { UserInfoService } from '../../../../static/login/service/user-info.service';
import { ConfirmWindowComponent } from '../../../matching/component/warning-window/confirm-window.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { PaymentRequirement } from '../../../participants/manager/model/payment.types';

@UntilDestroy()
@Component({
  selector: 'app-interview-overview',
  templateUrl: './interview-overview.component.html',
  styleUrls: ['./interview-overview.component.scss']
})
export class InterviewOverviewComponent implements OnInit {

  InterviewStatus = InterviewStatus;

  constructor(
    private service: InterviewService,
    private route: ActivatedRoute,
    private zoomService: ZoomService,
    private clipboard: Clipboard,
    private notifier: NotifierService,
    private router: Router,
    private userInfoService: UserInfoService,
    private dialog: MatDialog
  ) {
    this.role = this.userInfoService.getRole();
  }

  UserRole = UserRole;

  role: UserRole;
  id: number;
  startDate: number;
  participant: ParticipantProfile;
  interview: Interview;
  details: InterviewDetails;
  copyButtonText: string;
  isInterviewDisabled$ = interval(1000).pipe(
    startWith(true),
    map(this.isStartInterviewDisabled.bind(this))
  );

  ngOnInit(): void {
    this.copyButtonText = 'Copy link';
    this.route.params.subscribe(params => {
      this.id = +params.id;
      this.service.getInterview(this.id)
        .pipe(
          untilDestroyed(this),
          switchMap((interview: Interview) => {
            this.interview = interview;
            return this.service.getParticipant(this.interview.participantId,
              interview.type === InterviewType.BECOME_VOLUNTEER ? UserRole.VOLUNTEER : undefined);
          })
        )
        .subscribe((profile: ParticipantProfile) => {
          this.participant = profile;
          this.details = {
            comment: this.interview.comment,
            englishLevel: this.participant.englishLevel,
            interviewDecision: this.interview.decision,
            paymentRequirement: this.participant.paymentRequirement || PaymentRequirement.NO_NEED_REGISTERED_BEFORE_APR_15_2024,
            readyToBeVolunteer: this.participant.readyToBeVolunteer,
          };
          this.participant.location = [profile.city, profile.state, profile.country].filter(x => x != null).join(', ');
          this.participant.currentLocation = [profile.currentCity, profile.currentState, profile.currentCountry]
            .filter(x => x != null).join(', ');
        });
    });
  }

  isStartInterviewDisabled(): boolean {
    if (!this.interview?.date) {
      return true;
    }
    const date = this.interview.date * 1000;
    const min = moment(date).subtract(3, 'm');
    const max = moment(date).add(5, 'm');
    return !moment().isBetween(min, max);
  }

  start(): void {
    if (this.interview.interviewStatus === InterviewStatus.SCHEDULED) {
      this.service.startInterview(this.id, this.interview.date);
    }
  }

  cancelInterview(): void {
    const dialogRef = this.dialog.open(ConfirmWindowComponent, {
      disableClose: true,
      autoFocus: true,
      data: {
        title: 'Are you sure you want to cancel?',
        confirmButtonName: 'Cancel',
        cancelButtonName: 'Back',
      }
    });
    dialogRef.afterClosed().pipe(
      filter((isConfirmed: boolean) => isConfirmed),
      switchMap(() => this.service.cancelInterview(this.id)),
      untilDestroyed(this)
    ).subscribe({
      next: _ => this.router.navigateByUrl('/interviews'),
      error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
    });
  }

  copyUrl(): void {
    this.clipboard.copy(this.interview.joinUrl);
    this.copyButtonText = 'Link was copied';
  }

  changeInterviewer(staffId: number): void {
    this.service.changeInterviewer(this.id, staffId).subscribe({
      next: _ => this.notifier.notify('success', 'Saved')
    });
  }
}
