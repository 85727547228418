import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-message-announcement-window',
  templateUrl: './edit-message-announcement-window.component.html',
  styleUrls: ['./edit-message-announcement-window.component.scss']
})
export class EditMessageAnnouncementWindowComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<MatDialogRef<string>>) {
  }

  onClose(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
