<app-dashboard-card
  heading="Total"
  [schema]="schema"
  [students]="students"
  [volunteers]="volunteers"
></app-dashboard-card>
<app-dashboard-card
  heading="Age 13-17"
  [schema]="schema"
  [students]="students1"
  [volunteers]="volunteers1"
></app-dashboard-card>
<app-dashboard-card
  heading="Age 18"
  [schema]="schema"
  [students]="students2"
  [volunteers]="volunteers2"
></app-dashboard-card>
<app-dashboard-card
  heading="Age 19-22"
  [schema]="schema"
  [students]="students3"
  [volunteers]="volunteers3"
></app-dashboard-card>
<app-dashboard-card
  heading="Age 23+"
  [schema]="schema"
  [students]="students4"
  [volunteers]="volunteers4"
></app-dashboard-card>
