<div class="table-navigation-container">
  <div class="left-side">
    <input
      type="date"
      class="date-select"
      [(ngModel)]="filterByStartDate"
      (ngModelChange)="getTrainings()"
    />
    <input
      type="date"
      class="date-select"
      [(ngModel)]="filterByEndDate"
      (ngModelChange)="getTrainings()"
    />
    <select
      class="date-select"
      [(ngModel)]="filterByType"
      (ngModelChange)="doFilter()"
    >
      <option [ngValue]="null">Any Type</option>
      <option *ngFor="let enum of TrainingType | keyvalue" [ngValue]="enum.key">
        {{ enum.key | trainingType }}
      </option>
    </select>
    <select
      class="date-select"
      [(ngModel)]="filterBySupervisorId"
      (ngModelChange)="doFilter()"
    >
      <option [ngValue]="null">All trainers</option>
      <option *ngFor="let trainer of trainers" [ngValue]="trainer.id">
        {{ trainer.name }}
      </option>
    </select>
    <select
      class="date-select"
      [(ngModel)]="filterByStatus"
      (ngModelChange)="doFilter()"
    >
      <option [ngValue]="null">All statuses</option>
      <option *ngFor="let status of trainingStatuses" [ngValue]="status">
        {{ status | trainingStatus | capitalCase }}
      </option>
    </select>
  </div>
</div>

<table mat-table [dataSource]="dataSource" class="panel">
  <ng-container matColumnDef="interviewTime">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Time</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.startDate * 1000 | date : "shortTime" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="interviewDate">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.startDate * 1000 | date : "longDate" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="trainer">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>trainer</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.supervisorFirstName }} {{ element.supervisorLastName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>type</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.type | trainingType }}
    </td>
  </ng-container>

  <ng-container matColumnDef="fullness">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>fullness</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.participants.length }}/{{ element.slots }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>status</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.status | trainingStatus | capitalCase }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [routerLink]="['/trainings', row.id]"
  ></tr>
</table>

<mat-paginator
  *ngIf="dataSource && maxLength"
  [length]="maxLength"
  [pageSize]="pageSize"
  [pageSizeOptions]="[10, 20, 50]"
  showFirstLastButtons
  class="body-regular-text"
  (page)="changePage($event)"
></mat-paginator>
