import { Pipe, PipeTransform } from '@angular/core';
import {UserRole} from './user-role.enum';

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    const res = UserRole[value];
    return res ? res.toLowerCase() : null;
  }

}
