import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParticipantDetails, SessionCard, SessionHistory } from '../../../../model/participant-details';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SessionService } from '../../../../service/session.service';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleSessionComponent } from '../../modal-windows/schedule-session/schedule-session.component';

@UntilDestroy()
@Component({
  selector: 'app-participant-session-history',
  templateUrl: './participant-session-history.component.html',
  styleUrls: ['./participant-session-history.component.scss']
})
export class ParticipantSessionHistoryComponent implements OnInit {

  constructor(private sessionService: SessionService,
              private matDialog: MatDialog) {
  }

  @Input() history: SessionHistory;
  @Input() participant: ParticipantDetails;

  @Output() sessionsChanged = new EventEmitter<void>();

  ngOnInit(): void {
  }

  editSession(session: SessionCard): void {
    this.sessionService
      .getSession(this.participant.id, session.id)
      .pipe(untilDestroyed(this))
      .subscribe((session) => {
        const ref = this.matDialog.open(ScheduleSessionComponent, {
          data: {
            participantId: this.participant.id,
            participantDetails: this.participant,
            session
          }
        });

        ref.afterClosed()
          .pipe(untilDestroyed(this))
          .subscribe((r) => {
            if (ref) {
              this.sessionsChanged.emit();
            }
          });

      });
  }

}
