import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditSource } from '../edit-source';
import { Source, SubSource } from '../source';
import { SourcesService } from '../sources.service';
import { NotifierService } from 'angular-notifier';
import { HttpErrorResponse } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, Validators } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-create-source',
  templateUrl: './edit-source.component.html',
  styleUrls: ['./edit-source.component.scss']
})
export class EditSourceComponent implements OnInit {

  create: boolean;
  source: EditSource;
  parents: SubSource[] = [];
  parentControl = new FormControl(null, Validators.required);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Source,
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private service: SourcesService,
    private notifier: NotifierService
  ) {
    this.source = { name: null, userRole: null };
  }

  ngOnInit(): void {
    this.create = !this.data;
    if (!this.create) {
      this.source.name = this.data.name;
      this.source.parentId = this.data.parentId;
      this.parentControl.patchValue(this.data.parentId);
    }
    this.service.getParents()
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(value => this.parents = value);
  }

  submit(): void {
    if (this.parentControl.invalid) {
      return;
    }
    this.source.parentId = this.parentControl.value;
    if (this.create) {
      this.service.createSource(this.source).subscribe(
        value => {
          this.notifier.notify('success', 'Created successfully');
          this.close();
        },
        (error: HttpErrorResponse) => this.notifier.notify('success', error.error.message)
      );
    } else {
      this.service.editSource(this.data.id, this.source).subscribe(
        value => {
          this.notifier.notify('success', 'Saved successfully');
          this.close();
        },
        (error: HttpErrorResponse) => this.notifier.notify('success', error.error.message)
      );
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
