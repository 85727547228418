import {Component, Input} from '@angular/core';
import {DiagramDTO} from "../../../admin/model/diagram-dto";
import {DiagramType} from "../../constant/diagram-type.enum";

@Component({
  selector: 'app-diagram',
  templateUrl: './admin-dashboard-diagram.component.html',
  styleUrls: ['./admin-dashboard-diagram.component.scss']
})
export class AdminDashboardDiagramComponent {

  constructor() {
  }

  @Input() title: string;
  @Input() diagram: DiagramDTO;

  type: DiagramType = DiagramType.COUNT;

  changeType(): void {
    this.type = this.type == DiagramType.PERCENT
      ? DiagramType.COUNT
      : DiagramType.PERCENT;
  }

  getGridColumns(x: number): string {
    if (x === 0) {
      return '1%  max-content';
    } else if (x / this.diagram.max * 100 - 5 <= 0) {
      return '1% max-content';
    } else {
      return (x / this.diagram.max * 100 - 5) + '%' + ' max-content';
    }
  }

  getLabel(x: number): string {
    switch (this.type) {
      case DiagramType.PERCENT:
        return x == 0
          ? '<1%'
          : (x / this.diagram.max * 100).toFixed(2) + '%';
      case DiagramType.COUNT:
        return x.toFixed(0);
    }
  }

  getColor(i: number): string {
    switch(i % 7){
      case 0:
        return "#FBA17A";
      case 1:
        return "#313976";
      case 2:
        return "#2AB37B";
      case 3:
        return "#646472";
      case 4:
        return "#292639";
      case 5:
        return "#E9E9F1";
      case 6:
        return "#00A2FF";
      default:
        return "#F4A460";
    }
  }
}
