import { Pipe, PipeTransform } from '@angular/core';

/**
 * Like titlecase, but when there are multiple words only the first is capitalized.
 */
@Pipe({
  name: 'capitalCase'
})
export class CapitalCasePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return value ? value[0].toUpperCase() + value.slice(1).toLowerCase() : '';
  }
}
