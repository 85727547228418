import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserRole} from '../../../static/constants/user-role/user-role.enum';
import {UserInfoService} from '../../../static/login/service/user-info.service';


@Injectable({
  providedIn: 'root'
})
export class UserRoleGuard implements CanActivate {

  constructor(private roleSelectorService: UserInfoService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const roles = route.data.roles as Array<UserRole>;
    return roles.includes(this.roleSelectorService.getRole());
  }
}
