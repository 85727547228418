import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../service/login.service';
import {UserInfoService} from '../../service/user-info.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  password1: string;
  password2: string;

  constructor(
    private commonService: LoginService,
    private notifierService: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
    private roleSelectorService: UserInfoService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.roleSelectorService.setToken(params.token);
    });
  }

  changePassword(): void {
    if (!this.isValid()) { return; }
    this.commonService.changePassword({
      password: this.password1
    }).subscribe(
      _ => {
        this.notifierService.notify('success', 'Password changed');
        this.router.navigateByUrl('/login');
      },
      (error: HttpErrorResponse) => this.notifierService.notify('error', error.error.message)
    );
  }

  private isValid(): boolean {
    if (!this.password1 || !this.password2) {
      this.notifierService.notify('error', 'Password is required');
      return false;
    }
    if (this.password1 !== this.password2) {
      this.notifierService.notify('error', 'Passwords don\'t match');
      return false;
    }
    if (this.password1.length < 8) {
      this.notifierService.notify('error', 'Password must have at least 8 characters');
      return false;
    }
    return true;
  }
}
