<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Create group session</h6>
    <button class="secondary-button-icons close-button" (click)="onClose(false)">
      <span class="material-icons">close</span>
    </button>
  </div>
  <div class="wrapper">
    <div class="inputs-container">
      <span class="overline-text">Students slots</span>
      <input type="number" [(ngModel)]="studentSlots">
    </div>
    <div class="inputs-container">
      <span class="overline-text">Volunteers slots</span>
      <input type="number" [(ngModel)]="volunteerSlots">
    </div>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="supervisorId">
      <mat-button-toggle
        *ngFor="let supervisor of supervisors"
        [value]="supervisor.id"
      >{{ supervisor.name }}</mat-button-toggle>
    </mat-button-toggle-group>
    <div class="inputs-container">
      <span class="overline-text">Date</span>
      <input type="date" [(ngModel)]="date">
    </div>
    <!--  <div class="inputs-container container">-->
    <!--    <span class="overline-text">Session date</span>-->
    <!--    <div class="selects-container">-->
    <!--      <select name="day" id="day" class="form-control" [ngClass]="{'invalid-date': invalidDate}" [formControl]="dateForm.controls['day']">-->
    <!--        <option value="null" hidden>Day</option>-->
    <!--        <option *ngFor="let d of days" [ngValue]="d">{{formatForDropdown('day', d)}}</option>-->
    <!--      </select>-->
    <!--      <select name="month" id="month" class="form-control" [formControl]="dateForm.controls['month']">-->
    <!--        <option value="null" hidden>Month</option>-->
    <!--        <option *ngFor="let m of months" [ngValue]="m">{{formatForDropdown('month', m)}}</option>-->
    <!--      </select>-->
    <!--      <select name="year" id="year" class="form-control" [formControl]="dateForm.controls['year']">-->
    <!--        <option value="null" hidden>Year</option>-->
    <!--        <option *ngFor="let y of years" [ngValue]="y">{{formatForDropdown('year', y)}}</option>-->
    <!--      </select>-->
    <!--    </div>-->
    <div class="inputs-container">
      <span class="overline-text">Set time</span>
      <div class="inputs selects-container">
        <input type="time" [(ngModel)]="startTime" (ngModelChange)="changeEndTime()">
        <span class="body-regular-text">to</span>
        <input type="time" [(ngModel)]="endTime" readonly>
      </div>
    </div>
  </div>
  <div class="buttons-container">
    <button class="secondary-button" (click)="onClose(false)">Cancel</button>
    <button class="primary-button2" (click)="onClose(true)">Create session</button>
  </div>
</div>
