import { Pipe, PipeTransform } from '@angular/core';
import {ParticipantStatus} from './participant-status.enum';

@Pipe({
  name: 'participantStatus'
})
export class ParticipantStatusPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    const res = ParticipantStatus[value];
    if (!res) {
      return '';
    }
    const str = res.split('_').join(' ').toLowerCase().replace('ukraine', 'Ukraine');
    return `${str[0].toUpperCase()}${str.slice(1)}`;
  }
}
