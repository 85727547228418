import {Injectable} from '@angular/core';
import {
  ProfileAnswersData, ProfileAnswersDataControlValue,
  ProfileAnswersDto,
  ProfileQuestionsData,
  SelectedAnswer
} from '../../model/profile-questions-data';
import {QuestionKey, StudentsQuestionId, VolunteerQuestionId} from '../../model/question-id-mapper';
import {Observable, Subject, tap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UserRole} from '@static/constants/user-role/user-role.enum';

@Injectable({
  providedIn: 'root',
})
export class AnswersService {
  private _profileQuestionsData: ProfileQuestionsData[] = [];
  private _profileAnswerData: ProfileAnswersData = {} as ProfileAnswersData;
  private profileAnswerDataUpdatedSubject = new Subject<void>()

  public profileAnswerDataUpdated$ = this.profileAnswerDataUpdatedSubject.asObservable();

  userRole: number;

  constructor(private http: HttpClient) {
  }

  get profileQuestionsData(): ProfileQuestionsData[] {
    return  this._profileQuestionsData;
  }

  get profileAnswerData(): ProfileAnswersData {
    return this._profileAnswerData;
  }

  reset(): void {
    this._profileQuestionsData = [];
    this._profileAnswerData = {} as ProfileAnswersData;
  }

  private profileAnswerDataToDto(userId: number, profileAnswersData: Partial<ProfileAnswersDataControlValue>): ProfileAnswersDto[] {
    return Object.entries(profileAnswersData).map(([key, answerValue]: [string, ProfileAnswersDataControlValue[keyof ProfileAnswersDataControlValue]]) => {
      const currentQuestion = this.getQuestion(key as QuestionKey);
      if (!currentQuestion) {
        return null;
      }

      const id = currentQuestion?.id;
      let selectedAnswerIds: ProfileAnswersDto['selectedAnswerIds'] = null;

      if (Array.isArray(answerValue) && answerValue.length) {
        selectedAnswerIds = answerValue;
      }

      if (typeof answerValue === 'number') {
        selectedAnswerIds = [answerValue];
      }

      const answerDto = {
        selectedAnswerIds,
        customAnswer: typeof answerValue === 'string' ? answerValue : null
      };

      return id ? {
        id,
        ...answerDto
      } : {
        id: null,
        participantId: userId,
        questionId: currentQuestion.registrationQuestion.id,
        ...answerDto
      };
    }).filter(Boolean);
  }

  getQuestionsData(id: number): Observable<ProfileQuestionsData[]> {
    return this.http.get<ProfileQuestionsData[]>(
      `/answer/profile/${id}`,
      {
        params: {
          role: this.userRole || ''
        }
      }
    ).pipe(tap((questions) => {
      this._profileQuestionsData = questions;
      this._profileAnswerData = {
        experience: this.getAnswersCustom('experience') ? [...this.getSelectedAnswers('experience'), { value: this.getAnswersCustom('experience') }] : this.getSelectedAnswers('experience'),
        motivation: this.getAnswersCustom('motivation') ? [...this.getSelectedAnswers('motivation'), { value: this.getAnswersCustom('motivation') }] : this.getSelectedAnswers('motivation'),
        studentLevel: this.getSelectedAnswers('studentLevel') || null,
        hobbies: this.getSelectedAnswers('hobbies'),
        academicInterests: this.getSelectedAnswers('academicInterests'),
        availability: this.getSelectedAnswers('availability'),
        english: this.getSelectedAnswers('english')?.[0] || null,
        genderPref: this.getSelectedAnswers('genderPref')?.[0] || null,
      };
      this.profileAnswerDataUpdatedSubject.next();
    }));
  }

  saveAnswers(userId: number, profileAnswersData: Partial<ProfileAnswersDataControlValue>): Observable<void> {
    return this.http.post<void>(
      `/answer/save-answers`,
      this.profileAnswerDataToDto(userId, profileAnswersData)
    );
  }

  private getSelectedAnswers(key: keyof ProfileAnswersData): SelectedAnswer[] {
    return this.getQuestion(key)?.selectedAnswers || [];
  }

  private getAnswersCustom(key: keyof ProfileAnswersData): string {
    return this.getQuestion(key)?.customAnswer || '';
  }

  public getQuestion(key: QuestionKey): ProfileQuestionsData {
    const keyToIdMapper = this.userRole === UserRole.VOLUNTEER ? VolunteerQuestionId : StudentsQuestionId;

    return this.profileQuestionsData.find(question => question.registrationQuestion.id === keyToIdMapper[key]);
  }
}
