<div class="container-wrapper panel" *ngIf="students && volunteers">
  <div class="container left-container">
    <h5 class="dashboard-panel-title">
      <span class="student">STUDENTS</span> {{ heading }}
    </h5>
    <div class="info-container">
      <div *ngFor="let item of schema; index as i" class="info">
        <h4 class="number">{{ students[i] }}</h4>
        <span class="description caption-text">{{ item | uppercase }}</span>
      </div>
    </div>
  </div>
  <div class="container right-container">
    <h5 class="dashboard-panel-title">
      <span class="student">VOLUNTEERS</span> {{ heading }}
    </h5>
    <div class="info-container">
      <div *ngFor="let item of schema; index as i" class="info">
        <h4 class="number">{{ volunteers[i] }}</h4>
        <span class="description caption-text">{{ item | uppercase }}</span>
      </div>
    </div>
  </div>
</div>
