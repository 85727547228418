<section class="calendar">
  <header class="calendar__header">
    <button class="secondary-button-icons" (click)="changeMonth(-1)">
      <span class="material-icons">arrow_back</span>
    </button>

    <h5 class="calendar__selected-date">{{ date$ | async | moment }}</h5>

    <button class="secondary-button-icons" (click)="changeMonth(1)">
      <span class="material-icons">arrow_forward</span>
    </button>
  </header>

  <main class="calendar__dates">
    <table class="dates__table">
      <thead>
        <tr>
          <th class="dates__day">Sun</th>
          <th class="dates__day">Mon</th>
          <th class="dates__day">Tue</th>
          <th class="dates__day">Wed</th>
          <th class="dates__day">Thu</th>
          <th class="dates__day">Fri</th>
          <th class="dates__day">Sat</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let week of calendar$ | async" class="dates__cell-row">
          <td
            *ngFor="let day of week.days"
            class="dates__cell"
            [class.dates__cell--highlighted]="day.highlighted"
            [class.dates__cell--disabled]="day.disabled"
            [class.dates__cell--selected]="day.selected"
            (click)="selectDate(day.value)"
          >
            <span class="cell__text">{{ day.value | moment : 'D' }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </main>

  <footer class="calendar__footer" *ngIf="availabilityStats">
    <h6 class="footer__title body-regular-text">Available slots</h6>

    <div class="calendar__slots">
      <div class="slots__cell">
        <span class="cell__flag">🇺🇸</span>
        <span class="cell__text body-regular-text">{{ availabilityStats.us }}</span>
      </div>
      <div class="slots__cell">
        <span class="cell__flag">🇺🇦</span>
        <span class="cell__text body-regular-text">{{ availabilityStats.ukraine }}</span>
      </div>
      <div class="slots__cell">
        <span class="cell__text body-regular-text">Total: {{ availabilityStats.all }}</span>
      </div>
    </div>
  </footer>
</section>
