import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotifierService} from 'angular-notifier';
import {HttpErrorResponse} from '@angular/common/http';
import {SubprogramsService} from '../subprograms.service';
import {EditSubprogram} from './edit-subprogram';
import {Subprogram} from './subprogram';

@Component({
  selector: 'app-edit-subprogram',
  templateUrl: './edit-subprogram.component.html',
  styleUrls: ['./edit-subprogram.component.scss']
})
export class EditSubprogramComponent implements OnInit {

  create: boolean;
  subprogram: Subprogram;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: EditSubprogram,
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private service: SubprogramsService,
    private notifier: NotifierService
  ) {
    this.subprogram = {
      name: '',
      description: '',
      color: '',
      active: true,
      type: 100,
    };
  }

  ngOnInit(): void {
    this.create = !this.data;
    if (!this.create) {
      this.subprogram.name = this.data.name;
      this.subprogram.description = this.data.description;
      this.subprogram.color = this.data.color;
      this.subprogram.active = this.data.active;
      this.subprogram.type = this.data.type;
    }
  }

  submit(): void {
    if (this.create) {
      this.service.create(this.subprogram).subscribe({
        next: _ => {
          this.notifier.notify('success', 'Created successfully');
          this.close();
        },
        error: (error: HttpErrorResponse) => this.notifier.notify('success', error.error.message)
      });
    } else {
      this.service.edit({...this.subprogram, id: this.data.id}).subscribe({
        next: _ => {
          this.notifier.notify('success', 'Saved successfully');
          this.close();
        },
        error: (error: HttpErrorResponse) => this.notifier.notify('success', error.error.message)
      });
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
