import { Component, OnInit } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NotifierService} from 'angular-notifier';
import {Router} from '@angular/router';
import {LoginService} from '../../service/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email: string;

  constructor(
    private service: LoginService,
    private notifier: NotifierService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  requestPasswordChangeEmail(): void {
    if (!this.isValid()) { return; }
    this.service.forgotPassword(this.email).subscribe(
      _ => {
        this.notifier.notify('success', 'Email was sent');
        this.router.navigateByUrl('/login');
      },
      (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
    );
  }

  private isValid(): boolean {
    if (!this.email) {
      this.notifier.notify('error', 'Password is required');
      return false;
    }
    return true;
  }
}
