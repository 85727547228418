<div class="table-navigation-container">
  <div class="left-side">
    <input type="date" class="date-select" [(ngModel)]="filterByStartDate" (ngModelChange)="getInterviews()">
    <input type="date" class="date-select" [(ngModel)]="filterByEndDate" (ngModelChange)="getInterviews()">
    <select
      class="date-select"
      [(ngModel)]="filterByStatus"
      (ngModelChange)="doFilter()"
    >
      <option [ngValue]="null">All statuses</option>
      <option
        *ngFor="let status of interviewStatuses"
        [ngValue]="status"
      >{{ status | interviewStatus | capitalCase }}</option>
    </select>
    <select
      class="date-select"
      [(ngModel)]="filterByUserRole"
      (ngModelChange)="doFilter()">
      <option [ngValue]="null">All roles</option>
      <option [ngValue]="100">Student</option>
      <option [ngValue]="101">Volunteer</option>
    </select>

    <select
      class="date-select"
      [(ngModel)]="filterByDecision"
      (ngModelChange)="doFilter()"
    >
      <option [ngValue]="null">All decisions</option>
      <option
        *ngFor="let decision of decisions"
        [ngValue]="decision"
      >{{ decision | interviewDecision | capitalCase }}</option>
    </select>
    <select class="date-select" [(ngModel)]="teamMemberId" (ngModelChange)="doFilter()" *ngIf="role === UserRole.MANAGER || role === UserRole.ADMIN">
      <option [ngValue]="null">All</option>
      <option *ngFor="let teamMember of teamMembers" [ngValue]="teamMember.id">{{ teamMember.name }}</option>
    </select>
    <select class="date-select" [(ngModel)]="filterByType" (ngModelChange)="doFilter()">
      <option [ngValue]="null">Any Type</option>
      <option *ngFor="let enum of InterviewType | keyvalue" [ngValue]="enum.key">{{ enum.value | interviewTypeName }}</option>
    </select>
  </div>
  <div class="right-side">
    <input
      type="text"
      class="search"
      [(ngModel)]="searchByName"
      (keyup)="doFilter()"
      placeholder="Search"
    >
  </div>

</div>

<table mat-table [dataSource]="dataSource" class="panel">

  <ng-container matColumnDef="interviewTime">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>
      Time
    </th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.date * 1000 | date:'shortTime' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="interviewDate">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>
      Date
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.date * 1000 | date:'longDate' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="photo">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>
      Photo
    </th>
    <td class="body-regular-text photo-container" mat-cell *matCellDef="let element">
      <img class="avatar-img" src="{{ element.photo }}" alt="">
    </td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>
      Last name
    </th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      <a [routerLink]="['/interviews', element.id]" class="body-regular-text text-decoration-none">
        {{ element.lastName | capitalCase | nullReplace }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>
      First name
    </th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      <a [routerLink]="['/interviews', element.id]" class="body-regular-text text-decoration-none">
        {{ element.firstName | capitalCase | nullReplace }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="age">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>
      Age
    </th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.dateOfBirth | age | nullReplace }}
    </td>
  </ng-container>

  <ng-container matColumnDef="role">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>
      role
    </th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.userRole | userRole | capitalCase }}
    </td>
  </ng-container>

  <ng-container matColumnDef="interviewer">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>
      interviewer
    </th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.interviewerFirstName }} {{ element.interviewerLastName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="decision">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>
      decision
    </th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.interviewDecision | interviewDecision | capitalCase |nullReplace }}
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>
      type
    </th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.interviewType | interviewTypeName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>
      status
    </th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{ element.interviewStatus | interviewStatus | capitalCase }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row *matRowDef="let row; columns: displayedColumns;"
    [routerLink]="['/interviews', row.id]"
  ></tr>
</table>

<mat-paginator
  *ngIf="dataSource && maxLength"
  [length]="maxLength"
  [pageSize]="pageSize"
  [pageSizeOptions]="[7, 20, 50]"
  showFirstLastButtons
  class="body-regular-text"
  (page)="changePage($event)"
></mat-paginator>
