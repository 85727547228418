import { Component, Input, OnInit } from '@angular/core';
import { ParticipantDetails, ParticipantProfile } from '../../../../model/participant-details';
import { DatePipe } from '@angular/common';
import { GenderPipe } from '@static/constants/gender/gender.pipe';
import { UserRole } from '@static/constants/user-role/user-role.enum';
import { ParticipantService } from '../../../../service/participant.service';
import { NotifierService } from 'angular-notifier';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AnswersService } from '../../answers.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PaymentRequirement } from '../../../../model/payment.types';
import { SelectedAnswer } from '../../../../model/profile-questions-data';
import { SelectedAnswerListPipe } from '../../../../../../../features/matching/component/matching-profile/selected-answer-list.pipe';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FreezeDialogData, ParticipantProfileFreezeComponent } from '../participant-profile-freeze/participant-profile-freeze.component';
import { ConfirmWindowComponent } from '../../../../../../matching/component/warning-window/confirm-window.component';

@UntilDestroy()
@Component({
  selector: 'app-participant-profile',
  templateUrl: './participant-profile.component.html',
  styleUrls: ['./participant-profile.component.scss'],
  providers: [SelectedAnswerListPipe]
})
export class ParticipantProfileComponent implements OnInit {
  @Input() participant: ParticipantDetails;
  @Input() userRole: UserRole;

  userInfo: { icon: string, option: string }[] = [];
  extraInfo: { title: string, description: SelectedAnswer[] | string }[] = [];


  constructor(
    public answersService: AnswersService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private genderPipe: GenderPipe,
    private service: ParticipantService,
    private notifier: NotifierService,
    private router: Router,
    private selectedAnswerListPipe: SelectedAnswerListPipe
  ) {
  }

  ngOnInit(): void {
    this.init();

    this.answersService.profileAnswerDataUpdated$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.init();
      });
  }

  disableAccount(): void {
    this.service.disableAccount(this.id, this.currentUserRole)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: _ => {
          this.notifier.notify('success', 'Disabled successfully');
          setTimeout(() => window.location.reload(), 2000);
        },
        error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
      });
  }

  freezeAccount(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    const data: FreezeDialogData = {
      participantId: this.id,
      participantStatus: this.participant.profile.status,
      participantRole: this.currentUserRole,
    };
    dialogConfig.data = data;
    this.dialog.open(ParticipantProfileFreezeComponent, dialogConfig).afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          window.location.reload();
        }
      });
  }

  deleteAccountConfirm(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    dialogConfig.data = {
      title: 'Delete Participant',
      warning: 'Participant will be completely deleted. Are you sure to continue?',
      confirmButtonName: 'Delete',
      cancelButtonName: 'Cancel',
    };

    this.dialog.open(ConfirmWindowComponent, dialogConfig).afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((update: boolean) => {
        if (update) {
          this.deleteAccount();
        }
      }
    );
  }

  deleteAccount() {
    this.service.deleteAccount(this.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: _ => {
          this.notifier.notify('success', 'Deleted successfully');
          this.router.navigateByUrl('/participants');
        },
        error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
      });
  }

  paymentRequirementChange(paymentRequirement: PaymentRequirement): void {
    this.profile.paymentRequirement = paymentRequirement;
    this.service.setProfileDetails(this.id, this.participant.userRole, this.profile)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  get id(): number {
    return this.participant.id;
  }

  get currentUserRole(): UserRole {
    return this.userRole ? +this.userRole : this.participant.userRole;
  }

  get isStudent(): boolean {
    return this.currentUserRole === UserRole.STUDENT;
  }

  get hasAdditionalProfile(): boolean {
    return this.participant.userRoles?.includes(this.additionalProfileRole);
  }

  get additionalProfileRole(): number {
    return this.isStudent ? UserRole.VOLUNTEER : UserRole.STUDENT;
  }

  get profile(): ParticipantProfile {
    return this.participant.profile;
  }

  private init(): void {
    this.userInfo = [
      {
        icon: 'mail',
        option: this.profile.email
      },
      {
        icon: 'cake',
        option: this.datePipe.transform(this.profile.dateOfBirth * 1000, 'mediumDate')
      },
      {
        icon: 'person',
        option: this.genderPipe.transform(this.profile.gender)
      },
      {
        icon: 'location_on',
        option: [this.profile.city, this.profile.state, this.profile.country].filter(x => x !== null).join(', ')
      },
      {
        icon: 'location_on',
        option: 'zip: ' + this.participant.profile.zipCode
      },
      {
        icon: this.profile.parentalPermission ? 'check_box' : 'check_box_outline_blank',
        option: 'Parental permission is ' + (this.profile.parentalPermission ? 'signed' : 'not signed')
      },
      {
        icon: this.profile?.paid ? 'check_box' : 'check_box_outline_blank',
        option: (this.profile?.paid ? 'paid' : 'not paid')
      },
    ];
    if (this.isStudent) {
      this.extraInfo = [
        {
          title: 'Placement Test Score:',
          description: this.profile.placementTestScore?.toString()
        },
        {
          title: 'Source:',
          description: this.profile.sourceName
        },
        {
          title: 'Motivation:',
          description: this.selectedAnswerListPipe.transform(this.answersService.profileAnswerData.motivation)
        },
        {
          title: 'Emergency Contact:',
          description: this.profile.emergencyContact
        }
      ];
      this.userInfo.push(
        {
          icon: 'person_pin_circle',
          option: [this.profile.currentCity, this.profile.currentState, this.profile.currentCountry].filter(x => x !== null).join(', ')
        }
      );
    } else {
      this.extraInfo = [
        {
          title: 'Prior experience:',
          description: this.selectedAnswerListPipe.transform(this.answersService.profileAnswerData.experience)
        },
        {
          title: 'Motivation:',
          description: this.selectedAnswerListPipe.transform(this.answersService.profileAnswerData.motivation)
        },
        {
          title: 'Source:',
          description: this.profile.sourceName
        },
        {
          title: 'Preferred Student`s English Level:',
          description: this.answersService.profileAnswerData.studentLevel?.map(({value}) => value).join(', ') || ''
        },
      ];
    }
  }
}
