import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MarketerDashboardDTO} from '../component/marketer-dashboard/marketer-dashboard-dto';

@Injectable({
  providedIn: 'root'
})
export class MarketerDashboardService {

  constructor(private httpClient: HttpClient) {
  }

  public getData(): Observable<MarketerDashboardDTO> {
    return this.httpClient.get<MarketerDashboardDTO>('/marketer/dashboard');
  }
}
