import {Component, OnDestroy, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {ParticipantCard} from '../../../model/participant-card';
import {ParticipantService} from '../../../service/participant.service';
import {UserRole} from '@static/constants/user-role/user-role.enum';
import {ParticipantStatus} from '@static/constants/participant-status/participant-status.enum';
import * as _ from 'lodash';
import {BehaviorSubject, concat, debounceTime, distinctUntilChanged, first, skip, Subject, takeUntil, tap} from 'rxjs';
import {SubprogramCard} from '../../../../../settings/subprograms/subprogram-card';
import {SubprogramsService} from '../../../../../settings/subprograms/subprograms.service';
import {ParticipantFilterService} from '../participant-filter.service';

@Component({
  selector: 'app-participants-table',
  templateUrl: './participants-table.component.html',
  styleUrls: ['./participants-table.component.scss']
})
export class ParticipantsTableComponent implements OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    'photo',
    'firstName',
    'lastName',
    'role',
    'age',
    'email',
    'applied',
    'status',
    'currentMatch'
  ];

  public data: ParticipantCard[];
  public loading = true;
  public maxLength: number;
  public pageIndex = 0;
  public pageSize = 10;
  public statuses: ParticipantStatus[] = [];
  public status: ParticipantStatus = null;
  public role: UserRole = null;
  public search: string;
  public matchFrom: number = null;
  public matchTo: number = null;
  public sessionsTo: number = null;
  public sessionsFrom: number = null;
  public subprograms: SubprogramCard[] = [];
  public subprogramId: number = null;
  public paramsSubject$ = new BehaviorSubject(this.getParams());
  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: ParticipantService,
    private subprogramService: SubprogramsService,
    private participantFilterService: ParticipantFilterService
  ) {
    this.initGettingParticipants();
    this.getStatuses();
    this.getSubprograms();
    this.role = this.participantFilterService.role;
    this.matchFrom = this.participantFilterService.matchFrom;
    this.matchTo = this.participantFilterService.matchTo;
    this.search = this.participantFilterService.search;
    this.status = this.participantFilterService.status;
    this.statuses = this.participantFilterService.statuses;
    this.sessionsFrom = this.participantFilterService.sessionsFrom;
    this.sessionsTo = this.participantFilterService.sessionsTo;
    this.subprogramId = this.participantFilterService.subprogramId;
    this.subprograms = this.participantFilterService.subprograms;
    this.changeFilter();
  }

  initGettingParticipants(): void {
    this.service.participants$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.data = value.content;
        this.maxLength = value.totalElements;
        this.loading = false;
      });

    concat(
      this.paramsSubject$.pipe(first()),
      this.paramsSubject$.pipe(skip(1), debounceTime(1000))
    )
      .pipe(
        distinctUntilChanged(_.isEqual),
        tap(() => this.loading = true),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.getParticipants.bind(this));
  }

  getStatuses(): void {
    this.service.getStatuses().subscribe(
      value => this.statuses = value
    );
  }

  getSubprograms(): void {
    this.subprogramService.getAll().subscribe(value => this.subprograms = value);
  }

  changeFilter(): void {
    this.paramsSubject$.next(this.getParams());
    this.participantFilterService.role = this.role;
    this.participantFilterService.matchFrom = this.matchFrom;
    this.participantFilterService.matchTo = this.matchTo;
    this.participantFilterService.search = this.search;
    this.participantFilterService.status = this.status;
    this.participantFilterService.statuses = this.statuses;
    this.participantFilterService.sessionsFrom = this.sessionsFrom;
    this.participantFilterService.sessionsTo = this.sessionsTo;
    this.participantFilterService.subprogramId = this.subprogramId;
    this.participantFilterService.subprograms = this.subprograms;
  }

  getParticipants(params): void {
    this.service.updateParticipants(params);
  }

  getParams(): any {
    const params = _.omitBy({
      page: this.pageIndex,
      size: this.pageSize,
      role: this.role,
      status: this.status,
      subprogram: this.subprogramId,
      s: this.search || null,
    }, _.isNil);

    if (this.isCorrectValue(this.matchFrom) && this.isCorrectValue(this.matchTo)) {
      params.matchLength = `${this.matchFrom}-${this.matchTo}`;
    }

    if (this.isCorrectValue(this.sessionsFrom) && this.isCorrectValue(this.sessionsTo)) {
      params.sessionsNumber = `${this.sessionsFrom}-${this.sessionsTo}`;
    }
    return params;
  }

  changePage(page: PageEvent): void {
    this.pageIndex = page.pageIndex;
    this.pageSize = page.pageSize;
    this.changeFilter();
  }

  isCorrectValue(value): boolean {
    return !_.isNil(value) && value !== '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

