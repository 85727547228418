<div *ngIf="role !== UserRole.PARTNER" class="wrapper panel">
  <div class="inputs-container">
    <span class="body-regular-text">About</span>
    <input [(ngModel)]="firstName" type="text" placeholder="First name">
    <input [(ngModel)]="lastName" type="text" placeholder="Last name">
  </div>
  <div class="inputs-container">
    <span class="body-regular-text">Phone number</span>
    <input [(ngModel)]="phone" type="text" placeholder="Phone number">
  </div>
  <div class="inputs-container">
    <span class="body-regular-text">E-mail</span>
    <input [(ngModel)]="email" type="text" placeholder="E-mail">
  </div>
  <div class="inputs-container">
    <span class="body-regular-text">Zoom E-mail</span>
    <input [(ngModel)]="zoom" type="text" placeholder="Zoom E-mail">
  </div>
<!--  <div class="inputs-container">-->
<!--    <span class="body-regular-text">Time zone</span>-->
<!--    <select>-->
<!--      <option value="">Time zone</option>-->
<!--    </select>-->
<!--  </div>-->
  <div class="buttons-container">
    <button class="primary-button" (click)="buttonSaved()">Save</button>
  </div>
</div>

<div *ngIf="role === UserRole.PARTNER" class="wrapper panel">
  <div class="inputs-container">
    <span class="body-regular-text">Organization Name</span>
    <input [(ngModel)]="organizationName" type="text" placeholder="Organization name">
  </div>
  <div class="inputs-container">
    <span class="body-regular-text">Coordinator Name</span>
    <input [(ngModel)]="coordinatorName" type="text" placeholder="Coordinator Name">
  </div>
  <div class="inputs-container">
    <span class="body-regular-text">Coordinator E-mail</span>
    <input [(ngModel)]="coordinatorEmail" type="text" placeholder="E-mail">
  </div>
  <div class="inputs-container">
    <span class="body-regular-text">Phone</span>
    <input [(ngModel)]="coordinatorPhone" type="text" placeholder="Phone">
  </div>
<!--  <div class="inputs-container">-->
<!--    <span class="body-regular-text">Time zone</span>-->
<!--    <select name="" id="">-->
<!--      <option value="">Time zone</option>-->
<!--    </select>-->
<!--  </div>-->
  <div class="inputs-container">
    <span class="body-regular-text">Notes</span>
    <input [(ngModel)]="notes" type="text" placeholder="Notes">
  </div>
  <div class="buttons-container">
    <button class="primary-button" (click)="buttonSaved()">Save</button>
  </div>
</div>



