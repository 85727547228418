import {Impact} from './common.namespace';

export const REPLACE_VALUE = '{value}';

export const IMPACT_MAP_CONFIG: Record<string, Impact.Config> = {
  studentSelfAssessment: {
    title: `Student Self-Assessment (n = ${REPLACE_VALUE})`,
    categories: [['English', 'Communication'], ['Grammar', 'Vocab', 'Writing'], ['Intercultural', 'Competence'], 'Overall'],
    seriesProps: ['englishCommunication', 'grammarVocabWriting', 'interculturalCompetence', 'overall'],
    valueProps: ['initial', 'threeMonth', 'nineMonth'],
    labels: {
      initial: 'Initial',
      threeMonth: '3-month',
      nineMonth: '9-month',
    }
  },
  volunteerAssessmentOfStudent: {
    title: `Volunteer Assessment of Student (n = ${REPLACE_VALUE})`,
    categories: [['English', 'Communication'], ['Grammar', 'Vocab', 'Writing'], ['Intercultural', 'Competence'], 'Overall'],
    seriesProps: ['englishCommunication', 'grammarVocabWriting', 'interculturalCompetence', 'overall'],
    valueProps: ['initial', 'threeMonth', 'nineMonth'],
    labels: {
      initial: 'Initial',
      threeMonth: '3-month',
      nineMonth: '9-month',
    }
  },
  englishImprovement: {
    title: `Has your English improved?`,
    seriesProps: ['definitely', 'somewhat', 'veryLittle', 'notAtAll'],
    valueProps: ['threeMonth', 'nineMonth'],
    categories: ['Definitely', 'Somewhat', 'Very little', 'Not at all'],
    groupTitles: {
      threeMonth: `3-month (n = ${REPLACE_VALUE})`,
      nineMonth: `9-month (n = ${REPLACE_VALUE})`,
    }
  },
  languageBarrier: {
    title: `Have you broken the language barrier?`,
    seriesProps: ['definitely', 'somewhat', 'veryLittle', 'notAtAll'],
    valueProps: ['threeMonth', 'nineMonth'],
    categories: ['Definitely', 'Somewhat', 'Very little', 'Not at all'],
    groupTitles: {
      threeMonth: `3-month (n = ${REPLACE_VALUE})`,
      nineMonth: `9-month (n = ${REPLACE_VALUE})`,
    }
  },
  satisfactionWithProgram: {
    title: `Are you satisfied with the program?`,
    seriesProps: ['veryDissatisfied', 'notSatisfied', 'neutral', 'satisfied', 'verySatisfied'],
    valueProps: ['threeMonth', 'nineMonth'],
    categories: ['Very dissatisfied', 'Not satisfied', 'Neutral', 'Satisfied', 'Very satisfied'],
    groupTitles: {
      threeMonth: `3-month (n = ${REPLACE_VALUE})`,
      nineMonth: `9-month (n = ${REPLACE_VALUE})`,
    }
  },
  satisfactionWithOnboardingProcessStudent: {
    title: `Are you satisfied with the onboarding process?  (n = ${REPLACE_VALUE})`,
    categories: [['Registration', 'form'], ['Interview', 'scheduling'], 'Interview', ['New student', 'orientation'], ['Wait time for', 'a match'], ['Overall fit of', 'my match'], ['Ease of', 'making', 'contact with', ' my buddy']],
    seriesProps: ['registrationForm', 'interviewScheduling', 'interview', 'newStudentOrientation', 'waitTimeForMatch', 'overallFitOfMatch', 'easeOfMakingContactWithBuddy'],
  },
  satisfactionWithStudentExperience: {
    title: `Are you satisfied with the student experience?  (n = ${REPLACE_VALUE})`,
    categories: [['Finding/joining', 'sessions'], 'Support team', 'Student emails', 'Virtual events', 'Social media', ['Telegram', 'communities']],
    seriesProps: ['easeOfFindingAndJoiningSessions', 'studentSupportTeam', 'bimonthlyStudentEmails', 'virtualEventsForStudents', 'socialMedia', 'telegramCommunities'],
    valueProps: ['threeMonth', 'nineMonth'],
    labels: {
      threeMonth: '3-month',
      nineMonth: '9-month',
    }
  },
  satisfactionWithBuddy: {
    title: `Are you satisfied with your buddy?`,
    categories: [['Clearly answer', 'the questions I have'], ['Uses clear language', 'that I can understand'], ['Offers a variety', 'of activities'], ['Listens to what', 'I say carefully'], ['Uses teaching strategies ', 'for my progress'], ['Creates a comfortable', 'learning environment'], ['Encourages me to', 'perform better'], ['Is always prepared', 'for sessions'], ['Provides timely and', 'meaningful correction'], 'Is friendly', 'Is enthusiastic', 'Is respectful', 'Is helpful'],
    seriesProps: ['clearlyAnswersTheQuestions', 'usesClearLanguage', 'offersVarietyOfActivities', 'listensToWhatISayCarefully', 'teachingStrategies', 'comfortableLearningEnvironment', 'encouragesToPerformBetter', 'alwaysPreparedForSessions', 'providesTimelyAndMeaningfulCorrection', 'isFriendly', 'isEnthusiastic', 'isRespectful', 'isHelpful'],
  },
  volunteerSelfAssessment: {
    title: `Volunteer Self-Assessment (n = ${REPLACE_VALUE})`,
    categories: [['Confidence,', 'Leadership,', 'Communication'], ['Intercultural', 'Competence'], ['Knowledge about', 'Ukraine'], ['Tutoring', 'Skills'], 'Overall'],
    seriesProps: ['confidenceLeadershipCommunication', 'interculturalCompetence', 'knowledgeAboutUkraine', 'tutoringSkills', 'overall'],
    valueProps: ['initial', 'threeMonth', 'nineMonth'],
    labels: {
      initial: 'Initial',
      threeMonth: '3-month',
      nineMonth: '9-month',
    }
  },
  supportUkraine: {
    title: 'As a result of getting involved with ENGin, have you supported Ukraine in other ways?',
    seriesProps: ['yesAnswer', 'noAnswer'],
    valueProps: ['threeMonth', 'nineMonth'],
    categories: ['Yes', 'No'],
    groupTitles: {
      threeMonth: `3-month`,
      nineMonth: `9-month`,
    }
  },
  satisfactionWithOnboardingProcessVolunteer: {
    title: `Are you satisfied with the onboarding process?  (n = ${REPLACE_VALUE})`,
    categories: [['Registration', 'form'], ['Interview', 'scheduling'], 'Interview', ['Volunteer', 'training'], ['Wait time for', 'a match'], ['Overall fit of', 'my match'], ['Ease of', 'making', 'contact with', ' my buddy']],
    seriesProps: ['registrationForm', 'interviewScheduling', 'interview', 'volunteerTraining', 'waitTimeForMatch', 'overallFitOfMatch', 'easeOfMakingContactWithBuddy'],
  },
  satisfactionWithVolunteerExperience: {
    title: `Are you satisfied with the volunteer experience?  (n = ${REPLACE_VALUE})`,
    categories: [['Ease of', 'scheduling sessions'], ['Volunteer resources', 'in Volunteer Handbook'], 'Volunteer support team', 'Volunteer emails', 'Social media', 'Virtual workshops', 'Mentor program', ['Social media', 'communities']],
    seriesProps: ['easeOfSchedulingSessions', 'volunteerResourcesInVolunteerHandbook', 'volunteerSupportTeam', 'bimonthlyVolunteerEmails', 'socialMedia', 'virtualWorkshopsForVolunteers', 'volunteerMentorProgram', 'volunteerSocialMediaCommunities'],
    valueProps: ['threeMonth', 'nineMonth'],
    labels: {
      threeMonth: '3-month',
      nineMonth: '9-month',
    }
  },
};
