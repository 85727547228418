import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, of} from 'rxjs';
import {ImpactServiceHelper} from './impact.helper';
import {Impact} from '../entities/common.namespace';

@Injectable({
  providedIn: 'root'
})
export class ImpactService {

  constructor(
    private http: HttpClient
  ) {}

  getImpactData(): Observable<Impact.Data> {
    return this.http.get('/impact/charts').pipe(map(ImpactServiceHelper.mapImpactData.bind(null)));
  }
}
