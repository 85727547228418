import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ScheduleTraining } from '../model/schedule-training';
import { Trainer } from '../model/trainer';
import { Subject, takeUntil } from 'rxjs';
import { TrainingService } from '../../trainings/training.service';

@Component({
  selector: 'app-create-training',
  templateUrl: './create-training.component.html',
  styleUrls: ['./create-training.component.scss'],
})
export class CreateTrainingComponent implements OnInit {
  trainingForm = new FormGroup({
    type: new FormControl('', Validators.required),
    supervisorId: new FormControl('', Validators.required),
    slots: new FormControl(null, Validators.required),
    startDate: new FormControl(null, Validators.required),
    duration: new FormControl(null, Validators.required),
  });
  trainingTypeOptions = [
    {
      value: 'STUDENTS_YOUNG',
      name: 'Training for students - Ages 9-14 years old',
    },
    {
      value: 'STUDENTS_OLD',
      name: 'Training for students - Ages 15+ years old',
    },
    {
      value: 'VOLUNTEERS_PART1',
      name: 'Training for volunteers part 1',
    },
    {
      value: 'VOLUNTEERS_PART2',
      name: 'Training for volunteers part 2',
    },
  ];
  durationOptions = [
    {
      value: 1000 * 60 * 30,
      name: '30 min',
    },
    {
      value: 1000 * 60 * 45,
      name: '45 min',
    },
    {
      value: 1000 * 60 * 60,
      name: '1 h',
    },
    {
      value: 1000 * 60 * 75,
      name: '1 h 15 min',
    },
    {
      value: 1000 * 60 * 90,
      name: '1 h 30 min',
    },
    {
      value: 1000 * 60 * 105,
      name: '1 h 45 min',
    },
    {
      value: 1000 * 60 * 120,
      name: '2 h',
    },
  ];

  trainers: Trainer[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private trainingService: TrainingService,
  ) { }

  ngOnInit(): void {
    this.getTrainers();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getTrainers(): void {
    this.trainingService
      .getTrainers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.trainers = value));
  }

  onClose(schedule: boolean): void {
    const formValue = this.trainingForm.value;
    console.log(formValue);
    if (schedule) {
      const result: ScheduleTraining = {
        type: formValue.type,
        slots: formValue.slots,
        supervisorId: formValue.supervisorId,
        startDate: formValue.startDate.valueOf() / 1000,
        endDate: (formValue.startDate.valueOf() + formValue.duration) / 1000,
      };
      this.dialogRef.close(result);
    } else {
      this.dialogRef.close();
    }
  }
}
