<div class="table-navigation-container">
  <div class="left-side">
    <div class="buttons-container">
      <button class="secondary-button">Copy Emails</button>
      <button class="primary-button">Start session</button>
    </div>

  </div>
  <div class="right-side"><input type="text" value="search"></div>
</div>

<!--<h5>Volunteers</h5>-->

<div class="table-container" *ngIf="groupSession">

  <table mat-table [dataSource]="groupSession.participants">

    <!--    <ng-container matColumnDef="photo">-->
    <!--      <th class="overline-text" mat-header-cell *matHeaderCellDef></th>-->
    <!--      <td class="body-regular-text" mat-cell *matCellDef="let element">-->
    <!--        <img class="avatar-img" src="../../../../../assets/images/rick-astley.jpg" alt="">-->
    <!--      </td>-->
    <!--    </ng-container>-->

    <ng-container matColumnDef="lastName">
      <th class="overline-text" mat-header-cell *matHeaderCellDef>Last Name</th>
      <td class="body-regular-text" mat-cell *matCellDef="let element">
        {{ element.lastName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th class="overline-text" mat-header-cell *matHeaderCellDef>First Name</th>
      <td class="body-regular-text" mat-cell *matCellDef="let element">
        {{ element.firstName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th class="overline-text" mat-header-cell *matHeaderCellDef> E-mail</th>
      <td class="body-regular-text" mat-cell *matCellDef="let element">
        {{ element.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th class="overline-text" mat-header-cell *matHeaderCellDef>Role</th>
      <td class="body-regular-text" mat-cell *matCellDef="let element">
        {{ element.role | userRole }}
      </td>
    </ng-container>

    <ng-container matColumnDef="attended">
      <th class="overline-text" mat-header-cell *matHeaderCellDef>Attendance</th>
      <td class="body-regular-text" mat-cell *matCellDef="let element">
        <input type="checkbox" [checked]="element.attended" (change)="saveAttendance(element.id)">
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <!--  <mat-paginator [pageSizeOptions]="[1,5,10,50]" showFirstLastButtons class="body-regular-text"></mat-paginator>-->
</div>

<!--<h5>Students</h5>-->

<!--<div class="table-container">-->

<!--  <table mat-table [dataSource]="dataSource">-->

<!--    <ng-container matColumnDef="photo">-->
<!--      <th class="overline-text" mat-header-cell *matHeaderCellDef>  </th>-->
<!--      <td class="body-regular-text" mat-cell *matCellDef="let element"><img class="avatar-img" src="../../../../../assets/images/rick-astley.jpg" alt=""> </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="lastName">-->
<!--      <th class="overline-text" mat-header-cell *matHeaderCellDef> Last Name </th>-->
<!--      <td class="body-regular-text" mat-cell *matCellDef="let element"> {{element.lastName}} </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="firstName">-->
<!--      <th class="overline-text" mat-header-cell *matHeaderCellDef> First Name </th>-->
<!--      <td class="body-regular-text" mat-cell *matCellDef="let element"> {{element.firstName}} </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="email">-->
<!--      <th class="overline-text" mat-header-cell *matHeaderCellDef> E-mail </th>-->
<!--      <td class="body-regular-text" mat-cell *matCellDef="let element"> {{element.email}} </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="role">-->
<!--      <th class="overline-text" mat-header-cell *matHeaderCellDef> Role </th>-->
<!--      <td class="body-regular-text" mat-cell *matCellDef="let element"> {{element.email}} </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="options">-->
<!--      <th class="overline-text" mat-header-cell *matHeaderCellDef>  </th>-->
<!--      <td class="body-regular-text" mat-cell *matCellDef="let element"><span class="material-icons">edit</span><span-->
<!--        class="material-icons">delete</span> </td>-->
<!--    </ng-container>-->


<!--    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
<!--    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->
<!--  </table>-->
<!--&lt;!&ndash;  <mat-paginator [pageSizeOptions]="[1,5,10,50]" showFirstLastButtons class="body-regular-text"></mat-paginator>&ndash;&gt;-->
<!--</div>-->
