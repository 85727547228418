<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Which group do you want to send Student to?</h6>
    <button class="secondary-button-icons close-button" (click)="close()">
      <span class="material-icons">close</span>
    </button>
  </div>

  <div class="d-flex flex-column mt-2">
    <mat-form-field appearance="outline" class="w-100">
      <mat-select [formControl]="groupControl" class="no-global-input-styles">
        <mat-option *ngFor="let group of groups" [value]="group.id">{{
          group.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="buttons-container" mat-dialog-actions>
    <button class="secondary-button" (click)="close()">Cancel</button>
    <button class="primary-button2" cdkFocusInitial (click)="confirm()">
      Confirm
    </button>
  </div>
</div>
