import {Component, OnDestroy, OnInit} from '@angular/core';
import {DateService} from '../../../../shared/data-access/date.service';
import moment from 'moment';
import {AvailabilityService} from '../../service/availability.service';
import {NotifierService} from 'angular-notifier';
import {AvailabilityStats} from '../../model/availability-stats';
import {UserInfoService} from '../../../../static/login/service/user-info.service';
import {UserRole} from '../../../../static/constants/user-role/user-role.enum';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-interviewer-availability',
  templateUrl: './staff-availability.component.html',
  styleUrls: ['./staff-availability.component.scss']
})
export class StaffAvailabilityComponent implements OnInit, OnDestroy {

  UserRole = UserRole;

  role: UserRole;
  today: number;  // "current" time in milliseconds since the epoch
  rows: number[];
  activeTimes: Set<number>;
  activeTimesAdmin: Map<number, string[]>;
  currentTeamMemberId: number;
  availabilityStats: AvailabilityStats;
  highlightedDays: number[];
  isClicked = false;
  dateServiceSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private dateService: DateService,
    private service: AvailabilityService,
    private notifier: NotifierService,
    private userInfoService: UserInfoService
  ) {
    this.rows = [];
    this.activeTimes = new Set<number>();
    this.activeTimesAdmin = new Map<number, string[]>();
    this.highlightedDays = [];
    this.currentTeamMemberId = null;
  }

  ngOnInit(): void {
    this.role = this.userInfoService.getRole();
    this.getData();
  }

  ngOnDestroy(): void {
    this.dateServiceSubscription.unsubscribe();
  }

  private getData(): void {
    this.getAvailableDates();
    this.getAvailabilityStats();
    this.getCalendarAvailability();
  }

  private getAvailableDates(): void {
    this.dateServiceSubscription?.unsubscribe();
    this.dateServiceSubscription = this.dateService.date$.subscribe(next => {
      this.today = +next;
      const hour = moment(this.today).startOf('day');
      const nextDay = moment(hour).add(1, 'day');
      const arr: number[] = [];
      // Note that on days when the clock changes, there are either 23 or 25 hours.
      while (hour < nextDay) {
        arr.push(hour.unix());
        hour.add(1, 'hour');
      }
      this.rows = arr;
      this.service.getAll(moment(this.today).unix(), this.offset(), this.currentTeamMemberId).subscribe(
        (value: any[]) => {
          if (this.role === UserRole.ADMIN && !this.currentTeamMemberId) {
            this.activeTimesAdmin = new Map<number, string[]>(value.map(it => [it.time, it.names]));
          } else {
            this.activeTimes = new Set<number>(value);
          }
        },
        error => console.log(error)
      );
    });
  }

  private getAvailabilityStats(): void {
    this.service.getStats().subscribe({
      next: (value: AvailabilityStats) => this.availabilityStats = value,
      error: error => console.log(error)
    });
  }

  /**
   * @returns the timezone offset for the current day/time, in seconds.
   */
  private offset(): number {
    return moment(this.today).utcOffset() * 60;
  }

  getCalendarAvailability(): void {
    this.service.getCalendarAvailability(
      this.dateService.date.year(),
      this.dateService.date.month() + 1,
      this.dateService.date.utcOffset() * 60
    ).subscribe(
      (value) => this.highlightedDays = value
    );
  }

  go(dir: number): void {
    this.dateService.changeDay(dir);
  }

  goToday(): void {
    this.dateService.today();
  }

  toggleSwitch(time: number): void {
    if (this.role === UserRole.ADMIN && !this.currentTeamMemberId) {
      this.notifier.notify('warning', 'Please, select a specific team member to edit availability');
      return;
    }
    if (this.activeTimes.has(time)) {
      this.activeTimes.delete(time);
    } else {
      this.activeTimes.add(time);
    }
  }

  save(): void {
    const dto = {
      day: moment(this.today).unix(),
      availabilities: Array.from(this.activeTimes)
    };
    this.service.addAll(dto, this.offset(), this.currentTeamMemberId).subscribe({
      next: _ => {
        this.notifier.notify('success', 'saved');
        this.getAvailableDates();
        this.getCalendarAvailability();
        this.getAvailabilityStats();
      },
      error: err => this.notifier.notify('error', err.error.message)
    });
  }

  clearDay(): void {
    this.service.clearDay(moment(this.today).unix(), this.offset()).subscribe({ 
      next: _ => this.getData(),
      error: err => this.notifier.notify('error', err.error.message)
    });
  }

  isActive(time: number): boolean {
    if (this.role === UserRole.ADMIN && !this.currentTeamMemberId) {
      return this.activeTimesAdmin.has(time);
    }
    return this.activeTimes.has(time);
  }

  changeTeamMemberId(id: number): void {
    this.currentTeamMemberId = !id || isNaN(id) ? null : id;
    this.getAvailableDates();
  }
}
