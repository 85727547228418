import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { AnswersService } from '../../../../participants/manager/component/participant/answers.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import {
  ProfileQuestionsData,
  SelectedAnswer,
} from '../../../../participants/manager/model/profile-questions-data';
import { DayTimes, WeekDays } from '../../../../../shared/consts/time';
import { Subscription } from 'rxjs';
import {
  ParticipantProfile,
  StudentMiniGroupPreference,
  VolunteerSession,
} from '../../../model/participant-profile';
import { UserRole } from '../../../../../static/constants/user-role/user-role.enum';
import { InterviewService } from '../../../service/interview.service';
import { times } from 'lodash';

@Component({
  selector: 'app-edit-interview-info',
  templateUrl: './edit-interview-info.component.html',
  styleUrls: ['./edit-interview-info.component.scss'],
})
export class EditInterviewInfoComponent implements OnInit, OnDestroy {
  participant: ParticipantProfile;
  id: number;
  question: ProfileQuestionsData;
  availability: number[];
  days = WeekDays;
  times = DayTimes;
  subs = new Subscription();

  studentMiniGroupControl: FormControl;

  volunteerIndividualSessionControl: FormControl;

  volunteerMiniGroupSessionControl: FormControl;

  studentMiniGroups = [
    {
      id: StudentMiniGroupPreference.ONE_ON_ONE,
      value: 'I prefer a 1-on-1 match (1 student and 1 volunteer)',
    },
    {
      id: StudentMiniGroupPreference.MINI_GROUP,
      value:
        'I prefer a mini-group (2 students (or 3 upon request) and 1 volunteer)',
    },
    {
      id: StudentMiniGroupPreference.BOTH,
      value: 'I am comfortable with both options (fastest matching time)',
    },
  ];

  volunteerIndividualSessions: VolunteerSession[] = [
    { id: null, value: 'None' },
    ...times(20, (index) => {
      const studentCount = index + 1;
      const studentLabel = studentCount === 1 ? 'student' : 'students';

      return {
        id: studentCount,
        value: `${studentCount} ${studentLabel} (${studentCount}-hour commitment + prep time)`,
      };
    }),
  ];

  volunteerMiniGroupSessions: VolunteerSession[] = [
    { id: null, value: 'None' },
    ...times(20, (index) => {
      const miniGroupCount = index + 1;

      return {
        id: miniGroupCount,
        value: `${miniGroupCount} Mini-Groups with 2 Students (extendable to 3 students upon request) (${miniGroupCount}-hour commitment + prep time)`,
      };
    }),
  ];

  get isStudent(): boolean {
    return this.participant.userRole === UserRole.STUDENT;
  }

  get isVolunteer(): boolean {
    return this.participant.userRole === UserRole.VOLUNTEER;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      availability: ProfileQuestionsData;
      id: number;
      participant: ParticipantProfile;
    },
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private notifier: NotifierService,
    private service: AnswersService,
    private interviewService: InterviewService
  ) {
    this.participant = data.participant;
    this.id = data.id;
    this.question = data.availability;
  }

  ngOnInit(): void {
    this.availability = this.question.selectedAnswers.map((a) => a.id);
    this.studentMiniGroupControl = new FormControl(
      this.participant.miniGroup.studentMiniGroup
    );
    this.volunteerIndividualSessionControl = new FormControl(
      this.participant.miniGroup.volunteerIndividualSession
    );
    this.volunteerMiniGroupSessionControl = new FormControl(
      this.participant.miniGroup.volunteerMiniGroupSession
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onChange(value): void {
    this.availability = value;
  }

  save(): void {
    const miniGroupPreference = {
      studentMiniGroup: this.studentMiniGroupControl.value,
      volunteerIndividualSession: this.volunteerIndividualSessionControl.value,
      volunteerMiniGroupSession: this.volunteerMiniGroupSessionControl.value,
    };
    this.subs.add(
      this.interviewService
        .updateParticipantMiniGroup(this.id, miniGroupPreference)
        .subscribe()
    );

    this.subs.add(
      this.service
        .saveAnswers(this.id, { availability: this.availability })
        .subscribe({
          next: (value) => {
            this.notifier.notify('success', 'Updated successfully');
            this.dialogRef.close(true);
          },
          error: (error: HttpErrorResponse) =>
            this.notifier.notify('error', error.error.message),
        })
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  get possibleAnswers(): SelectedAnswer[] {
    return this.question.registrationQuestion.possibleAnswers;
  }
}
