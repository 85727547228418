import { Pipe, PipeTransform } from '@angular/core';
import {SubprogramType} from './subprogram-type';

@Pipe({
  name: 'subprogramType'
})
export class SubprogramTypePipe implements PipeTransform {

  transform(value: SubprogramType, ...args: unknown[]): string {
    return SubprogramType[value].toString().split('_').join(' ').toLowerCase();
  }
}
