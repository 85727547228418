import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {PartnerOrganisationCard} from './partner-organisation-card';
import {PartnerService} from './partner.service';
import {NotifierService} from 'angular-notifier';
import {HttpErrorResponse} from '@angular/common/http';
import {PartnerInviteComponent} from './partner-invite/partner-invite.component';
import {ChangePasswordDialogComponent} from '../../team/component/change-password/change-password-dialog.component';
import {Router} from '@angular/router';


@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  displayedColumns: string[];
  partnerOrganisations: PartnerOrganisationCard[];
  s: string;

  invitePartner(id?: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    dialogConfig.data = {id};
    const ref = this.dialog.open(PartnerInviteComponent, dialogConfig);
    ref.afterClosed().subscribe(_ => this.searchPartners());
  }

  constructor(
    private dialog: MatDialog,
    private service: PartnerService,
    private notifier: NotifierService,
    private router: Router
  ) {
    this.displayedColumns = ['organisationName', 'coordinatorName', 'coordinatorEmail', 'phone', 'invites', 'participants', 'notes', 'option'];
    this.partnerOrganisations = [];
    this.s = '';
  }

  ngOnInit(): void {
    this.searchPartners();
  }

  searchPartners(): void {
    this.service.getAll(this.s).subscribe({
      next: (value) => this.partnerOrganisations = value,
      error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
    });
  }

  openChangePasswordPartnerDialog(id: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    const ref = this.dialog.open(ChangePasswordDialogComponent, dialogConfig);
    ref.afterClosed().subscribe(password => {
      if (password) {
        this.service.changePartnerPassword(id, password).subscribe({
          next: _ => this.notifier.notify('success', 'Updated successfully'),
          error: (e) => this.notifier.notify('error', e.error.message)
        });
      }
    });
  }

  delete(id: number): void {
    this.service.deletePartnerOrganisation(id).subscribe({
      next: _ => {
        this.notifier.notify('success', 'Partner deleted');
        this.searchPartners();
      },
      error: (e: HttpErrorResponse) => this.notifier.notify('error', e.error.message)
    });
  }

  isActivePartner(id: number): void {
    this.service.isActiveOrganization(id).subscribe({
      next: _ => {
        const partner = this.partnerOrganisations.find(it => it.id === id);
        const index = this.partnerOrganisations.indexOf(partner);
        partner.active = !partner.active;
        this.partnerOrganisations[index] = partner;
        this.partnerOrganisations = [...this.partnerOrganisations];
      },
      error: error => this.notifier.notify('error', error.error.message)
    });
  }

  viewPartnerPage(id: number): void {
    this.router.navigateByUrl(`partners/${id}/participants`);
  }
}
