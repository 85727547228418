<div *ngIf="userRole === UserRole.VOLUNTEER">
  <label class="d-flex pb-4">{{ "Individual Format" | uppercase }}</label>
  <div class="common-info" style="grid-template-columns: repeat(2, 1fr)">
    <div class="common-info-block">
      <h4>{{ miniGroup?.volunteerIndividualSession | nullReplace }}</h4>
      <span class="overline-text">Students requested</span>
    </div>
    <div class="common-info-block">
      <h4>{{ matchedIndividualStudents.length | nullReplace }}</h4>
      <span class="overline-text">Matched students</span>
    </div>
  </div>
</div>

<div
  class="common-info"
  *ngIf="userRole === UserRole.STUDENT"
  style="grid-template-columns: repeat(3, 1fr)"
>
  <div class="common-info-block">
    <span class="body-regular-text">
      <span class="material-icons">{{
        miniGroup?.studentMiniGroup === StudentMiniGroupPreference.ONE_ON_ONE
          ? "check"
          : "remove"
      }}</span>
    </span>
    <span class="overline-text">Individual</span>
  </div>
  <div class="common-info-block">
    <span class="body-regular-text">
      <span class="material-icons">{{
        miniGroup?.studentMiniGroup === StudentMiniGroupPreference.MINI_GROUP
          ? "check"
          : "remove"
      }}</span>
    </span>
    <span class="overline-text">Mini-Group</span>
  </div>
  <div class="common-info-block">
    <span class="body-regular-text">
      <span class="material-icons">{{
        miniGroup?.studentMiniGroup === StudentMiniGroupPreference.BOTH
          ? "check"
          : "remove"
      }}</span>
    </span>
    <span class="overline-text">Both</span>
  </div>
</div>

<div
  class="section"
  *ngIf="userRole === UserRole.VOLUNTEER && matchedIndividualStudents?.length"
>
  <span class="caption-text">
    {{
      "Current match" + (matchedIndividualStudents.length > 1 ? "es" : "") + ":"
    }}
  </span>
  <div
    class="section-inner-container"
    *ngFor="let match of matchedIndividualStudents"
  >
    <div class="text-container">
      <span class="body-regular-text">{{
        match.firstName + " " + match.lastName
      }}</span>
      <span class="caption-text">{{
        match.matchDate * 1000 | date : "shortDate"
      }}</span>
      <span class="caption-text">{{ match.email }}</span>
    </div>
    <button class="secondary-button-icons" (click)="deleteMatching(match.id)">
      <span class="material-icons">delete_forever</span>
    </button>
  </div>
</div>

<div
  class="section"
  *ngIf="userRole === UserRole.STUDENT && matching?.matchedVolunteers?.length"
>
  <span class="caption-text">Current match:</span>
  <div class="section-inner-container">
    <div class="text-container">
      <span class="body-regular-text">
        {{
          matching.matchedVolunteers[0].firstName +
            " " +
            matching.matchedVolunteers[0].lastName
        }}
      </span>
      <span class="caption-text">{{
        matching.matchedVolunteers[0].matchDate * 1000 | date : "shortDate"
      }}</span>
      <span class="caption-text">{{
        matching.matchedVolunteers[0].email
      }}</span>
    </div>
    <button
      class="secondary-button-icons"
      (click)="deleteMatching(matching.matchedVolunteers[0].id)"
    >
      <span class="material-icons">delete_forever</span>
    </button>
  </div>
</div>

<div class="applied-on" *ngIf="matching.firstMatchDate">
  <span class="caption-text">First Match Date:</span>
  <span class="body-regular-text">{{
    matching.firstMatchDate * 1000 | date : "mediumDate"
  }}</span>
</div>
<div class="applied-on">
  <span class="caption-text">Days waiting</span>
  <span class="body-regular-text">{{
    matching.daysWaiting | nullReplace
  }}</span>
</div>
<!--<div id="prior-matching-container" class="applied-on">-->
<!--  <span class="caption-text">Match Priority</span>-->
<!--  <input class="apple-switch" type="checkbox">-->
<!--</div>-->

<ng-container *ngIf="userRole === UserRole.VOLUNTEER">
  <div class="pt-5">
    <label class="d-flex pb-4">{{ "Mini-Group Format" | uppercase }}</label>
    <div class="common-info" style="grid-template-columns: repeat(2, 1fr)">
      <div class="common-info-block">
        <h4>{{ miniGroup?.volunteerMiniGroupSession | nullReplace }}</h4>
        <span class="overline-text">Mini-Groups Requested</span>
      </div>
      <div class="common-info-block">
        <h4>{{ matchedMiniGroupsCount | nullReplace }}</h4>
        <span class="overline-text">Mini-Groups Matched</span>
      </div>
    </div>
  </div>

  <div class="section" *ngIf="matchedMiniGroups.length">
    <span class="caption-text">
      {{ "Current match" + (matchedMiniGroups.length > 1 ? "es" : "") + ":" }}
    </span>
    <div
      class="section-inner-container"
      *ngFor="let match of matchedMiniGroups"
    >
      <div class="text-container">
        <span class="body-regular-text"
          >{{ match.firstName + " " + match.lastName }} ({{
            match.groupName
          }})</span
        >
        <span class="caption-text">{{
          match.matchDate * 1000 | date : "shortDate"
        }}</span>
        <span class="caption-text">{{ match.email }}</span>
      </div>
      <button class="secondary-button-icons" (click)="deleteMatching(match.id)">
        <span class="material-icons">delete_forever</span>
      </button>
    </div>
  </div>

  <div class="applied-on" *ngIf="matching.firstMatchDate">
    <span class="caption-text">First Match Date:</span>
    <span class="body-regular-text">{{
      matching.firstMatchDate * 1000 | date : "mediumDate"
    }}</span>
  </div>
  <div class="applied-on">
    <span class="caption-text">Days waiting</span>
    <span class="body-regular-text">{{
      matching.daysWaiting | nullReplace
    }}</span>
  </div>
</ng-container>
