import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { NotifierService } from 'angular-notifier';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  MiniGroupPreference,
  StudentMiniGroupPreference,
} from '../../../../../../interview/model/participant-profile';
import { InterviewService } from '../../../../../../interview/service/interview.service';

@Component({
  selector: 'app-edit-mini-group',
  templateUrl: './edit-mini-group.component.html',
  styleUrls: ['./edit-mini-group.component.scss'],
})
export class EditMiniGroupComponent implements OnInit {
  participantId: number;
  miniGroup: MiniGroupPreference;

  studentMiniGroupControl: FormControl;

  studentMiniGroups = [
    {
      id: StudentMiniGroupPreference.ONE_ON_ONE,
      value: 'I prefer a 1-on-1 match (1 student and 1 volunteer)',
    },
    {
      id: StudentMiniGroupPreference.MINI_GROUP,
      value:
        'I prefer a mini-group (2 students (or 3 upon request) and 1 volunteer)',
    },
    {
      id: StudentMiniGroupPreference.BOTH,
      value: 'I am comfortable with both options (fastest matching time)',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private interviewService: InterviewService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.participantId = this.data.participantId;
    this.miniGroup = this.data.miniGroup;
    this.studentMiniGroupControl = new FormControl(this.miniGroup.studentMiniGroup);
  }

  save(): void {
    const miniGroupPreference: MiniGroupPreference = {
      ... this.miniGroup,
      studentMiniGroup: this.studentMiniGroupControl.value,
    };
    this.interviewService
      .updateParticipantMiniGroup(this.participantId, miniGroupPreference)
      .subscribe({
        next: (value) => {
          this.notifier.notify('success', 'Updated successfully');
          this.dialogRef.close(true);
        },
        error: (error: HttpErrorResponse) =>
          this.notifier.notify('error', error.error.message),
      });
  }

  close(): void {
    this.dialogRef.close();
  }
}
