<app-dashboard-card
  *ngIf="students && volunteers"
  [schema]="schema"
  [students]="students"
  [volunteers]="volunteers"
></app-dashboard-card>
<mat-button-toggle-group (change)="toggleRoleSelector($event)" name="fontStyle" aria-label="Font Style">
  <mat-button-toggle [value]="UserRole.STUDENT">Student</mat-button-toggle>
  <mat-button-toggle [value]="UserRole.VOLUNTEER">Volunteer</mat-button-toggle>
</mat-button-toggle-group>
<app-dashboard-table
  *ngIf="interviews"
  name="INTERVIEW DATA"
  [columns]="interviewDataColumns"
  [data]="interviews"
></app-dashboard-table>
<app-diagram
  title="{{ (role | userRole | uppercase) + ' DEMOGRAPHICS' }}"
  *ngIf="demographics && demographics.data.length > 0"
  [diagram]="demographics"
></app-diagram>
<app-diagram
  title="{{ (role | userRole | uppercase) + ' AGES' }}"
  *ngIf="age && age.data.length > 0"
  [diagram]="age"
></app-diagram>
<app-diagram
  title="{{ (role | userRole | uppercase) + ' TEST SCORES' }}"
  *ngIf="test && test.data.length > 0"
  [diagram]="test"
></app-diagram>
<app-diagram
  title="{{ (role | userRole | uppercase) + ' GENDERS' }}"
  *ngIf="gender && gender.data.length > 0"
  [diagram]="gender"
></app-diagram>
<app-diagram
  title="{{ (role | userRole | uppercase) + ' ENGLISH LEVELS' }}"
  *ngIf="english && english.data.length > 0"
  [diagram]="english"
></app-diagram>
<app-diagram
  title="{{ 'SOURCES OF ' + (role | userRole | uppercase) + 'S' }}"
  *ngIf="source && source.data.length > 0"
  [diagram]="source"
></app-diagram>
<app-diagram
  [title]="'BOUNCE RATE'"
  *ngIf="bounce && bounce.data.length > 0"
  [diagram]="bounce"
></app-diagram>
<app-diagram
  [title]="'SIGNUPS PER MONTH'"
  *ngIf="signup && signup.data.length > 0"
  [diagram]="signup"
></app-diagram>
<app-dashboard-table
  name="MONTHLY SUMMARY"
  [columns]="monthlySummaryColumns"
  [data]="summary"
></app-dashboard-table>
<app-dashboard-table
  name="TIME TO MATCH"
  [columns]="timeToFirstMatchColumns"
  [data]="matching"
></app-dashboard-table>
