<table mat-table [dataSource]="dataSource" class="panel" *ngIf="dataSource">

  <ng-container matColumnDef="name">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Name</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{element.name}}
    </td>
  </ng-container>

  <ng-container matColumnDef="columns">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Columns</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{element.columns | list}}
    </td>
  </ng-container>

  <ng-container matColumnDef="button">
    <th class="overline-text" mat-header-cell *matHeaderCellDef></th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      <button (click)="download(element.id, 'xlsx')" class="primary-button2">XLSX</button>
        &nbsp;
      <button (click)="download(element.id, 'csv')" class="primary-button2">CSV</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
