import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { UserRole } from '../../../static/constants/user-role/user-role.enum';
import { TrainingService } from '../training.service';
import { Subject, takeUntil } from 'rxjs';
import { TrainingCard } from '../../availability-training/model/training-card';
import { TrainingStatus, TrainingType, TrainingsDTO } from '../../availability-training/model/training';
import { TrainingFilterService } from '../training-filter.service';
import { Trainer } from '../../availability-training/model/trainer';

@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss'],
})
export class TrainingListComponent implements OnInit, OnDestroy {
  @Output() cardClick: EventEmitter<number> = new EventEmitter<number>();

  UserRole = UserRole;

  TrainingType = TrainingType;

  displayedColumns: string[];
  trainingStatuses: number[];
  dataSource: MatTableDataSource<TrainingCard>;
  maxLength: number;
  pageIndex: number;
  pageSize: number;
  filterByStartDate: string;
  filterByEndDate: string;
  filterByStatus: number;
  filterBySupervisorId: number;
  filterByType: TrainingType | null;
  trainers: Trainer[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private trainingService: TrainingService,
    private trainingFilterService: TrainingFilterService
  ) {
    this.displayedColumns = [
      'interviewTime',
      'interviewDate',
      'trainer',
      'type',
      'fullness',
      'status',
    ];
    this.trainingStatuses = Object.values(TrainingStatus).filter(
      (value) => typeof value === 'number'
    ) as number[];

    this.pageIndex = 0;
    this.pageSize = 10;
    this.filterByType = null;
  }

  ngOnInit(): void {
    this.filterByStatus = this.trainingFilterService.filterByStatus;
    this.filterByStartDate = this.trainingFilterService.filterByStartDate;
    this.filterByEndDate = this.trainingFilterService.filterByEndDate;
    this.filterBySupervisorId = this.trainingFilterService.filterBySupervisorId;
    this.filterByType = this.trainingFilterService.filterByType;
    this.getTrainings();
    this.getTrainers();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTrainings(): void {
    const startDate = this.filterByStartDate
      ? new Date(this.filterByStartDate + 'T00:00').getTime() / 1000
      : null;
    const endDate = this.filterByEndDate
      ? new Date(this.filterByEndDate + 'T00:00').getTime() / 1000 +
        24 * 60 * 60
      : null;
    this.trainingService
      .getAll(
        this.pageIndex,
        this.pageSize,
        this.filterByStatus,
        this.filterBySupervisorId,
        startDate,
        endDate,
        this.filterByType
      )
      .subscribe(
        (value: TrainingsDTO) => {
          this.dataSource = new MatTableDataSource<TrainingCard>(value.trainings);
          this.maxLength = value.length;
        },
        (error) => console.error(error)
      );
  }

  doFilter(): void {
    this.getTrainings();
    this.trainingFilterService.filterByStatus = this.filterByStatus;
    this.trainingFilterService.filterByStartDate = this.filterByStartDate;
    this.trainingFilterService.filterByEndDate = this.filterByEndDate;
    this.trainingFilterService.filterBySupervisorId = this.filterBySupervisorId;
    this.trainingFilterService.filterByType = this.filterByType;
  }

  changePage(page: PageEvent): void {
    this.pageIndex = page.pageIndex;
    this.pageSize = page.pageSize;
    this.getTrainings();
  }

  private getTrainers(): void {
    this.trainingService
      .getTrainers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.trainers = value));
  }
}
