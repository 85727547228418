import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CreateLevelComponent} from './create-level/create-level.component';
import {LevelCard} from './level-card';
import {SettingsService} from '../settings.service';
import {NotifierService} from 'angular-notifier';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-progress-levels',
  templateUrl: './progress-levels.component.html',
  styleUrls: ['./progress-levels.component.scss']
})
export class ProgressLevelsComponent implements OnInit {

  columns: string[] = ['image', 'name', 'description', 'hours'];

  levels: LevelCard[];

  constructor(
    private service: SettingsService,
    public dialog: MatDialog,
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {
    this.getLevels();
  }

  getLevels(): void {
    this.service.getLevels().subscribe(
      value => this.levels = value,
      (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
    );
  }

  createLevel(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    this.dialog.open(CreateLevelComponent, dialogConfig);
  }
}
