export enum ECountries {
    AFGHANISTAN="Afghanistan",
    AKROTIRI="Akrotiri",
    ALBANIA="Albania",
    ALGERIA="Algeria",
    AMERICAN_SAMOA="American Samoa",
    ANDORRA="Andorra",
    ANGOLA="Angola",
    ANGUILLA="Anguilla",
    ANTARCTICA="Antarctica",
    ANTIGUA_AND_BARBUDA="Antigua and Barbuda",
    ARGENTINA="Argentina",
    ARMENIA="Armenia",
    ARUBA="Aruba",
    ASHMORE_AND_CARTIER_ISLANDS="Ashmore and Cartier Islands",
    AUSTRALIA="Australia",
    AUSTRIA="Austria",
    AZERBAIJAN="Azerbaijan",
    BAHAMAS="Bahamas",
    BAHRAIN="Bahrain",
    BANGLADESH="Bangladesh",
    BARBADOS="Barbados",
    BASSAS_DA_INDIA="Bassas da India",
    BELARUS="Belarus",
    BELGIUM="Belgium",
    BELIZE="Belize",
    BENIN="Benin",
    BERMUDA="Bermuda",
    BHUTAN="Bhutan",
    BOLIVIA="Bolivia",
    BOSNIA_AND_HERZEGOVINA="Bosnia and Herzegovina",
    BOTSWANA="Botswana",
    BOUVET_ISLAND="Bouvet Island",
    BRAZIL="Brazil",
    BRITISH_INDIAN_OCEAN_TERRITORY="British Indian Ocean Territory",
    BRITISH_VIRGIN_ISLANDS="British Virgin Islands",
    BRUNEI="Brunei",
    BULGARIA="Bulgaria",
    BURKINA_FASO="Burkina Faso",
    BURMA="Burma",
    BURUNDI="Burundi",
    CAMBODIA="Cambodia",
    CAMEROON="Cameroon",
    CANADA="Canada",
    CAPE_VERDE="Cape Verde",
    CAYMAN_ISLANDS="Cayman Islands",
    CENTRAL_AFRICAN_REPUBLIC="Central African Republic",
    CHAD="Chad",
    CHILE="Chile",
    CHINA="China",
    CHRISTMAS_ISLAND="Christmas Island",
    CLIPPERTON_ISLAND="Clipperton Island",
    COCOS_KEELING_ISLANDS="Cocos (Keeling) Islands",
    COLOMBIA="Colombia",
    COMOROS="Comoros",
    CONGO_DEMOCRATIC_REPUBLIC_OF_THE="Congo, Democratic Republic of the",
    CONGO_REPUBLIC_OF_THE="Congo, Republic of the",
    COOK_ISLANDS="Cook Islands",
    CORAL_SEA_ISLANDS="Coral Sea Islands",
    COSTA_RICA="Costa Rica",
    COTE_DIVOIRE="Cote d'Ivoire",
    CROATIA="Croatia",
    CUBA="Cuba",
    CYPRUS="Cyprus",
    CZECH_REPUBLIC="Czech Republic",
    DENMARK="Denmark",
    DHEKELIA="Dhekelia",
    DJIBOUTI="Djibouti",
    DOMINICA="Dominica",
    DOMINICAN_REPUBLIC="Dominican Republic",
    ECUADOR="Ecuador",
    EGYPT="Egypt",
    EL_SALVADOR="El Salvador",
    EQUATORIAL_GUINEA="Equatorial Guinea",
    ERITREA="Eritrea",
    ESTONIA="Estonia",
    ETHIOPIA="Ethiopia",
    EUROPA_ISLAND="Europa Island",
    FALKLAND_ISLANDS_ISLAS_MALVINAS="Falkland Islands (Islas Malvinas)",
    FAROE_ISLANDS="Faroe Islands",
    FIJI="Fiji",
    FINLAND="Finland",
    FRANCE="France",
    FRENCH_GUIANA="French Guiana",
    FRENCH_POLYNESIA="French Polynesia",
    FRENCH_SOUTHERN_AND_ANTARCTIC_LANDS="French Southern and Antarctic Lands",
    GABON="Gabon",
    GAMBIA_THE="Gambia, The",
    GAZA_STRIP="Gaza Strip",
    GEORGIA="Georgia",
    GERMANY="Germany",
    GHANA="Ghana",
    GIBRALTAR="Gibraltar",
    GLORIOSO_ISLANDS="Glorioso Islands",
    GREECE="Greece",
    GREENLAND="Greenland",
    GRENADA="Grenada",
    GUADELOUPE="Guadeloupe",
    GUAM="Guam",
    GUATEMALA="Guatemala",
    GUERNSEY="Guernsey",
    GUINEA="Guinea",
    GUINEA_BISSAU="Guinea-Bissau",
    GUYANA="Guyana",
    HAITI="Haiti",
    HEARD_ISLAND_AND_MCDONALD_ISLANDS="Heard Island and McDonald Islands",
    HOLY_SEE_VATICAN_CITY="Holy See (Vatican City)",
    HONDURAS="Honduras",
    HONG_KONG="Hong Kong",
    HUNGARY="Hungary",
    ICELAND="Iceland",
    INDIA="India",
    INDONESIA="Indonesia",
    IRAN="Iran",
    IRAQ="Iraq",
    IRELAND="Ireland",
    ISLE_OF_MAN="Isle of Man",
    ISRAEL="Israel",
    ITALY="Italy",
    JAMAICA="Jamaica",
    JAN_MAYEN="Jan Mayen",
    JAPAN="Japan",
    JERSEY="Jersey",
    JORDAN="Jordan",
    JUAN_DE_NOVA_ISLAND="Juan de Nova Island",
    KAZAKHSTAN="Kazakhstan",
    KENYA="Kenya",
    KIRIBATI="Kiribati",
    KOREA_NORTH="Korea, North",
    KOREA_SOUTH="Korea, South",
    KUWAIT="Kuwait",
    KYRGYZSTAN="Kyrgyzstan",
    LAOS="Laos",
    LATVIA="Latvia",
    LEBANON="Lebanon",
    LESOTHO="Lesotho",
    LIBERIA="Liberia",
    LIBYA="Libya",
    LIECHTENSTEIN="Liechtenstein",
    LITHUANIA="Lithuania",
    LUXEMBOURG="Luxembourg",
    MACAU="Macau",
    MACEDONIA="Macedonia",
    MADAGASCAR="Madagascar",
    MALAWI="Malawi",
    MALAYSIA="Malaysia",
    MALDIVES="Maldives",
    MALI="Mali",
    MALTA="Malta",
    MARSHALL_ISLANDS="Marshall Islands",
    MARTINIQUE="Martinique",
    MAURITANIA="Mauritania",
    MAURITIUS="Mauritius",
    MAYOTTE="Mayotte",
    MEXICO="Mexico",
    MICRONESIA_FEDERATED_STATES_OF="Micronesia, Federated States of",
    MOLDOVA="Moldova",
    MONACO="Monaco",
    MONGOLIA="Mongolia",
    MONTSERRAT="Montserrat",
    MOROCCO="Morocco",
    MOZAMBIQUE="Mozambique",
    NAMIBIA="Namibia",
    NAURU="Nauru",
    NAVASSA_ISLAND="Navassa Island",
    NEPAL="Nepal",
    NETHERLANDS="Netherlands",
    NETHERLANDS_ANTILLES="Netherlands Antilles",
    NEW_CALEDONIA="New Caledonia",
    NEW_ZEALAND="New Zealand",
    NICARAGUA="Nicaragua",
    NIGER="Niger",
    NIGERIA="Nigeria",
    NIUE="Niue",
    NORFOLK_ISLAND="Norfolk Island",
    NORTHERN_MARIANA_ISLANDS="Northern Mariana Islands",
    NORWAY="Norway",
    OMAN="Oman",
    PAKISTAN="Pakistan",
    PALAU="Palau",
    PANAMA="Panama",
    PAPUA_NEW_GUINEA="Papua New Guinea",
    PARACEL_ISLANDS="Paracel Islands",
    PARAGUAY="Paraguay",
    PERU="Peru",
    PHILIPPINES="Philippines",
    PITCAIRN_ISLANDS="Pitcairn Islands",
    POLAND="Poland",
    PORTUGAL="Portugal",
    PUERTO_RICO="Puerto Rico",
    QATAR="Qatar",
    REUNION="Reunion",
    ROMANIA="Romania",
    RUSSIA="Russia",
    RWANDA="Rwanda",
    SAINT_HELENA="Saint Helena",
    SAINT_KITTS_AND_NEVIS="Saint Kitts and Nevis",
    SAINT_LUCIA="Saint Lucia",
    SAINT_PIERRE_AND_MIQUELON="Saint Pierre and Miquelon",
    SAINT_VINCENT_AND_THE_GRENADINES="Saint Vincent and the Grenadines",
    SAMOA="Samoa",
    SAN_MARINO="San Marino",
    SAO_TOME_AND_PRINCIPE="Sao Tome and Principe",
    SAUDI_ARABIA="Saudi Arabia",
    SENEGAL="Senegal",
    SERBIA_AND_MONTENEGRO="Serbia and Montenegro",
    SEYCHELLES="Seychelles",
    SIERRA_LEONE="Sierra Leone",
    SINGAPORE="Singapore",
    SLOVAKIA="Slovakia",
    SLOVENIA="Slovenia",
    SOLOMON_ISLANDS="Solomon Islands",
    SOMALIA="Somalia",
    SOUTH_AFRICA="South Africa",
    SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS="South Georgia and the South Sandwich Islands",
    SPAIN="Spain",
    SPRATLY_ISLANDS="Spratly Islands",
    SRI_LANKA="Sri Lanka",
    SUDAN="Sudan",
    SURINAME="Suriname",
    SVALBARD="Svalbard",
    SWAZILAND="Swaziland",
    SWEDEN="Sweden",
    SWITZERLAND="Switzerland",
    SYRIA="Syria",
    TAIWAN="Taiwan",
    TAJIKISTAN="Tajikistan",
    TANZANIA="Tanzania",
    THAILAND="Thailand",
    TIMOR_LESTE="Timor-Leste",
    TOGO="Togo",
    TOKELAU="Tokelau",
    TONGA="Tonga",
    TRINIDAD_AND_TOBAGO="Trinidad and Tobago",
    TROMELIN_ISLAND="Tromelin Island",
    TUNISIA="Tunisia",
    TURKEY="Turkey",
    TURKMENISTAN="Turkmenistan",
    TURKS_AND_CAICOS_ISLANDS="Turks and Caicos Islands",
    TUVALU="Tuvalu",
    UGANDA="Uganda",
    UKRAINE="Ukraine",
    UNITED_ARAB_EMIRATES="United Arab Emirates",
    UNITED_KINGDOM="United Kingdom",
    UNITED_STATES="United States",
    URUGUAY="Uruguay",
    UZBEKISTAN="Uzbekistan",
    VANUATU="Vanuatu",
    VENEZUELA="Venezuela",
    VIETNAM="Vietnam",
    VIRGIN_ISLANDS="Virgin Islands",
    WAKE_ISLAND="Wake Island",
    WALLIS_AND_FUTUNA="Wallis and Futuna",
    WEST_BANK="West Bank",
    WESTERN_SAHARA="Western Sahara",
    YEMEN="Yemen",
    ZAMBIA="Zambia",
    ZIMBABWE="Zimbabwe"
};
  
  export enum EUkraineRegions {
    CHERKASY_OBLAST="Cherkasy Oblast",
    CHERNIHIV_OBLAST="Chernihiv Oblast",
    CHERNIVTSI_OBLAST="Chernivtsi Oblast",
    DNIPROPETROVSK_OBLAST="Dnipropetrovsk Oblast",
    DONETSK_OBLAST="Donetsk Oblast",
    IVANO_FRANKIVSK_OBLAST="Ivano-Frankivsk Oblast",
    KHARKIV_OBLAST="Kharkiv Oblast",
    KHERSON_OBLAST="Kherson Oblast",
    KHMELNYTSKYI_OBLAST="Khmelnytskyi Oblast",
    KYIV_OBLAST="Kyiv Oblast",
    KIROVOHRAD_OBLAST="Kirovohrad Oblast",
    LUHANSK_OBLAST="Luhansk Oblast",
    LVIV_OBLAST="Lviv Oblast",
    MYKOLAIV_OBLAST="Mykolaiv Oblast",
    ODESA_OBLAST="Odesa Oblast",
    POLTAVA_OBLAST="Poltava Oblast",
    RIVNE_OBLAST="Rivne Oblast",
    SUMY_OBLAST="Sumy Oblast",
    TERNOPIL_OBLAST="Ternopil Oblast",
    VINNYTSIA_OBLAST="Vinnytsia Oblast",
    VOLYN_OBLAST="Volyn Oblast",
    ZAKARPATTIA_OBLAST="Zakarpattia Oblast",
    ZAPORIZHIA_OBLAST="Zaporizhia Oblast",
    ZHYTOMYR_OBLAST="Zhytomyr Oblast"
  }
  
  export enum EUSStates {
    ALABAMA="Alabama",
    ALASKA="Alaska",
    ARIZONA="Arizona",
    ARKANSAS="Arkansas",
    CALIFORNIA="California",
    COLORADO="Colorado",
    CONNECTICUT="Connecticut",
    DELAWARE="Delaware",
    FLORIDA="Florida",
    GEORGIA="Georgia",
    HAWAII="Hawaii",
    IDAHO="Idaho",
    ILLINOIS="Illinois",
    INDIANA="Indiana",
    IOWA="Iowa",
    KANSAS="Kansas",
    KENTUCKY="Kentucky",
    LOUISIANA="Louisiana",
    MAINE="Maine",
    MARYLAND="Maryland",
    MASSACHUSETTS="Massachusetts",
    MICHIGAN="Michigan",
    MINNESOTA="Minnesota",
    MISSISSIPPI="Mississippi",
    MISSOURI="Missouri",
    MONTANA="Montana",
    NEBRASKA="Nebraska",
    NEVADA="Nevada",
    NEW_HAMPSHIRE="New Hampshire",
    NEW_JERSEY="New Jersey",
    NEW_MEXICO="New Mexico",
    NEW_YORK="New York",
    NORTH_CAROLINA="North Carolina",
    NORTH_DAKOTA="North Dakota",
    OHIO="Ohio",
    OKLAHOMA="Oklahoma",
    OREGON="Oregon",
    PENNSYLVANIA="Pennsylvania",
    RHODE_ISLAND="Rhode Island",
    SOUTH_CAROLINA="South Carolina",
    SOUTH_DAKOTA="South Dakota",
    TENNESSEE="Tennessee",
    TEXAS="Texas",
    UTAH="Utah",
    VERMONT="Vermont",
    VIRGINIA="Virginia",
    WASHINGTON="Washington",
    WASHINGTON_DC="Washington, DC",
    WEST_VIRGINIA="West Virginia",
    WISCONSIN="Wisconsin",
    WYOMING="Wyoming"
  }
  