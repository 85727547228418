import {Component, OnInit} from '@angular/core';
import {UserInfoService} from '../../../static/login/service/user-info.service';
import {UserRole} from '../../../static/constants/user-role/user-role.enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  role: UserRole;
  UserRole = UserRole;

  constructor(private roleSelectorService: UserInfoService) {
    this.role = roleSelectorService.getRole();
  }
}
