import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { }

  filterByDay: number = null;
  filterByStatus = 100;
  filterByRole: number = null;
  filterByDecision: number = null;
}
