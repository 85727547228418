<div class="panel">
  <h5 class="header">Acceptance criteria</h5>
  <div class="inputs-container">
    <span class="overline-text">Volunteer's age</span>
    <input type="number" placeholder="Min age" [(ngModel)]="criteria.volunteerMinAge">
    <input type="number" placeholder="Max age" [(ngModel)]="criteria.volunteerMaxAge">
  </div>
  <div class="inputs-container">
    <span class="overline-text">Student's age</span>
    <input type="number" placeholder="Min age" [(ngModel)]="criteria.studentMinAge">
    <input type="number" placeholder="Max age" [(ngModel)]="criteria.studentMaxAge">
  </div>
  <div class="inputs-container">
    <span class="overline-text">Placement test score</span>
    <input type="number" [(ngModel)]="criteria.studentMinTest">
    <span class="overline-text">Training test score</span>
    <input type="number" [(ngModel)]="criteria.volunteerMinTraining">
  </div>
  <div class="inputs-container">
    <span class="overline-text">Students under 18</span>
    <input type="number" [(ngModel)]="criteria.studentsUnderEighteenLimit">
    <span class="overline-text">Students after 18</span>
    <input type="number" [(ngModel)]="criteria.studentsAfterEighteenLimit">
  </div>
  <div class="inputs-container">
    <span class="overline-text">Volunteers under 18</span>
    <input type="number" [(ngModel)]="criteria.volunteersUnderEighteenLimit">
    <span class="overline-text">Volunteers after 18</span>
    <input type="number" [(ngModel)]="criteria.volunteersAfterEighteenLimit">
  </div>
  <div class="buttons-container">
    <button class="primary-button2" (click)="setCriteria()">save changes</button>
  </div>
</div>
