<div class="loader">
    <div class="logo">
        <svg class="spinner" viewBox="25 25 50 50">
            <circle
                    class="path"
                    cx="50"
                    cy="50"
                    r="20"
                    fill="none"
                    stroke-width="2"
                    stroke-miterlimit="10"
            />
        </svg>
    </div>
</div>
