import { Component, OnInit } from '@angular/core';
import { MatchingService } from '../../service/matching.service';
import { ParticipantDto } from '../../model/participant-dto';
import { ParticipantCardDto } from '../../model/participant-card-dto';
import { NotifierService } from 'angular-notifier';
import { HttpErrorResponse } from '@angular/common/http';
import { UserRole } from '@static/constants/user-role/user-role.enum';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmWindowComponent } from '../warning-window/confirm-window.component';
import { PreviousMatchDto } from '../../model/previous-match-dto';
import { SubprogramsService } from '../../../settings/subprograms/subprograms.service';
import { SubprogramCard } from '../../../settings/subprograms/subprogram-card';
import { ProfileAnswersData } from '../../../participants/manager/model/profile-questions-data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { concat, EMPTY, Observable, tap, throwError } from 'rxjs';
import { PageableResponse } from '../../../../shared/types/pageable.response';
import { catchError, switchMap } from 'rxjs/operators';
import {
  MiniGroupPreference,
  StudentMiniGroupPreference,
} from '../../../interview/model/participant-profile';
import { ParticipantService } from '../../../participants/manager/service/participant.service';
import { MatchingDialogComponent } from '../matching-dialog/matching-dialog.component';
import {
  MatchingInfo,
  ParticipantDetails,
} from '../../../participants/manager/model/participant-details';

@UntilDestroy()
@Component({
  selector: 'app-matching',
  templateUrl: './matching.component.html',
  styleUrls: ['./matching.component.scss'],
})
export class MatchingComponent implements OnInit {
  columnNames: string[] = ['subProgramName', 'subProgramColor'];
  students: ParticipantCardDto[];
  volunteers: ParticipantCardDto[];
  subprograms: SubprogramCard[];
  totalStudents: number;
  totalVolunteers: number;
  studentAges: number[];
  volunteerAges: number[];
  student?: ParticipantDto | null;
  studentProfileQuestionsData: ProfileAnswersData;
  volunteerProfileQuestionsData: ProfileAnswersData;
  volunteer?: ParticipantDto | null;
  studentAge?: number | null;
  studentsProgramFormat: StudentMiniGroupPreference | null = null;
  volunteersProgramFormat: StudentMiniGroupPreference | null = null;
  studentsEnglishLevel: number | null = null;
  volunteersEnglishLevel: number | null = null;
  volunteerAge?: number | null;
  studentSearch: string;
  volunteerSearch: string;
  selectedSubprogramStudents?: number | null;
  selectedSubprogramVolunteers?: number | null;

  programFormatOptions = [
    {
      id: StudentMiniGroupPreference.ONE_ON_ONE,
      value: 'Individual',
    },
    {
      id: StudentMiniGroupPreference.MINI_GROUP,
      value: 'Mini-Group',
    },
    {
      id: StudentMiniGroupPreference.BOTH,
      value: 'Both',
    },
  ];

  englishLevelOptions = [
    { id: 10, value: 'Acceptable' },
    { id: 11, value: 'Good' },
    { id: 12, value: 'Excellent' },
    { id: 20, value: 'Unacceptable' },
  ];

  volunteerMatching: MatchingInfo;
  studentPreferences: MiniGroupPreference;
  volunteerPreferences: MiniGroupPreference;

  UserRole = UserRole;

  constructor(
    private matchingService: MatchingService,
    private notifier: NotifierService,
    private subProgramService: SubprogramsService,
    private participantService: ParticipantService,
    private dialog: MatDialog
  ) {
    this.students = [];
    this.volunteers = [];
    this.studentAge = null;
    this.volunteerAge = null;
    this.studentSearch = '';
    this.volunteerSearch = '';
    this.subprograms = [];
    this.selectedSubprogramStudents = null;
    this.selectedSubprogramVolunteers = null;
  }

  ngOnInit(): void {
    this.matchingService.getAges(UserRole.STUDENT).subscribe(
      (value: number[]) => (this.studentAges = value),
      (error) => console.log(error)
    );
    this.matchingService.getAges(UserRole.VOLUNTEER).subscribe(
      (value: number[]) => (this.volunteerAges = value),
      (error) => console.log(error)
    );
    this.getVolunteersPage(0);
    this.getStudentsPage(0);
    this.getAllSubprograms();
  }

  cancelStudentProfile(): void {
    this.student = null;
    this.getVolunteersPage(0);
    this.getStudentsPage(0, this.volunteer?.id);
  }

  cancelVolunteerProfile(): void {
    this.volunteer = null;
    this.getStudentsPage(0);
    this.getVolunteersPage(0, this.student?.id);
  }

  get inactiveButton(): boolean {
    return !this.student || !this.volunteer;
  }

  public getStudentsPage(page: number, id?: number | null): void {
    this.matchingService
      .getStudentCards(
        page,
        this.studentAge,
        this.studentSearch,
        this.selectedSubprogramStudents,
        id,
        this.studentsProgramFormat,
        this.studentsEnglishLevel
      )
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.students = value.content;
        this.totalStudents = value.totalElements;
      });
  }

  public getVolunteersPage(page: number, id?: number | null): void {
    this.matchingService
      .getVolunteerCards(
        page,
        this.volunteerAge,
        this.volunteerSearch,
        this.selectedSubprogramVolunteers,
        id,
        this.volunteersProgramFormat,
        this.volunteersEnglishLevel
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (value) => {
          this.volunteers = value.content;
          this.totalVolunteers = value.totalElements;
        },
        (error) => console.log(error)
      );
  }

  searchStudents(): void {
    this.getStudentsPage(0, this.volunteer?.id);
  }

  searchVolunteers(): void {
    this.getVolunteersPage(0, this.student?.id);
  }

  public getStudent(studentId: number): void {
    const streams = [
      this.loadStudent(studentId),
      this.loadStudentPreferences(studentId, UserRole.STUDENT),
      this.loadQuestionData(studentId, UserRole.STUDENT),
      this.getPossibleMatches(studentId),
    ];
    concat(...streams)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  public getVolunteer(id: number): void {
    const streams = [
      this.loadVolunteer(id),
      this.loadVolunteerPreferences(id, UserRole.VOLUNTEER),
      this.loadQuestionData(id, UserRole.VOLUNTEER),
      this.getPossibleMatches(id, false),
    ];

    concat(...streams)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  public match(): void {
    if (!this.student || !this.volunteer) {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';

    const dialogRef = this.dialog.open(MatchingDialogComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((groupId) => {
          if (groupId != null) {
            return this.matchingService.match(
              this.student.id,
              this.volunteer.id,
              groupId
            );
          } else {
            return EMPTY;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (value) => {
          this.notifier.notify('success', value.response);
          this.cancelVolunteerProfile();
          this.cancelStudentProfile();
          this.getVolunteersPage(0);
          this.getStudentsPage(0);
        },
        (error: HttpErrorResponse) => {
          this.notifier.notify('error', error.error.message);
          this.cancelVolunteerProfile();
          this.cancelStudentProfile();
          this.getVolunteersPage(0);
          this.getStudentsPage(0);
        }
      );
  }

  public warnAboutPreviousMatch(previousMatch: PreviousMatchDto): void {
    const start = new Date(previousMatch.startDate * 1000).toLocaleDateString();
    const end = new Date(previousMatch.endDate * 1000).toLocaleDateString();
    const dialogRef = this.dialog.open(ConfirmWindowComponent, {
      disableClose: true,
      autoFocus: true,
      data: {
        warning: `This volunteer and student were previously matched from ${start} to ${end}.`,
        confirmButtonName: 'Confirm Match',
        cancelButtonName: 'Cancel',
      },
    });
    dialogRef.afterClosed().subscribe((update: boolean) => {
      if (update) {
        this.match();
      }
    });
  }

  checkWarnings(): void {
    if (!this.student || !this.volunteer) {
      return;
    }

    this.matchingService
      .previousMatch(this.student.id, this.volunteer.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (previousMatch: PreviousMatchDto) => {
          this.warnAboutPreviousMatch(previousMatch);
        },
        error: (err: Error) => {
          // No previous match found
          this.match();
        },
      });
  }

  public getAllSubprograms(): void {
    this.subProgramService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => (this.subprograms = value),
        error: (error: HttpErrorResponse) =>
          this.notifier.notify('error', error.error.message),
      });
  }

  private loadStudent(studentId: number): Observable<ParticipantDto> {
    return this.matchingService.getStudent(studentId).pipe(
      tap((student) => (this.student = student)),
      catchError((error) => {
        this.notifier.notify('error', error.error.message);
        return throwError(error);
      })
    );
  }

  private loadStudentPreferences(
    studentId: number,
    role: UserRole
  ): Observable<ParticipantDetails> {
    return this.participantService.getParticipantDetails(studentId, role).pipe(
      tap((student) => (this.studentPreferences = student.miniGroup)),
      catchError((error) => {
        this.notifier.notify('error', error.error.message);
        return throwError(error);
      })
    );
  }

  private loadVolunteerPreferences(
    id: number,
    role: UserRole
  ): Observable<ParticipantDetails> {
    return this.participantService.getParticipantDetails(id, role).pipe(
      tap((volunteer) => {
        this.volunteerPreferences = volunteer.miniGroup;
        this.volunteerMatching = volunteer.matching;
      }),
      catchError((error) => {
        this.notifier.notify('error', error.error.message);
        return throwError(error);
      })
    );
  }

  private loadVolunteer(id: number): Observable<ParticipantDto> {
    return this.matchingService
      .getVolunteer(id)
      .pipe(tap((value: ParticipantDto) => (this.volunteer = value)));
  }

  private loadQuestionData(
    id: number,
    role: UserRole.STUDENT | UserRole.VOLUNTEER
  ): Observable<ProfileAnswersData> {
    return this.matchingService.getQuestionsData(id, role).pipe(
      tap((value: ProfileAnswersData) => {
        if (role === UserRole.VOLUNTEER) {
          this.volunteerProfileQuestionsData = value;
        } else {
          this.studentProfileQuestionsData = value;
        }
      })
    );
  }

  private getPossibleMatches(
    participantId: number,
    isStudentMatch = true
  ): Observable<PageableResponse<ParticipantCardDto>> {
    return this.matchingService
      .getPossibleMatches({
        participantId,
        roleId: isStudentMatch ? UserRole.VOLUNTEER : UserRole.STUDENT,
      })
      .pipe(
        tap((v) => {
          if (isStudentMatch) {
            this.volunteers = v.content;
            this.totalVolunteers = v.totalElements;
          } else {
            this.students = v.content;
            this.totalStudents = v.totalElements;
          }
        })
      );
  }
}
