import { Component, Input, OnInit } from '@angular/core';
import { InterviewService } from '../../service/interview.service';
import { NotifierService } from 'angular-notifier';
import { HttpErrorResponse } from '@angular/common/http';
import { ParticipantProfile } from '../../model/participant-profile';
import { InterviewStatus } from '@static/constants/interview-status/interview-status.enum';
import { PaymentRequirement } from '../../../participants/manager/model/payment.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, FormGroup } from '@angular/forms';
import { UserRole } from '@static/constants/user-role/user-role.enum';
import { Interview, InterviewType } from '../../model/interview';

@UntilDestroy()
@Component({
  selector: 'app-interview-overview-log',
  templateUrl: './interview-overview-log.component.html',
  styleUrls: ['./interview-overview-log.component.scss']
})
export class InterviewOverviewLogComponent implements OnInit {
  englishLevels = [
    {name: 'Unacceptable', value: 20},
    {name: 'Acceptable', value: 10},
    {name: 'Good', value: 11},
    {name: 'Excellent', value: 12}
  ];

  @Input() id: number;
  @Input() interview: Interview;
  @Input() participant: ParticipantProfile;

  completed: boolean;
  decisions = [
    100,
    201,
    302,
    301,
    303,
    304,
    305,
    306,
    300
  ];
  paymentRequirement = PaymentRequirement;

  formGroup = new FormGroup({
    englishLevel: new FormControl(),
    interviewDecision: new FormControl(),
    comment: new FormControl(),
    paymentRequirement: new FormControl(),
    readyToBeVolunteer: new FormControl(),
  });

  constructor(
    private interviewService: InterviewService,
    private notifier: NotifierService
  ) {
  }

  ngOnInit(): void {
    this.completed = this.interview.interviewStatus === InterviewStatus.COMPLETED;
    this.formGroup.patchValue({
      comment: this.interview.comment,
      englishLevel: this.participant.englishLevel,
      interviewDecision: this.interview.decision,
      paymentRequirement: this.participant.paymentRequirement ?? PaymentRequirement.NO_NEED_REGISTERED_BEFORE_APR_15_2024,
      readyToBeVolunteer: this.participant.readyToBeVolunteer,
    });
  }

  paymentRequirementChanged(paymentRequirement: PaymentRequirement): void {
    this.participant.paymentRequirement = paymentRequirement;
    this.formGroup.patchValue({paymentRequirement});
  }

  get selectedPaymentRequirement(): PaymentRequirement {
    return this.formGroup.get('paymentRequirement').value;
  }

  save(): void {
    const payload = {
      ...this.formGroup.value,
    };

    this.interviewService.setInterviewDetails(this.id, payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: _ => {
          this.interview.interviewStatus = InterviewStatus.COMPLETED;
          this.completed = true;
          this.notifier.notify('success', 'Saved');
        },
        error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
      });
  }

  get isStudent(): boolean {
    return this.participant.userRole === UserRole.STUDENT;
  }

  get isOnboarding(): boolean {
    return this.interview.type === InterviewType.ONBOARDING;
  }
}
