export const SessionsDuration = [
  {value: null, label: 'Choose duration'},
  {value: 30, label: '30 min'},
  {value: 45, label: '45 min'},
  {value: 60, label: '1 hour'},
  {value: 75, label: '1 hour 15 min'},
  {value: 90, label: '1 hour 30 min'},
  {value: 105, label: '1 hour 45 min'},
  {value: 120, label: '2 hours'},
  {value: 135, label: '2 hours 15 min'},
  {value: 150, label: '2 hours 30 min'},
  {value: 165, label: '2 hours 45 min'},
  {value: 180, label: '3 hours'},
  {value: 0, label: 'Session didn\'t happen'}
];
