import moment, {Moment, unix} from 'moment';
import {Day, Week} from '../models/date.interface';

export const generateCalendarForDate = (dateNow: Moment, daysToHighlight?: number[]): Week[] => {
  const startDay = dateNow.clone().startOf('month').startOf('week');
  const endDay = dateNow.clone().endOf('month').endOf('week');
  const date = startDay.clone().subtract(1, 'day');
  const calendar: Week[] = [];

  while (date.isBefore(endDay, 'day')) {
    const week: Day[] = Array.from({length: 7}, () => date.add(1, 'day').clone()).map((value) => ({
      value,
      active: moment().isSame(value, 'date'),
      disabled: !dateNow.isSame(value, 'month'),
      selected: dateNow.isSame(value, 'date'),
      highlighted:
        daysToHighlight?.some((highlightedDate) => unix(highlightedDate).isSame(value, 'day')) ??
        false,
    }));

    calendar.push({days: week});
  }

  return calendar;
};
