import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { StaffProfileComponent } from './features/profile/component/staff-profile/staff-profile.component';
import { SourcesComponent } from './features/settings/sources/sources.component';
import { EditSourceComponent } from './features/settings/sources/edit-source/edit-source.component';
import { ProgressLevelsComponent } from './features/settings/progress-levels/progress-levels.component';
import { CreateLevelComponent } from './features/settings/progress-levels/create-level/create-level.component';
import { AppComponent } from './app.component';
import { AdminDashboardComponent } from './features/dashboard/admin/component/admin-dashboard/admin-dashboard.component';
import { AdminDashboardDiagramComponent } from './features/dashboard/shared/component/dashboard-diagram/admin-dashboard-diagram.component';
import { AdminDashboardTableComponent } from './features/dashboard/shared/component/dashboard-table/admin-dashboard-table.component';
import { AcceptanceCriteriaComponent } from './features/settings/acceptance-criteria/acceptance-criteria.component';
import { SettingsComponent } from './features/settings/settings.component';
import { AdminTeamEditMemberComponent } from './features/team/component/admin-team-edit-member/admin-team-edit-member.component';
import { EditProfileNotesComponent } from './features/participants/manager/component/participant/modal-windows/edit-profile/edit-profile-notes/edit-profile-notes.component';
import { EditProfileComponent } from './features/participants/manager/component/participant/modal-windows/edit-profile/edit-profile.component';
import { AdminTeamComponent } from './features/team/component/admin-team/admin-team.component';
import { InterviewOverviewLogComponent } from './features/interview/component/interview-overview-log/interview-overview-log.component';
import { InterviewOverviewComponent } from './features/interview/component/interview-overview/interview-overview.component';
import { InterviewOverviewProfileComponent } from './features/interview/component/interview-overview-profile/interview-overview-profile.component';
import { StaffAvailabilityComponent } from './features/availability/component/staff-availability/staff-availability.component';
import { MarketerDashboardComponent } from './features/dashboard/marketer/component/marketer-dashboard/marketer-dashboard.component';
import { SessionHistoryComponent } from './features/participants/manager/component/participant/modal-windows/session-history/session-history.component';
import { MatchingComponent } from './features/matching/component/matching/matching.component';
import { MatchingProfileComponent } from './features/matching/component/matching-profile/matching-profile.component';
import { MatchingTableComponent } from './features/matching/component/matching-table/matching-table.component';
import { DashboardCardComponent } from './features/dashboard/shared/component/dashboard-card/dashboard-card.component';
import { WeAreDownComponent } from '@static/error-page/we-are-down/we-are-down.component';
import { NotFoundPageComponent } from '@static/error-page/not-found-page/not-found-page.component';
import { ManagerParticipantsComponent } from './features/participants/manager/component/participant/manager-participants/manager-participants.component';
import { ParticipantsTableComponent } from './features/participants/manager/component/participant/participants-table/participants-table.component';
import { ParticipantProfileComponent } from './features/participants/manager/component/participant/participant-details/participant-profile/participant-profile.component';
import { ParticipantMatchingSettingsComponent } from './features/participants/manager/component/participant/participant-details/participant-matching-settings/participant-matching-settings.component';
import { ParticipantDetailsComponent } from './features/participants/manager/component/participant/participant-details/participant-details.component';
import { CancelWindowComponent } from './features/participants/manager/component/participant/modal-windows/cancel-window/cancel-window.component';
import { HistoryWindowComponent } from './features/participants/manager/component/participant/modal-windows/history-window/history-window.component';
import { DeleteProfileComponent } from './features/participants/manager/component/participant/modal-windows/delete-profile/delete-profile.component';
import { ToolbarMenuComponent } from '@static/toolbar/component/toolbar-menu/toolbar-menu.component';
import { PageMenuBarComponent } from '@static/page-menu-bar/page-menu-bar.component';
import { HeaderComponent } from '@static/header/component/header/header.component';
import { ParticipantInterviewComponent } from './features/participants/manager/component/participant/participant-details/participant-interview/participant-interview.component';
import { ParticipantMatchingComponent } from './features/participants/manager/component/participant/participant-details/participant-matching/participant-matching.component';
import { ParticipantSessionHistoryComponent } from './features/participants/manager/component/participant/participant-details/participant-session-history/participant-session-history.component';
import { ParticipantHistoryBlockComponent } from './features/participants/manager/component/participant/participant-details/participant-history-block/participant-history-block.component';
import { ParticipantProfileNotesComponent } from './features/participants/manager/component/participant/participant-details/participant-profile-notes/participant-profile-notes.component';
import { EditProfileBasicInfoComponent } from './features/participants/manager/component/participant/modal-windows/edit-profile/edit-profile-basic-info/edit-profile-basic-info.component';
import { EditProfileMatchingSettingsComponent } from './features/participants/manager/component/participant/modal-windows/edit-profile/edit-profile-matching-settings/edit-profile-matching-settings.component';
import { EditProfileInterviewComponent } from './features/participants/manager/component/participant/modal-windows/edit-profile/edit-profile-interview/edit-profile-interview.component';
import { GroupSessionComponent } from './features/group-session/component/group-session/group-session.component';
import { GroupSessionParticipantsComponent } from './features/group-session/component/group-session-participants/group-session-participants.component';
import { InterviewerDashboardComponent } from './features/dashboard/interviewer/component/interviewer-dashboard/interviewer-dashboard.component';
import { InterviewListComponent } from './features/interview/component/interview-list/interview-list.component';
import { GroupSessionCreateComponent } from './features/group-session/component/group-session-create/group-session-create.component';
import { NotificationsListComponent } from './.deprecated/component/notifications/notifications-list/notifications-list.component';
import { NotificationsComponent } from './features/settings/notifications/notifications.component';
import { NotificationsListSystemComponent } from './.deprecated/component/notifications/notifications-list-system/notifications-list-system.component';
import { NotificationsListAnnouncementsComponent } from './.deprecated/component/notifications/notifications-list-announcements/notifications-list-announcements.component';
import { EditMessageTemplateWindowComponent } from './.deprecated/component/notifications/modal-windows/edit-message-template-window/edit-message-template-window.component';
import { EditMessageTemplateComponent } from './.deprecated/component/notifications/modal-windows/edit-message-template-window/edit-message-template/edit-message-template.component';
import { EditMessageTemplateTelegramComponent } from './.deprecated/component/notifications/modal-windows/edit-message-template-window/edit-message-template-telegram/edit-message-template-telegram.component';
import { EditMessageAnnouncementWindowComponent } from './.deprecated/component/notifications/modal-windows/edit-message-announcement-window/edit-message-announcement-window.component';
import { MessageAnnouncementComponent } from './.deprecated/component/notifications/modal-windows/edit-message-announcement-window/message-announcement/message-announcement.component';
import { MatchingHistoryComponent } from './features/participants/manager/component/participant/modal-windows/matching-history/matching-history.component';
import { ChangeHistoryComponent } from './features/participants/manager/component/participant/modal-windows/change-history/change-history.component';
import { MatcherDashboardComponent } from './features/dashboard/matcher/component/matcher-dashboard/matcher-dashboard.component';
import { DashboardPipe } from './features/dashboard/interviewer/pipe/dashboard.pipe';
import { LoginComponent } from '@static/login/component/login/login.component';
import { TableComponent } from '@static/table/component/table/table.component';
import { PartnerDashboardComponent } from './features/dashboard/partner/component/partner-dashboard/partner-dashboard.component';
import { PartnerParticipantsComponent } from './features/participants/partner/component/partner-participants/partner-participants.component';
import { PartnerInviteComponent } from './features/settings/partners/partner-invite/partner-invite.component';
import { UserRolePipe } from '@static/constants/user-role/user-role.pipe';
import { InterviewStatusPipe } from '@static/constants/interview-status/interview-status.pipe';
import { CapitalCasePipe } from '@static/pipes/capital-case.pipe';
import { EmailTemplateComponent } from '@static/email-template/email-template.component';
import { GroupSessionRowComponent } from './features/group-session/component/group-session-row/group-session-row.component';
import { DatepickerComponent } from '@static/datepicker/datepicker.component';
import { MatNativeDateModule } from '@angular/material/core';
import { GroupSessionListComponent } from './features/group-session/component/group-session-list/group-session-list.component';
import { AppRoutes } from './config/constant/app-routes';
import { AgePipe } from '@static/pipes/age.pipe';
import { GenderPipe } from '@static/constants/gender/gender.pipe';
import { EnglishPipe } from '@static/constants/english/english.pipe';
import { ListPipe } from '@static/pipes/list.pipe';
import { NullReplacePipe } from '@static/pipes/null-replace.pipe';
import { TimeZoneInfoPipe } from '@static/pipes/time-zone-info.pipe';
import { InterviewDecisionPipe } from '@static/constants/interview-decision/interview-decision.pipe';
import { TokenInterceptor } from './config/token.interceptor';
import { ZoomComponent } from '@static/zoom/zoom/zoom.component';
import { ParticipantStatusPipe } from '@static/constants/participant-status/participant-status.pipe';
import { NotifierModule } from 'angular-notifier';
import { ChangePasswordComponent } from '@static/login/component/change-password/change-password.component';
import { ForgotPasswordComponent } from '@static/login/component/forgot-password/forgot-password.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { PartnersComponent } from './features/settings/partners/partners.component';
import { PartnerComponent } from './features/settings/partners/partner/partner.component';
import { EditCommentComponent } from './features/participants/manager/component/participant/modal-windows/edit-comment/edit-comment.component';
import { ScheduleSessionComponent } from './features/participants/manager/component/participant/modal-windows/schedule-session/schedule-session.component';
import { PartnerParticipantStatusPipe } from './features/participants/partner/component/partner-participants/partner-participant-status.pipe';
import { InviteParticipantComponent } from './features/participants/partner/component/invite-participant/invite-participant.component';
import { EditMatchingProfileComponent } from './features/participants/manager/component/participant/modal-windows/edit-matching-profile/edit-matching-profile.component';
import { SelectStaffComponent } from './features/availability/component/select-staff/select-staff.component';
import { FailedMatchesComponent } from './features/developer/failed-matches/failed-matches.component';
import { ParticipantsComponent } from './features/participants/participants/participants.component';
import { DashboardComponent } from './features/dashboard/dashboard/dashboard.component';
import { ChangePasswordDialogComponent } from './features/team/component/change-password/change-password-dialog.component';
import { GenderPreferencePipe } from '@static/constants/gender-preference/gender-preference.pipe';
import { ConfirmWindowComponent } from './features/matching/component/warning-window/confirm-window.component';
import { EditMatchingDetailsComponent } from './features/participants/manager/component/participant/modal-windows/edit-matching-details/edit-matching-details.component';
import { ParticipantAutomatedEmailsComponent } from './features/participants/manager/component/participant/participant-details/participant-automated-emails/participant-automated-emails.component';
import { MatDividerModule } from '@angular/material/divider';
import { NumbersOnlyModule } from './shared/directives/numbers-only/numbers-only.module';
import { LoaderModule } from './shared/components/loader/loader.module';
import { NoDataModule } from './shared/components/no-data/no-data.module';
import { VolunteerPreferredLevelPipe } from '@static/constants/english/volunteer-preferred-level.pipe';
import { ImpactComponent } from './features/impact/component/impact.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ParticipantPreviousMatchesComponent } from './features/participants/manager/component/participant/participant-details/participant-previous-matches/participant-previous-matches.component';
import { ParticipantImpactMeasurementComponent } from './features/participants/manager/component/participant/participant-details/participant-impact-measurement/participant-impact-measurement.component';
import { CalendarModule } from './calendar/calendar.module';
import { ExportComponent } from './features/settings/export/export.component';
import { SubprogramsComponent } from './features/settings/subprograms/subprograms.component';
import { EditSubprogramComponent } from './features/settings/subprograms/edit-subprogram/edit-subprogram.component';
import { ParticipantChangesHistoryComponent } from './features/participants/manager/component/participant/participant-details/participant-changes-history/participant-changes-history.component';
import { SubprogramTypePipe } from './features/settings/subprograms/subprogram-type.pipe';
import { SelectedAnswerListPipe } from './features/matching/component/matching-profile/selected-answer-list.pipe';
import { PaymentSelectModule } from './shared/components/payment-select/payment-select.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditInterviewInfoComponent } from './features/interview/component/interview-overview-profile/edit-interview-info/edit-interview-info.component';
import { TimezonePublicService } from './features/timezone/timezone-public.service';
import { InterviewTypeNamePipe } from './static/pipes/interview-type-name.pipe';
import { ParticipantProfileFreezeComponent } from './features/participants/manager/component/participant/participant-details/participant-profile-freeze/participant-profile-freeze.component';
import { EditMiniGroupComponent } from './features/participants/manager/component/participant/modal-windows/edit-mini-group/edit-mini-group.component';
import { MatchingDialogComponent } from './features/matching/component/matching-dialog/matching-dialog.component';
import { AvailabilityTrainingComponent } from './features/availability-training/availability-training.component';
import { CreateTrainingComponent } from './features/availability-training/create-training/create-training.component';
import { TrainingCardComponent } from './features/availability-training/training-card/training-card.component';
import { TrainingListComponent } from './features/trainings/training-list/training-list.component';
import { TrainingTypePipe } from './static/constants/training-type/training-type.pipe';
import { TrainingStatusPipe } from './static/constants/training-status/training-status.pipe';
import { TrainingOverviewComponent } from './features/trainings/training-overview/training-overview.component';
import { TrainerDashboardComponent } from './features/dashboard/trainer/component/trainer-dashboard/trainer-dashboard.component';
import { TrainerDashboardPipe } from './features/dashboard/trainer/pipe/trainer-dashboard.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminDashboardComponent,
    AdminDashboardDiagramComponent,
    AdminDashboardTableComponent,
    AcceptanceCriteriaComponent,
    SettingsComponent,
    AdminTeamComponent,
    AdminTeamEditMemberComponent,
    EditProfileComponent,
    EditProfileNotesComponent,
    InterviewOverviewLogComponent,
    InterviewOverviewComponent,
    InterviewOverviewLogComponent,
    InterviewOverviewProfileComponent,
    StaffAvailabilityComponent,
    MarketerDashboardComponent,
    SessionHistoryComponent,
    MatchingComponent,
    MatchingProfileComponent,
    MatchingTableComponent,
    DashboardCardComponent,
    WeAreDownComponent,
    NotFoundPageComponent,
    HeaderComponent,
    PageMenuBarComponent,
    ToolbarMenuComponent,
    ManagerParticipantsComponent,
    ParticipantsTableComponent,
    DeleteProfileComponent,
    HistoryWindowComponent,
    CancelWindowComponent,
    ParticipantDetailsComponent,
    ParticipantProfileComponent,
    ParticipantMatchingSettingsComponent,
    ParticipantInterviewComponent,
    ParticipantMatchingComponent,
    ParticipantSessionHistoryComponent,
    ParticipantHistoryBlockComponent,
    ParticipantProfileNotesComponent,
    ParticipantProfileFreezeComponent,
    EditProfileBasicInfoComponent,
    EditProfileMatchingSettingsComponent,
    EditProfileInterviewComponent,
    GroupSessionComponent,
    GroupSessionListComponent,
    GroupSessionCreateComponent,
    GroupSessionParticipantsComponent,
    InterviewListComponent,
    InterviewerDashboardComponent,
    NotificationsComponent,
    NotificationsListComponent,
    NotificationsComponent,
    NotificationsListSystemComponent,
    NotificationsListAnnouncementsComponent,
    EditMessageTemplateWindowComponent,
    EditMessageTemplateComponent,
    EditMessageTemplateTelegramComponent,
    EditMessageAnnouncementWindowComponent,
    MessageAnnouncementComponent,
    MatchingHistoryComponent,
    ChangeHistoryComponent,
    MatcherDashboardComponent,
    MatchingDialogComponent,
    StaffProfileComponent,
    SourcesComponent,
    EditSourceComponent,
    ProgressLevelsComponent,
    CreateLevelComponent,
    DashboardPipe,
    TableComponent,
    PartnerDashboardComponent,
    PartnerParticipantsComponent,
    PartnerInviteComponent,
    UserRolePipe,
    TrainingTypePipe,
    InterviewStatusPipe,
    CapitalCasePipe,
    EmailTemplateComponent,
    GroupSessionRowComponent,
    DatepickerComponent,
    EditMessageAnnouncementWindowComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    AgePipe,
    SelectedAnswerListPipe,
    GenderPipe,
    EnglishPipe,
    ListPipe,
    NullReplacePipe,
    TimeZoneInfoPipe,
    InterviewDecisionPipe,
    ZoomComponent,
    ParticipantStatusPipe,
    PartnersComponent,
    PartnerComponent,
    EditCommentComponent,
    ScheduleSessionComponent,
    PartnerParticipantStatusPipe,
    InviteParticipantComponent,
    EditMatchingProfileComponent,
    SelectStaffComponent,
    AvailabilityTrainingComponent,
    CreateTrainingComponent,
    TrainingCardComponent,
    TrainingListComponent,
    TrainingOverviewComponent,
    FailedMatchesComponent,
    ParticipantsComponent,
    TrainerDashboardComponent,
    DashboardComponent,
    ChangePasswordDialogComponent,
    GenderPreferencePipe,
    ConfirmWindowComponent,
    EditMatchingDetailsComponent,
    EditMiniGroupComponent,
    ParticipantAutomatedEmailsComponent,
    VolunteerPreferredLevelPipe,
    ImpactComponent,
    ParticipantPreviousMatchesComponent,
    ParticipantImpactMeasurementComponent,
    ExportComponent,
    SubprogramsComponent,
    EditSubprogramComponent,
    ParticipantChangesHistoryComponent,
    SubprogramTypePipe,
    EditInterviewInfoComponent,
    InterviewTypeNamePipe,
    TrainingStatusPipe,
  ],
  imports: [
    RouterModule.forRoot(AppRoutes.allRoles, { useHash: true }),
    MatDialogModule,
    MatNativeDateModule,
    BrowserModule,
    CalendarModule,
    MatTableModule,
    MatMenuModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NotifierModule,
    MatButtonToggleModule,
    MatIconModule,
    MatDividerModule,
    NumbersOnlyModule,
    LoaderModule,
    NoDataModule,
    NgApexchartsModule,
    PaymentSelectModule,
    MatTooltipModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
  ],
  providers: [
    HttpClientModule,
    HttpClient,
    DashboardPipe,
    TrainerDashboardPipe,
    InterviewStatusPipe,
    DatePipe,
    GenderPipe,
    ListPipe,
    PartnerParticipantStatusPipe,
    AgePipe,
    VolunteerPreferredLevelPipe,
    InterviewTypeNamePipe,
    TimezonePublicService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  entryComponents: [
    CancelWindowComponent,
    DeleteProfileComponent,
    EditProfileComponent,
    SessionHistoryComponent,
    ChangeHistoryComponent,
    HistoryWindowComponent,
    EditMessageAnnouncementWindowComponent,
    GroupSessionCreateComponent,
    EditMessageTemplateWindowComponent,
    EditSourceComponent,
    CreateLevelComponent,
    DatepickerComponent,
  ],
  bootstrap: [AppComponent],
  exports: [EditProfileBasicInfoComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
