<div class="table-navigation-container">
  <div class="left-side">
    <input placeholder="Search" type="text">
  </div>
  <div class="right-side">
    <button class="primary-button2">Create Email</button>
  </div>
</div>


<table [dataSource]="dataSource" class="panel" mat-table>

  <ng-container matColumnDef="image">
    <th *matHeaderCellDef class="overline-text" mat-header-cell> image</th>
    <td *matCellDef="let element" class="body-regular-text" mat-cell><h4>{{element.image}}</h4></td>
  </ng-container>

  <ng-container matColumnDef="subject">
    <th *matHeaderCellDef class="overline-text" mat-header-cell> subject</th>
    <td *matCellDef="let element" class="body-regular-text" mat-cell> {{element.subject}} </td>
  </ng-container>

  <ng-container matColumnDef="heading">
    <th *matHeaderCellDef class="overline-text" mat-header-cell> heading</th>
    <td *matCellDef="let element" class="body-regular-text" mat-cell> {{element.heading}} </td>
  </ng-container>

  <ng-container matColumnDef="body">
    <th *matHeaderCellDef class="overline-text" mat-header-cell> body</th>
    <td *matCellDef="let element" class="body-regular-text photo-container" mat-cell>{{element.body}}</td>
  </ng-container>

  <ng-container matColumnDef="recipient">
    <th *matHeaderCellDef class="overline-text" mat-header-cell> recipient</th>
    <td *matCellDef="let element" class="body-regular-text" mat-cell>{{element.recipient}} </td>
  </ng-container>

  <ng-container matColumnDef="script">
    <th *matHeaderCellDef class="overline-text" mat-header-cell> script</th>
    <td *matCellDef="let element" class="body-regular-text" mat-cell> {{element.script}} </td>
  </ng-container>

  <ng-container matColumnDef="lastUpdate">
    <th *matHeaderCellDef class="overline-text" mat-header-cell> Last update</th>
    <td *matCellDef="let element" class="body-regular-text" mat-cell> {{element.lastUpdate}} </td>
  </ng-container>

  <ng-container matColumnDef="options">
    <th *matHeaderCellDef class="overline-text" mat-header-cell> options</th>
    <td *matCellDef="let element" class="body-regular-text" mat-cell><span class="material-icons">edit</span><span
      class="material-icons">delete</span></td>
  </ng-container>


  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
</table>
