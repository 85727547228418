import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RegisterStaffAccountDTO} from '../../../.deprecated/dto/register-staff-account-dto';
import {HttpClient} from '@angular/common/http';
import {StaffPage} from '../model/staff-page';

@Injectable({
  providedIn: 'root',
})
export class TeamService {

  private static BASE_URL = '/admin/staff';

  constructor(private http: HttpClient) {
  }

  public getStaffListItems(searchText: string, pageIndex: number, pageSize: number): Observable<StaffPage> {
    return this.http.get<StaffPage>(`${TeamService.BASE_URL}/list`, {params: {s: searchText, page: pageIndex, size: pageSize}});
  }

  public registerStaffAccount(
    firstName: string,
    lastName: string,
    role: number,
    email: string,
    phone: string
  ): Observable<any> {

    const dto: RegisterStaffAccountDTO = {
      firstName,
      lastName,
      role,
      email,
      phone
    };

    return this.http.post(`${TeamService.BASE_URL}/invite`, dto);
  }

  public editStaffProfile(staffId: number, dto: RegisterStaffAccountDTO): Observable<any> {
    return this.http.patch(`${TeamService.BASE_URL}/${staffId}`, dto);
  }

  public deleteStaffAccount(staffId: number): Observable<any> {
    return this.http.delete<any>(`${TeamService.BASE_URL}/${staffId}`);
  }

  public changePasswordForTeamMember(id: number, password: string): Observable<any> {
    return this.http.patch<any>(`${TeamService.BASE_URL}/${id}/password`, {password});
  }
}
