import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: [
    '../../../../../style/basic-styles.scss',
    '../../../../../style/component-styles.scss',
    './dashboard-card.component.scss'
  ]
})
export class DashboardCardComponent implements OnInit {

  @Input() heading: string;
  @Input() schema: string[];
  @Input() students: number[];
  @Input() volunteers: number[];

  constructor() {
  }

  ngOnInit(): void {
  }

}
