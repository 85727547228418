<ng-template #loader>
  <app-loader class="loader"></app-loader>
</ng-template>
<div class="container" *ngIf="data$ | async as data; else loader">
  <div class="collection-title">
    STUDENTS
  </div>
  <div class="collection">
    <div class="item" *ngFor="let item of data.student">
      <!--      TODO: Move to separate component-->
      <div class="title">{{item.title}}</div>
      <div class="chart-wrapper" [ngSwitch]="item.type">
        <apx-chart
          *ngSwitchCase="'bar'"
          [series]="item.chartData.series"
          [chart]="item.chartData.chart"
          [dataLabels]="item.chartData.dataLabels"
          [plotOptions]="item.chartData.plotOptions"
          [stroke]="item.chartData.stroke"
          [xaxis]="item.chartData.xaxis"
          [colors]="colors"
        ></apx-chart>
        <apx-chart
          *ngSwitchCase="'pie'"
          [series]="item.chartData.series"
          [chart]="item.chartData.chart"
          [labels]="item.chartData.labels"
          [responsive]="item.chartData.responsive"
          [legend]="item.chartData.legend"
          [colors]="colors"
        ></apx-chart>
        <div *ngSwitchCase="'groupPie'" class="group-wrapper">
          <div *ngFor="let groupItem of item.chartItems" class="group-item">
            <div class="title">{{groupItem.title}}</div>
            <apx-chart
              [series]="groupItem.chartData.series"
              [chart]="groupItem.chartData.chart"
              [labels]="groupItem.chartData.labels"
              [responsive]="groupItem.chartData.responsive"
              [legend]="groupItem.chartData.legend"
              [colors]="colors"
            ></apx-chart>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="collection-title">
    VOLUNTEERS
  </div>
  <div class="collection">
    <div class="item" *ngFor="let item of data.volunteer">
      <!--      TODO: Move to separate component-->
      <div class="title">{{item.title}}</div>
      <div class="chart-wrapper" [ngSwitch]="item.type">
        <apx-chart
          *ngSwitchCase="'bar'"
          [series]="item.chartData.series"
          [chart]="item.chartData.chart"
          [dataLabels]="item.chartData.dataLabels"
          [plotOptions]="item.chartData.plotOptions"
          [stroke]="item.chartData.stroke"
          [xaxis]="item.chartData.xaxis"
          [colors]="colors"
        ></apx-chart>
        <apx-chart
          *ngSwitchCase="'pie'"
          [series]="item.chartData.series"
          [chart]="item.chartData.chart"
          [labels]="item.chartData.labels"
          [responsive]="item.chartData.responsive"
          [legend]="item.chartData.legend"
          [colors]="colors"
        ></apx-chart>
        <div *ngSwitchCase="'groupPie'" class="group-wrapper">
          <div *ngFor="let groupItem of item.chartItems" class="group-item">
            <div class="title">{{groupItem.title}}</div>
            <apx-chart
              [series]="groupItem.chartData.series"
              [chart]="groupItem.chartData.chart"
              [labels]="groupItem.chartData.labels"
              [responsive]="groupItem.chartData.responsive"
              [legend]="groupItem.chartData.legend"
              [colors]="colors"
            ></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
