import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PaymentRequirement, PaymentRequirementMap } from '../../../features/participants/manager/model/payment.types';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-payment-select',
  template: `
    <mat-form-field class="w-100">
      <mat-label>Select payment Requirement</mat-label>
      <mat-select [formControl]="formControl">
        <mat-option *ngFor="let paymentType of paymentTypes | keyvalue" [value]="paymentType.value['enum']">
          {{ paymentType.value.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `
})
export class PaymentSelectComponent implements OnInit, OnChanges {

  public paymentTypes = PaymentRequirementMap;
  public formControl = new FormControl(PaymentRequirement.NEED_TO_PAY);
  private changedFromOutside = false;

  @Input() selectedPaymentRequirement: PaymentRequirement;
  @Output() paymentRequirementChange = new EventEmitter<PaymentRequirement>();

  ngOnInit(): void {
    if (this.selectedPaymentRequirement) {
      this.formControl.setValue(this.selectedPaymentRequirement);
    }
    this.formControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: PaymentRequirement) => {
      if (this.changedFromOutside) {
        this.changedFromOutside = false;
        return;
      }
      this.paymentRequirementChange.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
   // if the payment requirement is changed from outside
    if (changes.selectedPaymentRequirement && !changes.selectedPaymentRequirement.firstChange) {
      this.changedFromOutside = true;
      this.formControl.setValue(changes.selectedPaymentRequirement.currentValue);
    }
  }

}
