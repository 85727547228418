import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EditMessageTemplateWindowComponent} from '../../../.deprecated/component/notifications/modal-windows/edit-message-template-window/edit-message-template-window.component';
import {SettingsService} from '../settings.service';
import {NotifierService} from 'angular-notifier';
import {Notification} from './notification';

@Component({
  selector: 'app-notifications-list-email',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email', 'emailValidationMessage', 'telegram', 'telegramValidationMessage'];
  dataSource: Notification[];
  search: string;

  constructor(
    private service: SettingsService,
    private dialog: MatDialog,
    private notifier: NotifierService
  ) {
    this.search = '';
  }

  ngOnInit(): void {
    this.getNotifications();
  }

  editMessageTemplate(row: Notification): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    dialogConfig.data = row;
    const ref = this.dialog.open(EditMessageTemplateWindowComponent, dialogConfig);
    ref.afterClosed().subscribe(_ => this.getNotifications());
  }

  getNotifications(): void {
    this.service.getNotifications(this.search).subscribe({
      next: (value) => this.dataSource = value,
      error: _ => this.notifier.notify('error', 'error loading content')
    });
  }
}
