import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ScheduleSession, SessionEditDto, SessionInfoDto } from '../model/sessions';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  static baseUrl = '/sessions';

  constructor(private http: HttpClient) {
  }

  public schedule(dto: ScheduleSession): Observable<void> {
    return this.http.post<void>(`${SessionService.baseUrl}/schedule`, dto);
  }

  public getSession(participantId: number, sessionId: number): Observable<SessionInfoDto> {
    return this.http.get<SessionInfoDto>(`/manager/participants/${participantId}/sessions/${sessionId}`);
  }

  public updateSession(id: number, payload: SessionEditDto): Observable<void> {
    return this.http.patch<void>(`/sessions/${id}/edit`, payload);
  }
}
