export const WeekDays = [
  {
    short: 'Mon',
    full: 'Monday' 
  },
  {
    short: 'Tue',
    full: 'Tuesday' 
  },
  {
    short: 'Wed',
    full: 'Wednesday'
  },
  {
    short: 'Thu',
    full: 'Thursday'
  },
  {
    short: 'Fri',
    full: 'Friday' 
  },
  {
    short: 'Sat',
    full: 'Saturday' 
  },
  {
    short: 'Sun',
    full: 'Sunday'
  }
];

export const DayTimes = [
  'Morning',
  'Afternoon',
  'Evening',
];