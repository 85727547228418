<div class="table-navigation-container">
  <div class="left-side">
    <div class="right-side">
      <input type="text" placeholder="Search" [(ngModel)]="s" (ngModelChange)="searchPartners()">
    </div>
  </div>
  <button class="primary-button2" (click)="invitePartner()">
    <span class="material-icons">add</span> Add organization
  </button>
</div>


<table mat-table [dataSource]="partnerOrganisations" class="panel">

  <ng-container matColumnDef="organisationName">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Organisation name</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.organisationName }}</td>
  </ng-container>

  <ng-container matColumnDef="coordinatorName">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Coordinator Name</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.coordinatorName }}</td>
  </ng-container>

  <ng-container matColumnDef="coordinatorEmail">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Coordinator Email</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.coordinatorEmail }}</td>
  </ng-container>

  <ng-container matColumnDef="phone">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Phone</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.phone }}</td>
  </ng-container>

  <ng-container matColumnDef="invites">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Invites</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.invites }}</td>
  </ng-container>

  <ng-container matColumnDef="participants">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Participants</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.participants }}</td>
  </ng-container>

  <ng-container matColumnDef="notes">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Notes</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.notes }}</td>
  </ng-container>

  <ng-container matColumnDef="option">
    <th class="overline-text" mat-header-cell *matHeaderCellDef> options</th>
    <td class="body-regular-text options" mat-cell *matCellDef="let element">
      <span class="material-icons" (click)="invitePartner(element.id)">edit</span>
<!--      <span class="material-icons" (click)="delete(element.id)">delete</span>-->
      <span class="material-icons" (click)="openChangePasswordPartnerDialog(element.id)">key</span>
      <span class="material-icons" (click)="isActivePartner(element.id)">{{ element.active ? 'delete' : 'undo' }}</span>
      <span class="material-icons" (click)="viewPartnerPage(element.id)">visibility</span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
