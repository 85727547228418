import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {

  constructor(private http: HttpClient) {
  }

  public getAllParticipants(): Observable<any> {
    return this.http.get('/partner/students');
  }

  public getAllParticipantsAdmin(id: number): Observable<any> {
    return this.http.get(`/partner/${id}/students`);
  }

  public inviteParticipant(email: string): Observable<any> {
    return this.http.post('/partner/invite', {email});
  }
}
