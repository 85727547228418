import {Component, OnInit} from '@angular/core';
import {DateService} from '../../../../shared/data-access/date.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {GroupSessionCreateComponent} from '../group-session-create/group-session-create.component';
import {GroupSessionService} from '../../service/group-session.service';
import {GroupSessionCard} from '../../model/group-session-card';
import {ScheduleSession} from '../../model/schedule-session';
import {NotifierService} from 'angular-notifier';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-group-session',
  templateUrl: './group-session.component.html',
  styleUrls: ['./group-session.component.scss']
})
export class GroupSessionComponent implements OnInit {

  constructor(
    public dateService: DateService,
    private dialog: MatDialog,
    private service: GroupSessionService,
    private notifier: NotifierService,
  ) {
    this.highlightedDates = [];
  }

  today: number;
  cards: GroupSessionCard[];
  highlightedDates: number[];

  ngOnInit(): void {
    this.dateService.date$.subscribe(next => {
      this.today = next.startOf('day').unix();
      this.service.getAllForDay(this.today).subscribe(
        (value: GroupSessionCard[]) => this.cards = value
      );
      this.highlightDates();
    });
  }

  public go(dir: number): void {
    this.dateService.changeDay(dir);
  }

  public goToday(): void {
    this.dateService.today();
  }

  getCreateForm(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '30vw';
    const dialogRef = this.dialog.open(GroupSessionCreateComponent, dialogConfig);
    const sub = dialogRef.afterClosed().subscribe((value: ScheduleSession) => {
      if (value) {
        this.service.schedule(value).subscribe(val => {
          this.notifier.notify('success', 'Scheduled successfully');
          sub.unsubscribe();
        }, (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message));
      }
    });
  }

  highlightDates(): void {
    this.service.getDaysInMonth(
      this.dateService.date.year(),
      this.dateService.date.month() + 1,
      this.dateService.date.utcOffset() * 60
    ).subscribe({
      next: (value) => this.highlightedDates = value,
      error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
    });
  }
}
