<div class="panel left-block">
  <div class="d-flex header">
    <h5>{{ training?.typeFullName }}</h5>
    <div class="fullness">{{training?.participants.length }}/{{ training?.slots }}</div>
  </div>

  <div class="participants-list">
    <label class="d-flex">Participants:</label>
    <div *ngFor="let participant of training?.participants; index as i" class="participants-row">
      <div>{{ i + 1 }}.</div>
      <div>{{ participant.firstName }} {{
        participant.lastName }}</div>
      <div>{{ participant.email }}</div>
    </div>
  </div>
</div>
<div class="right-block" *ngIf="training">
  <div class="panel">
    <div class="block-header">
      <h5>{{ training.startDate * 1000 | date : "shortTime" }}</h5>
      <span class="overline-text">{{ training.startDate * 1000 | date }}</span>
      <span class="caption-text label">{{
        training.status | trainingStatus | capitalCase
        }}</span>
    </div>
    <div class="buttons-container">
      <!-- *ngIf="interview.interviewStatus === InterviewStatus.SCHEDULED"
        [disabled]="isInterviewDisabled$ | async" -->
      <button *ngIf="training.status === TrainingStatus.SCHEDULED" class="primary-button2"
        (click)="startTraining()">Start
        Training</button>
      <button *ngIf="training.status === TrainingStatus.SCHEDULED && role === UserRole.ADMIN" class="primary-button2" (click)="cancelTraining()">
        Cancel Training
      </button>
      <button class="secondary-button" *ngIf="training.status === TrainingStatus.IN_PROGRESS"
        (click)="copyUrl()">{{ copyButtonText }}</button>
    </div>
  </div>

  <div *ngIf="role === UserRole.ADMIN" class="panel">
    <div class="block-header">
      <h5>Trainer</h5>
      <mat-form-field appearance="outline" class="w-100">
        <mat-select [formControl]="supervisorId" placeholder="Trainer" class="no-global-input-styles">
          <mat-option *ngFor="let trainer of trainers" [value]="trainer.id">{{
            trainer.name
            }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="buttons-container">
      <button class="primary-button" (click)="changeTrainer()" [disabled]="supervisorId.invalid">Save</button>
    </div>
  </div>
</div>