import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly xAuthenticated: EventEmitter<boolean>;

  constructor() {
    this.xAuthenticated = new EventEmitter<boolean>();
  }

  get authenticated(): EventEmitter<boolean> {
    return this.xAuthenticated;
  }

  public emit(authenticated: boolean): void {
    this.xAuthenticated.emit(authenticated);
  }
}
