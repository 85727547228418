<div class="table-navigation-container">
  <div class="left-side">
    <input type="text" placeholder="Search" [(ngModel)]="search" (ngModelChange)="getNotifications()">
  </div>
<!--  <div class="right-side">-->
<!--    <button class="primary-button2" (click)="editMessageTemplate()">Create Email</button>-->
<!--  </div>-->
</div>

<table mat-table [dataSource]="dataSource" class="panel" *ngIf="dataSource">

  <ng-container matColumnDef="name">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Name</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{element.name}}
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Email</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{element.email.text}}
    </td>
  </ng-container>

  <ng-container matColumnDef="emailValidationMessage">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Email Validation Message</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{element.email.validationMessage}}
    </td>
  </ng-container>

  <ng-container matColumnDef="telegram">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Telegram</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{element.telegram.text}}
    </td>
  </ng-container>

  <ng-container matColumnDef="telegramValidationMessage">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Telegram Validation Message</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      {{element.telegram.validationMessage}}
    </td>
  </ng-container>

<!--  <ng-container matColumnDef="description">-->
<!--    <th class="overline-text" mat-header-cell *matHeaderCellDef>Description</th>-->
<!--    <td class="body-regular-text" mat-cell *matCellDef="let element">-->
<!--      {{element.description}}-->
<!--    </td>-->
<!--  </ng-container>-->

<!--  <ng-container matColumnDef="subject">-->
<!--    <th class="overline-text" mat-header-cell *matHeaderCellDef>Subject</th>-->
<!--    <td class="body-regular-text" mat-cell *matCellDef="let element">-->
<!--      {{element.subject}}-->
<!--    </td>-->
<!--  </ng-container>-->

<!--  <ng-container matColumnDef="heading">-->
<!--    <th class="overline-text" mat-header-cell *matHeaderCellDef> heading </th>-->
<!--    <td class="body-regular-text" mat-cell *matCellDef="let element">-->
<!--      {{element.heading}}-->
<!--    </td>-->
<!--  </ng-container>-->

<!--  <ng-container matColumnDef="body">-->
<!--    <th class="overline-text" mat-header-cell *matHeaderCellDef> body </th>-->
<!--    <td class="body-regular-text photo-container" mat-cell *matCellDef="let element">-->
<!--      {{element.body}}-->
<!--    </td>-->
<!--  </ng-container>-->

<!--  <ng-container matColumnDef="recipient">-->
<!--    <th class="overline-text" mat-header-cell *matHeaderCellDef> recipient </th>-->
<!--    <td class="body-regular-text" mat-cell *matCellDef="let element">-->
<!--      {{element.recipient}}-->
<!--    </td>-->
<!--  </ng-container>-->

<!--  <ng-container matColumnDef="script">-->
<!--    <th class="overline-text" mat-header-cell *matHeaderCellDef> script </th>-->
<!--    <td class="body-regular-text" mat-cell *matCellDef="let element">-->
<!--      {{element.script}}-->
<!--    </td>-->
<!--  </ng-container>-->

<!--  <ng-container matColumnDef="lastUpdate">-->
<!--    <th class="overline-text" mat-header-cell *matHeaderCellDef> Last update </th>-->
<!--    <td class="body-regular-text" mat-cell *matCellDef="let element">-->
<!--      {{element.lastUpdate}}-->
<!--    </td>-->
<!--  </ng-container>-->

<!--  <ng-container matColumnDef="options">-->
<!--    <th class="overline-text" mat-header-cell *matHeaderCellDef></th>-->
<!--    <td class="body-regular-text" mat-cell *matCellDef="let element">-->
<!--      <span class="material-icons"  >edit</span>-->
<!--      <span class="material-icons">delete</span>-->
<!--    </td>-->
<!--  </ng-container>-->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="editMessageTemplate(row)"></tr>
</table>
