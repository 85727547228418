import {Component} from '@angular/core';
import {ImpactService} from '../service/impact.service';
import {Observable} from 'rxjs';
import {Impact} from '../entities/common.namespace';

@Component({
  selector: 'app-impact',
  templateUrl: './impact.component.html',
  styleUrls: ['./impact.component.scss']
})
export class ImpactComponent {
  public colors = ['#38B6FF', '#CA489A', '#36949F', '#4F5D75', '#8D8741'];
  public data$: Observable<Impact.Data>;

  constructor(private impactService: ImpactService) {
    this.data$ = this.impactService.getImpactData();
  }
}
