import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ParticipantProfile,
  StudentMiniGroupPreference,
} from '../../model/participant-profile';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { AnswersService } from '../../../participants/manager/component/participant/answers.service';
import { EditInterviewInfoComponent } from './edit-interview-info/edit-interview-info.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { WeekDays, DayTimes } from '../../../../shared/consts/time';
import { HttpErrorResponse } from '@angular/common/http';
import {
  StudentsQuestionId,
  VolunteerQuestionId,
} from '../../../participants/manager/model/question-id-mapper';
import { ProfileQuestionsData } from '../../../participants/manager/model/profile-questions-data';
import { UserRole } from '../../../../static/constants/user-role/user-role.enum';
import { InterviewService } from '../../service/interview.service';
import { Interview, InterviewType } from '../../model/interview';
import { times } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-interview-overview-profile',
  templateUrl: './interview-overview-profile.component.html',
  styleUrls: ['./interview-overview-profile.component.scss'],
})
export class InterviewOverviewProfileComponent implements OnInit, OnDestroy {
  constructor(
    private dialog: MatDialog,
    private service: InterviewService,
    private notifier: NotifierService,
    public answersService: AnswersService
  ) {}
  @Input() participant: ParticipantProfile;
  @Input() interview: Interview;
  days = WeekDays;
  times = DayTimes;
  availabilities: number[] = [];
  subs = new Subscription();

  studentMiniGroups: Record<StudentMiniGroupPreference, string> = {
    [StudentMiniGroupPreference.ONE_ON_ONE]:
      'I prefer a 1-on-1 match (1 student and 1 volunteer)',
    [StudentMiniGroupPreference.MINI_GROUP]:
      'I prefer a mini-group (2 students (or 3 upon request) and 1 volunteer)',
    [StudentMiniGroupPreference.BOTH]:
      'I am comfortable with both options (fastest matching time)',
  };

  volunteerIndividualSessions = times(20, (index) => ({
    id: index + 1,
    value: `${index + 1} ${index === 0 ? 'student' : 'students'} (${
      index + 1
    }-hour commitment + prep time)`,
  })).reduce((acc, cur) => ({ ...acc, [cur.id]: cur.value }), {});

  volunteerMiniGroupSessions = times(20, (index) => ({
    id: index + 1,
    value: `${
      index + 1
    } Mini-Groups with 2 Students (extendable to 3 students upon request) (${
      index + 1
    }-hour commitment + prep time)`,
  })).reduce((acc, cur) => ({ ...acc, [cur.id]: cur.value }), {});

  ngOnInit(): void {
    this.refreshProfile(this.participant);
  }

  refreshProfile(profile: ParticipantProfile) {
    this.availabilities = profile.availability.map((a) => a.intValue ?? -1);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get interviewUserRole(): UserRole {
    switch (this.interview.type) {
      case InterviewType.BECOME_VOLUNTEER:
        return UserRole.VOLUNTEER;
      default:
        return this.participant.userRole;
    }
  }

  editAvailability(): void {
    this.answersService.reset();
    this.answersService.userRole = this.interviewUserRole;

    this.subs.add(
      this.answersService.getQuestionsData(this.participant.id).subscribe({
        next: (value) => {
          this.showAvailabilityDialog(
            value.find((q) => this.isAvailability(q.registrationQuestion.id))
          );
        },
        error: (error: HttpErrorResponse) =>
          this.notifier.notify('error', error.error.message),
      })
    );
  }

  get isStudent(): boolean {
    return this.participant.userRole === UserRole.STUDENT;
  }

  get isVolunteer(): boolean {
    return this.participant.userRole === UserRole.VOLUNTEER;
  }

  get hasAdditionalProfile(): boolean {
    return this.participant.userRoles?.includes(
      this.isStudent ? UserRole.VOLUNTEER : UserRole.STUDENT
    );
  }

  isAvailability(id: number) {
    return (
      StudentsQuestionId.availability == id ||
      VolunteerQuestionId.availability == id
    );
  }

  showAvailabilityDialog(question?: ProfileQuestionsData): void {
    if (!question) {
      this.notifier.notify('error', 'No availability settings available');
      return;
    }

    const config = new MatDialogConfig();
    config.disableClose = true;
    config.autoFocus = true;
    config.width = '50vw';
    config.data = {
      id: this.participant.id,
      availability: question,
      participant: this.participant,
    };
    const ref = this.dialog.open(EditInterviewInfoComponent, config);
    this.subs.add(
      ref
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((value) => {
          if (value) {
            this.subs.add(
              this.service.getParticipant(this.participant.id).subscribe({
                next: (result) => {
                  this.refreshProfile(result);
                },
                error: (error: HttpErrorResponse) => {
                  this.notifier.notify('error', error.error.message);
                },
              })
            );
          }
        })
    );
  }
}
