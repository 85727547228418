export enum InterviewType {
  ONBOARDING = "ONBOARDING", 
  BECOME_VOLUNTEER = "BECOME_VOLUNTEER",
}

export interface Interview {
  interviewId: number;
  participantId: number;
  date: number;
  startUrl: string;
  interviewStatus: number;
  comment: string;
  decision: number;
  decisionDate: number;
  joinUrl: string;
  interviewerId: number;
  type: InterviewType;
}
