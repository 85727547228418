<div
  class="wrapper"
  (mouseleave)="isOpen = false"
  (mouseover)="isOpen = true"
  [ngClass]="{ 'menu-is-open': isOpen == true }"
>
  <div class="header">
    <div class="img logo"></div>
  </div>
  <div
    class="menu-container"
    style="'grid-template-columns: repeat(' + options.length + ', min-content) 1fr min-content'"
  >
    <a *ngFor="let feature of features"
         routerLink="{{ feature.link }}"
         class="menu-option text-decoration-none"
    >
      <span class="material-icons">
        {{ feature.icon }}
      </span>
      <span class="body-regular-text" *ngIf="isOpen == true">
        {{ feature.name }}
      </span>
    </a>
    <div></div>
    <div
      class="menu-option logout"
      (click)="signOut()"
    >
      <span class="material-icons">
        logout
      </span>
      <span class="body-regular-text" *ngIf="isOpen === true">
        {{ signOutName }}
      </span>
    </div>
  </div>

</div>

