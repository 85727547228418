import { Injectable } from '@angular/core';
import { InterviewType } from './model/interview';

@Injectable({
  providedIn: 'root'
})
export class InterviewFilterService {
  private _searchByName: string;
  private _filterByStartDate: string | null;
  private _filterByEndDate: string | null;
  private _filterByStatus: number;
  private _filterByUserRole: number;
  private _filterByDecision: number;
  private _teamMemberId: number;
  private _filterByType: InterviewType | null;

  constructor() {
    this.filterByStatus = null;
    this.filterByUserRole = null;
    this.filterByDecision = null;
    this.teamMemberId = null;
    this._filterByType = null;
  }
  get searchByName(): string {
    return this._searchByName;
  }

  set searchByName(value: string) {
    this._searchByName = value;
  }

  get filterByStartDate(): string | null {
    return this._filterByStartDate;
  }

  set filterByStartDate(value: string | null) {
    this._filterByStartDate = value;
  }

  get filterByEndDate(): string | null {
    return this._filterByEndDate;
  }

  set filterByEndDate(value: string | null) {
    this._filterByEndDate = value;
  }

  get filterByStatus(): number {
    return this._filterByStatus;
  }

  set filterByStatus(value: number) {
    this._filterByStatus = value;
  }

  get filterByUserRole(): number {
    return this._filterByUserRole;
  }

  set filterByUserRole(value: number) {
    this._filterByUserRole = value;
  }

  get filterByDecision(): number {
    return this._filterByDecision;
  }

  set filterByDecision(value: number) {
    this._filterByDecision = value;
  }

  get teamMemberId(): number {
    return this._teamMemberId;
  }

  set teamMemberId(value: number) {
    this._teamMemberId = value;
  }

  get filterByType(): InterviewType | null {
    return this._filterByType;
  }

  set filterByType(value: InterviewType | null) {
    this._filterByType = value;
  }
}
