import { Pipe, PipeTransform } from '@angular/core';
import {VolunteersPreferredStudentsEnglishLevel} from './english.enum';

@Pipe({
  name: 'volunteerPreferredLevel'
})
export class VolunteerPreferredLevelPipe implements PipeTransform {

  transform(value: number | string, ...args: unknown[]): string {
    value = typeof value === 'string' ? parseInt(value, 10) : value;
    const res = VolunteersPreferredStudentsEnglishLevel[value];
    return res ? res.toLowerCase() : null;
  }

}
