import {Component, Input, OnInit} from '@angular/core';
import { MatcherDashboardService} from '../../service/matcher-dashboard.service';
import {Dashboard} from '../../model/dashboard';

@Component({
  selector: 'app-matcher-dashboard',
  templateUrl: './matcher-dashboard.component.html',
  styleUrls: ['./matcher-dashboard.component.scss']
})
export class MatcherDashboardComponent implements OnInit {

  tables: string[] = [
    'first match',
    'rematch'
  ];

  schema: string[] = [
    '<1 week',
    '1-2 weeks',
    '2+ weeks'
  ];

  firstMatchedStudents: number[];
  firstMatchedVolunteers: number[];
  rematchedStudents: number[];
  rematchedVolunteers: number[];

  constructor(private service: MatcherDashboardService) {
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.service.getData().subscribe(
      (value: Dashboard) => {
        this.firstMatchedStudents = [
          value.firstStudents.lessThanOne,
          value.firstStudents.oneToTwo,
          value.firstStudents.moreThanTwo
        ];
        this.firstMatchedVolunteers = [
          value.firstVolunteers.lessThanOne,
          value.firstVolunteers.oneToTwo,
          value.firstVolunteers.moreThanTwo
        ];
        this.rematchedStudents = [
          value.rematchStudents.lessThanOne,
          value.rematchStudents.oneToTwo,
          value.rematchStudents.moreThanTwo
        ];
        this.rematchedVolunteers = [
          value.rematchVolunteers.lessThanOne,
          value.rematchVolunteers.oneToTwo,
          value.rematchVolunteers.moreThanTwo
        ];
      },
      error => console.error(error)
    );
  }
}
