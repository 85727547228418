<div class="panel" [formGroup]="formGroup">
  <div class="title-wrapper">
    <h4 class="title">Schedule Session</h4>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>

  <div>
    <span *ngIf="isStudent()">Student:</span>
    <span *ngIf="!isStudent()">Volunteer:</span>
    {{participantDetails.profile.firstName}}
    {{participantDetails.profile.lastName}}
  </div>

  <div *ngIf="!haveAnyParticipants && !data.session">
    No matching
    <span *ngIf="isStudent()">volunteers</span>
    <span *ngIf="!isStudent()">students</span>
    found
  </div>

  <div *ngIf="participants.length > 0 || pastParticipants.length > 0 || data.session">
    <mat-form-field appearance="standard" *ngIf="participants.length > 0">
      <mat-select class="no-global-input-styles"
                  formControlName="participantIds"
                  [disabled]="data !== undefined && data.session !== undefined"
                  [compareWith]="checkSelected"
                  [multiple]="!isStudent()"
                  placeholder="Select participant">
        <mat-option *ngFor="let p of participants" [value]="p.participantId">
          {{ p.firstName }} {{ p.lastName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="standard" *ngIf="pastParticipants.length > 0">
      <mat-select class="no-global-input-styles"
                  [disabled]="data !== undefined && data.session !== undefined"
                  formControlName="pastParticipantIds" [multiple]="true"
                  [compareWith]="checkSelected"
                  placeholder="Past participants">
        <mat-option *ngFor="let p of pastParticipants" [value]="p.participantId">
          {{ p.firstName }} {{ p.lastName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <textarea
        matInput
        class="no-global-input-styles"
        formControlName="instructions"
        type="text"
        placeholder='How should the student join the session? (Include platform and link, if applicable). What will you be discussing?'
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="standard" class="w-100">
      <mat-select formControlName="duration"
                  class="no-global-input-styles"
                  placeholder="select duration">
        <mat-option *ngFor="let duration of sessionDuration" [value]="duration.value">
          {{ duration.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field label="Date*" appearance="standard" class="date-picker">
      <input formControlName="startDate"
             class="no-global-input-styles"
             matInput
             [matDatepicker]="picker" (click)="picker.open()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field label="Time*" class="time-picker" appearance="standard">
      <span>
        <input matInput
               class="no-global-input-styles"
               formControlName="startTime"
               type="time">
      </span>
    </mat-form-field>

    <mat-checkbox formControlName="approved"
                  [disabled]="data?.session?.approved"
                  *ngIf="!isSessionInFuture || data?.session?.approved">Time is confirmed
    </mat-checkbox>
    <mat-checkbox formControlName="repeatTillControl" *ngIf="!data.session">Repeat weekly till Date</mat-checkbox>

    <mat-form-field appearance="standard" *ngIf="formGroup.value.repeatTillControl && !data.session">
      <div>
        <input formControlName="repeatTillDate"
               matInput
               class="no-global-input-styles"
               [matDatepicker]="pickerTill" (click)="pickerTill.open()"
        >
        <mat-datepicker-toggle matIconSuffix [for]="pickerTill"></mat-datepicker-toggle>
        <mat-datepicker #pickerTill></mat-datepicker>
      </div>
    </mat-form-field>
  </div>

  <div class="buttons-container">
    <button class="secondary-button" (click)="close()">Cancel</button>
    <button class="primary-button" (click)="save()" *ngIf="formGroup.valid">Save</button>
  </div>
</div>
