<div id="wrapper">
  <div class="panel">
    <div class="component-header">
      <h5>{{ heading }}</h5>
      <span class="material-icons">cross</span>
    </div>
    <form name="staffForm" [formGroup]="staffForm">
      <label>
        <input placeholder="First Name" type="text" formControlName="firstName" required [(ngModel)]="staff.firstName">
      </label>
      <div class="caption-text" *ngIf="staffForm.controls['firstName'].invalid && (staffForm.controls['firstName'].dirty || staffForm.controls['firstName'].touched)">
        <div *ngIf="staffForm.controls['firstName'].errors.required">
          <p>First name needed</p>
        </div>
        <div *ngIf="staffForm.controls['firstName'].errors.minlength">
          <p>Too short</p>
        </div>
        <div *ngIf="staffForm.controls['firstName'].errors.pattern">
          <p>Wrong symbols</p>
        </div>
      </div>
      <label>
        <input placeholder="Last name" type="text" formControlName="lastName" required [(ngModel)]="staff.lastName">
      </label>
      <div class="caption-text" *ngIf="staffForm.controls['lastName'].invalid && (staffForm.controls['lastName'].dirty || staffForm.controls['lastName'].touched)">
        <div *ngIf="staffForm.controls['lastName'].errors.required">
          <p>Last name needed</p>
        </div>
        <div *ngIf="staffForm.controls['lastName'].errors.minlength">
          <p>Too short</p>
        </div>
        <div *ngIf="staffForm.controls['lastName'].errors.pattern">
          <p>Wrong symbols</p>
        </div>
      </div>
      <label>
        <input placeholder="Email" type="text" formControlName="email" required [(ngModel)]="staff.email">
      </label>
      <div class="caption-text" *ngIf="staffForm.controls['email'].invalid && (staffForm.controls['email'].dirty || staffForm.controls['email'].touched)">
        <div *ngIf="staffForm.controls['email'].errors.required">
          <p>Email needed</p>
        </div>
        <div *ngIf="staffForm.controls['email'].errors.minlength">
          <p>Too short</p>
        </div>
        <div *ngIf="staffForm.controls['email'].errors.pattern">
          <p>Wrong email</p>
        </div>
      </div>
      <label>
        <input placeholder="Phone" type="text" formControlName="phone" required [(ngModel)]="staff.phone">
      </label>
      <div class="caption-text" *ngIf="staffForm.controls['phone'].invalid && (staffForm.controls['phone'].dirty || staffForm.controls['phone'].touched)">
        <div *ngIf="staffForm.controls['phone'].errors.required">
          <p>Phone number needed</p>
        </div>
        <div *ngIf="staffForm.controls['phone'].errors.minlength">
          <p>Too short</p>
        </div>
        <div *ngIf="staffForm.controls['phone'].errors.pattern">
          <p>Invalid phone number</p>
        </div>
      </div>
      <label>
        <select type="text" formControlName="role" required [(ngModel)]="staff.role">
          <option [ngValue]="null" disabled selected>Role</option>
          <option [ngValue]="200">Admin</option>
          <option [ngValue]="201">Manager</option>
          <option [ngValue]="202">Interviewer</option>
          <option [ngValue]="203">Matcher</option>
          <option [ngValue]="204">Marketer</option>
          <option [ngValue]="206">Old Matcher</option>
          <option [ngValue]="207">Trainer</option>
          <!--          <option *ngFor="let role of roles" [ngValue]="role">{{role}}-->
        </select>
      </label>
      <div class="caption-text" *ngIf="staffForm.controls['role'].invalid && (staffForm.controls['role'].dirty || staffForm.controls['role'].touched)">
        <div *ngIf="staffForm.controls['role'].errors.required">
          <p>Role needed</p>
        </div>
      </div>
<!--      <label>-->
<!--        <select type="text" formControlName="timeZone" required [(ngModel)]="staff.timeZone">-->
<!--          <option value="" disabled selected>Time zone</option>-->
<!--          <option value="-240">PRT</option>-->
<!--          <option value="-210">CNT</option>-->
<!--          <option value="-180">AGT</option>-->
<!--          <option value="-60">CAT</option>-->
<!--          <option value="0">UTC</option>-->
<!--          <option value="60">CET</option>-->
<!--          <option value="120">EET</option>-->
<!--          <option value="180">EAT</option>-->
<!--          <option value="210">MET</option>-->
<!--        </select>-->
<!--      </label>-->
<!--      <div class="caption-text" *ngIf="staffForm.controls['timeZone'].invalid && (staffForm.controls['timeZone'].dirty || staffForm.controls['timeZone'].touched)">-->
<!--        <div *ngIf="staffForm.controls['timeZone'].errors.required">-->
<!--          <p>Time zone needed</p>-->
<!--        </div>-->
<!--      </div>-->
    </form>
    <div class="buttons-container">
      <button class="secondary-button" (click)="close(false)">Cancel</button>
      <button class="primary-button" [disabled]="!staffForm.valid" (click)="submit()">{{ submitButtonText }}</button>
    </div>
  </div>
</div>
