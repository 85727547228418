import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-failed-matches',
  templateUrl: './failed-matches.component.html',
  styleUrls: ['./failed-matches.component.scss']
})
export class FailedMatchesComponent implements OnInit {

  displayedColumns: string[] = [
    'timestamp',
    'studentEmail',
    'volunteerEmail',
    'volunteerStatus',
    'matchingScore',
    'leftToAdd',
    'date'
  ];
  constructor() { }
  ngOnInit(): void {
  }
}
