import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TrainingCard } from '../availability-training/model/training-card';
import { Training, TrainingType, TrainingsDTO } from '../availability-training/model/training';
import { ScheduleTraining } from '../availability-training/model/schedule-training';
import { Trainer } from '../availability-training/model/trainer';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrainingService {
  private static url = '/training';

  constructor(private http: HttpClient) { }

  getAll(
    page: number,
    size: number,
    status?: number,
    supervisorId?: number,
    startDate?: number,
    endDate?: number,
    type?: TrainingType
  ): Observable<TrainingsDTO> {
    let params = new HttpParams()
      .append('page', `${page}`)
      .append('size', `${size}`);

    if (status) {
      params = params.append('status', `${status}`);
    }

    if (supervisorId) {
      params = params.append('supervisorId', `${supervisorId}`);
    }

    if (startDate) {
      params = params.append('startDate', `${startDate}`);
    }

    if (endDate) {
      params = params.append('endDate', `${endDate}`);
    }

    if (type) {
      params = params.append('type', type);
    }

    return this.http
      .get<TrainingsDTO>(`${TrainingService.url}`, { params })
  }

  getAllForDay(day: number): Observable<TrainingCard[]> {
    return this.http.get<TrainingCard[]>(`${TrainingService.url}/list`, {
      params: { day },
    });
  }

  getTraining(id: number): Observable<Training> {
    return this.http.get<Training>(`${TrainingService.url}/${id}`);
  }

  schedule(dto: ScheduleTraining): Observable<any> {
    return this.http.post(TrainingService.url, dto);
  }

  getTrainers(): Observable<Trainer[]> {
    return this.http.get<Trainer[]>(`${TrainingService.url}/supervisors`);
  }

  startTraining(url: string): void {
    window.open(`${url}`, '_blank');
  }

  cancelTraining(id: number) {
    return this.http.patch(`${TrainingService.url}/${id}/cancel`, {});
  }

  changeTrainer(id: number, newTrainerId: number) {
    return this.http.patch(`${TrainingService.url}/${id}/change-trainer?newTrainerId=${newTrainerId}`, {});
  }

  public getDaysInMonth(
    year: number,
    month: number,
    offset: number
  ): Observable<number[]> {
    return this.http.get<number[]>(`${TrainingService.url}/days-in-month`, {
      params: { year, month, offset },
    });
  }
}
