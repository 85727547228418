import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeZoneInfo'
})
export class TimeZoneInfoPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string | null {
    if (!value || !value.name || !value.offset) {
      return null;
    }
    return `${value.name} (UTC ${value.offset})`;
  }

}
