
<div class="wrapper">
<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Level create/edit</h6>
    <button class="secondary-button-icons close-button" (click)="onClose()"><span class="material-icons">close</span></button>
  </div>

  <div class="inputs-container">
    <span class="overline-text">Name</span>
    <input type="text" placeholder="Name">
  </div>

  <div class="inputs-container">
    <span class="overline-text">Description</span>
    <textarea type="text" placeholder="Description"></textarea>
  </div>

  <div class="input-container container">
    <span class="overline-text">Amount of hours</span>
    <section class="hours">
      <input type="number" placeholder="from"><input type="number" placeholder="to">
    </section>
  </div>

  <div class="input-container container">
    <span class="overline-text">Picture</span>
    <section class="upload">
      <input disabled type="text" placeholder="Way to the file">
      <button class="secondary-button">Choose from computer</button>
    </section>
  </div>

  <div class="input-container container">
    <span class="body-highlighted-text">Recipients</span>
    <section>
      <mat-checkbox class="example-margin">Student</mat-checkbox>
      <mat-checkbox class="example-margin">Volunteer</mat-checkbox>
    </section>
  </div>

  <div class="buttons-container">
    <button class="secondary-button">Cancel</button>
    <button class="primary-button">Create source</button>
  </div>
</div>
</div>
