<div *ngFor="let item of data" class="item">
  <div class="body-regular-text">{{item.email}}</div>
  <div class="matches-container">
    <div class="caption-text">Match date</div>
    <div class="caption-text">{{item.matchedTimestamp * 1000 | date:'MMM d, y, h:mm a'}}</div>
  </div>
  <div class="matches-container">
    <div class="caption-text">Unmatch date</div>
    <div class="caption-text">{{item.unmatchedTimestamp * 1000 | date:'MMM d, y, h:mm a'}}</div>
  </div>
</div>
