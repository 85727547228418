import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CurrentMatchCard,
  MatchingInfo,
} from '../../../../model/participant-details';
import { UserRole } from '../../../../../../../static/constants/user-role/user-role.enum';
import { ParticipantService } from '../../../../service/participant.service';
import { NotifierService } from 'angular-notifier';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MiniGroupPreference,
  StudentMiniGroupPreference,
} from '../../../../../../interview/model/participant-profile';

@Component({
  selector: 'app-participant-matching',
  templateUrl: './participant-matching.component.html',
  styleUrls: ['./participant-matching.component.scss'],
})
export class ParticipantMatchingComponent {
  @Input() id: number;
  @Input() userRole: UserRole;
  @Input() matching: MatchingInfo;
  @Input() miniGroup: MiniGroupPreference;
  @Output() matchRemoved: EventEmitter<void>;

  matchedMiniGroups: CurrentMatchCard[];
  matchedIndividualStudents: CurrentMatchCard[];
  matchedMiniGroupsCount: number;

  get StudentMiniGroupPreference() {
    return StudentMiniGroupPreference;
  }

  UserRole = UserRole;

  constructor(
    private service: ParticipantService,
    private notifier: NotifierService
  ) {
    this.matchRemoved = new EventEmitter<void>();
  }

  ngOnInit(): void {
    this.matchedMiniGroups = (this.matching.matchedStudents ?? []).filter(
      (student) => student.groupId
    );
    this.matchedIndividualStudents = (this.matching.matchedStudents ?? []).filter(
      (student) => !student.groupId
    );

    this.matchedMiniGroupsCount = this.calculateGroupsCount(
      this.matchedMiniGroups
    );
  }

  cancelCheckIn(): void {
    if (this.matching.checkIn) {
      return;
    }
    this.matching.checkIn = false;
    this.service.cancelCheckInEmail(this.id).subscribe({
      next: (_) => this.notifier.notify('success', 'Cancelled successfully'),
      error: (e: HttpErrorResponse) =>
        this.notifier.notify('error', e.error.message),
    });
  }
  deleteMatching(matchId: number): void {
    this.service.removeMatch(matchId).subscribe({
      next: (_) => {
        this.notifier.notify('success', 'Deleted successfully');
        this.matchRemoved.emit();
      },
      error: (e: HttpErrorResponse) =>
        this.notifier.notify('error', e.error.message),
    });
  }

  private calculateGroupsCount(participants: CurrentMatchCard[]): number {
    const groupCounts: { [key: number]: number } = {};

    for (const participant of participants) {
      if (groupCounts[participant.groupId]) {
        groupCounts[participant.groupId]++;
      } else {
        groupCounts[participant.groupId] = 1;
      }
    }

    let numberOfGroups = 0;
    for (const groupId in groupCounts) {
      if (groupCounts[groupId] >= 2) {
        numberOfGroups++;
      }
    }

    return numberOfGroups;
  }
}
