import { Injectable } from '@angular/core';
import { ToolbarOption } from '../../toolbar/model/toolbar-option';
import { Features } from '../../../config/constant/features';
import { UserRole } from '../../constants/user-role/user-role.enum';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  private static tokenKey = '_token';
  private static roleKey = '_role';

  /**
   * Temporary constructor to facilitate transition from localStorage to localStorage. This can be
   * deleted 7 days after this change has been deployed to prod.
   */
  constructor() {
    this.transferStorage(UserInfoService.tokenKey);
    this.transferStorage(UserInfoService.roleKey);
  }

  private transferStorage(key: string) {
    const value = localStorage.getItem(key);
    if (value) {
      console.log(`Transferring $key=$value`);
      localStorage.setItem(key, value);
    }
  }

  /**
   * @returns the token for the user's current session, or null if there isn't one.
   */
  getToken(): string | null {
    return localStorage.getItem(UserInfoService.tokenKey);
  }

  setToken(value: string) {
    localStorage.setItem(UserInfoService.tokenKey, value);
  }

  /**
   *  @returns the user's role, or 0 if the role is not stored.
   */
  getRole(): number {
    return Number(localStorage.getItem(UserInfoService.roleKey));
  }

  setRole(value: number) {
    localStorage.setItem(UserInfoService.roleKey, value.toString());
  }

  // Signing out
  removeToken(): void {
    localStorage.removeItem(UserInfoService.tokenKey);
    localStorage.removeItem(UserInfoService.roleKey);
  }

  getFeatures(): ToolbarOption[] {
    switch (this.getRole()) {
      case UserRole.ADMIN:
        return Features.admin;
      case UserRole.MANAGER:
        return Features.manager;
      case UserRole.INTERVIEWER:
        return Features.interviewer;
      case UserRole.MATCHER:
        return Features.matcher;
      case UserRole.OLD_MATCHER:
        return Features.oldMatcher;
      case UserRole.MARKETER:
        return Features.marketer;
      case UserRole.PARTNER:
        return Features.partner;
      case UserRole.TRAINER:
        return Features.trainer;
      default:
        return [];
    }
  }
}
