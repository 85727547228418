<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Date picker</h6>
    <button class="secondary-button-icons close-button"
            (click)="onClose()"><span class="material-icons">close</span></button>
  </div>

  <div class="wrapper">
    <app-calendar class="panel"></app-calendar>
    <app-calendar class="panel"></app-calendar>
  </div>
  <div class="buttons-container"  mat-dialog-actions>
    <button class="secondary-button" (click)="onClose()" >Cancel</button>
    <button class="primary-button2">Apply</button>
  </div>
</div>
