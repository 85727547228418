import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ScheduleSession} from '../model/schedule-session';
import {Supervisor} from '../component/group-session-create/supervisor';

@Injectable({
  providedIn: 'root'
})
export class GroupSessionService {

  constructor(
    private http: HttpClient
  ) {
  }

  getSupervisors(): Observable<Supervisor[]> {
    return this.http.get<Supervisor[]>('/manager/group-session/supervisors');
  }

  public getAllForDay(day: number): Observable<any> {
    return this.http.get('/manager/group-session/list?day=' + day);
  }

  public getOne(id: number): Observable<any> {
    return this.http.get('/manager/group-session/' + id);
  }

  public schedule(dto: ScheduleSession): Observable<any> {
    return this.http.post('/manager/group-session', dto);
  }

  startGroupSession(id: number, date: number): void {
    const state = encodeURIComponent(JSON.stringify({id, date}));
    window.open(`${environment.videoServiceUrl}/start-group-session?state=${state}`, '_blank');
  }

  public attendance(id: number): Observable<any> {
    return this.http.patch('/manager/group-session/participant/attended', null, {params: {id}});
  }

  public getDaysInMonth(year: number, month: number, offset: number): Observable<number[]> {
    return this.http.get<number[]>(`/manager/group-session/days-in-month`, {params: {year, month, offset}});
  }
}
