<div id="wrapper" *ngIf="interview">
  <div class="confirmed-time">
    <mat-checkbox [checked]="interview.participantConfirmation" disabled></mat-checkbox><span class="caption-text">{{ interview.participantConfirmation ? 'Confirmed the time' : 'Didn\'t confirm the time' }}</span>
  </div>
  <div class="common-info">
    <div class="common-info-block">
      <span class="body-regular-text">
        {{ interview.decision | interviewDecision | nullReplace | capitalCase }}
      </span>
      <span class="overline-text">Decision</span>
    </div>
    <div class="common-info-block">
      <span class="body-regular-text">
        {{ interview.english | english | nullReplace | capitalCase }}
      </span>
      <span class="overline-text">English level (interview result)</span>
    </div>
    <div class="common-info-block">
      <span class="body-regular-text">
        <span class="material-icons" *ngIf="interview.agreement">check</span>
        <span class="material-icons" *ngIf="!interview.agreement">close</span>
      </span>
      <span class="overline-text">Agreement</span>
    </div>
  </div>

  <div *ngFor="let info of extraInfo" class="section">
    <span class="caption-text">{{ info.title }}</span>
    <span class="body-regular-text">{{ info.description | nullReplace }}</span>
  </div>

  <div class="section" *ngIf="interview.interviewer">
    <span class="caption-text">Interviewer</span>
    <div>
      <img src="{{ interview.interviewer.photo }}" alt="">
      <span class="body-regular-text">
        {{ interview.interviewer.firstName + ' ' + interview.interviewer.lastName }}
      </span>
    </div>
  </div>

  <div class="applied-on">
    <span class="caption-text">Date & Time</span>
    <span class="body-regular-text">{{ interview.date * 1000 | date:'mediumDate' | nullReplace }}</span>
  </div>

  <div class="applied-on">
    <span class="caption-text">Decision Date:</span>
    <span class="body-regular-text">{{ interview.decisionDate * 1000 | date:'mediumDate' }}</span>
  </div>

</div>
<div class="wrapper" *ngIf="!interview">
  <h6>No information</h6>
</div>
