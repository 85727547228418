import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfirmWindow} from "./confirm-window";

@Component({
  selector: 'app-confirm-window',
  templateUrl: './confirm-window.component.html',
  styleUrls: ['./confirm-window.component.scss']
})
export class ConfirmWindowComponent {
  @Output() onConfirm: EventEmitter<boolean>;

  title = 'Are you sure?';
  data: ConfirmWindow;

  constructor(
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    @Inject(MAT_DIALOG_DATA) data: ConfirmWindow
  ) {
    this.onConfirm = new EventEmitter<boolean>();
    this.data = data;
  }

  ngOnInit() {
    this.title = this.data?.title || this.title;
  }

  close(): void {
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
