export enum English {
  ACCEPTABLE = 10,
  GOOD,
  EXCELLENT,
  UNACCEPTABLE = 20,
  NOT_SET
}

export enum StudentEnglishEstimated {
  BEGINNER = 10,
  ELEMENTARY,
  PRE_INTERMEDIATE,
  INTERMEDIATE,
  UPPER_INTERMEDIATE,
  ADVANCED
}

export enum VolunteerEnglishEstimated {
  NATIVE = 10,
  C2,
  C1
}

export enum VolunteersPreferredStudentsEnglishLevel {
  NO_PREFERENCE,
  BEGINNER = 10,
  INTERMEDIATE = 20,
  ADVANCED = 30
}
