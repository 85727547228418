import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullReplace'
})
export class NullReplacePipe implements PipeTransform {

  transform(value: any, replacement?: any): string {
    return (value || value === 0) ? value : replacement ? replacement : '-';
  }
}
