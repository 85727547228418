import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {TeamMember} from './team-member';
import {AvailabilityService} from '../../service/availability.service';

@Component({
  selector: 'app-select-staff',
  templateUrl: './select-staff.component.html',
  styleUrls: ['./select-staff.component.scss']
})
export class SelectStaffComponent implements OnInit, OnChanges {

  @Output() selected: EventEmitter<number>;
  @Input() all: boolean;
  @Input() button: boolean;
  @Input() initialId: number;

  teamMemberId: number;
  teamMembers: TeamMember[];

  constructor(
    private service: AvailabilityService
  ) {
    this.selected = new EventEmitter<number>();
  }

  ngOnInit(): void {
    this.service.getInterviewerNames().subscribe(
      (value) => this.teamMembers = value
    );
  }

  ngOnChanges(): void {
    this.teamMemberId = this.initialId ? this.initialId : null;
  }

  emitFromSelect(): void {
    if (this.button) {
      return;
    }
    this.emit();
  }

  emit(): void {
    const id = !this.teamMemberId || isNaN(this.teamMemberId) ? null : this.teamMemberId;
    this.selected.emit(id);
  }
}
