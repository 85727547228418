import {Component, OnInit} from '@angular/core';
import {ExportService, ExportType} from './export.service';
import {NotifierService} from 'angular-notifier';
import {HttpErrorResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {ExportCard} from './export-card';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {

  displayedColumns: string[] = ['name', 'columns', 'button'];
  dataSource: ExportCard[] = [];

  constructor(
    private service: ExportService,
    private notifier: NotifierService
  ) {
  }

  public ngOnInit(): void {
    this.getAll();
  }

  getAll(): void {
    this.service.getAll().subscribe({
      next: value => this.dataSource = value,
      error: (e: HttpErrorResponse) => this.notifier.notify('error', e.error.message)
    });
  }

  download(id: number, type: ExportType): void {
    this.notifier.notify('info', 'Downloading, please, don\'t close the window');
    this.service.getExport(id, type).subscribe({
      next: response => {
        const filename = this.contentFileName(type, response.headers.get('Engin-File-Name'));
        this.notifier.notify('success', 'downloaded successfully');
        saveAs(response.body, filename);
      },
      error: (e: HttpErrorResponse) => this.notifier.notify('error', e.error.message ?? 'Download failed')
    });
  }

  private contentFileName(type: ExportType, contentHeader?: string): string {
    if (!contentHeader) {
      return Date.now() + '.' + type;
    }

    return contentHeader.trim();
  }
}
