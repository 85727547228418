import {Component, OnInit} from '@angular/core';
import {AdminDashboardService} from '../../service/admin-dashboard.service';
import {DiagramDTO} from '../../model/diagram-dto';
import {UserRole} from '../../../../../static/constants/user-role/user-role.enum';
import {MatButtonToggleEvent} from './mat-button-toggle-event';
import {DashboardTableRow} from '../../../shared/component/dashboard-table/dashboard-table-row';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  schema: string[] = [
    'Active',
    'Onboarding',
    'Didn\'t finish onboarding',
    'Dropped out',
    'Rejected'
  ];

  interviewDataColumns: string[] = [
    'TOTAL INTERVIEWS BOOKED',
    'TOTAL INTERVIEWS COMPLETED',
    'TOTAL INTERVIEWER NO-SHOWS',
    'TOTAL PARTICIPANT CANCELS / NO-SHOWS',
    'TOTAL SLOTS'
  ];

  monthlySummaryColumns: string[] = [
    'SIGNED UP',
    'REJECTED',
    'DROPPED OUT',
    'WAITLIST',
    'IN PROCESS',
    'APPROVED',
    'STILL ACTIVE'
  ];

  timeToFirstMatchColumns: string[] = [
    '<1 WEEK',
    '1-2 WEEKS',
    '2-3 WEEKS',
    '3+ WEEKS',
    'STILL WAITING'
  ];

  // todo change to real objects
  students: number[];
  volunteers: number[];

  interviews: DashboardTableRow[];
  summary: DashboardTableRow[];
  matching: DashboardTableRow[];

  UserRole = UserRole;
  role: UserRole;
  demographics: DiagramDTO;
  age: DiagramDTO;
  test: DiagramDTO;
  gender: DiagramDTO;
  english: DiagramDTO;
  source: DiagramDTO;

  bounce: DiagramDTO;
  signup: DiagramDTO;

  month: any[];
  match: any[];

  constructor(private service: AdminDashboardService) { }

  ngOnInit(): void {
    this.getStudentData();
    this.getVolunteersData();
    this.getInterviewTable();
    this.changeRole(UserRole.STUDENT);
  }

  public getStudentData(): void {
    this.service.getStudentData().subscribe(
      (value: number[]) => this.students = value);
  }

  public getVolunteersData(): void {
    this.service.getVolunteerData().subscribe(
      (value: number[]) => this.volunteers = value,
    );
  }

  getInterviewTable(): void {
    this.service.getInterviewData(0, 12, 0).subscribe(
      (value) => this.interviews = value.table.map(it => ({name: '', cells: it})).reverse(),
    );
  }

  getDemographicsData(): void {
    this.service.getDemographyData(this.role).subscribe(
      (value: DiagramDTO) => this.demographics = value,
    );
  }

  getAgeData(): void {
    this.service.getAgeData(this.role).subscribe(
      (value: DiagramDTO) => this.age = value,
    );
  }

  getGenderData(): void {
    this.service.getGenderData(this.role).subscribe(
      (value: DiagramDTO) => this.gender = value,
    );
  }

  getEnglishLevelData(): void {
    this.service.getEnglishLevelData(this.role).subscribe(
      (value: DiagramDTO) => this.english = value,
    );
  }

  getSourcesData(): void {
    this.service.getSourcesData(this.role).subscribe(
      (value: DiagramDTO) => this.source = value,
    );
  }

  getTestData(): void {
    if (this.role === UserRole.VOLUNTEER) {
      this.test = null;
      return;
    }
    this.service.getTestData().subscribe(
      (value: DiagramDTO) => this.test = value,
    );
  }

  getMonthlySummary(): void {
    this.service.getMonthlySummary(this.role).subscribe(
      (value) => this.summary = value.table.map(it => ({name: '', cells: it})).reverse(),
    );
  }

  getTimeToFirstMatch(): void {
    this.service.getTimeToFirstMatch(this.role).subscribe(
      (value) => this.matching = value.table.map(it => ({name: '', cells: it})),
    );
  }

  toggleRoleSelector($event: MatButtonToggleEvent<UserRole>): void {
    this.changeRole($event.value);
  }

  changeRole(role: UserRole): void {
    this.role = role;
    this.getTestData();
    this.getDemographicsData();
    this.getAgeData();
    this.getTestData();
    this.getGenderData();
    this.getEnglishLevelData();
    this.getSourcesData();
    this.getMonthlySummary();
    this.getTimeToFirstMatch();
  }
}
