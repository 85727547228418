import {Component, Input} from '@angular/core';
import {GroupSessionCard} from '../../model/group-session-card';
import {GroupSessionService} from '../../service/group-session.service';

@Component({
  selector: 'app-group-session-row',
  templateUrl: './group-session-row.component.html',
  styleUrls: ['./group-session-row.component.scss']
})
export class GroupSessionRowComponent {

  @Input() card: GroupSessionCard;

  constructor(private service: GroupSessionService) { }

  start(): void {
    this.service.startGroupSession(this.card.id, this.card.startDate);
  }
}
