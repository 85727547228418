import { Pipe, PipeTransform } from '@angular/core';

import { TrainingStatus } from '../../../features/availability-training/model/training';

@Pipe({
  name: 'trainingStatus'
})
export class TrainingStatusPipe implements PipeTransform {

  transform(value: number): string {
    const res = TrainingStatus[value];
    return res ? res.split('_').join(' ').toLowerCase() : null;
  }

}
