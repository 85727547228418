<div *ngIf="profile">
  <div class="photo-and-name-container">
    <img src="{{ null }}" alt="" class="avatar" />
    <h5 class="name">{{ profile.fullName }}</h5>
    <br />
    <span class="body-regular-text status">{{ profile.email }}</span>
  </div>

  <div class="common-info">
    <div class="common-info-block">
      <!--      <span class="body-regular-text">{{ profile.dateOfBirth | age }}</span>-->
      <span class="body-regular-text">{{
        profile.dateOfBirth | nullReplace
      }}</span>
      <span class="overline-text">Age</span>
    </div>
    <div class="common-info-block">
      <span class="body-regular-text">{{
        profile.gender | gender | nullReplace
      }}</span>
      <span class="overline-text">Gender</span>
    </div>
    <div class="common-info-block">
      <span class="body-regular-text">{{
        profileQuestionsData?.genderPref?.value | nullReplace
      }}</span>
      <span class="overline-text">Gender Preference</span>
    </div>
  </div>

  <br />
  <div class="section section-one">
    <span class="caption-text">English</span>
    <span class="body-regular-text">{{
      profile.englishLevel | english | nullReplace | capitalCase
    }}</span>
    <span class="caption-text">Favorite Areas of Study:</span>
    <span class="body-regular-text">{{
      profileQuestionsData?.academicInterests | selectedAnswerList | nullReplace
    }}</span>
    <span class="caption-text">Outside interests:</span>
    <span class="body-regular-text">{{
      profileQuestionsData?.hobbies | selectedAnswerList | nullReplace
    }}</span>
  </div>

  <div class="section pt-4" *ngIf="role === UserRole.STUDENT">
    <span class="caption-text">Format preference:</span>
    <span *ngIf="miniGroupPreference?.studentMiniGroup">
      {{ studentMiniGroups[miniGroupPreference.studentMiniGroup] }}
    </span>
  </div>

  <br />
  <div class="section">
    <span class="caption-text">Interview Notes:</span>
    <span class="body-regular-text">{{
      profile.interviewComment | nullReplace
    }}</span>
  </div>

  <br />
  <div class="section">
    <span class="caption-text">Comment:</span>
    <span class="body-regular-text">{{ profile.comment | nullReplace }}</span>
  </div>

  <br />
  <div class="section">
    <span class="caption-text">Decision letter date:</span>
    <span class="body-regular-text">{{
      profile.decisionDate
        ? (profile.decisionDate * 1000 | date : "medium")
        : "-"
    }}</span>
  </div>

  <br *ngIf="role === UserRole.VOLUNTEER" />
  <div class="section" *ngIf="role === UserRole.VOLUNTEER">
    <span class="caption-text">Matched students:</span>
    <div
      class="section-inner-container"
      *ngFor="let match of matching.matchedStudents"
    >
      <div class="text-container">
        <span class="body-regular-text"
          >{{ match.firstName + " " + match.lastName }}
        </span>
        <span class="caption-text"
          >({{ match.groupName ? match.groupName : "Individual" }})</span
        >
      </div>
    </div>
  </div>
  <div class="section pt-4" *ngIf="role === UserRole.VOLUNTEER">
    <span class="caption-text">Individual Format:</span>
    <span *ngIf="miniGroupPreference?.volunteerIndividualSession">
      {{
        volunteerIndividualSessions[
          miniGroupPreference.volunteerIndividualSession
        ]
      }}
    </span>
  </div>
  <div class="section pt-4" *ngIf="role === UserRole.VOLUNTEER">
    <span class="caption-text">Mini-Group Format:</span>
    <span *ngIf="miniGroupPreference?.volunteerMiniGroupSession">
      {{
        volunteerMiniGroupSessions[
          miniGroupPreference.volunteerMiniGroupSession
        ]
      }}
    </span>
  </div>

  <div class="section pt-4" *ngIf="role === UserRole.VOLUNTEER">
    <span class="caption-text">Preferred student`s level:</span>
    <span class="body-regular-text">{{
      profileQuestionsData?.studentLevel | selectedAnswerList | nullReplace
    }}</span>
  </div>
  <br />
  <div class="section">
    <span class="caption-text">Time zone:</span>
    <span class="body-regular-text">{{
      profile.timeZone | timeZoneInfo | nullReplace
    }}</span>
  </div>
</div>

<div class="section">
  <span class="caption-text">Availability for Sessions</span>
  <div *ngFor="let time of times; index as i" class="time-container">
    <span class="overline-text">{{ time }}</span>
    <div class="days-container">
      <div
        *ngFor="let day of days; index as j"
        [ngClass]="{
          'day-disabled': isDayDisabled({
            availability: profileQuestionsData?.availability,
            day: i * 7 + j
          })
        }"
        class="day"
      >
        <span class="caption-text">{{ day }}</span>
      </div>
    </div>
  </div>
</div>
