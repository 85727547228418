<div class="table-navigation-container">
  <div class="left-side">
    <select required [(ngModel)]="status" (ngModelChange)="changeFilter()">
      <option [ngValue]="null">All statuses</option>
      <option *ngFor="let status of statuses" [ngValue]="status">{{ status | participantStatus }}</option>
    </select>
    <select required [(ngModel)]="role" (ngModelChange)="changeFilter()">
      <option [ngValue]="null">All participants</option>
      <option [ngValue]="100">Student</option>
      <option [ngValue]="101">Volunteer</option>
    </select>
    <select required [(ngModel)]="subprogramId" (ngModelChange)="changeFilter()">
      <option [ngValue]="null">All subprograms</option>
      <option *ngFor="let subprogram of subprograms" [ngValue]="subprogram.id">{{ subprogram.name }}</option>
    </select>
    <mat-divider vertical class="divider"></mat-divider>
    <div class="filter-wrap">
      <div class="title">Match length:</div>
      <label>
        <input numbersOnly placeholder="from" [(ngModel)]="matchFrom" (ngModelChange)="changeFilter()">
      </label>
      <span class="divider"> - </span>
      <label>
        <input numbersOnly placeholder="to" [(ngModel)]="matchTo" (ngModelChange)="changeFilter()">
      </label>
    </div>
    <mat-divider vertical class="divider"></mat-divider>
    <div class="filter-wrap">
      <div class="title">Number of sessions:</div>
      <label>
        <input numbersOnly placeholder="from" [(ngModel)]="sessionsFrom" (ngModelChange)="changeFilter()">
      </label>
      <span class="divider"> - </span>
      <label>
        <input numbersOnly placeholder="to" [(ngModel)]="sessionsTo" (ngModelChange)="changeFilter()">
      </label>
    </div>
  </div>
  <div class="right-side">
    <label>
      <input type="text" placeholder="Search" class="search" [(ngModel)]="search" (ngModelChange)="changeFilter()">
    </label>
  </div>
</div>
<div class="table">
  <div class="table-container panel">
    <ng-container *ngIf="data">
      <table mat-table [dataSource]="data">
        <ng-container matColumnDef="photo">
          <th class="overline-text" mat-header-cell *matHeaderCellDef>Photo</th>
          <td mat-cell *matCellDef="let element">
            <img class="avatar-img" src="{{ element.photo }}" alt="">
          </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th class="overline-text" mat-header-cell *matHeaderCellDef>First name</th>
          <td class="body-regular-text" mat-cell *matCellDef="let element">
            {{ element.firstName | nullReplace }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th class="overline-text" mat-header-cell *matHeaderCellDef>Last name</th>
          <td class="body-regular-text" mat-cell *matCellDef="let element">
            {{ element.lastName | nullReplace }}
          </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th class="overline-text" mat-header-cell *matHeaderCellDef>Role</th>
          <td class="body-regular-text" mat-cell *matCellDef="let element">
            {{ element.role | userRole | nullReplace }}
          </td>
        </ng-container>

        <ng-container matColumnDef="age">
          <th class="overline-text" mat-header-cell *matHeaderCellDef>Age</th>
          <td class="body-regular-text" mat-cell *matCellDef="let element">
            {{ element.dateOfBirth | age | nullReplace }}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th class="overline-text" mat-header-cell *matHeaderCellDef>Email</th>
          <td class="body-regular-text" mat-cell *matCellDef="let element">
            <a [routerLink]="['/participants', element.id]" class="body-regular-text text-decoration-none">
              {{ element.email | nullReplace }}
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="applied">
          <th class="overline-text" mat-header-cell *matHeaderCellDef>Applied</th>
          <td class="body-regular-text" mat-cell *matCellDef="let element">
            {{ element.applied | date:'mediumDate' | nullReplace }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th class="overline-text" mat-header-cell *matHeaderCellDef>Status</th>
          <td class="body-regular-text" mat-cell *matCellDef="let element">
            {{ element.status | participantStatus | capitalCase | nullReplace }}
          </td>
        </ng-container>

        <ng-container matColumnDef="currentMatch">
          <th class="overline-text" mat-header-cell *matHeaderCellDef>Current match</th>
          <td class="body-regular-text" mat-cell *matCellDef="let element">
            {{ element.currentMatch | nullReplace }}
          </td>
        </ng-container>

        <tr class="overline-text" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          class="body-regular-text"
          mat-row *matRowDef="let row;
          columns: displayedColumns;"
          [routerLink]="['/participants', row.id]"
        ></tr>
      </table>

      <mat-paginator
        *ngIf="maxLength"
        [length]="maxLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="[10, 20, 50]"
        showFirstLastButtons
        class="body-regular-text"
        (page)="changePage($event)"
      ></mat-paginator>

    </ng-container>
    <app-loader class="loader" *ngIf="loading"></app-loader>
    <app-no-data *ngIf="!loading && !data?.length" class="no-data"></app-no-data>
  </div>
</div>
