import {Component, Input, OnInit} from '@angular/core';
import {MatchingProfile} from '../../../../model/participant-details';
import {ListPipe} from '@static/pipes/list.pipe';
import {UserRole} from '@static/constants/user-role/user-role.enum';
import {AnswersService} from "../../answers.service";
import memo from 'memo-decorator';
import {SelectedAnswer} from "../../../../model/profile-questions-data";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-participant-matching-settings',
  templateUrl: './participant-matching-settings.component.html',
  styleUrls: ['./participant-matching-settings.component.scss']
})
export class ParticipantMatchingSettingsComponent implements OnInit {

  constructor(private listPipe: ListPipe, public readonly answersService: AnswersService) {
  }

  days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  times = ['Morning', 'Afternoon', 'Evening'];

  @Input() role: UserRole;
  @Input() profile: MatchingProfile;

  UserRole = UserRole;
  extraInfo: any[];

  ngOnInit(): void {
    this.init()

    this.answersService.profileAnswerDataUpdated$.pipe(untilDestroyed(this)).subscribe(() => {
      this.init()
    })
  }

  @memo()
  isDayDisabled({availability, day}: { availability: SelectedAnswer[], day: number }): boolean {
    return availability.map(({value}) => value).includes(day.toString())
  }

  private init(): void {
    this.extraInfo = [
      {
        title: 'Favorite Areas of Study:',
        description: this.listPipe.transform(this.answersService.profileAnswerData.academicInterests.map(({value}) => value)),
      },
      {
        title: 'Outside  Interests :',
        description: this.listPipe.transform(this.answersService.profileAnswerData.hobbies.map(({value}) => value))
      },
      {
        title: 'Source:',
        description: this.profile.source
      }
    ];

    if (this.profile.testScore) {
      this.extraInfo.unshift({
          title: 'Student`s entry test score',
          description: this.profile.testScore
        }
      );
    }
  }
}
