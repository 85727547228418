<!--<app-page-menu-bar></app-page-menu-bar>-->

<div class="table-navigation-container">
  <div class="left-side">
    <input type="text" class="search" placeholder="Search by name">
  </div>
  <div class="right-side">
    <button class="primary-button2" (click)="editSource()">Create source</button>
  </div>
</div>

<table mat-table [dataSource]="sources" class="panel">

  <ng-container matColumnDef="name">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Name</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="participants">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Participants</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.participants }}</td>
  </ng-container>

  <ng-container matColumnDef="percent">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Participants percentage</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ (element.percent | number:'1.1-1') + '%' }}</td>
  </ng-container>

  <ng-container matColumnDef="parentName">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Source type</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.parentName }}</td>
  </ng-container>

  <ng-container matColumnDef="userRole">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>User role</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.userRole | userRole}}</td>
  </ng-container>

  <!--    <ng-container matColumnDef="source">-->
  <!--      <th class="overline-text" mat-header-cell *matHeaderCellDef>Source</th>-->
  <!--      <td class="body-regular-text" mat-cell *matCellDef="let element">{{element.source}}</td>-->
  <!--    </ng-container>-->

  <!--    <ng-container matColumnDef="amount">-->
  <!--      <th class="overline-text" mat-header-cell *matHeaderCellDef>Amount</th>-->
  <!--      <td class="body-regular-text" mat-cell *matCellDef="let element"> {{element.amount}} </td>-->
  <!--    </ng-container>-->

  <!--    <ng-container matColumnDef="averageAge">-->
  <!--      <th class="overline-text" mat-header-cell *matHeaderCellDef>Average Age</th>-->
  <!--      <td class="body-regular-text" mat-cell *matCellDef="let element"> {{element.averageAge}} </td>-->
  <!--    </ng-container>-->

  <!--    <ng-container matColumnDef="country">-->
  <!--      <th class="overline-text" mat-header-cell *matHeaderCellDef>Country</th>-->
  <!--      <td class="body-regular-text" mat-cell *matCellDef="let element"> {{element.country}} </td>-->
  <!--    </ng-container>-->

  <!--    <ng-container matColumnDef="englishLevel">-->
  <!--      <th class="overline-text" mat-header-cell *matHeaderCellDef>English Level</th>-->
  <!--      <td class="body-regular-text" mat-cell *matCellDef="let element"> {{element.englishLevel}} </td>-->
  <!--    </ng-container>-->

  <!--    <ng-container matColumnDef="commonInterests">-->
  <!--      <th class="overline-text" mat-header-cell *matHeaderCellDef>Common Interests</th>-->
  <!--      <td class="body-regular-text" mat-cell *matCellDef="let element"> {{element.commonInterests}} </td>-->
  <!--    </ng-container>-->

  <ng-container matColumnDef="options">
    <th class="overline-text" mat-header-cell *matHeaderCellDef></th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      <span class="material-icons" (click)="editSource(element)">edit</span>
      <span class="material-icons" (click)="isActiveSources(element.id)">{{ element.active ? 'delete' : 'undo' }}</span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<!--  <mat-paginator [pageSizeOptions]="[1,5,10,50]" showFirstLastButtons class="body-regular-text"></mat-paginator>-->
