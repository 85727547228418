<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Edit matching profile</h6>
    <button class="secondary-button-icons close-button" (click)="close()">
      <span class="material-icons">close</span>
    </button>
  </div>
  <mat-accordion>
    <div class="wrapper">
      <mat-form-field appearance="fill">
        <mat-label>Favorite Areas of Study:</mat-label>
        <mat-select multiple placeholder="Favorite Areas of Study:" [(ngModel)]="academicInterestsArr">
          <mat-option *ngFor="let item of getItemsByKey('academicInterests')" [value]="item.id">
            {{ item.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Outside Interests:</mat-label>
        <mat-select multiple placeholder="Outside Interests:" [(ngModel)]="hobbiesArr">
          <mat-option *ngFor="let hobby of getItemsByKey('hobbies')" [value]="hobby.id">
            {{ hobby.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>English level:</mat-label>
        <mat-select placeholder="Outside Interests:" [(ngModel)]="englishLevel">
          <mat-option *ngFor="let englishLvl of getItemsByKey('english')" [value]="englishLvl.id">
            {{ englishLvl.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-button-toggle-group multiple [value]="availability" (change)="onAvailabilityChange(buttonsGroup.value)" #buttonsGroup="matButtonToggleGroup">
      <table class="for-pc" >
        <tbody>
        <tr>
          <td class="days overline-text-thin"></td>
          <td *ngFor="let day of days" class="days overline-text-thin">{{ day.short }}</td>
        </tr>
        <tr *ngFor="let time of times; index as i">
          <td class="time overline-text-thin">{{ time }}</td>
          <td *ngFor="let days of days; index as j" class="cell">
            <mat-button-toggle [value]="possibleAvailabilityAnswers[i*7+j].id">
              <span class="material-icons">done</span>
            </mat-button-toggle>
          </td>
        </tr>
        </tbody>
      </table>
    </mat-button-toggle-group>
    <mat-button-toggle-group multiple [value]="availability" (change)="onAvailabilityChange(buttonsGroup2.value)" #buttonsGroup2="matButtonToggleGroup" style="border: none;">
      <table class="for-mobile">
        <tbody>
        <tr>
          <td></td>
          <td *ngFor="let time of times" class="time overline-text-thin">{{ time }}</td>
        </tr>
        <tr *ngFor="let day of days; index as j">
          <td class="days overline-text-thin">{{day.short}}</td>
          <td *ngFor="let time of times; index as i" class="cell">
            <mat-button-toggle [value]="possibleAvailabilityAnswers[i*7+j].id" style="border-radius: 4px; border: solid 1px #e0e0e0; display: inline-block;">
              <span class="material-icons">done</span>
            </mat-button-toggle>
          </td>
        </tr>
        </tbody>
      </table>
    </mat-button-toggle-group>

      <!--      <div class="input-container">-->
      <!--        <span class="overline-text">Status</span>-->
      <!--        <select class="form-control" [(ngModel)]="participant.profile.status">-->
      <!--          <option *ngFor="let key of participantStatusKeys" [value]="key">{{ key | participantStatus }}</option>-->
      <!--        </select>-->
      <!--      </div>-->

      <!--      <div class="input-container">-->
      <!--        <span class="overline-text">First name</span>-->
      <!--        <input type="text" placeholder="First name" [(ngModel)]="participant.profile.firstName">-->
      <!--      </div>-->
      <!--      <div class="input-container">-->
      <!--        <span class="overline-text">Last name</span>-->
      <!--        <input type="text" placeholder="Last name" [(ngModel)]="participant.profile.lastName">-->
      <!--      </div>-->
      <!--      <div class="input-container">-->
      <!--        <span class="overline-text">Email</span>-->
      <!--        <input type="text" placeholder="Email" [(ngModel)]="participant.profile.email">-->
      <!--      </div>-->

      <!--      <div class="input-container">-->
      <!--        <span class="overline-text">City</span>-->
      <!--        <input type="text" placeholder="City" [(ngModel)]="participant.profile.city">-->
      <!--      </div>-->

      <!--      <div class="input-container container">-->
      <!--        <span class="overline-text">Time zone</span>-->

      <!--        <select name="day" class="form-control">-->
      <!--          <option value="null" hidden>Eastern Standard Time, GTM -5:00</option>-->
      <!--          <option>Eastern Standard Time, GTM -5:00</option>-->
      <!--        </select>-->
      <!--      </div>-->
      <!--      <div class="input-container container">-->
      <!--        <span class="overline-text">Source</span>-->
      <!--        <select class="form-control" [(ngModel)]="participant.profile.source">-->
      <!--          <option *ngFor="let source of sources" [value]="source.id">{{ source.name }}</option>-->
      <!--        </select>-->
      <!--      </div>-->

      <!--      <div class="input-container container" *ngIf="participant.userRole === UserRole.VOLUNTEER">-->
      <!--        <span class="overline-text">Prior Experience</span>-->
      <!--        <input type="text" placeholder="Experience" [(ngModel)]="participant.profile.experience">-->
      <!--      </div>-->

      <!--      <div class="input-container container" *ngIf="participant.userRole === UserRole.VOLUNTEER">-->
      <!--        <span class="overline-text">Motivation</span>-->
      <!--        <input type="text" placeholder="Motivation" [(ngModel)]="participant.profile.motivation">-->
      <!--      </div>-->

    </div>
  </mat-accordion>

  <!--  <app-edit-profile-basic-info></app-edit-profile-basic-info>-->
  <!--  <app-edit-profile-notes></app-edit-profile-notes>-->
  <!--  <app-edit-profile-matching-settings></app-edit-profile-matching-settings>-->
  <!--  <app-edit-profile-interview></app-edit-profile-interview>-->

  <div class="buttons-container" mat-dialog-actions>
    <button class="secondary-button" (click)="close()">Cancel</button>
    <button class="primary-button2" cdkFocusInitial (click)="save()">Save</button>
  </div>
</div>

