import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-message-announcement',
  templateUrl: './message-announcement.component.html',
  styleUrls: ['./message-announcement.component.scss']
})
export class MessageAnnouncementComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
