import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotifierService} from 'angular-notifier';
import {ParticipantService} from '../../service/participant.service';

@Component({
  selector: 'app-invite-participant',
  templateUrl: './invite-participant.component.html',
  styleUrls: ['./invite-participant.component.scss']
})
export class InviteParticipantComponent {

  email: string;

  constructor(
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private service: ParticipantService,
    private notifier: NotifierService
  ) {
    this.email = null;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  invite(): void {
    this.service.inviteParticipant(this.email).subscribe(
      _ => {
        this.notifier.notify('success', 'Успішно запрошено');
        this.onClose();
      },
      error => this.notifier.notify('error', error.error.message)
    );
  }
}
