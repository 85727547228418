<div class="table-navigation-container">
  <div></div>
  <div class="right-side">
    <button class="primary-button2" (click)="edit()">Add subprogram</button>
  </div>
</div>

<table mat-table [dataSource]="subprograms" class="panel">

  <ng-container matColumnDef="name">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Name</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Description</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.description }}</td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Type</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.type | subprogramType }}</td>
  </ng-container>

  <ng-container matColumnDef="color">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Color</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      <div class="color-circle" [style.background-color]="element.color"></div>
    </td>
  </ng-container>

  <ng-container matColumnDef="numberOfRegisteredStudents">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Students</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.numberOfRegisteredStudents }}</td>
  </ng-container>

  <ng-container matColumnDef="numberOfRegisteredVolunteers">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Volunteers</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.numberOfRegisteredVolunteers }}</td>
  </ng-container>

  <ng-container matColumnDef="numberOfActiveStudents">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Active students</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.numberOfActiveStudents }}</td>
  </ng-container>

  <ng-container matColumnDef="numberOfActiveVolunteers">
    <th class="overline-text" mat-header-cell *matHeaderCellDef>Active volunteers</th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">{{ element.numberOfActiveVolunteers }}</td>
  </ng-container>

  <ng-container matColumnDef="options">
    <th class="overline-text" mat-header-cell *matHeaderCellDef></th>
    <td class="body-regular-text" mat-cell *matCellDef="let element">
      <span class="material-icons" (click)="edit(element)">edit</span>
      <span class="material-icons" (click)="toggleActive(element)">{{ element.active ? 'delete' : 'undo' }}</span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
