import {Component, Input, OnInit} from '@angular/core';
import {HeaderService} from '../../service/header.service';
import {FullName} from '../../model/full-name';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() page: string;

  name: string;

  constructor(private service: HeaderService) {
  }

  ngOnInit(): void {
    this.service.getFullName().subscribe(
      (value: FullName) => {
        this.name = value.fullName;
      },
    );
  }
}
