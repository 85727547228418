<!--<div class="table-navigation-container">-->
<!--  <div class="left-side">-->
<!--    <button class="primary-button2" (click)="invitePartner()">-->
<!--      <span class="material-icons">add</span> Запросити учасника-->
<!--    </button>-->
<!--&lt;!&ndash;    <select name="" id="">&ndash;&gt;-->
<!--&lt;!&ndash;      <option value="">role</option>&ndash;&gt;-->
<!--&lt;!&ndash;    </select>&ndash;&gt;-->
<!--  </div>-->
<!--&lt;!&ndash;  <div class="right-side">&ndash;&gt;-->
<!--&lt;!&ndash;    <input type="text" placeholder="Пошук">&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--</div>-->

<div class="table">
  <div class="table-container">
    <table mat-table [dataSource]="data" class="panel">

      <ng-container matColumnDef="firstName">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>Ім&quot;я</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>Прізвище</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element.lastName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>Email</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>Статус</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element" style="display: flex; width: max-content">
          <p>{{ element.status | partnerParticipantStatus}}</p>
          <div class="colorful-round" [style.background-color]="getStatusColor(element.status)"></div>
        </td>
      </ng-container>

      <tr class="overline-text" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        class="body-regular-text"
        mat-row *matRowDef="let row; columns: displayedColumns;"
      ></tr>
    </table>
<!--    <mat-paginator [pageSizeOptions]="[1,5,10,50]" showFirstLastButtons class="body-regular-text"></mat-paginator>-->
  </div>
</div>
