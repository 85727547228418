import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PartnerService} from '../partner.service';
import {PartnerOrganisation} from './partner-organisation';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-partner-invite',
  templateUrl: './partner-invite.component.html',
  styleUrls: ['./partner-invite.component.scss']
})
export class PartnerInviteComponent {

  id: number;
  partnerOrganisation: PartnerOrganisation;

  constructor(
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    @Inject(MAT_DIALOG_DATA) data: {id: number},
    private service: PartnerService,
    private notifier: NotifierService
  ) {
    if (data.id) {
      this.service.getPartnerOrganisation(data.id).subscribe({
        next: value => {
          this.id = data.id;
          this.partnerOrganisation = {
            organisationName: value.organisationName,
            coordinatorName: value.coordinatorName,
            coordinatorEmail: value.coordinatorEmail,
            phone: value.phone,
            notes: value.notes
          };
        },
        error: error => this.notifier.notify('error', error.error.message)
      });
    } else {
      this.partnerOrganisation = {
        organisationName: '',
        coordinatorName: '',
        coordinatorEmail: '',
        phone: '',
        notes: ''
      };
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.id) {
      this.service.editPartnerOrganisation(this.id, this.partnerOrganisation).subscribe({
        next: _ => {
          this.notifier.notify('success', 'Updated successfully');
          this.onClose();
        },
        error: error => this.notifier.notify('error', error.error.message)
      });
    } else {
      this.service.addPartnerOrganisation(this.partnerOrganisation).subscribe({
        next: _ => {
          this.notifier.notify('success', 'Invited successfully');
          this.onClose();
        },
        error: error => this.notifier.notify('error', error.error.message)
      });
    }
  }
}
