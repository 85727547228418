import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SettingsService} from '../../../../../features/settings/settings.service';
import {EditNotification, Notification} from '../../../../../features/settings/notifications/notification';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-edit-message-template-window',
  templateUrl: './edit-message-template-window.component.html',
  styleUrls: ['./edit-message-template-window.component.scss']
})
export class EditMessageTemplateWindowComponent implements OnInit {

  notification: Notification;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Notification,
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private service: SettingsService,
    private notifier: NotifierService
  ) {
  }

  ngOnInit(): void {
    this.notification = this.data;
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const dto: EditNotification = {
      name: this.notification.name,
      email: {
        subject: this.notification.email.subject,
        text: this.notification.email.text
      },
      telegram: {
        text: this.notification.telegram.text
      }
    };
    this.service.editNotification(this.notification.id, dto).subscribe(
      _ => this.notifier.notify('success', 'Saved'),
      error => this.notifier.notify('error', error.error.message),
      () => this.close()
    );
  }
}
