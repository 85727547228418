<table class="panel">
  <h3 class="dashboard-block-title">{{ name }}</h3>
  <tr>
    <th class="date"></th>
    <th *ngFor="let column of columns" class="caption-text">{{ column }}</th>
  </tr>
  <tr *ngFor="let row of data; index as i">
    <td class="date dashboard-block-title">{{ getMonth(i) }}</td>
    <td
      *ngFor="let cell of row.cells"
      class="cell dashboard-block-title"
      [ngClass]="'cellHover'"
    >{{ cell }}</td>
  </tr>
</table>
