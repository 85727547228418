<div class="common-info">
  <div class="common-info-block">
    <span class="body-regular-text">{{ profile.dateOfBirth | age | nullReplace }}</span>
    <span class="overline-text">Age</span>
  </div>
  <div class="common-info-block">
    <span class="body-regular-text">{{ answersService.profileAnswerData.english?.value | nullReplace }}</span>
    <span class="overline-text">English Level (self-estimated)</span>
  </div>
  <div class="common-info-block">
    <span class="body-regular-text">{{ answersService.profileAnswerData.genderPref?.value | nullReplace }}</span>
    <span class="overline-text">Gender Preference</span>
  </div>
</div>
<div *ngFor="let extraInfo of extraInfo" class="section">
  <span class="caption-text">{{ extraInfo.title }}</span>
  <span class="body-regular-text">{{ extraInfo.description | nullReplace }}</span>
</div>

<div class="section">
  <span class="caption-text">Availability for Sessions</span>
  <div *ngFor="let time of times; index as i" class="time-container">
    <span class="overline-text">{{ time }}</span>
    <div class="days-container">
      <div
        *ngFor="let day of days; index as j"
        [ngClass]="{'day-disabled': isDayDisabled({availability: answersService.profileAnswerData.availability, day: i * 7 + j})}"
        class="day">
        <span class="caption-text">{{ day }}</span>
      </div>
    </div>
  </div>
</div>
