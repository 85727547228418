import { Component, Input } from '@angular/core';

import { TrainingCard } from '../model/training-card';

@Component({
  selector: 'app-training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss'],
})
export class TrainingCardComponent {
  @Input() card: TrainingCard;
}
