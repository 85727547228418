import { Pipe, PipeTransform } from '@angular/core';
import {InterviewStatus} from './interview-status.enum';

@Pipe({
  name: 'interviewStatus'
})
export class InterviewStatusPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    const res = InterviewStatus[value];
    return res ? res.split('_').join(' ').toLowerCase() : null;
  }

}
