import {ToolbarOption} from '../../static/toolbar/model/toolbar-option';

export class Features {

  public static admin: ToolbarOption[] = [
    {
      icon: 'dashboard',
      name: 'Dashboard',
      link: '/dashboard'
    },
    {
      icon: 'person',
      name: 'Team',
      link: '/team'
    },
    {
      icon: 'school',
      name: 'Participants',
      link: '/participants'
    },
    {
      icon: 'mic',
      name: 'Interviews',
      link: '/interviews'
    },
    {
      icon: 'date_range',
      name: 'Availability',
      link: '/availability'
    },
    {
      icon: 'people_outline',
      name: 'Availability Training',
      link: '/availability-training'
    },
    {
      icon: 'sports_soccer',
      name: 'Trainings',
      link: '/trainings'
    },
    {
      icon: 'extension',
      name: 'Matching',
      link: '/matching'
    },
    {
      icon: 'assignment_late',
      name: 'Announcements',
      link: '/announcements'
    },
    // {
    //   icon: 'groups',
    //   name: 'Group sessions',
    //   link: '/group-sessions'
    // },
    // {
    //   icon: 'check_circle_outline',
    //   name: 'Training',
    //   link: '/training'
    // },
    {
      icon: 'settings',
      name: 'Settings',
      link: '/settings'
    },
    {
      icon: 'bar_chart',
      name: 'Impact',
      link: '/impact'
    },
    {
      icon: 'book',
      name: 'Resources',
      link: '/resources'
    },
  ];

  public static manager: ToolbarOption[] = [
    {
      icon: 'dashboard',
      name: 'Dashboard',
      link: '/dashboard'
    },
    {
      icon: 'school',
      name: 'Participants',
      link: '/participants'
    },
    {
      icon: 'mic',
      name: 'Interviews',
      link: '/interviews'
    },
    {
      icon: 'date_range',
      name: 'Availability',
      link: '/availability'
    },
    {
      icon: 'extension',
      name: 'Matching',
      link: '/matching'
    },
    // {
    //   icon: 'groups',
    //   name: 'Group sessions',
    //   link: '/group-sessions'
    // }
    {
      icon: 'settings',
      name: 'Settings',
      link: '/settings'
    }
  ];

  public static interviewer: ToolbarOption[] = [
    {
      icon: 'dashboard',
      name: 'Dashboard',
      link: '/dashboard'
    },
    {
      icon: 'mic',
      name: 'Interviews',
      link: '/interviews'
    },
    {
      icon: 'date_range',
      name: 'Availability',
      link: '/availability'
    }
  ];

  public static matcher: ToolbarOption[] = [
    {
      icon: 'dashboard',
      name: 'Dashboard',
      link: '/dashboard'
    },
    {
      icon: 'extension',
      name: 'Matching',
      link: '/matching'
    }
  ];

  public static oldMatcher: ToolbarOption[] = [
    {
      icon: 'dashboard',
      name: 'Dashboard',
      link: '/dashboard'
    },
    {
      icon: 'extension',
      name: 'Matching',
      link: '/matching'
    },
    {
      icon: 'report',
      name: 'Failed matches',
      link: '/failed-matches'
    }
  ];

  public static marketer: ToolbarOption[] = [
    {
      icon: 'dashboard',
      name: 'Dashboard',
      link: '/dashboard'
    }
  ];

  public static trainer: ToolbarOption[] = [
    {
      icon: 'dashboard',
      name: 'Dashboard',
      link: '/dashboard'
    },
    {
      icon: 'people_outline',
      name: 'Availability Training',
      link: '/availability-training'
    },
    {
      icon: 'sports_soccer',
      name: 'Trainings',
      link: '/trainings'
    },
  ];

  public static partner: ToolbarOption[] = [
    // {
    //   icon: 'dashboard',
    //   name: 'Dashboard',
    //   link: '/dashboard'
    // },
    {
      icon: 'school',
      name: 'Учасники',
      link: '/participants'
    }
  ];
}
