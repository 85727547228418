import {Component, OnInit} from '@angular/core';
import {MarketerDashboardService} from '../../service/marketer-dashboard.service';
import {MarketerDashboardCardDTO} from './marketer-dashboard-card-dto';

@Component({
  selector: 'app-marketer-dashboard',
  templateUrl: './marketer-dashboard.component.html',
  styleUrls: ['./marketer-dashboard.component.scss']
})
export class MarketerDashboardComponent implements OnInit {

  schema: string[] = [
    'Need match',
    'Funnel',
    'Subtotal',
    'Prospects',
    'Total'
  ];

  students: number[];
  volunteers: number[];
  students1: number[];
  volunteers1: number[];
  students2: number[];
  volunteers2: number[];
  students3: number[];
  volunteers3: number[];
  students4: number[];
  volunteers4: number[];

  constructor(private service: MarketerDashboardService) {
    this.students = [];
    this.volunteers = [];
    this.students1 = [];
    this.volunteers1 = [];
    this.students2 = [];
    this.volunteers2 = [];
    this.students3 = [];
    this.volunteers3 = [];
    this.students4 = [];
    this.volunteers4 = [];
  }

  private static transform(card: MarketerDashboardCardDTO): number[] {
    const needMatch = card.needMatch;
    const funnel = card.funnel;
    const subtotal = needMatch + funnel;
    const prospects = card.prospects;
    const total = subtotal + prospects;
    return [needMatch, funnel, subtotal, prospects, total];
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.service.getData().subscribe(
      (value) => {
        this.students = MarketerDashboardComponent.transform(value.totalStudents);
        this.volunteers = MarketerDashboardComponent.transform(value.totalVolunteers);
        this.students1 = MarketerDashboardComponent.transform(value.studentsUpTo17);
        this.volunteers1 = MarketerDashboardComponent.transform(value.volunteersUpTo17);
        this.students2 = MarketerDashboardComponent.transform(value.studentsUpTo18);
        this.volunteers2 = MarketerDashboardComponent.transform(value.volunteersUpTo18);
        this.students3 = MarketerDashboardComponent.transform(value.studentsUpTo23);
        this.volunteers3 = MarketerDashboardComponent.transform(value.volunteersUpTo23);
        this.students4 = MarketerDashboardComponent.transform(value.studentsAbove23);
        this.volunteers4 = MarketerDashboardComponent.transform(value.volunteersAbove23);
      },
    );
  }
}
