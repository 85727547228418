import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TimeZoneDto } from './model/time-zone-dto';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TimezonePublicService {
  constructor(private readonly httpClient: HttpClient) {
  }

  getTimezones(): Observable<TimeZoneDto[]> {
    return this.httpClient.get<TimeZoneDto[]>('/timezones');
  }
}
