import { Pipe, PipeTransform } from '@angular/core';
import { TrainerDashboard } from "../model/dashboard";
import { Card } from "../model/card";

@Pipe({
  name: 'dashboard'
})
export class TrainerDashboardPipe implements PipeTransform {

  transform(value: TrainerDashboard): Card[] {
    return [
      { name: 'Upcoming today', value: value.upcomingToday },
      { name: 'All upcoming', value: value.upcomingAll },
      { name: 'Completed last month', value: value.completedLastMonth },
      { name: 'All completed', value: value.completedAll },
      { name: 'Hours last month', value: value.hoursLastMonth },
      { name: 'All hours', value: value.hoursAll }
    ];
  }

}
