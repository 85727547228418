import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public options = [
    {
      title: 'Acceptance criteria',
      link: '/acceptance-criteria',
      icon: 'book',
      options: ['Student', 'Volunteer']
    },
    {
      title: 'Progress levels',
      link: '/progress-levels',
      icon: 'model_training',
      options: ['Student', 'Volunteer']
    },
    {
      title: 'Partners',
      link: '/partners',
      icon: 'person',
      options: ['', '']
    },
    {
      title: 'Sources',
      link: '/sources',
      icon: 'travel_explore',
      options: ['Student', 'Volunteer']
    },
    {
      title: 'Mails & Notifications',
      link: '/notifications/email',
      icon: 'mail',
      options: ['Mails', 'Notifications']
    },
    {
      title: 'Exports',
      link: '/exports',
      icon: 'download',
      options: []
    },
    {
      title: 'Subprograms',
      link: '/subprograms',
      icon: 'star',
      options: []
    },
  ];

  constructor() {
  }

  ngOnInit(): void {
  }
}
