<div class="panel-header">
  <h6 mat-dialog-title>{{ interview.type | interviewTypeName }} Interview log</h6>
  <!--  <button class="secondary-button-icons close-button">-->
  <!--    <span class="material-icons">edit</span>-->
  <!--  </button>-->
</div>

<ng-container>
  <div class="no-data-label">
    <span class="body-highlighted-text">No data</span>
    <span class="body-regular-text">The interview has not passed yet</span>
  </div>
</ng-container>

<div id="wrapper" [formGroup]="formGroup">
  <div class="section">
    <mat-form-field class="w-100">
      <mat-label>English Level</mat-label>
      <mat-select formControlName="englishLevel">
        <mat-option *ngFor="let level of englishLevels" [value]="level.value">
          {{ level.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="section">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Interview Notes</mat-label>
      <textarea
        matInput
        class="no-global-input-styles"
        formControlName="comment"
        placeholder="Enter your note"></textarea>
    </mat-form-field>
  </div>

  <div class="section" *ngIf="isStudent && isOnboarding">
    <app-payment-select [selectedPaymentRequirement]="selectedPaymentRequirement"
                        (paymentRequirementChange)="paymentRequirementChanged($event)"></app-payment-select>
  </div>
  <div class="section d-flex justify-content-start" *ngIf="isStudent && isOnboarding">
    <mat-checkbox formControlName="readyToBeVolunteer">Ready to be a Volunteer</mat-checkbox>
  </div>
  <div class="section">
    <mat-form-field class="w-100">
      <mat-label>Decision</mat-label>
      <mat-select formControlName="interviewDecision">
        <mat-option *ngFor="let decision of decisions" [value]="decision">
          {{ decision | interviewDecision | capitalCase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="button-container section">
    <button class="primary-button" (click)="save()">Save</button>
    <button class="secondary-button">Cancel</button>
  </div>
</div>
