<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Edit Comment</h6>
    <button class="secondary-button-icons close-button" (click)="close()">
      <span class="material-icons">close</span>
    </button>
  </div>

  <mat-accordion>
    <div class="wrapper">
      <div class="input-container">
        <span class="overline-text">Comment</span>
        <textarea type="text" placeholder="First name" class="comment-field" [(ngModel)]="comment"></textarea>
      </div>
    </div>
  </mat-accordion>

  <div class="buttons-container" mat-dialog-actions>
    <button class="secondary-button" (click)="close()">Cancel</button>
    <button class="primary-button2" cdkFocusInitial (click)="save()">Save</button>
  </div>
</div>

