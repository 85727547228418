import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';
import {UserInfoService} from '@static/login/service/user-info.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent {
  showNavBar: boolean;
  page: string;

  noNavBarPages = new Set<string>([
    '/',
    '/login',
    '/datepicker',
    '/forgot-password',
    '/change-password',
    '/we-are-down',
    '/not-found',
    '/reset-password'
  ]);

  constructor(
    private router: Router,
    private userInfoService: UserInfoService
  ) {
    router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        const urlWithoutQueryParams = e.urlAfterRedirects.split('?')[0];
        this.showNavBar = !this.noNavBarPages.has(urlWithoutQueryParams);
        this.page = this.getPageName(urlWithoutQueryParams);
      });
  }

  private getPageName(url: string): string {
    const feature = this.userInfoService.getFeatures().filter(it => it.link === url);
    if (feature.length > 0) {
      return feature[0].name;
    } else {
      switch (url) {
        case '/profile':
          return 'Profile';
        case '/partners':
          return 'Partners';
        case '/acceptance-criteria':
          return 'Acceptance criteria';
        case '/sources':
          return 'Sources';
        case '/notifications/email':
          return 'Notifications & emails';
      }
    }
  }
}
