import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainerDashboardService {

  constructor(private http: HttpClient) {
  }

  public getData(): Observable<any> {
    const offset = -new Date().getTimezoneOffset() * 60;
    return this.http.get('/training/dashboard', { params: { offset } });
  }
}
