import {Component, OnInit} from '@angular/core';
import {GroupSessionService} from '../../service/group-session.service';
import {ActivatedRoute} from '@angular/router';
import {GroupSession} from '../../model/group-session';
import {NotifierService} from 'angular-notifier';

export interface ParticipantData {
  photo: string;
  lastName: string;
  firstName: string;
  email: string;
  role: string;
}

@Component({
  selector: 'app-group-session-participants',
  templateUrl: './group-session-participants.component.html',
  styleUrls: ['./group-session-participants.component.scss']
})
export class GroupSessionParticipantsComponent implements OnInit {
  displayedColumns: string[] = ['lastName', 'firstName', 'email', 'role', 'attended'];

  constructor(
    private route: ActivatedRoute,
    private service: GroupSessionService,
    private notifier: NotifierService
  ) {
  }

  groupSession: GroupSession;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.service.getOne(+params.id).subscribe((value: GroupSession) => {
        this.groupSession = value;
      });
    });
  }

  saveAttendance(id: number): void {
    this.service.attendance(id).subscribe({
      next: value => {
        this.groupSession.participants.forEach(it => {
          if (id === it.id) {
            it.attended = !it.attended;
          }
        });
      },
      error: error => this.notifier.notify('error', error.error.message)
    });
  }
}


