import { FormGroup, ValidationErrors } from '@angular/forms';

export class CustomValidators {
  static endDateLessThanStartDate(startDate: string, endDate: string) {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const startDateVal = formGroup.controls[startDate].value;
      const endDateVal = formGroup.controls[endDate].value;

      if (startDateVal && endDateVal && new Date(startDateVal).getTime() > new Date(endDateVal).getTime()) {
        return { endDateLessThanStartDate: true };
      } else {
        return null;
      }
    };
  }
}
