import {Component, OnInit} from '@angular/core';
import {ParticipantService} from '../../../service/participant.service';
import {ParticipantCard} from '../../../model/participant-card';

@Component({
  selector: 'app-manager-participants',
  templateUrl: './manager-participants.component.html',
  styleUrls: ['./manager-participants.component.scss']
})
export class ManagerParticipantsComponent implements OnInit {

  role: number;
  // animalControl = new FormControl('', Validators.required);
  // selectFormControl = new FormControl('', Validators.required);

  constructor(private participantService: ParticipantService) {
  }

  data: ParticipantCard[];

  ngOnInit(): void {
    // this.participantService.getAll().subscribe(
    //   (value: ParticipantCard[]) => this.data = value,
    // );
  }
}

