import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ExportCard} from './export-card';

export enum ExportType {
  XLSX = 'xlsx',
  CSV = 'csv',
}

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  private BASE_URL = '/exports';

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<ExportCard[]> {
    return this.http.get<ExportCard[]>(this.BASE_URL);
  }

  getExport(id: number, type: ExportType): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${id}.${type}`,
      {responseType: 'blob', observe: 'response', headers: {Accept: 'application/octet-stream'}}
    );
  }
}
