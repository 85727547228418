import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AcceptanceCriteria} from './acceptance-criteria/acceptance-criteria';
import {EditNotification, Notification} from './notifications/notification';
import {LevelCard} from './progress-levels/level-card';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  getAcceptanceCriteria(): Observable<AcceptanceCriteria> {
    return this.http.get<AcceptanceCriteria>('/settings/acceptance-criteria');
  }

  setAcceptanceCriteria(dto: AcceptanceCriteria): Observable<any> {
    return this.http.put('/settings/acceptance-criteria', dto);
  }

  getNotifications(s: string): Observable<Notification[]> {
    let params: HttpParams = new HttpParams();
    if (s) {
      params = params.append('s', s);
    }
    return this.http.get<Notification[]>('/settings/notifications', {params});
  }

  editNotification(id: number, dto: EditNotification): Observable<any> {
    return this.http.patch(`/settings/notifications/${id}`, dto);
  }

  getLevels(): Observable<LevelCard[]> {
    return this.http.get<LevelCard[]>('/settings/levels');
  }

  // editLevel(id: number, dto: EditNotification): Observable<any> {
  //   return this.http.patch(`/settings/levels/${id}`, dto);
  // }
  //
  // createLevel(): Observable<any> {
  //
  // }
}
