import {Component, Input, OnInit} from '@angular/core';
import {DashboardTableRow} from './dashboard-table-row';

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './admin-dashboard-table.component.html',
  styleUrls: ['./admin-dashboard-table.component.scss']
})
export class AdminDashboardTableComponent implements OnInit {

  #months: string[] = [
    'December',
    'November',
    'October',
    'September',
    'August',
    'July',
    'June',
    'May',
    'April',
    'March',
    'February',
    'January',
  ];

  @Input() name: string;
  @Input() columns: string[];
  @Input() data: DashboardTableRow[];

  #month: number

  constructor() {
    this.#month = new Date().getMonth();
  }

  ngOnInit(): void {
  }

  public getMonth(i : number): string {
    return this.#months[(11 - this.#month + i) % 12];
  }
}
