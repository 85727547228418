import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ZoomService} from '../zoom.service';

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent implements OnInit {

  message;

  constructor(
    private route: ActivatedRoute,
    private service: ZoomService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.service.authorizationCode = params.code;
      this.service.getToken().subscribe(
        value => {
          // this.accessToken
          this.message = value;
        },
        error => {
          this.message = error;
        }
      );
    });
  }
}
