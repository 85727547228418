<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Edit Matching Info</h6>
    <button class="secondary-button-icons close-button" (click)="close()">
      <span class="material-icons">close</span>
    </button>
  </div>

  <div class="field-wrapper d-flex flex-column mt-4">
    <label>Individual Sessions Format (one-on-one)</label>
    <mat-form-field appearance="outline" class="w-100">
      <mat-select
        [formControl]="volunteerIndividualSessionControl"
        class="no-global-input-styles"
      >
        <mat-option
          *ngFor="let session of volunteerIndividualSessions"
          [value]="session.id"
          >{{ session.value }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="field-wrapper d-flex flex-column">
    <label>Mini-Groups Sessions Format</label>
    <mat-form-field appearance="outline" class="w-100">
      <mat-select
        [formControl]="volunteerMiniGroupSessionControl"
        class="no-global-input-styles"
      >
        <mat-option
          *ngFor="let session of volunteerMiniGroupSessions"
          [value]="session.id"
          >{{ session.value }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <div class="buttons-container" mat-dialog-actions>
    <button class="secondary-button" (click)="close()">Cancel</button>
    <button class="primary-button2" cdkFocusInitial (click)="save()">
      Save
    </button>
  </div>
</div>
