export enum InterviewStatus {
  SCHEDULED = 100,
  DID_NOT_HAPPEN = 200,
  CANCELLED_BY_PARTICIPANT,
  CANCELLED_BY_INTERVIEWER,
  RESCHEDULED,
  MISSED_BY_PARTICIPANT,
  MISSED_BY_INTERVIEWER,
  MISSED_BY_BOTH,
  PENDING_LOG = 300,
  COMPLETED
}
