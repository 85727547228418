import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ParticipantDetails } from '../../../../model/participant-details';
import { UserRole } from '@static/constants/user-role/user-role.enum';
import { ParticipantService } from '../../../../service/participant.service';
import { NotifierService } from 'angular-notifier';
import { HttpErrorResponse } from '@angular/common/http';
import { ParticipantStatus } from '@static/constants/participant-status/participant-status.enum';
import { SourcesService } from '../../../../../../settings/sources/sources.service';
import { Source } from '../../../../../../settings/sources/source';
import { map, Observable, startWith } from 'rxjs';
import { SubprogramsService } from '../../../../../../settings/subprograms/subprograms.service';
import { SubprogramCard } from '../../../../../../settings/subprograms/subprogram-card';
import { ECountries, EUkraineRegions, EUSStates } from '@static/enums/locations.enum';
import { PaymentRequirement } from '../../../../model/payment.types';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TimeZoneDto } from '../../../../../../timezone/model/time-zone-dto';
import { TimezonePublicService } from '../../../../../../timezone/timezone-public.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@UntilDestroy()
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  nonVolunteerStatuses: number[] = [103, 111, 203, 204];
  withoutStatuses: number[] = [
    ParticipantStatus.INACTIVE,
    ParticipantStatus.ON_BOARDING,
    ParticipantStatus.ACTIVE,
    ParticipantStatus.INACTIVE_FROZEN,
  ];
  allowedDoubleRoleStatuses: number[] = [
    ParticipantStatus.DROPOUT_PENDING,
    ParticipantStatus.DROPPED_OUT,
    ParticipantStatus.MATCHED,
    ParticipantStatus.WAITING_FOR_MATCH,
    ParticipantStatus.REQUESTED_REMATCH,
  ];
  participantStatusKeys: number[];
  sources: Source[] = [];
  subprograms: SubprogramCard[] = [];
  selectedSubprograms: number[] = [];
  UserRole = UserRole;
  id: number;
  participant: ParticipantDetails;
  paymentRequirement = PaymentRequirement;
  paidControl = new FormControl(false);

  timezoneControl = new FormControl(null, [Validators.required]);
  selectedTimezone: TimeZoneDto | undefined;
  timezoneList: TimeZoneDto[] | undefined = [];
  $filteredTimezones: Observable<TimeZoneDto[]>;


  public ECountries = ECountries;
  public EUkraineRegions = EUkraineRegions;
  public EUSStates = EUSStates;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private service: ParticipantService,
    private sourcesService: SourcesService,
    private notifier: NotifierService,
    private subprogramService: SubprogramsService,
    private cdRef: ChangeDetectorRef,
    private readonly timezoneService: TimezonePublicService,
  ) {

  }

  ngOnInit(): void {
    this.participantStatusKeys = Object.keys(ParticipantStatus)
      .filter(it => !isNaN(Number(it)))
      .map(Number)
      .filter(it => !this.withoutStatuses.includes(it));
    this.id = this.data.id;
    this.participant = this.data.participant;

    if (this.participant.userRole === UserRole.VOLUNTEER) {
      const hasStudentProfile = this.participant.userRoles.includes(UserRole.STUDENT);
      if (hasStudentProfile) {
        this.participantStatusKeys = this.allowedDoubleRoleStatuses;
      } else {
        this.participantStatusKeys = this.participantStatusKeys
          .filter(it => !this.nonVolunteerStatuses.includes(it));
      }
    }

    this.getSources();
    this.getSubprograms();
    this.paidControl.setValue(this.participant.profile?.paid);
    this.paidControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: boolean) => {
        this.participant.profile.paid = value;
        this.cdRef.detectChanges();
      });
    this.handleTimezones();
  }

  getSources(): void {
    this.sourcesService.getSources()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: value => this.sources = value,
        error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
      });
  }

  getSubprograms(): void {
    this.subprogramService.getAll()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: value => {
          this.subprograms = value;
          this.selectedSubprograms = this.participant.profile.subprograms.map(it => it.id);
        },
        error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
      });
  }

  onChangeSubprograms(): void {
    this.participant.profile.subprograms = this.subprograms.filter(it => this.selectedSubprograms.includes(it.id));
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.service.setProfileDetails(this.id, this.participant.userRole, this.participant.profile)
      .pipe(untilDestroyed(this))
      .subscribe(
        _ => {
          this.notifier.notify('success', 'Updated successfully');
          this.dialogRef.close(true);
        },
        (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
      );
  }

  setDateOfBirth(date: string): void {
    this.participant.profile.dateOfBirth = new Date(date).getTime() / 1000;
  }

  paymentRequirementChange($event: PaymentRequirement): void {
    this.participant.profile.paymentRequirement = $event;
  }



  selectTz(timezone: TimeZoneDto): void {
    this.participant.profile.timeZone = timezone;
    this.service.setProfileDetails(this.id, this.participant.userRole, this.participant.profile)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onTzOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const tzName = event.option.value;
    const timezone = this.timezoneList.find(tz => tz.name === tzName);
    this.selectTz(timezone);
  }

  private handleTimezones(): void {
    this.timezoneService.getTimezones()
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((timezones) => {
        this.timezoneList = timezones;
        this.$filteredTimezones = this.timezoneControl.valueChanges.pipe(
          startWith(''),
          map(value => this.filterTz(value || '')),
        );
        this.timezoneControl.patchValue(this.participant.profile.timeZone?.name);
      });
  }

  private filterTz(value: string): TimeZoneDto[] {
    const filterValue = value.toLowerCase();
    return this.timezoneList
      .filter(option => option.name.toLowerCase().includes(filterValue));
  }
}
