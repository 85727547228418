import { Component, Input, OnInit } from '@angular/core';

import { UserRole } from '@static/constants/user-role/user-role.enum';
import memo from 'memo-decorator';
import { times } from 'lodash';

import { ParticipantDto } from '../../model/participant-dto';
import {
  ProfileAnswersData,
  SelectedAnswer,
} from '../../../participants/manager/model/profile-questions-data';
import {
  MiniGroupPreference,
  StudentMiniGroupPreference,
} from '../../../interview/model/participant-profile';
import { MatchingInfo } from '../../../participants/manager/model/participant-details';

@Component({
  selector: 'app-matching-profile',
  templateUrl: './matching-profile.component.html',
  styleUrls: [
    '../../../interview/component/interview-overview-profile/interview-overview-profile.component.scss',
    './matching-profile.component.scss',
  ],
})
export class MatchingProfileComponent implements OnInit {
  constructor() {}

  days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  times = ['Morning', 'Afternoon', 'Evening'];

  @Input() profile: ParticipantDto;
  @Input() profileQuestionsData: ProfileAnswersData;
  @Input() role: UserRole;
  @Input() matching: MatchingInfo;
  @Input() miniGroupPreference: MiniGroupPreference;

  roleText: string;

  UserRole = UserRole;

  studentMiniGroups: Record<StudentMiniGroupPreference, string> = {
    [StudentMiniGroupPreference.ONE_ON_ONE]:
      'I prefer a 1-on-1 match (1 student and 1 volunteer)',
    [StudentMiniGroupPreference.MINI_GROUP]:
      'I prefer a mini-group (2 students (or 3 upon request) and 1 volunteer)',
    [StudentMiniGroupPreference.BOTH]:
      'I am comfortable with both options (fastest matching time)',
  };

  volunteerIndividualSessions = times(20, (index) => ({
    id: index + 1,
    value: `${index + 1} ${index === 0 ? 'student' : 'students'} (${
      index + 1
    }-hour commitment + prep time)`,
  })).reduce((acc, cur) => ({ ...acc, [cur.id]: cur.value }), {});

  volunteerMiniGroupSessions = times(20, (index) => ({
    id: index + 1,
    value: `${
      index + 1
    } Mini-Groups with 2 Students (extendable to 3 students upon request) (${
      index + 1
    }-hour commitment + prep time)`,
  })).reduce((acc, cur) => ({ ...acc, [cur.id]: cur.value }), {});

  ngOnInit(): void {
    this.roleText = this.role === UserRole.STUDENT ? 'student' : 'volunteer';
  }

  @memo()
  isDayDisabled({
    availability,
    day,
  }: {
    availability: SelectedAnswer[];
    day: number;
  }): boolean {
    return availability?.map(({ value }) => value).includes(day.toString());
  }
}
