<div id="wrapper">
  <div class="common-info">
    <div class="common-info-block">
      <h4>{{ history.count | nullReplace }}</h4>
      <span class="overline-text">Session count</span>
    </div>
    <div class="common-info-block">
      <h4>{{ history.hours | nullReplace }}</h4>
      <span class="overline-text">Total Time</span>
    </div>
  </div>
  <div class="container">
    <div class="history-section d-flex justify-content-between align-items-center session-history-list"
         [matTooltip]="'Edit session'"
         (click)="editSession(card)"
         *ngFor="let card of history.sessions">
      <span class="caption-text date">{{ card.date * 1000 | date:'shortDate' }}</span>
      <span class="caption-text time">{{ card.date * 1000 | date:'shortTime' }}</span>
      <mat-icon *ngIf="card.isApproved === null" class="hidden">done</mat-icon>
      <mat-icon *ngIf="card.isApproved === true">done</mat-icon>
      <mat-icon class="text-danger" *ngIf="card.isApproved === false">clear</mat-icon>
      <span class="body-regular-text time">{{ card.minutes }}</span>
    </div>
  </div>
</div>
