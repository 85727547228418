import {Component, OnInit} from '@angular/core';
import {UserInfoService} from '@static/login/service/user-info.service';
import {UserRole} from '@static/constants/user-role/user-role.enum';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent implements OnInit {

  id: number;
  role: UserRole;
  UserRole = UserRole;

  constructor(
    private roleSelectorService: UserInfoService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.role = this.roleSelectorService.getRole();
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = +params.id;
        this.role = UserRole.PARTNER;
      }
    });
  }
}
