<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Create Training</h6>
    <button class="secondary-button-icons close-button" (click)="onClose(false)">
      <span class="material-icons">close</span>
    </button>
  </div>
  <div class="wrapper" [formGroup]="trainingForm">
    <label>Name of Training:</label>
    <mat-form-field appearance="outline" class="w-100">
      <mat-select formControlName="type" placeholder="Name of Training" class="no-global-input-styles">
        <mat-option *ngFor="let type of trainingTypeOptions" [value]="type.value">{{ type.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <label>Trainer:</label>
    <mat-form-field appearance="outline" class="w-100">
      <mat-select formControlName="supervisorId" placeholder="Trainer" class="no-global-input-styles">
        <mat-option *ngFor="let trainer of trainers" [value]="trainer.id">{{
          trainer.name
          }}</mat-option>
      </mat-select>
    </mat-form-field>

    <label>Number of Participants:</label>
    <mat-form-field appearance="outline" class="w-100">
      <input matInput placeholder="Number of participants" class="no-global-input-styles"
        formControlName="slots" type="number" />
    </mat-form-field>

    <label>Start Date:</label>
    <mat-form-field appearance="outline" class="date-picker w-100">
      <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" formControlName="startDate"
        class="no-global-input-styles">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #picker>
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <label>Duration:</label>
    <mat-form-field appearance="outline" class="w-100">
      <mat-select formControlName="duration" placeholder="Duration" class="no-global-input-styles">
        <mat-option *ngFor="let duration of durationOptions" [value]="duration.value">{{ duration.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="buttons-container">
    <button class="secondary-button" (click)="onClose(false)">Cancel</button>
    <button class="primary-button2" [disabled]="trainingForm.invalid" (click)="onClose(true)">
      Save
    </button>
  </div>
</div>