<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>Reset password</h6>
    <button class="secondary-button-icons close-button" (click)="close(false)">
      <span class="material-icons">close</span>
    </button>
  </div>
  <input type="password" placeholder="Enter new password" [(ngModel)]="password1">
  <input type="password" placeholder="Verify new password" [(ngModel)]="password2">
  <div class="buttons-container">
    <button class="secondary-button" (click)="close(false)">Cancel</button>
    <button class="primary-button" (click)="close(true)">Reset password</button>
  </div>
</div>
