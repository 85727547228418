import {Pipe, PipeTransform} from '@angular/core';
import {InterviewDecision} from './interview-decision.enum';

@Pipe({
  name: 'interviewDecision'
})
export class InterviewDecisionPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    switch (value) {
      case InterviewDecision.NO_SHOW_TECHNICAL_ISSUE:
        return 'Technical issue';
      case InterviewDecision.REJECTED_ENGLISH:
        return 'Rejected (English)';
      case InterviewDecision.REJECTED_SAFETY:
        return 'Rejected (safety)';
      case InterviewDecision.REJECTED_SCHEDULE:
        return 'Rejected (schedule/commitment)';
      case InterviewDecision.REJECTED_MOTIVATION:
        return 'Rejected (motivation)';
      case InterviewDecision.REJECTED_SOCIAL_SKILLS:
        return 'Rejected (social skills)';
      case InterviewDecision.REJECTED_BY_OPINION_ON_WAR:
        return 'Rejected (opinion on war/Ukraine)';
      case InterviewDecision.REJECTED:
        return 'Rejected (other)';
      default:
        const res = InterviewDecision[value];
        return res ?
          res.split('_').join(' ').toLowerCase() : null;
    }
  }

}
