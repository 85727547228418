import { Component, Inject, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { times } from 'lodash';

import { VolunteerSession } from '../../../../../../interview/model/participant-profile';
import { InterviewService } from '../../../../../../interview/service/interview.service';
import { ParticipantDetails } from '../../../../model/participant-details';

@Component({
  selector: 'app-edit-matching-details',
  templateUrl: './edit-matching-details.component.html',
  styleUrls: ['./edit-matching-details.component.scss'],
})
export class EditMatchingDetailsComponent implements OnInit {
  participant: ParticipantDetails;

  volunteerIndividualSessionControl: FormControl;

  volunteerMiniGroupSessionControl: FormControl;

  volunteerIndividualSessions: VolunteerSession[] = [
    { id: null, value: 'None' },
    ...times(20, (index) => {
      const studentCount = index + 1;
      const studentLabel = studentCount === 1 ? 'student' : 'students';

      return {
        id: studentCount,
        value: `${studentCount} ${studentLabel} (${studentCount}-hour commitment + prep time)`,
      };
    }),
  ];

  volunteerMiniGroupSessions: VolunteerSession[] = [
    { id: null, value: 'None' },
    ...times(20, (index) => {
      const miniGroupCount = index + 1;

      return {
        id: miniGroupCount,
        value: `${miniGroupCount} Mini-Groups with 2 Students (extendable to 3 students upon request) (${miniGroupCount}-hour commitment + prep time)`,
      };
    }),
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private interviewService: InterviewService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.participant = this.data.participant;

    this.volunteerIndividualSessionControl = new FormControl(
      this.participant.miniGroup.volunteerIndividualSession
    );
    this.volunteerMiniGroupSessionControl = new FormControl(
      this.participant.miniGroup.volunteerMiniGroupSession
    );
  }

  save(): void {
    const miniGroupPreference = {
      ... this.participant.miniGroup,
      volunteerIndividualSession: this.volunteerIndividualSessionControl.value,
      volunteerMiniGroupSession: this.volunteerMiniGroupSessionControl.value,
    };
    this.interviewService
      .updateParticipantMiniGroup(this.participant.id, miniGroupPreference)
      .subscribe({
        next: (value) => {
          this.notifier.notify('success', 'Updated successfully');
          this.dialogRef.close(true);
        },
        error: (error: HttpErrorResponse) =>
          this.notifier.notify('error', error.error.message),
      });
  }

  close(): void {
    this.dialogRef.close();
  }
}
