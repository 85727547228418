<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>{{ create ? 'Add subprogram' : 'Edit subprogram' }}</h6>
    <button class="secondary-button-icons close-button" (click)="close()">
      <span class="material-icons">close</span>
    </button>
  </div>
  <input type="text" placeholder="Name" [(ngModel)]="subprogram.name">
  <input type="text" placeholder="Description" [(ngModel)]="subprogram.description">
  <select [(ngModel)]="subprogram.type">
    <option [ngValue]="100">Student only</option>
    <option [ngValue]="101">Volunteer only</option>
    <option [ngValue]="102">Student and volunteer</option>
  </select>
  <input type="text" placeholder="Color code" [(ngModel)]="subprogram.color">
  <button class="primary-button" (click)="submit()">Submit</button>
</div>
