import { Injectable } from '@angular/core';
import {ParticipantStatus} from '@static/constants/participant-status/participant-status.enum';
import {UserRole} from '@static/constants/user-role/user-role.enum';
import {SubprogramCard} from '../../../../settings/subprograms/subprogram-card';

@Injectable({
  providedIn: 'root'
})
export class ParticipantFilterService {

  private _statuses: ParticipantStatus[] = [];
  private _status: ParticipantStatus = null;
  private _role: UserRole = null;
  private _search: string;
  private _matchFrom: number = null;
  private _matchTo: number = null;
  private _sessionsTo: number = null;
  private _sessionsFrom: number = null;
  private _subprograms: SubprogramCard[] = [];
  private _subprogramId: number = null;
  constructor() { }

  get statuses(): ParticipantStatus[] {
    return this._statuses;
  }

  set statuses(value: ParticipantStatus[]) {
    this._statuses = value;
  }

  get status(): ParticipantStatus {
    return this._status;
  }

  set status(value: ParticipantStatus) {
    this._status = value;
  }

  get role(): UserRole {
    return this._role;
  }

  set role(value: UserRole) {
    this._role = value;
  }

  get search(): string {
    return this._search;
  }

  set search(value: string) {
    this._search = value;
  }

  get matchFrom(): number {
    return this._matchFrom;
  }

  set matchFrom(value: number) {
    this._matchFrom = value;
  }

  get matchTo(): number {
    return this._matchTo;
  }

  set matchTo(value: number) {
    this._matchTo = value;
  }

  get sessionsTo(): number {
    return this._sessionsTo;
  }

  set sessionsTo(value: number) {
    this._sessionsTo = value;
  }

  get sessionsFrom(): number {
    return this._sessionsFrom;
  }

  set sessionsFrom(value: number) {
    this._sessionsFrom = value;
  }

  get subprograms(): SubprogramCard[] {
    return this._subprograms;
  }

  set subprograms(value: SubprogramCard[]) {
    this._subprograms = value;
  }

  get subprogramId(): number {
    return this._subprogramId;
  }

  set subprogramId(value: number) {
    this._subprogramId = value;
  }
}
