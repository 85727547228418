import { v4 } from 'uuid';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserInfoService } from '../static/login/service/user-info.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private userInfoService: UserInfoService,
    private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request.url.startsWith('http') ? request.url : `${environment.baseUrl}${request.url}`;
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let headers = request.headers.set('x-correlation-id', v4());
    if (tz) {
      headers = headers.set('The-Timezone-IANA', tz);
    }
    const token = this.userInfoService.getToken();
    if (token) {
      headers = headers.set('Authorization', token);
      headers = headers.set('x-time-zone', (new Date().getTimezoneOffset() * -60).toString(10));
    }
    request = request.clone({
      withCredentials: true,
      url,
      headers
    });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.router.navigateByUrl('/login');
        }
        return throwError(() => error);
     }
    ));
  }
}
