import { Injectable } from '@angular/core';
import { TrainingType } from '../availability-training/model/training';

@Injectable({
  providedIn: 'root'
})
export class TrainingFilterService {
  private _filterByStartDate: string | null;
  private _filterByEndDate: string | null;
  private _filterByStatus: number;
  private _filterBySupervisorId: number;
  private _filterByType: TrainingType | null;

  constructor() {
    this.filterByStatus = null;
    this.filterBySupervisorId = null;
    this._filterByType = null;
  }

  get filterByStartDate(): string | null {
    return this._filterByStartDate;
  }

  set filterByStartDate(value: string | null) {
    this._filterByStartDate = value;
  }

  get filterByEndDate(): string | null {
    return this._filterByEndDate;
  }

  set filterByEndDate(value: string | null) {
    this._filterByEndDate = value;
  }

  get filterByStatus(): number {
    return this._filterByStatus;
  }

  set filterByStatus(value: number) {
    this._filterByStatus = value;
  }

  get filterBySupervisorId(): number {
    return this._filterBySupervisorId;
  }

  set filterBySupervisorId(value: number) {
    this._filterBySupervisorId = value;
  }

  get filterByType(): TrainingType | null {
    return this._filterByType;
  }

  set filterByType(value: TrainingType | null) {
    this._filterByType = value;
  }
}
