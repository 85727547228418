import { Pipe, PipeTransform } from '@angular/core';
import {Gender} from './gender.enum';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  transform(value: number | string, ...args: unknown[]): string {
    value = typeof value === 'string' ? parseInt(value, 10) : value;
    const res = Gender[value];
    return res ? res.toLowerCase() : null;
  }
}
