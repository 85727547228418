import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'list'
})
export class ListPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    if (Array.isArray(value)) {
      return value.join(', ');
    } else {
      return value.includes(',,')
        ? value.split(',,').join(', ')
        : value.includes(', ')
          ? value
          : value.split(',').join(', ');
    }
  }
}
