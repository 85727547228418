<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        E-mail message
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="wrapper">

      <div class="input-container container">
        <span class="overline-text">Heading</span>
        <input type="text">
      </div>


      <div class="input-container container">
        <span class="overline-text">Announcement Body</span>
        <textarea cols="30" id="" name="" rows="10"></textarea>
      </div>

      <div class="input-container container">
        <span class="overline-text">Button</span>
        <input type="text">
      </div>

      <div class="input-container container">
        <span class="overline-text">Picture</span>
        <section class="upload">
          <input disabled placeholder="Way to the file" type="text">
          <button class="secondary-button">Choose from computer</button>
        </section>
      </div>

      <div class="input-container container">
        <span class="overline-text">Action link</span>
        <input placeholder="Action link" type="text">
      </div>

      <div class="input-container container">
        <span class="overline-text">Appear date</span>
        <input placeholder="DD/MM/YYYY" type="text">
      </div>

      <div class="input-container container">
        <span class="overline-text">Disappear date</span>
        <input placeholder="DD/MM/YYYY" type="text">
      </div>

      <div class="input-container container">
        <span class="body-highlighted-text">Recipients</span>
        <section>
          <mat-checkbox class="example-margin">Student</mat-checkbox>
          <mat-checkbox class="example-margin">Volunteer</mat-checkbox>
        </section>
      </div>

    </div>
  </mat-expansion-panel>
</mat-accordion>
