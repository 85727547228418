<div class="table-navigation-container">
  <div class="left-side">
    <input type="text" class="search" placeholder="Search by name">
  </div>
  <div class="right-side">
    <button class="primary-button2" (click)="createLevel()">Create new level</button>
  </div>
</div>

<div class="table-container">

  <table mat-table [dataSource]="levels">

    <ng-container matColumnDef="image">
      <th class="overline-text" mat-header-cell *matHeaderCellDef> Image </th>
      <td class="body-regular-text" mat-cell *matCellDef="let element">
        <img class="table-img" src="../../../../assets/images/rocket.png" alt="">
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th class="overline-text" mat-header-cell *matHeaderCellDef>Title</th>
      <td class="body-regular-text" mat-cell *matCellDef="let element">{{element.title}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th class="overline-text" mat-header-cell *matHeaderCellDef>Description</th>
      <td class="body-regular-text" mat-cell *matCellDef="let element">{{element.description}}</td>
    </ng-container>

    <ng-container matColumnDef="hours">
      <th class="overline-text" mat-header-cell *matHeaderCellDef>Hours</th>
      <td class="body-regular-text" mat-cell *matCellDef="let element">{{element.startHours + ' - ' + element.endHours}}</td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th class="overline-text" mat-header-cell *matHeaderCellDef>  </th>
      <td class="body-regular-text" mat-cell *matCellDef="let element"><span class="material-icons">edit</span><span
        class="material-icons">delete</span> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[1,5,10,50]" showFirstLastButtons class="body-regular-text"></mat-paginator>
</div>
