<div class="panel">
  <div class="dialog-header">
    <h6 mat-dialog-title>{{ create ? 'Create source' : 'Edit source' }}</h6>
    <button class="secondary-button-icons close-button" (click)="close()">
      <span class="material-icons">close</span>
    </button>
  </div>
  <input type="text" placeholder="Source name" [(ngModel)]="source.name">
  <div class="section">
    <span class="body-regular-text">Source Type</span>
    <select class="select" [formControl]="parentControl">
      <option *ngFor="let sourceType of parents" [value]="sourceType.id">{{ sourceType.name }}</option>
    </select>
  </div>
  <div class="section">
    <span class="body-regular-text">User role</span>
    <select class="select" [(ngModel)]="source.userRole">
      <option [value]="100">Student</option>
      <option [value]="101">Volunteer</option>
      <option [value]="null">Doesn`t matter</option>
    </select>
  </div>
  <button class="primary-button" (click)="submit()">Submit</button>
</div>
