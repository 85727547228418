<div class="container">
  <div class="links m-b">
    <a [routerLink]="['/progress', id]" class="link">View Self-Evaluation Summary</a>
    <a [routerLink]="['/progress', id]" [queryParams]="{isVolunteerEvaluation: true}" class="link" *ngIf="isStudent">View Volunteer Evaluation Summary</a>
  </div>
  <ng-container *ngIf="mappedData.completed?.length">
    <div class="m-b body-regular-text">Completed</div>
    <div class="group m-b">
      <div class="item m-b" *ngFor="let item of mappedData.completed">
        {{item.desc}}
        <ng-container *ngIf="item.email">
          ({{item.email}})
        </ng-container>
        - {{item.timestamp | date:'MMM d, y'}}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="mappedData.upcomingAndOverdue?.length">
    <div class="m-b m-t body-regular-text line-top">Upcoming + Overdue</div>
    <div class="group m-b">
      <div class="item" *ngFor="let item of mappedData.upcomingAndOverdue" [class.overdue]="item.isOverdue">
        {{item.desc}}
        <ng-container *ngIf="item.email">
          ({{item.email}})
        </ng-container>
        - due {{item.timestamp | date:'MMM d, y'}}
      </div>
    </div>
  </ng-container>
</div>
