import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ParticipantService} from '../../../../service/participant.service';
import {NotifierService} from 'angular-notifier';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-edit-comment',
  templateUrl: './edit-comment.component.html',
  styleUrls: ['./edit-comment.component.scss']
})
export class EditCommentComponent implements OnInit {

  id: number;
  comment: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<MatDialogRef<string>>,
    private service: ParticipantService,
    private notifier: NotifierService
  ) {
  }

  ngOnInit(): void {
    this.id = this.data.id;
    this.comment = this.data.comment;
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.service.setComment(this.id, this.comment).subscribe(
      _ => {
        this.notifier.notify('success', 'Updated successfully');
        this.dialogRef.close();
      },
      (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
    );
  }
}
