import {Component} from '@angular/core';
import {LoginService} from '../../service/login.service';
import {Router} from '@angular/router';
import {UserInfoService} from '../../service/user-info.service';
import {HttpErrorResponse} from '@angular/common/http';
import {NotifierService} from 'angular-notifier';
import {UserRole} from '../../../constants/user-role/user-role.enum';
import {SignInResponse} from './sign-in-response';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  email: string;
  password: string;

  constructor(
    private loginService: LoginService,
    private userInfoService: UserInfoService,
    private router: Router,
    private notifier: NotifierService
  ) {
  }

  login(): void {
    this.loginService.signIn({
      email: this.email,
      password: this.password
    }).subscribe({
      next: (value: SignInResponse) => {
        this.userInfoService.setToken(value.token);
        this.userInfoService.setRole(value.role);
        this.router.navigateByUrl(value.role === UserRole.PARTNER ? '/participants' : '/dashboard');
      },
      error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message ?? 'Something went wrong')
    });
  }

}
