<!--<app-page-menu-bar></app-page-menu-bar>-->
<div class="table-navigation-container">
  <div class="left-side">
    <button class="primary-button" (click)="openInviteWindow()">invite member</button>
  </div>
  <div class="right-side">
    <label>
      <input type="text" placeholder="Search" class="search" [(ngModel)]="searchText" (ngModelChange)="refreshTable()">
    </label>
  </div>
</div>

<div class="table panel">
  <div class="table-container">

    <table mat-table [dataSource]="dataSource" class=" ">

      <ng-container matColumnDef="photo">
        <th class="overline-text" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <img class="avatar-img" src="" alt="">
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th class="overline-text" mat-header-cell *matHeaderCellDef> First name</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th class="overline-text" mat-header-cell *matHeaderCellDef> Last name</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element.lastName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th class="overline-text" mat-header-cell *matHeaderCellDef> E-mail</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th class="overline-text" mat-header-cell *matHeaderCellDef> Phone</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element.phone }}
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th class="overline-text" mat-header-cell *matHeaderCellDef> Role</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element.role | userRole | capitalCase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastVisit">
        <th class="overline-text" mat-header-cell *matHeaderCellDef> Last visit</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element.lastVisit === null ? 'Not active yet' : element.lastVisit * 1000 | date:'medium' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th class="overline-text" mat-header-cell *matHeaderCellDef> Features</th>
        <td class="body-regular-text options" mat-cell *matCellDef="let element">
          <span class="material-icons" (click)="delete(element.id)">delete</span>
          <span class="material-icons" (click)="openEditWindow(element)">edit</span>
          <span class="material-icons" (click)="openChangePasswordDialog(element.id)">key</span>
        </td>
      </ng-container>

      <tr class="overline-text" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="body-regular-text" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20, 50]"
      [pageIndex]="page"
      [pageSize]="size"
      [length]="totalElements"
      (page)="changePage($event)"

      showFirstLastButtons class="body-regular-text"></mat-paginator>
  </div>

</div>

<app-edit-member
  *ngIf="showEditWindow"
  [type]="editWindowType"
  [staff]="staff"
  [heading]="editWindowHeading"
  [submitButtonText]="editWindowSubmitButton"
  (onClose)="closeEditWindow($event)"
></app-edit-member>
