<div class="panel" [formGroup]="formGroup">
  <div class="dialog-header">
    <h6 mat-dialog-title>Freeze Account of {{ participantRole | userRole }}</h6>
    <button class="secondary-button-icons close-button" (click)="close()">
      <span class="material-icons">close</span>
    </button>
  </div>

  <mat-form-field *ngIf="!isAccountFrozen" appearance="outline" class="date-picker">
    <mat-label>Start date</mat-label>
    <input formControlName="startTime"
           class="no-global-input-styles"
           matInput
           required
           [matDatepicker]="startTimePicker" (click)="startTimePicker.open()">
    <mat-datepicker-toggle matSuffix [for]="startTimePicker"></mat-datepicker-toggle>
    <mat-datepicker #startTimePicker></mat-datepicker>
    <mat-hint *ngIf="formGroup.controls.startTime.touched && formGroup.controls.startTime.hasError('required')" class="error-text">Start date is required</mat-hint>
  </mat-form-field>

  <div *ngIf="isAccountFrozen">
    Freeze start date: {{ freezeStartDate | date:'MMM d, y, h:mm a' }}
  </div>

  <mat-form-field appearance="outline" class="date-picker" [class.mat-form-field-invalid]="formGroup.hasError('endDateLessThanStartDate') || formGroup.controls.endTime.touched && formGroup.controls.endTime.hasError('required')">
    <mat-label>End date</mat-label>
    <input formControlName="endTime"
           class="no-global-input-styles"
           matInput
           [required]="isAccountFrozen"
           [max]="maxDate"
           [matDatepicker]="endTimePicker" (click)="endTimePicker.open()">
    <mat-datepicker-toggle matSuffix [for]="endTimePicker"></mat-datepicker-toggle>
    <mat-datepicker #endTimePicker></mat-datepicker>
    <mat-hint *ngIf="formGroup.controls.endTime.touched && formGroup.controls.endTime.hasError('required')" class="error-text">End date is required</mat-hint>
    <mat-hint *ngIf="formGroup.hasError('endDateLessThanStartDate')" class="error-text">End date should be after start date</mat-hint>
  </mat-form-field>

  <mat-form-field *ngIf="isAccountFrozen" appearance="outline">
    <mat-label>New status</mat-label>
    <mat-select formControlName="newStatus" required panelClass="participant-status-options" class="no-global-input-styles">
      <mat-option *ngFor="let key of participantStatusKeys" [value]="key">{{ key | participantStatus }}</mat-option>
    </mat-select>
    <mat-hint *ngIf="formGroup.controls.newStatus.touched && formGroup.controls.newStatus.hasError('required')" class="error-text">New status is required</mat-hint>
  </mat-form-field>

  <div class="buttons-container" mat-dialog-actions>
    <button class="secondary-button" (click)="close()">Cancel</button>
    <button *ngIf="!isAccountFrozen" class="primary-button2" cdkFocusInitial [disabled]="formGroup.invalid" (click)="frozeAccount()">Save</button>
    <button *ngIf="isAccountFrozen" class="primary-button2" cdkFocusInitial [disabled]="formGroup.invalid" (click)="unfreezeAccount()">Save</button>
  </div>
</div>

