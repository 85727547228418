import {Pipe, PipeTransform} from '@angular/core';
import {English, StudentEnglishEstimated, VolunteerEnglishEstimated} from './english.enum';

@Pipe({
  name: 'english'
})
export class EnglishPipe implements PipeTransform {

  transform(value: number, ...args: string[]): string {
    let res = (
      args.length === 0 ? English[value] :
      args[0] === 'student' ? StudentEnglishEstimated[value] :
      args[0] === 'volunteer' ? VolunteerEnglishEstimated[value] :
      null
    );
    return res ? res.replace("_", " ") : "null"
  }
}
