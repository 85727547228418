<div class="container">
  <div class="student-and-volunteer-container">
    <div class="container-header">
      <div class="student-header header-block">
        <span class="material-icons" (click)="cancelStudentProfile()"
          >cancel</span
        >
        <h6 class="body-highlighted-text">Student</h6>
      </div>
      <button
        class="primary-button2"
        (click)="checkWarnings()"
        [ngClass]="{ 'in-active-primary-button': inactiveButton }"
      >
        Match
      </button>
      <div class="volunteer-header header-block">
        <h6 class="body-highlighted-text">Volunteer</h6>
        <span
          class="material-icons"
          (click)="cancelVolunteerProfile()"
          [ngClass]="{ 'disabled-span-color': inactiveButton }"
          >cancel</span
        >
      </div>
    </div>

    <div
      class="student-info panel"
      [ngClass]="{ inActivePanel: student === null }"
    >
      <div></div>

      <app-matching-profile
        [profile]="student"
        [profileQuestionsData]="studentProfileQuestionsData"
        [miniGroupPreference]="studentPreferences"
        [role]="UserRole.STUDENT"
        *ngIf="student; else studentDontShow"
      ></app-matching-profile>

      <ng-template class="no-profile dontShow" #studentDontShow>
        <div class="no-profile">
          <h6 class="body-regular-text">No student selected</h6>
          <span>Please select a student to matching.</span>
        </div>
      </ng-template>
    </div>

    <div
      class="volunteer-info panel"
      [ngClass]="{ inActivePanel: volunteer == null }"
    >
      <div></div>
      <app-matching-profile
        [matching]="volunteerMatching"
        [profile]="volunteer"
        [profileQuestionsData]="volunteerProfileQuestionsData"
        [miniGroupPreference]="volunteerPreferences"
        [role]="UserRole.VOLUNTEER"
        *ngIf="volunteer; else volunteerDontShow"
      ></app-matching-profile>
      <ng-template class="no-profile dontShow" #volunteerDontShow>
        <div class="no-profile">
          <h6 class="body-regular-text">No volunteer selected</h6>
          <span>Please select a volunteer to matching.</span>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="accordions-container">
    <mat-expansion-panel *ngIf="students">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="body-highlighted-text">Students</span>
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="inputs-container">
        <input
          type="text"
          placeholder="Search"
          [(ngModel)]="studentSearch"
          (ngModelChange)="searchStudents()"
        />
        <select
          [(ngModel)]="studentAge"
          (ngModelChange)="getStudentsPage(0, volunteer?.id)"
        >
          <option [value]="null">All ages</option>
          <option *ngFor="let option of studentAges" [value]="option">
            {{ option }}
          </option>
        </select>
        <select
          [(ngModel)]="selectedSubprogramStudents"
          (change)="getStudentsPage(0, volunteer?.id)"
        >
          <option [ngValue]="null">All subprograms</option>
          <option *ngFor="let sub of subprograms" [value]="sub.id">
            {{ sub.name }}
          </option>
        </select>
        <select
          [(ngModel)]="studentsProgramFormat"
          (ngModelChange)="getStudentsPage(0, volunteer?.id)"
        >
          <option [ngValue]="null">All mini-group preferences</option>
          <option
            *ngFor="let option of programFormatOptions"
            [value]="option.id"
          >
            {{ option.value }}
          </option>
        </select>
        <select
          [(ngModel)]="studentsEnglishLevel"
          (ngModelChange)="getStudentsPage(0, volunteer?.id)"
        >
          <option [ngValue]="null">All English levels</option>
          <option
            *ngFor="let option of englishLevelOptions"
            [value]="option.id"
          >
            {{ option.value }}
          </option>
        </select>
      </div>

      <app-matching-table
        *ngIf="students"
        (cardClick)="getStudent($event)"
        (pageChanged)="getStudentsPage($event, volunteer?.id)"
        [data]="students"
        [length]="totalStudents"
      ></app-matching-table>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="volunteers">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="body-highlighted-text">Volunteers</span>
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="inputs-container">
        <input
          type="text"
          placeholder="Search"
          [(ngModel)]="volunteerSearch"
          (ngModelChange)="searchVolunteers()"
        />
        <select
          [(ngModel)]="volunteerAge"
          (change)="getVolunteersPage(0, student?.id)"
        >
          <option [value]="null">All ages</option>
          <option *ngFor="let option of volunteerAges" [value]="option">
            {{ option }}
          </option>
        </select>
        <select
          [(ngModel)]="selectedSubprogramVolunteers"
          (change)="getVolunteersPage(0, student?.id)"
        >
          <option [value]="null">All subprograms</option>
          <option *ngFor="let sub of subprograms" [value]="sub.id">
            {{ sub.name }}
          </option>
        </select>
        <select
          [(ngModel)]="volunteersProgramFormat"
          (ngModelChange)="getVolunteersPage(0, volunteer?.id)"
        >
          <option [ngValue]="null">All mini-group preferences</option>
          <option
            *ngFor="let option of programFormatOptions"
            [value]="option.id"
          >
            {{ option.value }}
          </option>
        </select>
        <select
          [(ngModel)]="volunteersEnglishLevel"
          (ngModelChange)="getVolunteersPage(0, volunteer?.id)"
        >
          <option [ngValue]="null">All English levels</option>
          <option
            *ngFor="let option of englishLevelOptions"
            [value]="option.id"
          >
            {{ option.value }}
          </option>
        </select>
      </div>

      <app-matching-table
        *ngIf="volunteers"
        (cardClick)="getVolunteer($event)"
        (pageChanged)="getVolunteersPage($event, student?.id)"
        [data]="volunteers"
        [length]="totalVolunteers"
      ></app-matching-table>
    </mat-expansion-panel>
  </div>
  <div class="panel">
    <table mat-table [dataSource]="subprograms" *ngIf="subprograms">
      <ng-container matColumnDef="subProgramName">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>
          SubProgram
        </th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="subProgramColor">
        <th class="overline-text" mat-header-cell *matHeaderCellDef>Color*</th>
        <td class="body-regular-text" mat-cell *matCellDef="let element">
          <div
            class="sub-program-color"
            [style.background-color]="element.color"
          ></div>
        </td>
      </ng-container>

      <tr
        class="overline-text"
        mat-header-row
        *matHeaderRowDef="columnNames"
      ></tr>
      <tr
        class="body-regular-text"
        mat-row
        *matRowDef="let row; columns: columnNames"
      ></tr>
    </table>
  </div>
</div>
