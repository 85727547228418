import {Component, OnInit} from '@angular/core';

export interface interviewParticipant {
  image: string;
  subject: string;
  heading: string;
  body: string;
  recipient: string;
  script: string;
  lastUpdate: string;
  options: string;
}

const ELEMENT_DATA: interviewParticipant[] = [
  {
    image: '😥',
    subject: 'Registration results',
    heading: 'Lorem ipsum dolor sit amet',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent placerat leo in erat iaculis, sit amet varius odio facilisis.  Mauris suscipit...;',
    recipient: 'Student, Volunteer',
    script: 'Immediately',
    lastUpdate: 'Oct 23, 2020 16:00, Olive Green',
    options: '',
  }
];

@Component({
  selector: 'app-notifications-list-system',
  templateUrl: './notifications-list-system.component.html',
  styleUrls: ['./notifications-list-system.component.scss']
})
export class NotificationsListSystemComponent implements OnInit {
  displayedColumns: string[] = ['image', 'subject', 'heading', 'body', 'recipient', 'script', 'lastUpdate', 'options'];
  dataSource = ELEMENT_DATA;

  constructor() {
  }

  ngOnInit(): void {
  }

}
